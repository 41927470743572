import { React, useState, useEffect, useRef } from "react";
import Navbars from "./navbar";
import Sidebar from "./sidebar";
import { useNavigate } from "react-router-dom";
import actualbalance from "./images/actual_balance-01.png";
import walletbalance from "./images/wallet_balance-01.png";
import deposit from './images/deposit-01.png';
import pay from './images/wallet_icons/Pay-01.png';
import withdrawl from './images/withdrawl-01.png';
import wallet from './images/wallet_to_wallet-01.png';
import deposits from './images/deposit_funds-01.png';
import withdrawls from './images/withdraw_funds-01.png';
import wallettowallet from './images/wallet_to_wallet_transfer-01.png';
import down1 from './images/down_arrow-01.png';
import exportpdf from './images/folder-export-01.png';
import Modal from 'react-bootstrap/Modal';
import copy from './images/Icons (1)/Copy-01.png';
import qrcode from './images/275.jpg';
import sharee from './images/Icons (1)/share-01.png';
import closeicon from './images/times-circle-01.png';
import leftarrow from './images/arrow-left-01.png';
import user from './images/user-3.jpg';
import mastercard from './images/wallet_icons/Master_Card-01.png';
import angle from './images/angle_down-01.png';
import scanqr from './images/Icons (1)/Scan_qr-01.png';
import edit from './images/edit-02.png';
import eye from './images/eye-01.png';
import eyeslash from './images/eye_slash-01.png';
import info1 from './images/Icons (1)/info-01.png';
import './transactions.css';
import process from './images/Payment_Process.gif';
import success from './images/Payment_Successful (1).gif';
import angleorange from './images/wallet_icons/angle-down-circle-01.png';
import currancy from './images/wallet_icons/Currency-01.png';
function Transactions() {
    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState("All");

    const handleItemClick = (item) => {
        setSelectedItem(item);
    };
    const [actualbal, setActualbal] = useState(false);
    const angledown = () => {
        setActualbal(!actualbal);
        setActualbal1(false);
    }
    const [actualbal1, setActualbal1] = useState(false);
    const angledown1 = () => {
        setActualbal1(!actualbal1);
        setActualbal(false);
    }
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const closeIcon = () => {
        setShow(false);
        
    }
    const depositfunds = () => {
        setShow(true)
    }
    const [show1, setShow1] = useState(false);
    const withdrawlfunds = () => {
        setShow1(true);
    }
    const handleCloses = () => {
        setShow1(false);
        setAmount('');
    } 
    const [amount, setAmount] = useState('');


    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setAmount(inputValue);
    };
    const getButtonClass = () => {
        return amount > 0 ? 'withdrawamounbutton active1' : 'withdrawamounbutton';
    };
    const closedialog1 = () => {
            setShow2(false);
            setShow1(false)
            setAmount('');
    }
    const back = () => {
        if (enterwallet === "") {
            setShow3(false);
            setShow1(true);
            setShow2(false)
        } else {
            setEnterwallet('');
        }
    }
    const withdrawqr = () => {
        if (amount > 0) {
            setShow2(true);
            setAmount('');
        }

        setShow1(false)
    }
    const [show2, setShow2] = useState(false);
    const handleCloses1 = () => setShow2(false);
    const [enterwallet, setEnterwallet] = useState('');
    const getButtonClass1 = () => {
        return enterwallet > " " ? 'withdrawamounbutton active' : 'withdrawamounbutton';
    };
    const handleInputChange1 = (e) => {
        const inputValue = e.target.value;
        setEnterwallet(inputValue);
    };
    const closedialog2 = () => {
        setShow2(false);
        setEnterwallet('');
    }
    const [show3, setShow3] = useState(false);
    const closedialog3 = () => {
        setShow3(false);
    }
    const handleCloses2 = () =>
     {
        setEnterwallet('');
        setShow3(false);
     }
    const withdrawqr1 = () => {
        if (enterwallet > "") {
            setShow3(true);
            setEnterwallet("")
        }
        setShow2(false)
    }
    const back1 = () => {
        setShow2(true);
        setShow3(false);
    }
    const [isEditable, setIsEditable] = useState(false);
    const [inputValue, setInputValue] = useState('UGX 1000'); // Set your initial value here

    const toggleEdit = () => {
        setIsEditable(!isEditable);
    };
    const handleInputChange4 = (e) => {
        setInputValue(e.target.value);
    };
    const withdrawqr2 = () => {
        setShow3(false);
        setShow4(true);
    }
    const [show4, setShow4] = useState(false);
    const handleCloses3 = () =>
     { 
        
        setShow4(false);
     }  
    const closedialog4 = () => {
        setShow4(true);
        setenterpasscode('');
    }
    const back2 = () => {
        if (enterpasscode === "") {
            setShow4(false);
            setShow3(true);
            setShow5(false)
        } else {
            setenterpasscode('')
        }

    }
    const [passcode, setPasscode] = useState('');
    const togglePasswordVisibility = () => {
        setPasscode(!passcode);
    };
    const [enterpasscode, setenterpasscode] = useState('');
    const withdrawqr3 = () => {
        if (enterpasscode !== "") {
            setShow4(false); // Close Modal 4
            setShow5(true);
            setTimeout(() => {
                setApiStatus('processing');
                setTimeout(() => {
                    setApiStatus('success');
                }, 2000)
            }, 10000);
        }
    };

    const getButtonClass2 = () => {

        return enterpasscode > " " ? 'withdrawamounbutton active ' : 'withdrawamounbutton ';
    };
    const getButtonClass3 = () => {
        return enterpasscode > " " ? ' hide' : "agentamountp";
    }
    const [showpasscode, setShowpasscode] = useState(false);
    const handleInputChange5 = (e) => {
        setenterpasscode(e.target.value);
    }
    const togglePasswordVisibility1 = () => {
        setShowpasscode(!showpasscode);
    }
    const [show5, setShow5] = useState(false);
    const handleCloses4 = () => setShow5(false);
    const [apiStatus, setApiStatus] = useState('processing');

    const donesuccess = () => {
        setShow5(false);
    }

    const fundsBoxRef = useRef(null);


    // Function to handle resizing and scrolling
    const handleResize = () => {
        if (window.innerWidth >= 768 && fundsBoxRef.current) {
            // Scroll the funds box to a specific position (520 pixels from the top)
            fundsBoxRef.current.scrollTop = 100;
        }
    };

    useEffect(() => {
        // Add an event listener to the window's resize event
        window.addEventListener('resize', handleResize);

        // Call the handleResize function immediately to check the width on mount
        handleResize();

        // Remove the listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    // transfers

    const [show10, setShow10] = useState(false);


    const walletfunds = () => {
        setShow10(true);
    }
    const handleCloses10 = () => {
        setShow10(false)
    }

    // pay

    const [show6, setShow6] = useState(false);
    const [enterwalletid, setEnterwalletid] = useState('');
    const paynow = () => {
        setShow6(true);
    }
    const back6 = () => {
        if (!enterwalletid) {
            setShow6(false);
        }
        setEnterwalletid('');
    }
    const handleCloses6 = () => {
            setShow6(false);
        setEnterwalletid('');
    }
    const closedialog6 = () => {
            setShow6(false);
        setEnterwalletid('');
    }
    const handleInputChange6 = (e) => {
        setEnterwalletid(e.target.value);
    }
    const getButtonClass6 = () => {
        return enterwalletid > " " ? 'withdrawamounbutton active' : 'withdrawamounbutton';
    };
    const withdrawqr6 = () => {
        setShow7(true);
        setShow6(false);
    }
    const [show7, setShow7] = useState(false);
    const [amount7, setAmount7] = useState('');
    const handleCloses7 = () => {
        setShow7(false);
        setAmount7('');
        setEnterwalletid('');
    }
    const back7 = () => {
        if (!amount7) {
            setShow7(false);
            setShow6(true);
        }
        setAmount7('');
        setEnterwalletid('');
    }
    const closedialog7 = () => {
        setShow7(false);
        setAmount7('');
        setEnterwalletid('');
    }
    const getButtonClass7 = () => {
        return amount7 > " " ? 'withdrawamounbutton active' : 'withdrawamounbutton';
    };
    const withdrawqr7 = () => {
        setShow7(false);
        setShow8(true);
    }
    const [show8, setShow8] = useState(false);
    const [enterpasscode8, setEnterpasscode8] = useState('');
    const handleCloses8 = () => {
        setShow8(false);
        setEnterwalletid('');
        setEnterpasscode8('');
        setAmount7('');
    }
    const back8 = () => {
        if (!enterpasscode8) {
            setShow8(false);
            setShow7(true);
        }
        setEnterwalletid('');
        setEnterpasscode8('');
        setAmount7('');
    }
    const closedialog8 = () => {
        setShow8(false);
        setEnterwalletid('');
        setEnterpasscode8('');
        setAmount7('');
    }
    const handleInputChange8 = (e) => {
        setEnterpasscode8(e.target.value);
    }
    const getButtonClass8 = () => {
        return enterpasscode8 > " " ? ' hide' : "agentamountp";
    }
    const getButtonClasss8 = () => {
        return enterpasscode8 > " " ? 'withdrawamounbutton active ' : 'withdrawamounbutton ';
    }
    const withdrawqr8 = () => {
        setShow8(false);
        setShow9(true);
        if (enterpasscode8 !== "") {
            setShow8(false); // Close Modal 4
            setShow9(true);
            setTimeout(() => {
                setApiStatus('processing');
                setTimeout(() => {
                    setApiStatus('success');
                }, 2000)
            }, 10000);
        }
    }
    const [show9, setShow9] = useState(false);
    const handleCloses9 = () => {
        setShow9(false);
    }
    const paynowsuccess = () => {
        setShow9(false);
        setEnterwalletid('');
        setEnterpasscode8('');
        setAmount7('');
    }
    const addsources = () => {
        navigate('/Wallet/Addsources');
        localStorage.setItem('activeSection', 'cards');
    }
    const [show26, setShow26] = useState(false);
    const currancyselect = () => {
        setShow26(true);
    }
    const handleCloses26 = () => {
        setShow26(false);
    }
    const proc = () => {
        setShow26(false);
    }
    const dtransfer = () => {
        navigate('/Wallet/D-Transfer');
        localStorage.setItem('activeSection', 'collection');
    }
    const ctransfer = () => {
        navigate('/Wallet/C-Transfer');
        localStorage.setItem('activeSection', 'purchase');
    }
    const qrtrans = () => {
        navigate('/Wallet/QR-Transactions');
        localStorage.setItem('activeSection', 'agentrecord');
    }
    const viewtransaction = () => {
        navigate('/Wallet/QR-Transactions');
        localStorage.setItem('activeSection', 'agentrecord');
    }
    const [deporange, setDeporange] = useState(false);
    const depositwall = (e) => {
        e.stopPropagation();
        setDeporange(!deporange);
        setDepgreen(false);
    }
    const [depgreen, setDepgreen] = useState(false);
    const withwall = (e) => {
        e.stopPropagation();
        setDepgreen(!depgreen);
        setDeporange(false);
    }
    return (
        <>
            <Navbars />
            <div className="container-fluid bg-white transactions">
                <div className="row transactionrow">
                    <div className="col-12 col-md-3 col-lg-3 transactionside">
                        <Sidebar />
                    </div>
                    <div className="col-12 col-md-9 col-lg-9 transactionmain ">
                        <div className="text-center">
                            <h4 style={{ fontWeight: '500', fontSize: '24px', verticalAlign: 'Cap-height', lineHeight: '36px', color: '#232323' }}>Wallet</h4>
                            <p style={{ fontWeight: '400', fontSize: '16px', verticalAlign: 'Cap-height', lineHeight: '24px', color: '#232323' }}>Manage your Wallet details</p>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="box p-3 wallet-balancebox" style={{ backgroundColor: 'white', width: '100%', maxWidth: '426px', border: '1px solid #ABA7A7', borderRadius: '18px' }}>
                                        <div className="d-flex wallet-balpad flex-column ps-4 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <h6 className="text-start wallet-balanceboxh6 fw-800 mt-0" style={{ fontWeight: '600', fontSize: '20px', verticalAlign: 'Cap-height', lineHeight: '30px', color: '#232323' }}>Wallet Balance</h6>
                                                <div>
                                                    <p className="curp">Default Currency</p>
                                                    <select onClick={currancyselect} className="form-select curselect">
                                                        <option>UGX</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="d-flex mt-3">
                                                <img className="me-md-3  mb-md-0 wallet-balanceboxactualimg" src={actualbalance} alt="actual balance" width="43px" height="45px" />
                                                <div className="d-flex wallet-balpad ps-3 flex-column text-start  ml-md-2">
                                                    <div className='walbal'>
                                                        <p className="wallet-balanceboxp" style={{ color: '#7D7878', fontSize: '14px', fontWeight: '400', verticalAlign: 'Cap-height', lineHeight: '21px', marginBottom: '0px' }}>Actual balance</p>
                                                        <img src={angle} alt="down" className={` angle ${actualbal ? 'angle-expanded' : 'angle-collapsed'}`} onClick={angledown}></img>
                                                    </div>
                                                    <p className="wallet-balanceboxp2 mb-1" style={{ color: '#02CD8D', fontSize: '20px', fontWeight: '600', verticalAlign: 'Cap-height', lineHeight: '30px', marginBottom: '0px' }}>UGX 37, 420</p>
                                                    {actualbal && (
                                                        <div className="walp d-flex" style={{ width: '100%' }}>
                                                            <div className="txet">
                                                                <p >Available Balance :</p>
                                                                <p>Gift cards :</p>
                                                                <p>Refunds : </p>
                                                            </div>
                                                            <div className="txet">
                                                                <p>UGX 30,000</p>
                                                                <p>UGX 5,000</p>
                                                                <p>UGX 2,420</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="wallet-balanceboxhr " style={{ color: '#ABA7A7', marginTop: '8px' }} />
                                        <div className="d-flex flex-column wallet-balpad ps-4">
                                            <div className="d-flex mt-2">
                                                <img className="me-md-3  mb-md-0 wallet-balanceboxwalletimg" src={walletbalance} alt="actual balance" width="39px" height="43px" />
                                                <div className="d-flex ps-3 wallet-balpad flex-column text-start ml-md-2">
                                                    <div className='walbal'>
                                                        <p className="wallet-balanceboxp" style={{ color: '#7D7878', fontSize: '14px', fontWeight: '400', verticalAlign: 'Cap-height', lineHeight: '21px', marginBottom: '0px' }}>Actual balance</p>
                                                        <img src={angle} alt="down" className={` angle ${actualbal1 ? 'angle-expanded' : 'angle-collapsed'}`} onClick={angledown1} ></img>
                                                    </div>
                                                    <p className="wallet-balanceboxp2 mb-1" style={{ color: '#F77F00', fontSize: '20px', fontWeight: '600', verticalAlign: 'Cap-height', lineHeight: '30px', marginBottom: '0px' }}>UGX 12, 000</p>
                                                    {actualbal1 && (
                                                        <div className="walp d-flex" style={{ width: '100%' }}>
                                                            <div className="txet">
                                                                <p >Cash  : </p>
                                                                <p>Coupons :</p>
                                                            </div>
                                                            <div className="txet">
                                                                <p> UGX 6,000</p>
                                                                <p>UGX 6,000</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="wallet-balanceboxhr1 " style={{ color: '#ABA7A7', marginTop: '27px' }} />
                                    </div>
                                    <hr className="wallet-balanceboxhr2" style={{ color: '#ABA7A7' }}></hr>
                                    <div className="box p-4  mt-3 fundsbox " ref={fundsBoxRef} style={{ backgroundColor: 'white', width: '100%', maxWidth: '426px', minHeight: '421px', border: '1px solid #ABA7A7', borderRadius: '18px', overflow: 'hidden' }}>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h5 className="text-start fw-800 mt-2 fundsboxh5" style={{ color: '#232323', fontSize: '20px', fontWeight: '600', verticalAlign: 'Cap-height', lineHeight: '30px' }}>Actions</h5>
                                            <button className="addsources" onClick={addsources}>+ Add Sources</button>
                                        </div>
                                        <div className="d-flex fundboxuttons flex-column">
                                            <div className=" mt-2">
                                                <button onClick={depositfunds} style={{ position: 'relative' }} className=" wallettowallet">
                                                    <div className="butimag">
                                                        <div className="__col">
                                                            <img src={deposits} alt="deposits" width="41.68px" height="33px" />
                                                        </div>
                                                        <span className="__col" >Deposit Funds</span>
                                                    </div>
                                                    <p className="" onClick={depositwall} style={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer', borderRadius: ' 12px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '77px', height: '100%', backgroundColor: '#BD5103' }}><img src={angleorange} className={` angle1 ${deporange ? 'angle-expanded1' : 'angle-collapsed1'}`} alt="angle" width='24px' height='24px'></img></p>
                                                </button>
                                                {deporange && (
                                                    <div className="deporange p-3">
                                                        <div className="d-flex justify-content-between align-items-center mb-3 pt-2">
                                                            <div className="text-start">
                                                                <h6>Direct Deposit</h6>
                                                                <p className="mb-0">Deposit to Wallet With EVzone Agent</p>
                                                            </div>
                                                            <img src={down1} alt="down" className="down1img"></img>
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-center mb-3 pt-2">
                                                            <div className="text-start">
                                                                <h6>Bank Deposit</h6>
                                                                <p className="mb-0">Transfer From Bank to wallet</p>
                                                            </div>
                                                            <img src={down1} alt="down" className="down1img"></img>
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-center mb-3 pt-2">
                                                            <div className="text-start">
                                                                <h6>Card Deposit</h6>
                                                                <p className="mb-0">Transfer From Card to Wallet</p>
                                                            </div>
                                                            <img src={down1} alt="down" className="down1img"></img>
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-center mb-3 pt-2">
                                                            <div className="text-start">
                                                                <h6>Mobile Money</h6>
                                                                <p className="mb-0">Transfer from Mobile Money to Wallet</p>
                                                            </div>
                                                            <img src={down1} alt="down" className="down1img"></img>
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-center mb-1 pt-2">
                                                            <div className="text-start">
                                                                <h6>Paypal Deposit</h6>
                                                                <p className="mb-0">Transfer from Paypal to Wallet</p>
                                                            </div>
                                                            <img src={down1} alt="down" className="down1img"></img>
                                                        </div>
                                                    </div>
                                                )}
                                                <button onClick={withdrawlfunds} style={{ position: 'relative' }} className=" withdrawls mt-3 ">
                                                    <div className="butimag">
                                                        <div className="__col">
                                                            <img src={withdrawls} alt="withdrawls" width="41.68px" height="33px" style={{ marginTop: '5%' }} />
                                                        </div>
                                                        <span className="__col">Withdrawal Funds</span>
                                                    </div>
                                                    <p className="" onClick={withwall} style={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer', borderRadius: ' 12px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '77px', height: '100%', backgroundColor: '#00A154' }}><img className={` mb-0 angle1 ${depgreen ? 'angle-expanded1' : 'angle-collapsed1'}`} src={angleorange} alt="angle" width='24px' height='24px'></img></p>
                                                </button>
                                                {depgreen && (
                                                    <div className="depgreen p-3">
                                                        <div className="d-flex justify-content-between align-items-center mb-3 pt-2">
                                                            <div className="text-start">
                                                                <h6>Direct Deposit</h6>
                                                                <p className="mb-0">Deposit to Wallet With EVzone Agent</p>
                                                            </div>
                                                            <img src={down1} alt="down" className="down1img"></img>
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-center mb-3 pt-2">
                                                            <div className="text-start">
                                                                <h6>Bank Deposit</h6>
                                                                <p className="mb-0">Transfer From Bank to wallet</p>
                                                            </div>
                                                            <img src={down1} alt="down" className="down1img"></img>
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-center mb-3 pt-2">
                                                            <div className="text-start">
                                                                <h6>Card Deposit</h6>
                                                                <p className="mb-0">Transfer From Card to Wallet</p>
                                                            </div>
                                                            <img src={down1} alt="down" className="down1img"></img>
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-center mb-3 pt-2">
                                                            <div className="text-start">
                                                                <h6>Mobile Money</h6>
                                                                <p className="mb-0">Transfer from Mobile Money to Wallet</p>
                                                            </div>
                                                            <img src={down1} alt="down" className="down1img"></img>
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-center mb-1 pt-2">
                                                            <div className="text-start">
                                                                <h6>Paypal Deposit</h6>
                                                                <p className="mb-0">Transfer from Paypal to Wallet</p>
                                                            </div>
                                                            <img src={down1} alt="down" className="down1img"></img>
                                                        </div>
                                                    </div>
                                                )}
                                                <button onClick={walletfunds} className=" deposits  mt-3 ">
                                                    <div className="butimag">
                                                        <div className="__col">
                                                            <img src={wallettowallet} className="" alt="wallettowallet" width="40px" height="40px" />
                                                        </div>
                                                        <span className="__col">Transfers</span>
                                                    </div>
                                                </button>
                                                <button onClick={paynow} className="pay mt-3 ">
                                                    <div className="butimag">
                                                        <div className="__col">
                                                            <img src={pay} className="me-2" alt="wallettowallet" width="40px" height="40px" />
                                                        </div>
                                                        <span className="__col">Pay Now</span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="wallet-balanceboxhr3" style={{ color: '#ABA7A7', display: 'none' }}></hr>
                                <div className="col-12 col-md-6 ">
                                    <div className="table-responsive">
                                        <div className="box transaction-box p-3 ">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h6 className="text-start transactionh6" style={{ fontWeight: '600', fontSize: '20px', verticalAlign: 'Cap-height', lineHeight: '30px' }}>Transaction History</h6>
                                                <button className="viewtransaction"  onClick={viewtransaction}>View transaction history</button>
                                            </div>
                                            <div className="payment-history-container overflow-auto" style={{ maxHeight: "100px", width: '100%', maxWidth: '551px', }}>
                                                <ul className="list-unstyled d-flex ">
                                                    <li style={{ fontWeight: '500', fontSize: '16px', verticalAlign: 'Cap-height', lineHeight: '24px', color: '#7D7878' }}
                                                        className={`me-3  ${selectedItem === "All" ? "selected-item" : ""}`}
                                                        onClick={() => handleItemClick("All")}
                                                    >
                                                        All
                                                    </li>
                                                    <li style={{ fontWeight: '500', fontSize: '16px', verticalAlign: 'Cap-height', lineHeight: '24px', color: '#7D7878' }}
                                                        className={`me-3  ${selectedItem === "Deposit" ? "selected-item" : ""}`}
                                                        onClick={() => handleItemClick("Deposit")}
                                                    >
                                                        Deposits
                                                    </li>
                                                    <li style={{ fontWeight: '500', fontSize: '16px', verticalAlign: 'Cap-height', lineHeight: '24px', color: '#7D7878' }}
                                                        className={`me-3  ${selectedItem === "Withdrawal" ? "selected-item" : ""}`}
                                                        onClick={() => handleItemClick("Withdrawal")}
                                                    >
                                                        Withdraws
                                                    </li>
                                                    {/* <li style={{ fontWeight: '500', fontSize: '16px', verticalAlign: 'Cap-height', lineHeight: '24px', color: '#000000' }}
                                                    className={`me-3 flex-grow-1 ${selectedItem === "Payouts" ? "selected-item" : ""}`}
                                                    onClick={() => handleItemClick("Payouts")}
                                                >
                                                    Payouts
                                                </li>
                                                <li style={{ fontWeight: '500', fontSize: '16px', verticalAlign: 'Cap-height', lineHeight: '24px', color: '#000000' }}
                                                    className={`flex-grow-1 ${selectedItem === "Collections" ? "selected-item" : ""}`}
                                                    onClick={() => handleItemClick("Collections")}
                                                >
                                                    Collections
                                                </li> */}
                                                </ul>
                                            </div>
                                            <div className="d-flex mt-4 align-items-center">
                                                <div className="mb-3" >
                                                    <img
                                                        className="me-md-3 me-sm-3 me-lg-2 mb-md-0 transactionwallet"
                                                        src={deposit} // Replace 'yourImageSourceHere' with the actual image source URL
                                                        alt="wallet"
                                                        width="42px"
                                                        height="42px"
                                                    />
                                                </div>

                                                <div className="d-flex  flex-fill ml-md-2 text-start">
                                                    <img src={user} className="me-2 rounded-circle" alt="aj" width="24px" height="24px"></img>
                                                    <div>
                                                        <p style={{ fontWeight: '600', fontSize: '16px', verticalAlign: 'Cap-height', lineHeight: '24px', color: '#292929' }}>Deposit</p>
                                                        <p style={{ fontWeight: '400', fontSize: '14px', verticalAlign: 'Cap-height', lineHeight: '21px', color: '#7D7878' }} >Bank transfer</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column flex-fill ml-md-2 text-end">

                                                    <p className="transactionp" style={{ fontWeight: '600', fontSize: '16px', verticalAlign: 'Cap-height', lineHeight: '24px', color: '#292929' }}>UGX 3000</p>
                                                    <p className="transactionp1" style={{ fontWeight: '400', fontSize: '14px', verticalAlign: 'Cap-height', lineHeight: '21px', color: '#7D7878' }}>20.05.2023</p>
                                                </div>
                                            </div>
                                            <hr className="mb-0 mt-0" style={{ marginLeft: '13%', color: '#ABA7A7' }}></hr>
                                            <div className="d-flex mt-2 align-items-center ">
                                                <div className="mb-3" >
                                                    <img
                                                        className="me-md-3 me-sm-3 me-lg-2 mb-md-0 transactionwallet"
                                                        src={withdrawl} // Replace 'yourImageSourceHere' with the actual image source URL
                                                        alt="wallet"
                                                        width="42px"
                                                        height="42px"
                                                    />
                                                </div>
                                                <div className="d-flex  flex-fill ml-md-2 text-start">
                                                    <img src={user} className="me-2 rounded-circle" alt="aj" width="24px" height="24px"></img>
                                                    <div >
                                                        <p className="transactionp" style={{ fontWeight: '600', fontSize: '16px', verticalAlign: 'Cap-height', lineHeight: '24px', color: '#292929' }}>Withdrawal</p>
                                                        <p className="transactionp1" style={{ fontWeight: '400', fontSize: '14px', verticalAlign: 'Cap-height', lineHeight: '21px', color: '#7D7878' }}>Bank transfer</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column flex-fill ml-md-2 text-end">
                                                    <p className="transactionp" style={{ fontWeight: '600', fontSize: '16px', verticalAlign: 'Cap-height', lineHeight: '24px', color: '#292929' }}>UGX 3000</p>
                                                    <p className="transactionp1" style={{ fontWeight: '400', fontSize: '14px', verticalAlign: 'Cap-height', lineHeight: '21px', color: '#7D7878' }}>20.05.2023</p>
                                                </div>
                                            </div>
                                            <hr className="mb-0 mt-0" style={{ marginLeft: '13%', color: '#ABA7A7' }}></hr>
                                            <div className="d-flex mt-2 align-items-center">
                                                <div className="mb-3" >
                                                    <img
                                                        className="me-md-3 me-sm-3 me-lg-2  mb-md-0 transactionwallet"
                                                        src={wallet} // Replace 'yourImageSourceHere' with the actual image source URL
                                                        alt="wallet"
                                                        width="42px"
                                                        height="42px"
                                                    />
                                                </div>
                                                <div className="d-flex  flex-fill ml-md-2 text-start">
                                                    <img src={user} className="me-2 rounded-circle" alt="aj" width="24px" height="24px"></img>
                                                    <div>
                                                        <p className="transactionp" style={{ fontWeight: '600', fontSize: '16px', verticalAlign: 'Cap-height', lineHeight: '24px', color: '#292929' }}>Wallet to Wallet</p>
                                                        <p className="transactionp1" style={{ fontWeight: '400', fontSize: '14px', verticalAlign: 'Cap-height', lineHeight: '21px', color: '#7D7878' }}>Bank transfer</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column flex-fill ml-md-2 text-end">
                                                    <p className="transactionp" style={{ fontWeight: '600', fontSize: '16px', verticalAlign: 'Cap-height', lineHeight: '24px', color: '#292929' }}>UGX 3000</p>
                                                    <p className="transactionp1" style={{ fontWeight: '400', fontSize: '14px', verticalAlign: 'Cap-height', lineHeight: '21px', color: '#7D7878' }}>20.05.2023</p>
                                                </div>
                                            </div>
                                            <hr className="mb-0 mt-0" style={{ marginLeft: '13%', color: '#ABA7A7' }}></hr>
                                            <div className="d-flex mt-2 align-items-center">
                                                <div className="mb-3" >
                                                    <img
                                                        className="me-md-3 me-lg-2 me-sm-3 mb-md-0 transactionwallet"
                                                        src={deposit} // Replace 'yourImageSourceHere' with the actual image source URL
                                                        alt="wallet"
                                                        width="42px"
                                                        height="42px"
                                                    />
                                                </div>
                                                <div className="d-flex  flex-fill ml-md-2 text-start">
                                                    <img src={user} className="me-2 rounded-circle" alt="aj" width="24px" height="24px"></img>
                                                    <div>
                                                        <p className="transactionp" style={{ fontWeight: '600', fontSize: '16px', verticalAlign: 'Cap-height', lineHeight: '24px', color: '#292929' }}>Deposit</p>
                                                        <p className="transactionp1" style={{ fontWeight: '400', fontSize: '14px', verticalAlign: 'Cap-height', lineHeight: '21px', color: '#7D7878' }}>Bank transfer</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column flex-fill ml-md-2 text-end">
                                                    <p className="transactionp" style={{ fontWeight: '600', fontSize: '16px', verticalAlign: 'Cap-height', lineHeight: '24px', color: '#292929' }}>UGX 3000</p>
                                                    <p className="transactionp1" style={{ fontWeight: '400', fontSize: '14px', verticalAlign: 'Cap-height', lineHeight: '21px', color: '#7D7878' }}>20.05.2023</p>
                                                </div>
                                            </div>
                                            <hr className="mb-0 mt-0" style={{ marginLeft: '13%', color: '#ABA7A7' }}></hr>
                                            <div className="d-flex mt-2 align-items-center">
                                                <div className="mb-3" >
                                                    <img
                                                        className="me-md-3 me-sm-3 me-lg-2 mb-md-0 transactionwallet"
                                                        src={withdrawl} // Replace 'yourImageSourceHere' with the actual image source URL
                                                        alt="wallet"
                                                        width="42px"
                                                        height="42px"
                                                    />
                                                </div>
                                                <div className="d-flex  flex-fill ml-md-2 text-start">
                                                    <img src={user} className="me-2 rounded-circle" alt="aj" width="24px" height="24px"></img>
                                                    <div>
                                                        <p className="transactionp" style={{ fontWeight: '600', fontSize: '16px', verticalAlign: 'Cap-height', lineHeight: '24px', color: '#292929' }}>Withdrawal</p>
                                                        <p className="transactionp1" style={{ fontWeight: '400', fontSize: '14px', verticalAlign: 'Cap-height', lineHeight: '21px', color: '#7D7878' }}>Bank transfer</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column flex-fill ml-md-2 text-end">
                                                    <p className="transactionp" style={{ fontWeight: '600', fontSize: '16px', verticalAlign: 'Cap-height', lineHeight: '24px', color: '#292929' }}>UGX 3000</p>
                                                    <p className="transactionp1" style={{ fontWeight: '400', fontSize: '14px', verticalAlign: 'Cap-height', lineHeight: '21px', color: '#7D7878' }}>20.05.2023</p>
                                                </div>
                                            </div>
                                            <hr className="mb-0 mt-0" style={{ marginLeft: '13%', color: '#ABA7A7' }}></hr>
                                            <div className="d-flex mt-2 align-items-center">
                                                <div className="mb-3" >
                                                    <img
                                                        className="me-md-3 me-sm-3 me-lg-2 mb-md-0 transactionwallet"
                                                        src={wallet} // Replace 'yourImageSourceHere' with the actual image source URL
                                                        alt="wallet"
                                                        width="42px"
                                                        height="42px"
                                                    />
                                                </div>
                                                <div className="d-flex  flex-fill ml-md-2 text-start">
                                                    <img src={user} className="me-2 rounded-circle" alt="aj" width="24px" height="24px"></img>
                                                    <div>
                                                        <p className="transactionp" style={{ fontWeight: '600', fontSize: '16px', verticalAlign: 'Cap-height', lineHeight: '24px', color: '#292929' }}>Wallet to Wallet</p>
                                                        <p className="transactionp1" style={{ fontWeight: '400', fontSize: '14px', verticalAlign: 'Cap-height', lineHeight: '21px', color: '#7D7878' }}>Bank transfer</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column flex-fill ml-md-2 text-end">
                                                    <p className="transactionp" style={{ fontWeight: '600', fontSize: '16px', verticalAlign: 'Cap-height', lineHeight: '24px', color: '#292929' }}>UGX 3000</p>
                                                    <p className="transactionp1" style={{ fontWeight: '400', fontSize: '14px', verticalAlign: 'Cap-height', lineHeight: '21px', color: '#7D7878' }}>20.05.2023</p>
                                                </div>
                                            </div>
                                            <hr className="mb-0 mt-0" style={{ marginLeft: '13%', color: 'ABA7A7' }}></hr>
                                            <div className="d-flex mt-2 align-items-center">
                                                <div className="mb-3" >
                                                    <img
                                                        className="me-md-3 me-sm-3 me-lg-2 mb-md-0 transactionwallet"
                                                        src={wallet} // Replace 'yourImageSourceHere' with the actual image source URL
                                                        alt="wallet"
                                                        width="42px"
                                                        height="42px"
                                                    />
                                                </div>
                                                <div className="d-flex  flex-fill ml-md-2 text-start">
                                                    <img src={user} className="me-2 rounded-circle" alt="aj" width="24px" height="24px"></img>
                                                    <div>
                                                        <p className="transactionp" style={{ fontWeight: '600', fontSize: '16px', verticalAlign: 'Cap-height', lineHeight: '24px', color: '#292929' }}>Wallet to Wallet</p>
                                                        <p className="transactionp1" style={{ fontWeight: '400', fontSize: '14px', verticalAlign: 'Cap-height', lineHeight: '21px', color: '#7D7878' }}>Bank transfer</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column flex-fill ml-md-2 text-end">
                                                    <p className="transactionp" style={{ fontWeight: '600', fontSize: '16px', verticalAlign: 'Cap-height', lineHeight: '24px', color: '#292929' }}>UGX 3000</p>
                                                    <p className="transactionp1" style={{ fontWeight: '400', fontSize: '14px', verticalAlign: 'Cap-height', lineHeight: '21px', color: '#7D7878' }}>20.05.2023</p>
                                                </div>
                                            </div>
                                            <hr className="mb-0 mt-0" style={{ marginLeft: '13%', color: 'ABA7A7' }}></hr>
                                            <div className="text-end mt-3">
                                                <button className=" export-pdf"><img className="me-2" src={exportpdf} alt="export" width='15px' height='14px'></img>Export to PDF</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* withdraw_funds */}

            <Modal show={show} dialogClassName="example-dialog" contentClassName="example-content" onHide={handleClose} centered>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                    <div className="row modal-row">
                        <div className="col-md-6 col-sm-12 mb-4 mb-md-0">
                            <div className="left-column">
                                <span className="left-columnh6">Display your QR to be scanned
                                    to receive deposit. or click
                                    copy to send it</span>
                                <p className="left-columnp">Wallet ID: wallet256<img className="ms-2" src={copy} alt="copy" width='11.08px' height="13.54px"></img></p>
                                <p className="left-columnp">Email ID: johndoe@gmail.com<img className="ms-2" src={copy} alt="copy" width='11.08px' height="13.54px"></img></p>
                                <p className="left-columnp">Mobile No: +256 98687594<img className="ms-2" src={copy} alt="copy" width='11.08px' height="13.54px"></img></p>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 ">
                            <div className="right-column">
                                <img className="close-icon" onClick={closeIcon} src={closeicon} alt="closeicon" width='32px' height="32px"></img>
                                <img src={qrcode} className="qrcode" alt="qrcode" ></img>
                                <button className="shareqrbutton mt-4"><img className="me-3" src={sharee} alt="share" width="14px" height="14px"></img>Share QR</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* modalwithdraw */}
            <Modal show={show1} dialogClassName="example-dialog1" contentClassName="example-content1" onHide={handleCloses} centered>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                    <div className="d-flex align-items-center justify-content-between">
                        <img src={leftarrow} onClick={closedialog1} className="leftarrows" alt="leftarrow" width="19.5px" height="15.5px"></img>
                        <img src={closeicon} onClick={closedialog1} className="closeicons" alt="closeicon" width="32px" height="32px"></img>
                    </div>
                    <label className="withdrawamountlabel mt-4 mb-3 ps-3">Enter Amount to be Withdrawn</label>
                    <div className="d-flex justify-content-start mb-4 ps-3 ">
                        <div className="input-container" style={{ position: 'relative', width: '100%', maxWidth: '380px' }}>
                            <input type="text" value={amount} onChange={(e) => setAmount(e.target.value)} className="form-control withdrawamountinputs" placeholder="Amount" style={{ height: '40px', }} />
                            <p className="currancysel1" style={{ position: 'absolute', right: '0px', transform: 'translateY(-50%)', cursor: 'pointer', fontWeight: "500", }}>UGX</p>
                        </div>
                    </div>
                    <div className="row depositbuttons  ps-sm-3  mb-4">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                            <button type="button" className=" currancybutton  btn-block">+ UGX 100</button>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                            <button type="button" className=" currancybutton btn-block">+ UGX 250</button>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                            <button type="button" className=" currancybutton btn-block">+ UGX 500</button>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                            <button type="button" className=" currancybutton  btn-block">+ UGX 1000</button>
                        </div>
                    </div>
                    <label className="withdrawamountrecentlabel ps-3 mb-4">Recents</label>
                    <div className="  d-flex flex-wrap align-items-center justify-content-between mx-4">
                        <div className="flex-1 text-center">
                            <div className="rounded-circle overflow-hidden">
                                <img className="rounded-circle mb-2" src={user} alt="Profile 1" width="32px" height="32px" />
                            </div>
                            <p className="recentchatsname mb-0">John Doe</p>
                            <p className="recentchatsamount">UGX1000</p>
                        </div>
                        <div className="flex-1 text-center">
                            <div className="rounded-circle overflow-hidden">
                                <img className="rounded-circle mb-2" src={user} alt="Profile 2" width="32px" height="32px" />
                            </div>
                            <p className="recentchatsname mb-0">Charles David</p>
                            <p className="recentchatsamount">UGX500</p>
                        </div>
                        <div className="flex-1 text-center">
                            <div className="rounded-circle overflow-hidden">
                                <img className="rounded-circle mb-2" src={user} alt="Profile 3" width="32px" height="32px" />
                            </div>
                            <p className="recentchatsname mb-0">Cody Fisher</p>
                            <p className="recentchatsamount">UGX500</p>
                        </div>
                        <div className="flex-1 text-center">
                            <div className="rounded-circle overflow-hidden">
                                <img className="rounded-circle mb-2" src={user} alt="Profile 4" width="32px" height="32px" />
                            </div>
                            <p className="recentchatsname mb-0">Theresa Webb</p>
                            <p className="recentchatsamount">UGX500</p>
                        </div>
                        <div className="flex-1 text-center">
                            <div className="rounded-circle overflow-hidden">
                                <img className="rounded-circle mb-2" src={user} alt="Profile 5" width="32px" height="32px" />
                            </div>
                            <p className="recentchatsname mb-0">Cameron David</p>
                            <p className="recentchatsamount">UGX500</p>
                        </div>
                    </div>
                    <hr className="mt-5" style={{ color: '#8D8D8D' }}></hr>
                    <div className="text-center mt-4">
                        <button className={getButtonClass()} onClick={withdrawqr}>Withdraw</button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* modal qrcode */}
            <Modal show={show2} dialogClassName="example-dialog2" contentClassName="example-content2" onHide={handleCloses1} centered>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                    <div className="d-flex align-items-center justify-content-between">
                        <img src={leftarrow} onClick={back} className="leftarrows" alt="leftarrow" width="19.5px" height="15.5px"></img>
                        <img src={closeicon} onClick={closedialog2} className="closeicons" alt="closeicon" width="32px" height="32px"></img>
                    </div>
                    <div className=" text-center  mb-4 ">
                        <label className="withdrawqtscannerlabel">Scan QR code</label>
                    </div>
                    <div className="text-center mb-5">
                        <img className="scanqrimage" src={scanqr} onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave} alt="scanqr" ></img>
                        {isHovered && (
                            <div className="desc">
                                <p>Please Connect QR code scanner to scanning</p>
                            </div>
                        )}

                    </div>
                    <div className="text-center">
                        <div className="withdrawmanuallabel-container mt-4 mb-4">
                            <div className="withdrawmanualline"></div>
                            <label className="withdrawmanuallabel">Or enter manually</label>
                            <div className="withdrawmanualline"></div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <input type="text" className="withdraw-inputox" value={enterwallet} placeholder="Enter Wallet ID" onChange={handleInputChange1} />
                    </div>
                    <hr className="mt-4" style={{ color: '#8D8D8D' }}></hr>
                    <div className="text-center mt-4 mb-3">
                        <button className={getButtonClass1()} onClick={withdrawqr1}>Continue</button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* modalwithdrawamount */}

            <Modal show={show3} dialogClassName="example-dialog3" contentClassName="example-content3" onHide={handleCloses2} centered>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                    <div className="d-flex align-items-center justify-content-between">
                        <img src={leftarrow} onClick={back1} className="leftarrows" alt="leftarrow" width="19.5px" height="15.5px"></img>
                        <img src={closeicon} onClick={closedialog3} className="closeicons" alt="closeicon" width="32px" height="32px"></img>
                    </div>
                    <div className="text-center mt-4 mb-4">
                        <img className="profile-image rounded-circle" src={user} alt="Profile " width="80px" height="80px" />
                    </div>
                    <p className="modalwithdrawname">Charles David</p>
                    <p className="modalwithdrawwalletid">charlesdavid22@wallet.com</p>
                    <div className="mb-5" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className="mb-5" style={{ position: 'relative', width: '180px' }}>
                            <input
                                type="text"
                                className="withdrawamountchanged border-0"
                                placeholder="UGX 1000"
                                onChange={handleInputChange4}
                                value={inputValue}
                                readOnly={!isEditable}
                                style={{ width: '100%', height: '60px', paddingTop: '13px' }}
                            />

                            <img
                                src={edit}
                                alt="edit"
                                width="18px"
                                height="18px"
                                onClick={toggleEdit}
                                style={{ position: 'absolute', right: '0px', top: '60%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                            />

                        </div>
                    </div>
                    <hr className="mt-5"></hr>
                    <div className="text-center mt-3 ">
                        <button className="withdrawamounbutton active" onClick={withdrawqr2}>Continue</button>
                    </div>
                </Modal.Body>
            </Modal>
            {/* modalwithdrawpasscode */}
            <Modal show={show4} dialogClassName="example-dialog30" contentClassName="example-content30" onHide={handleCloses3} centered>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                    <div className="d-flex align-items-center justify-content-between">
                        <img src={leftarrow} onClick={back2} className="leftarrows1" style={{ cursor: 'pointer' }} alt="leftarrow" width="19.5px" height="15.5px"></img>
                        <img src={closeicon} onClick={closedialog4} className="closeicons1" alt="closeicon" width="32px" height="32px"></img>
                    </div>
                    <div>
                        <p className="withdrawlmoneyptag ">Withdraw Money</p>
                        <p className="withdrawagent ">Agent info:</p>
                    </div>
                    <div className="d-flex mt-4 align-items-center ">
                        <div className="mb-3" >
                            <img
                                className="me-md-3 me-sm-3 mb-md-0 rounded-circle"
                                src={user}
                                alt="wallet"
                                width="32px"
                                height="32px"
                            />
                        </div>
                        <div className="d-flex flex-column flex-fill ml-md-2">
                            <p className="agentname mb-2">Charles David</p>
                            <p className="agentemail" >charlesdavid22@wallet.com</p>
                        </div>
                        <div className="d-flex flex-column flex-fill ml-md-2 text-end">
                            <p className={getButtonClass3()} >Amount</p>
                            <p className="agentamount">UGX 1000</p>
                        </div>
                    </div>
                    <hr className="full-widthhr"></hr>
                    <label className="enterpasscodelabel mb-3">Enter Passcode</label>
                    <div style={{ position: 'relative' }}>
                        <input type={showpasscode ? 'text' : 'password'} value={enterpasscode} onChange={handleInputChange5} className="withdrawpasscode form-control"></input>
                        <p onClick={togglePasswordVisibility1} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            {showpasscode ? (
                                <img src={eye} className='eyeslash' alt="Show Password" />

                            ) : (
                                <img src={eyeslash} className='eyeslash' alt="Hide Password" />
                            )}
                        </p>
                    </div>
                    <div className="box mt-3" style={{ width: '100%', maxWidth: '380px', minHeight: '72px', borderRadius: '6px', backgroundColor: '#FFE8D0', padding: '0px' }}>
                        <div className="d-flex align-items-center">
                            <img className="ms-2" src={info1} alt="info1" width="24px" height="24px"></img>
                            <p className="infocomments mx-auto mt-2">You are initiating a withdraw transaction
                                from your Wallet Account, this
                                will deduct money from your wallet</p>
                        </div>
                    </div>

                    <div className="text-center">
                        <button className={getButtonClass2()} onClick={withdrawqr3}>
                            {enterpasscode ? 'Complete Withdraw' : 'Withdraw'}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={show5} dialogClassName="example-dialog4" contentClassName="example-content4" onHide={handleCloses4} centered>
                <Modal.Body style={{ margin: '0', padding: '0', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                    {apiStatus === 'processing' && (
                        <>
                            <img src={process} alt="process" width="200px" height="200px" />
                            <p className="processwithdraw">Processing Withdraw</p>
                        </>
                    )}
                    {apiStatus === 'success' && (
                        <>
                            <div>
                                <img src={success} alt="success" width="200px" height="200" />
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <p className="withdrawsuceess">Withdraw successful</p>
                                <p className="withdrawagentsuccess">Completed by Merchant :<b className="withdrawsuccessname"> Charles David</b></p>
                                <p className="withdrawsuccessamount ">UGX 1000</p>
                            </div>
                            <div className="text-center mt-3 mb-3" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <button className="withdrawdonebutton " onClick={donesuccess}>Done</button>
                            </div>
                        </>
                    )}
                </Modal.Body>
            </Modal>

            {/* pay */}

            <Modal show={show6} dialogClassName="example-dialog2" contentClassName="example-content2" onHide={handleCloses6} centered>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                    <div className="d-flex align-items-center justify-content-between">
                        <img src={leftarrow} onClick={back6} className="leftarrows" alt="leftarrow" width="19.5px" height="15.5px"></img>
                        <img src={closeicon} onClick={closedialog6} className="closeicons" alt="closeicon" width="32px" height="32px"></img>
                    </div>
                    <div className="ps-4  paynowhr">
                        <h6 className="paynowh6">PayNow</h6>
                    </div>
                    <div className=" text-center  mb-4 ">
                        <label className="withdrawqtscannerlabel">Scan QR code</label>
                    </div>
                    <div className="text-center mb-5">
                        <img className="scanqrimage" src={scanqr} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} alt="scanqr"></img>
                        {isHovered && (
                            <div className="desc">
                                <p>Please Connect QR code scanner to scanning</p>
                            </div>
                        )}
                    </div>
                    <div className="text-center">
                        <div className="withdrawmanuallabel-container mt-4 mb-4">
                            <div className="withdrawmanualline"></div>
                            <label className="withdrawmanuallabel">Or enter manually</label>
                            <div className="withdrawmanualline"></div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <input type="text" className="withdraw-inputox" value={enterwalletid} placeholder="Enter Wallet ID" onChange={handleInputChange6} />
                    </div>
                    <hr className="mt-4" style={{ color: '#8D8D8D' }}></hr>
                    <div className="text-center mt-4 mb-3">
                        <button className={getButtonClass6()} onClick={withdrawqr6}>Continue</button>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal show={show7} dialogClassName="example-dialog3" contentClassName="example-content3" onHide={handleCloses7} >
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                    <div className="d-flex align-items-center justify-content-between">
                        <img src={leftarrow} onClick={back7} className="leftarrows" alt="leftarrow" width="19.5px" height="15.5px"></img>
                        <img src={closeicon} onClick={closedialog7} className="closeicons" alt="closeicon" width="32px" height="32px"></img>
                    </div>
                    <div className="ps-4  paynowhr">
                        <h6 className="paynowh6">PayNow</h6>
                    </div>
                    <div className="text-center mb-4">
                        <img className="profile-image rounded-circle" src={mastercard} alt="Profile " width="80px" height="80px" />
                    </div>
                    <p className="modalwithdrawname" style={{ marginBottom: '8px' }}>Mastercard</p>
                    <p className="modalwithdrawwalletid mt-0">W-123456789</p>
                    <div style={{ padding: '0px 19px 0px 21px' }}>
                        <label className="modalamountwithdrawnp mb-2 ">Enter Billed amount(final amount will include 0.5% tax)</label>
                        <div className="input-container" style={{ position: 'relative', width: '100%', maxWidth: '380px' }}>
                            <input type="text" value={amount7} onChange={(e) => setAmount7(e.target.value)} className="form-control withdrawamountinputs" placeholder="Amount" style={{ height: '40px', }} />
                            <p className="currancysel1" style={{ position: 'absolute', right: '0px', transform: 'translateY(-50%)', cursor: 'pointer', fontWeight: "500", }}>UGX</p>
                        </div>
                    </div>
                    <div className="row mt-0 depositbuttons" style={{ padding: '18px 0px 18px 21px' }}>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                            <button type="button" className=" currancybutton  btn-block">+ UGX 100</button>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                            <button type="button" className=" currancybutton btn-block">+ UGX 250</button>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                            <button type="button" className=" currancybutton btn-block">+ UGX 500</button>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                            <button type="button" className=" currancybutton  btn-block">+ UGX 1000</button>
                        </div>
                    </div>
                    <hr className="mt-0 " style={{ color: '#8D8D8D' }}></hr>
                    <div className="text-center mt-4 ">
                        <button className={`mb-3 ${getButtonClass7()}`} onClick={withdrawqr7}>Pay Now</button>
                    </div>
                </Modal.Body>
            </Modal>



            <Modal show={show8} dialogClassName="example-dialog30" contentClassName="example-content30" onHide={handleCloses8}>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                    <div className="d-flex align-items-center justify-content-between">
                        <img src={leftarrow} onClick={back8} className="leftarrow" style={{ cursor: 'pointer' }} alt="leftarrow" width="19.5px" height="15.5px"></img>
                        <img src={closeicon} onClick={closedialog8} className="closeicon" alt="closeicon" width="32px" height="32px"></img>
                    </div>
                    <div>
                        <div className=" ps-4 paynowhr1">
                            <p className="withdrawlmoneyptag ">Pay Now</p>
                        </div>
                        <p className="withdrawagent ">Merchant Info :</p>
                    </div>
                    <div className="d-flex mt-4 align-items-center ">
                        <div className="mb-3" >
                            <img
                                className="me-md-3 me-sm-3 mb-md-0 rounded-circle"
                                src={mastercard}
                                alt="wallet"
                                width="32px"
                                height="32px"
                            />
                        </div>
                        <div className="d-flex flex-column flex-fill ml-md-2">
                            <p className="agentname mb-2">Mastercard</p>
                            <p className="agentemail" >W-123456789</p>
                        </div>
                        <div className="d-flex flex-column flex-fill ml-md-2 text-end">
                            <p className={getButtonClass8()} >Amount</p>
                            <p className="agentamount">UGX 1000</p>
                        </div>
                    </div>
                    <hr className="full-widthhr"></hr>
                    <label className="enterpasscodelabel mb-3">Enter Passcode</label>
                    <div style={{ position: 'relative' }}>
                        <input type={passcode ? 'text' : 'password'} value={enterpasscode8} onChange={handleInputChange8} className="withdrawpasscode form-control"></input>
                        <p onClick={togglePasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            {passcode ? (
                                <img src={eye} className='eyeslash' alt="Show Password" />
                            ) : (

                                <img src={eyeslash} className='eyeslash' alt="Hide Password" />
                            )}
                        </p>
                    </div>
                    <div className="box mt-3 mb-3" style={{ width: '100%', maxWidth: '380px', minHeight: '72px', borderRadius: '6px', backgroundColor: '#FFE8D0', padding: '0px' }}>
                        <div className="d-flex align-items-center">
                            <img className="ms-2" src={info1} alt="info1" width="24px" height="24px"></img>
                            <p className="infocomments infocomments1 mx-auto mt-2">You are making a payment to <b>Mastercard</b> and amount <b>UGX 1000</b> will be deducted off your wallet, including 0.5% tax (UGX 500)) and 0.5% wallet fee (UGX 500)</p>
                        </div>
                    </div>

                    <div className="text-center mb-2">
                        <button className={getButtonClasss8()} onClick={withdrawqr8}>
                            Complete Payment
                        </button>
                    </div>
                </Modal.Body>
            </Modal>



            <Modal show={show9} dialogClassName="example-dialog4" contentClassName="example-content4" onHide={handleCloses9} centered>
                <Modal.Body style={{ margin: '0', padding: '0', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    {apiStatus === 'processing' && (
                        <>
                            <img src={process} alt="process" width="200px" height="200px" />
                            <p className="processwithdraw">Processing Withdraw</p>
                        </>
                    )}
                    {apiStatus === 'success' && (
                        <>
                            <div>
                                <img src={success} alt="success" width="200px" height="200" />
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <p className="withdrawsuceess">Payment Successful</p>
                                <p className="withdrawagentsuccess">Completed by Merchant :<b className="withdrawsuccessname"> Charles David</b></p>
                                <p className="withdrawsuccessamount ">UGX 1000</p>
                            </div>
                            <div className="text-center mt-3 mb-3" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <button className="withdrawdonebutton withdrawdonebuttonss " onClick={paynowsuccess}>Done</button>
                            </div>
                        </>
                    )}
                </Modal.Body>
            </Modal>

            <Modal show={show10} dialogClassName="example-dialog22" contentClassName="example-content22" onHide={handleCloses10} centered>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                    <div className="row mobile-alignment">
                        <div className="col-md-4 column_corr  " style={{ border: 'none' }}>
                            <div className="girds-pop col-contt" onClick={dtransfer}>
                                <div className="d-flex flex-cloumn">
                                    <div className="d-transfer mt-3">
                                        <h5 style={{ color: 'white' }}>D-Transfer</h5>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                <div className="girds-desc text-center">
                                    <h5 className="girds-desc-h5" style={{ fontWeight: '600', fontSize: '20px', lineHeight: '30px', color: '#343434' }}>Debit Transfer</h5>
                                    <p className="girds-desc-p">This is when app user transfers funds
                                        from another wallet or provider, to
                                        their wallet (wallet inflow)</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 column_corr ">
                            <div className="girds-pop col-contt" onClick={ctransfer}>
                                <div className="d-flex flex-cloumn">
                                    <div className="d-transfer d-transfer2 mt-3">
                                        <h5 style={{ color: 'white' }}>C-Transfer</h5>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                <div className="girds-desc text-center">
                                    <h5 className="girds-desc-h5">Credit Transfer</h5>
                                    <p className="girds-desc-p">This is when app user transfers funds
                                        from their wallet to another wallet
                                        or provider</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 column_corr ">
                            <div className="girds-pop col-contt" onClick={qrtrans}>
                                <div className="d-flex flex-cloumn">
                                    <div className="d-transfer d-transfer3 mt-3">
                                        <div className="d-flex flex-column text-center">
                                            <h5 style={{ color: 'white' }}>QR</h5>
                                            <h5 style={{ color: 'white' }}>Transactions</h5>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                <div className="girds-desc text-center">
                                    <h5 className="girds-desc-h5">Direct QR Transactions</h5>
                                    <p className="girds-desc-p">All transaction performed via your QR and direct purchases</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={show26} dialogClassName="example-dialog26" contentClassName="example-content26" onHide={handleCloses26} centered>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                    <div className="wallet mt-4 mb-2" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={currancy} alt="currancy" width='105px' height='105px'></img>
                    </div>
                    <div className="text-center">
                        <h6 className="conh6">Currency Conversion</h6>
                        <p className="conp">You are about to convert</p>
                        <p className="conp1">UGX 10,000  to USD 3</p>
                        <p className="conratep">Rate : UGX 1  = USD 0.00027</p>
                        <p className="ratechargep">(Please note that standard rates and charges apply)</p>
                    </div>
                    <div className="proce text-center">
                        <button onClick={proc}>Proceed</button>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
}
export default Transactions;