import React from "react";
import Sidebar from "./sidebar";
import Navbars from "./navbar";
import './review.css';
import googleplay from "./images/google_play-01.png";
import appstore from './images/app_store-01.png';
import school from './images/school_green-01.png';
import user from './images/user-3.jpg';
import starorange from './images/star_orange-01.png';
import schoolorange from './images/school_orange-01.png';
import starhalf from './images/star_grey_and_orange-01.png';
import stargray from './images/star_grey-01.png';
import { useNavigate } from "react-router-dom";
import { extractLoginUser } from "./api/common/commonapi";
function Review() {
    const navigate = useNavigate();
    const login_user = extractLoginUser();
    const reviews = () => {
        navigate(`/u/${login_user}/reviewratings`);
    }
    return (
        <>
            <Navbars />
            <div className="container-fluid bg-white review" style={{padding:'0px'}}>
                <div className=" documentrow">
                    <div className="col-12 col-md-3 col-lg-3  documentside">
                        <Sidebar />
                    </div>
                    <div className="col-12  col-md-9 col-lg-9  documentmain">
                        <div className="box reviewedbox border-rounded pt-3 px-md-4 mb-4" style={{ backgroundColor: 'white',width:'100%',border:'1px solid #ABA7A7',borderRadius:'18px', }}>
                            <div className="d-flex justify-content-between align-items-center pt-4">
                                <h5 className="boxheading">Client app services</h5>
                                <div>
                                    <button className="googleplay me-4"><img className="me-1" src={googleplay} alt="google" width='16.99px' height='15.01px'></img>Google Play</button>
                                    <button className="appstore me-4"><img className="me-1" src={appstore} alt="appstore" width='16.99px' height='15.01px'></img>App Store</button>
                                </div>
                            </div>
                            <div className="row mt-5 ">
                                <div className="col-12 col-md-4">
                                    <div className="box-container reviw-box border p-3" style={{ backgroundColor: 'white' , width:'100%',minHeight:'332px',border:'1px solid #EAEDF2' }}>
                                        <img className="schoolimg" src={school} alt="Your " width='48px' height='48px' />
                                        <h5 className="boxh5" style={{fontWeight:'500',fontSize:'18px',lineHeight:'28.8px',color:'#000000'}}>School</h5>
                                        <p className="reviewquots" style={{fontWeight:'400',fontSize:'10px',lineHeight:'20.3px',color:'#333333',fontFamily: 'Inter',width:'100%',maxWidth:'208px'}}>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        </p>
                                        <div className="row align-items-center mt-2 mb-2">
                                            <div className="col-4 col-lg-4 text-center">
                                                <p className="rating me-4">4.2 <img src={stargray} width='8px'height='8px'></img></p>
                                                <p className="reviews ">1.39Mreviews</p>
                                            </div>

                                            <div className="col-4 text-center vertical-line">
                                                <p className="rating">10M</p>
                                                <p className="reviews">New Users</p>
                                            </div>

                                            <div className="col-4 text-center vertical-line">
                                                <p className="rating">5M</p>
                                                <p className="reviews">Active Users</p>
                                            </div>
                                        </div>
                                        <h6 className="recentcomment" >Recent Comment</h6>
                                        <div className="d-flex   align-items-center">

                                            <img
                                                src={user}
                                                alt="Profile"
                                                width="24px"
                                                height="24px"
                                                className="rounded-circle reviewimg me-3"
                                            />

                                            <p className="reviewname">John Doe</p>
                                        </div>
                                        <div className="d-flex mb-2 align-items-center justify-content-between reviewstars">
                                            <div className="d-flex align-items-center" style={{width:'70%'}}>
                                                <div className="d-flex me-3">
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starhalf} width='6px' height='6px' alt='star' ></img>
                                                </div>
                                            <div style={{width:'100%'}}>
                                                <p className="reviewdate">May 23,2023</p>
                                            </div>
                                            </div>
                                            <div style={{width:'30%',textAlign:'end'}}>
                                                <button className="viewmore"onClick={reviews}>View more</button>
                                            </div>
                                        </div>
                                        <p className="reviewend">No doubbit's on app which nowadays necessary to keep in phone for many useful purposes. It's now became a part of our day-to-day life</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="box-container reviw-box border p-3"  style={{ backgroundColor: 'white' , width:'100%',minHeight:'332px',border:'1px solid #EAEDF2'}} >
                                        <img src={schoolorange} className="schoolimg" alt="Your " width='48px' height='48px' />
                                        <h5 className="boxh5">EvMarketPlace</h5>
                                        <p className="reviewquots">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        </p>
                                        <div className="row align-items-center mt-2 mb-2">
                                            <div className="col-4 text-center">
                                                <p className="rating">4.2</p>
                                                <p className="reviews">1.39Mreviews</p>
                                            </div>
                                            <div className="col-4 text-center vertical-line">
                                                <p className="rating ">10M</p>
                                                <p className="reviews">New Users</p>
                                            </div>

                                            <div className="col-4 text-center vertical-line">
                                                <p className="rating">5M</p>
                                                <p className="reviews">Active Users</p>
                                            </div>
                                        </div>
                                        <h6 className="recentcomment">Recent Comment</h6>
                                        <div className=" d-flex   align-items-center">
                                            <img
                                                src={user}
                                                alt="Profile"
                                                className="rounded-circle reviewimg me-3"
                                            />
                                            <p className="reviewname">John Doe</p>
                                        </div>
                                        <div className="d-flex mb-2 align-items-center justify-content-between reviewstars">
                                            <div className="d-flex align-items-center" style={{width:'70%'}}>
                                                <div className="d-flex me-3">
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starhalf} width='6px' height='6px' alt='star' ></img>
                                                </div>
                                            <div style={{width:'100%'}}>
                                                <p className="reviewdate">May 23,2023</p>
                                            </div>
                                            </div>
                                            <div style={{width:'30%',textAlign:'end'}}>
                                                <button className="viewmore"onClick={reviews}>View more</button>
                                            </div>
                                        </div>
                                        <p className="reviewend">No doubbit's on app which nowadays necessary to keep in phone for many useful purposes. It's now became a part of our day-to-day life</p>
                                    </div>
                                </div>
                                <div className="col-12 reviw-box col-md-4">
                                    <div className="box-container border p-3 "  style={{ backgroundColor: 'white' , width:'100%',minHeight:'332px',border:'1px solid #EAEDF2' }}>
                                        <img src={school} className="schoolimg" alt="Your " width='48px' height='48px' />
                                        <h5 className="boxh5">FaithHub</h5>
                                        <p className="reviewquots">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        </p>
                                        <div className="row align-items-center mt-2 mb-2">
                                            <div className="col-4 text-center ">
                                                <p className="rating">4.2</p>
                                                <p className="reviews">1.39Mreviews</p>
                                            </div>

                                            <div className="col-4 text-center vertical-line">
                                                <p className="rating">10M</p>
                                                <p className="reviews">New Users</p>
                                            </div>

                                            <div className="col-4 text-center vertical-line">
                                                <p className="rating">5M</p>
                                                <p className="reviews">Active Users</p>
                                            </div>
                                        </div>
                                        <h6 className="recentcomment">Recent Comment</h6>
                                        <div className=" d-flex   align-items-center">
                                            <img
                                                src={user}
                                                alt="Profile"
                                                width="5px"
                                                height="5px"
                                                className="rounded-circle reviewimg me-3"
                                            />
                                            <p className="reviewname">John Doe</p>
                                        </div>
                                        <div className="d-flex mb-2 align-items-center justify-content-between reviewstars">
                                            <div className="d-flex align-items-center" style={{width:'70%'}}>
                                                <div className="d-flex me-3">
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starhalf} width='6px' height='6px' alt='star' ></img>
                                                </div>
                                            <div style={{width:'100%'}}>
                                                <p className="reviewdate">May 23,2023</p>
                                            </div>
                                            </div>
                                            <div style={{width:'30%',textAlign:'end'}}>
                                                <button className="viewmore"onClick={reviews}>View more</button>
                                            </div>
                                        </div>
                                        <p className="reviewend">No doubbit's on app which nowadays necessary to keep in phone for many useful purposes. It's now became a part of our day-to-day life</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* provider app service */}
                        <div className="box reviewedbox border-rounded pt-3 px-md-4 mb-4" style={{ backgroundColor: 'white',width:'100%',border:'1px solid #ABA7A7',borderRadius:'18px' }}>
                            <div className="d-flex justify-content-between align-items-center pt-4">
                                <h5 className="boxheading">Agent apps</h5>
                                <div>
                                    <button className="googleplay me-4"><img className="me-1" src={googleplay} alt="google" width='17px' height='17px'></img>Google Play</button>
                                    <button className="appstore me-4"><img className="me-1" src={appstore} alt="appstore" width='17px' height='17px'></img>App Store</button>
                                </div>
                            </div>
                            <div className="row mt-4 ">
                                <div className="col-12 col-md-4 ">
                                    <div className="box-container reviw-box border p-3"  style={{ backgroundColor: 'white' , width:'100%',minHeight:'332px',border:'1px solid #EAEDF2' }}>
                                        <img className="schoolimg" src={school} alt="Your " width='48px' height='48px' />
                                        <h5 className="boxh5" >Education Provider</h5>
                                        <p className="reviewquots">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.

                                        </p>
                                        <div className="row align-items-center mt-2 mb-2">
                                            <div className="col-4 text-center">
                                                <p className="rating ">4.2</p>
                                                <p className="reviews">1.39Mreviews</p>
                                            </div>

                                            <div className="col-4 text-center vertical-line">
                                                <p className="rating">10M</p>
                                                <p className="reviews">New Users</p>
                                            </div>

                                            <div className="col-4 text-center vertical-line">
                                                <p className="rating">5M</p>
                                                <p className="reviews">Active Users</p>
                                            </div>
                                        </div>
                                        <h6 className="recentcomment">Recent Comment</h6>
                                        <div className="d-flex   align-items-center">

                                            <img
                                                src={user}
                                                alt="Profile"
                                                className="rounded-circle reviewimg me-3"
                                            />

                                            <p className="reviewname">John Doe</p>
                                        </div>
                                        <div className="d-flex mb-2 align-items-center justify-content-between reviewstars">
                                            <div className="d-flex align-items-center" style={{width:'70%'}}>
                                                <div className="d-flex me-3">
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starhalf} width='6px' height='6px' alt='star' ></img>
                                                </div>
                                            <div style={{width:'100%'}}>
                                                <p className="reviewdate">May 23,2023</p>
                                            </div>
                                            </div>
                                            <div style={{width:'30%',textAlign:'end'}}>
                                                <button className="viewmore"onClick={reviews}>View more</button>
                                            </div>
                                        </div>
                                        <p className="reviewend">No doubbit's on app which nowadays necessary to keep in phone for many useful purposes. It's now became a part of our day-to-day life</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="box-container reviw-box border p-3"  style={{ backgroundColor: 'white' , width:'100%',minHeight:'332px',border:'1px solid #EAEDF2' }}>
                                        <img src={schoolorange} className="schoolimg" alt="Your " width='48px' height='48px' />
                                        <h5 className="boxh5">E-Commerce Seller</h5>
                                        <p className="reviewquots">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        </p>
                                        <div className="row align-items-center mt-2 mb-2">
                                            <div className="col-4 text-center">
                                                <p className="rating">4.2</p>
                                                <p className="reviews">1.39Mreviews</p>
                                            </div>
                                            <div className="col-4 text-center vertical-line">
                                                <p className="rating ">10M</p>
                                                <p className="reviews">New Users</p>
                                            </div>

                                            <div className="col-4 text-center vertical-line">
                                                <p className="rating">5M</p>
                                                <p className="reviews">Active Users</p>
                                            </div>
                                        </div>
                                        <h6 className="recentcomment">Recent Comment</h6>
                                        <div className=" d-flex   align-items-center">
                                            <img
                                                src={user}
                                                alt="Profile"
                                                width="5px"
                                                height="5px"
                                                className="rounded-circle reviewimg me-3"
                                            />
                                            <p className="reviewname">John Doe</p>
                                        </div>
                                        <div className="d-flex mb-2 align-items-center justify-content-between reviewstars">
                                            <div className="d-flex align-items-center" style={{width:'70%'}}>
                                                <div className="d-flex me-3">
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starhalf} width='6px' height='6px' alt='star' ></img>
                                                </div>
                                            <div style={{width:'100%'}}>
                                                <p className="reviewdate">May 23,2023</p>
                                            </div>
                                            </div>
                                            <div style={{width:'30%',textAlign:'end'}}>
                                                <button className="viewmore"onClick={reviews}>View more</button>
                                            </div>
                                        </div>
                                        <p className="reviewend">No doubbit's on app which nowadays necessary to keep in phone for many useful purposes. It's now became a part of our day-to-day life</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="box-container reviw-box border p-3 "  style={{ backgroundColor: 'white' , width:'100%',minHeight:'332px',border:'1px solid #EAEDF2' }}>
                                        <img src={school} className="schoolimg" alt="Your " width='48px' height='48px' />
                                        <h5 className="boxh5">FaithHub</h5>
                                        <p className="reviewquots">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        </p>
                                        <div className="row align-items-center mt-2 mb-2">
                                            <div className="col-4 text-center ">
                                                <p className="rating">4.2</p>
                                                <p className="reviews">1.39Mreviews</p>
                                            </div>

                                            <div className="col-4 text-center vertical-line">
                                                <p className="rating">10M</p>
                                                <p className="reviews">New Users</p>
                                            </div>

                                            <div className="col-4 text-center vertical-line">
                                                <p className="rating">5M</p>
                                                <p className="reviews">Active Users</p>
                                            </div>
                                        </div>
                                        <h6 className="recentcomment">Recent Comment</h6>
                                        <div className=" d-flex   align-items-center">
                                            <img
                                                src={user}
                                                alt="Profile"
                                                className="rounded-circle reviewimg me-3"
                                            />
                                            <p className="reviewname">John Doe</p>
                                        </div>
                                        <div className="d-flex mb-2 align-items-center justify-content-between reviewstars">
                                            <div className="d-flex align-items-center" style={{width:'70%'}}>
                                                <div className="d-flex me-3">
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starhalf} width='6px' height='6px' alt='star' ></img>
                                                </div>
                                            <div style={{width:'100%'}}>
                                                <p className="reviewdate">May 23,2023</p>
                                            </div>
                                            </div>
                                            <div style={{width:'30%',textAlign:'end'}}>
                                                <button className="viewmore"onClick={reviews}>View more</button>
                                            </div>
                                        </div>
                                        <p className="reviewend">No doubbit's on app which nowadays necessary to keep in phone for many useful purposes. It's now became a part of our day-to-day life</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* provider Webservice */}
                        <div className="box reviewedbox border-rounded pt-3 px-md-4 mb-4" style={{ backgroundColor: 'white',width:'100%',border:'1px solid #ABA7A7',borderRadius:'18px' }}>
                            <div className="d-flex justify-content-between align-items-center pt-4">
                                <h5 className="boxheading">Provider WebServices</h5>
                            
                            </div>
                            <div className="row mt-4">
                                <div className="col-12 col-md-4 ">
                                    <div className="box-container reviw-box border p-3" style={{ backgroundColor: 'white' }}>
                                        <img className="schoolimg" src={school} alt="Your " width='50px' height='auto' />
                                        <h5 className="boxh5">E-Commerce Seller</h5>
                                        <p className="reviewquots">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.

                                        </p>
                                        <div className="row align-items-center mt-2 mb-2">
                                            <div className="col-4 text-center">
                                                <p className="rating ">4.2</p>
                                                <p className="reviews">1.39Mreviews</p>
                                            </div>

                                            <div className="col-4 text-center vertical-line">
                                                <p className="rating">10M</p>
                                                <p className="reviews">New Users</p>
                                            </div>

                                            <div className="col-4 text-center vertical-line">
                                                <p className="rating">5M</p>
                                                <p className="reviews">Active Users</p>
                                            </div>
                                        </div>
                                        <h6 className="recentcomment">Recent Comment</h6>
                                        <div className="d-flex   align-items-center">

                                            <img
                                                src={user}
                                                alt="Profile"
                                                width="5px"
                                                height="5px"
                                                className="rounded-circle reviewimg me-2"
                                            />

                                            <p className="reviewname">John Doe</p>
                                           
                                        </div>
                                        <div className="d-flex mb-2 align-items-center justify-content-between reviewstars">
                                            <div className="d-flex align-items-center" style={{width:'70%'}}>
                                                <div className="d-flex me-3">
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starhalf} width='6px' height='6px' alt='star' ></img>
                                                </div>
                                            <div style={{width:'100%'}}>
                                                <p className="reviewdate">May 23,2023</p>
                                            </div>
                                            </div>
                                            <div style={{width:'30%',textAlign:'end'}}>
                                                <button className="viewmore"onClick={reviews}>View more</button>
                                            </div>
                                        </div>
                                        <p className="reviewend">No doubbit's on app which nowadays necessary to keep in phone for many useful purposes. It's now became a part of our day-to-day life</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="box-container reviw-box border p-3" style={{ backgroundColor: 'white' , width:'100%',minHeight:'332px',border:'1px solid #EAEDF2' }}>
                                        <img src={schoolorange} className="schoolimg" alt="Your " width='48px' height='48px' />
                                        <h5 className="boxh5">School</h5>
                                        <p className="reviewquots">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        </p>
                                        <div className="row align-items-center mt-2 mb-2">
                                            <div className="col-4 text-center">
                                                <p className="rating">4.2</p>
                                                <p className="reviews">1.39Mreviews</p>
                                            </div>
                                            <div className="col-4 text-center vertical-line">
                                                <p className="rating ">10M</p>
                                                <p className="reviews">New Users</p>
                                            </div>

                                            <div className="col-4 text-center vertical-line">
                                                <p className="rating">5M</p>
                                                <p className="reviews">Active Users</p>
                                            </div>
                                        </div>
                                        <h6 className="recentcomment">Recent Comment</h6>
                                        <div className=" d-flex   align-items-center">
                                            <img
                                                src={user}
                                                alt="Profile"
                                                className="rounded-circle reviewimg me-3"
                                            />
                                            <p className="reviewname">John Doe</p>
                                           
                                        </div>
                                        <div className="d-flex mb-2 align-items-center justify-content-between reviewstars">
                                            <div className="d-flex align-items-center" style={{width:'70%'}}>
                                                <div className="d-flex me-3">
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starhalf} width='6px' height='6px' alt='star' ></img>
                                                </div>
                                            <div style={{width:'100%'}}>
                                                <p className="reviewdate">May 23,2023</p>
                                            </div>
                                            </div>
                                            <div style={{width:'30%',textAlign:'end'}}>
                                                <button className="viewmore"onClick={reviews}>View more</button>
                                            </div>
                                        </div>
                                        <p className="reviewend">No doubbit's on app which nowadays necessary to keep in phone for many useful purposes. It's now became a part of our day-to-day life</p>
                                    </div>
                                </div> 
                                <div className="col-12 col-md-4">
                                      
                                </div>    
                            </div>
                        </div>

                         {/* enterpriseservices */}
                        <div className="box reviewedbox border-rounded pt-3 px-md-4 mb-4" style={{ backgroundColor: 'white',width:'100%',border:'1px solid #ABA7A7',borderRadius:'18px' }}>
                            <div className="d-flex justify-content-between align-items-center pt-4">
                                <h5 className="boxheading">Enterprise services</h5>
                            
                            </div>
                            <div className="row mt-4">
                                <div className="col-12 col-md-4 ">
                                    <div className="box-container reviw-box border p-3"style={{ backgroundColor: 'white' , width:'100%',minHeight:'332px',border:'1px solid #EAEDF2' }}>
                                        <img className="schoolimg" src={school} alt="Your " width='50px' height='auto' />
                                        <h5 className="boxh5">School Center</h5>
                                        <p className="reviewquots">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.

                                        </p>
                                        <div className="row align-items-center mt-2 mb-2">
                                            <div className="col-4 text-center">
                                                <p className="rating ">4.2</p>
                                                <p className="reviews">1.39Mreviews</p>
                                            </div>

                                            <div className="col-4 text-center vertical-line">
                                                <p className="rating">10M</p>
                                                <p className="reviews">New Users</p>
                                            </div>

                                            <div className="col-4 text-center vertical-line">
                                                <p className="rating">5M</p>
                                                <p className="reviews">Active Users</p>
                                            </div>
                                        </div>
                                        <h6 className="recentcomment">Recent Comment</h6>
                                        <div className="d-flex   align-items-center">

                                            <img
                                                src={user}
                                                alt="Profile"
                                                className="rounded-circle reviewimg me-3"
                                            />

                                            <p className="reviewname">John Doe</p>
                                            
                                        </div>
                                        <div className="d-flex mb-2 align-items-center justify-content-between reviewstars">
                                            <div className="d-flex align-items-center" style={{width:'70%'}}>
                                                <div className="d-flex me-3">
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starhalf} width='6px' height='6px' alt='star' ></img>
                                                </div>
                                            <div style={{width:'100%'}}>
                                                <p className="reviewdate">May 23,2023</p>
                                            </div>
                                            </div>
                                            <div style={{width:'30%',textAlign:'end'}}>
                                                <button className="viewmore"onClick={reviews}>View more</button>
                                            </div>
                                        </div>
                                        <p className="reviewend">No doubbit's on app which nowadays necessary to keep in phone for many useful purposes. It's now became a part of our day-to-day life</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="box-container reviw-box border p-3" style={{ backgroundColor: 'white' , width:'100%',minHeight:'332px',border:'1px solid #EAEDF2' }}>
                                        <img src={schoolorange} className="schoolimg" alt="Your " width='50px' height='auto' />
                                        <h5 className="boxh5">Video Workspace</h5>
                                        <p className="reviewquots">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        </p>
                                        <div className="row align-items-center mt-2 mb-2">
                                            <div className="col-4 text-center">
                                                <p className="rating">4.2</p>
                                                <p className="reviews">1.39Mreviews</p>
                                            </div>
                                            <div className="col-4 text-center vertical-line">
                                                <p className="rating ">10M</p>
                                                <p className="reviews">New Users</p>
                                            </div>

                                            <div className="col-4 text-center vertical-line">
                                                <p className="rating">5M</p>
                                                <p className="reviews">Active Users</p>
                                            </div>
                                        </div>
                                        <h6 className="recentcomment">Recent Comment</h6>
                                        <div className=" d-flex   align-items-center">
                                            <img
                                                src={user}
                                                alt="Profile"
                                                className="rounded-circle reviewimg me-3"
                                            />
                                            <p className="reviewname">John Doe</p>
                                            
                                        </div>
                                        <div className="d-flex mb-2 align-items-center justify-content-between reviewstars">
                                            <div className="d-flex align-items-center" style={{width:'70%'}}>
                                                <div className="d-flex me-3">
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starorange} width='6px' height='6px' alt='star' ></img>
                                                <img className="reviewstar" src={starhalf} width='6px' height='6px' alt='star' ></img>
                                                </div>
                                            <div style={{width:'100%'}}>
                                                <p className="reviewdate">May 23,2023</p>
                                            </div>
                                            </div>
                                            <div style={{width:'30%',textAlign:'end'}}>
                                                <button className="viewmore"onClick={reviews}>View more</button>
                                            </div>
                                        </div>
                                        <p className="reviewend">No doubbit's on app which nowadays necessary to keep in phone for many useful purposes. It's now became a part of our day-to-day life</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                      
                                </div>
                             </div>   
                         </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Review;