import React from "react";
import Navbars from "./navbar";
import Systemsidebar from "./systemsidebar";
import plus from './images/ApplePay.png';
import download from './images/wallet/download-01.png';
import copy1 from './images/wallet/Copy-01.png';
function Payout(){
    return(
        <>
            {/* <Navbars/>
            <div className="container-fluid " style={{ backgroundColor: '#F8F8F8', width: '100%', height: '100%' }}>
                <div className="row collectionrow">
                    <div className="col-12 col-lg-3 col-md-4 col-xl-3 col-xxl-3 col_corre_2">
                        <Systemsidebar />
                    </div> */}
                    <div className="col-12 col-lg-9 col-md-8 col-xl-9 col-xxl-9 col_corr_1"  >
                        <main className="mobile_height" style={{ backgroundColor: '#FFFFFF', width: '100%', boxShadow: "0px 0px 5px 0px #0000001A", height: '100%',minHeight:'500px' }}>
                            <h5 className="ps-4 pt-4">Payouts</h5>
                            <p className="cardsp ps-4 mb-4">Salaries, commissions, bonuses, and remunerations are found here</p>
                            <div className="massage_box_corr mobile_maxheight ">
                                <div className="purchaserow22 after-one">
                                    <div className="_row">
                                        <div className="col col_70">
                                            <div className="d-flex align-items-center">
                                                <div className="ticketimg mobile_co_img">
                                                    <img src={plus} alt="plus" width='93px' height="70.67px"></img>
                                                </div>
                                                <div className="se-corr mar-0">
                                                    <h5 className="mar-left-0 productname1h5">EV Ride: Taxi Ride</h5>
                                                    <p className="productname1p">Paid at : <strong className="productname1pp">14 June,2023</strong></p>
                                                    <p className="productnamep">Payout ID : <strong className="productname1pp"> 623013459648945465</strong><img className="mx-2" src={copy1} alt="copy" width='10.5px' height='12px' style={{verticalAlign:'middle'}}></img></p>
                                                    <p className="productnamep">Beneficiary :<strong className="productname1pp"> Lwanga Obete</strong ></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col col_30 text-center" >
                                            <button className="_downloads mar-301 bg-color">Download <img src={download} className="mx-2" alt="doownload" width='16px' height='auto' style={{verticalAlign:'text-top'}}></img></button>
                                            <p className="color-green">Amount: UGX 1000</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                {/* </div>
            </div> */}
        </>
    );
}
export default Payout;