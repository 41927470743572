// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en.json';
import chTranslation from './locales/ch.json';
export const LANGUAGE_STORAGE_KEY = 'selectedLanguage';
i18n
  .use(initReactI18next)
  .init({
    resources: { 
      en: {
        translation: enTranslation,
      },
      ch: {
        translation: chTranslation,
      },
    },
    lng: localStorage.getItem(LANGUAGE_STORAGE_KEY) || 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
