import { React, useState, useEffect, useRef,useCallback } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import eyeslash from './images/eye_slash-01.png';
import eye from './images/eye-01.png';
import user from './images/user-3.jpg';
import Select, { components } from 'react-select';
import {isValidNumber} from 'libphonenumber-js';
import edit from './images/edit-01.png'
import logo from './images/evzone-logo-01.png';
import './login.css';
import './signup.css';
import greentick from './images/check-circle-01.png';
import { useNavigate } from 'react-router-dom';
import google from './images/google-01.png';
import facebook from './images/facebook-01.png';
import Modal from 'react-bootstrap/Modal'
import twitter from './images/Twitter X.png';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import axiosInstance from './api/common/axiosinstand';
import resetpasswordlock from './images/forgot-passoward/Reset_Password.png';
import resetpasswordotp from './images/forgot-passoward/Enter-Otp-code.png';
import resetpasswordkey from './images/forgot-passoward/New-Password.png';
import blueeye from './images/eye.png';
import blueeyeslash from './images/eye-slash.png';
import OtpInput from 'react-otp-input';
import { handleApiError } from './api/common/commonapi';
import { getQueryParameter,redirectToWithAuthUser } from './api/common/commonapi';
import { loginapi, signupapi, country_codeapi, countryselectapi, forgototpapi, verifyotpapi, resendotpapi, resetpasswordsetapi, signupverifyapi } from './api/url';
import WithToast from './api/common/withtoast';
import { useIpContext } from './api/common/ipContext';
const loginUrl = loginapi();
const signupUrl = signupapi();
const country_code = country_codeapi();
const countryselect = countryselectapi();
const forgetotp = forgototpapi();
const verifyotp = verifyotpapi();
const resendOTP = resendotpapi();
const resetpasswordset = resetpasswordsetapi();
const signupverify = signupverifyapi();
function Log({showErrorToast}) {
    const navigate = useNavigate();
      useEffect(() => {
    const url = '/';
    const route = window.location.pathname;

    if (url === route) {
      const elements = document.getElementsByClassName('header-profile-user1');

      if (elements.length > 0) {
        const originalDisplayValues = [];

        Array.from(elements).forEach((element, index) => {
          originalDisplayValues[index] = element.style.display;
          element.style.display = 'none';
        });

        return () => {
          Array.from(elements).forEach((element, index) => {
            element.style.display = originalDisplayValues[index] || ''; // Revert to original display value or default
          });
        };
      }
    }
  }, []);
//   const [ipCountryCode,setIpCountryCode] = useState('');
const { ipCountryCode, getUserIpCountryCode } = useIpContext();
useEffect(() => {
    if (ipCountryCode) {
      fetchCountryCode1();
    }
  }, [ipCountryCode]);
        // const sid = () => {
    //     const config = {
    //         headers: {'Accept': 'application/json' },
    //         withCredentials: true
    //     };  
    //     return axios.get('https://api.v1.evzone.app/api/v1.0/app/core/auth/sid',config)
    // }
    // useEffect(() => {
    //     sid().then(()=>{
                   
    //     }).catch(()=>{
            
    //     });
    //     // const fetchData =  () => {
    //     //     try {
    //     //         const config = {
    //     //             headers: {
    //     //                 'Content-Type': 'application/json'
    //     //             },
    //     //             withCredentials: true
    //     //         };
    //     //         const response =  axios.get('https://api.v1.evzone.app/api/v1.0/app/core/auth/sid',{config});
                
    //     //     } catch (error) {
    //     //         console.log(error);
    //     //         // Handle errors here
    //     //     }
    //     // };
    
    //     // fetchData();
    // }, []);
     
    // useEffect(() => {
    //     localStorage.removeItem('access_token');
    // })
    const [showedit1, setShowedit1] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [mobile, setMobile] = useState('');
    const [passwords, setPasswords] = useState('');
    const [showPasswords, setShowPasswords] = useState(false);
    const [signupClicked, setSignupClicked] = useState(false);
    const [signinClicked, setSigninClicked] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [is422ErrorToastActive, setIs422ErrorToastActive] = useState(false);
    const [showForm1, setShowForm1] = useState(true);
    const [showForm2, setShowForm2] = useState(false);
    const [showForm3, setShowForm3] = useState(false);
    const [showForm4, setShowForm4] = useState(false);
    const [showForm5, setShowForm5] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmpassword] = useState('');
    const [referral, setReferral] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [usernameError, setUsernameError] = useState('');
    const [errors, setErrors] = useState('');
    const [passwordError, setpasswordError] = useState('');
    const [error, setError] = useState('');
    // const [countryCodes, setCountryCodes] = useState([]);
    // const [showCountryInput, setShowCountryInput] = useState(false);
    // const [selectedCountryMccId, setSelectedCountryMccId] = useState('');
    const [selectedCountryMccId1, setSelectedCountryMccId1] = useState('');
    const [showCountryInputforget, setShowCountryInputforget] = useState(false);
    const [selectedCountryMccId2, setSelectedCountryMccId2] = useState('');
    const [username, setUsername] = useState('');
    const [selectedCountrycode, setSelectedCountrycode] = useState('');
    const [nameerror, setNameError] = useState('');
    const [emailerror, setEmailError] = useState('');
    const [mobileerror, setMobileError] = useState('');
    const [passwordserror, setPasswordsError] = useState('');
    const [confirmpassworderror, setConfirmpasswordError] = useState('');
    const [selectedCountrycodeerror, setSelectedCountrycodeError] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [isFocused1, setIsFocused1] = useState(false);
    const [verify, setVerify] = useState(false);
    const [selectedOption, setSelectedOption] = useState('email');
    const [forgotpassemail, setForgetpassemail] = useState('');
    const [forgotpassmobile, setForgotpassmobile] = useState('');
    const [showforgototp, setShowforgototp] = useState(false);
    const [otp, setOtp] = useState('');
    const [forgetmail, setForgetmail] = useState('');
    const [otphide, setOtphide] = useState(false);
    const [resendhide, setResendhide] = useState(false);
    const [verifyemail, setVerifyemail] = useState(email);
    const [verifymobile, setVerifymobile] = useState(mobile);
    const [isreadonly, setIsreadonly] = useState(false);
    const [sendverifybutton, setSendverifybutton] = useState(false);
    const [sendotpbutton, setSendotpbutton] = useState(true);
    const [otpfield, setOtpfield] = useState('');
    const [countdown, setCountdown] = useState(120);
    const [countdown1, setCountdown1] = useState(120);
    const [doyousign, setDoyousign] = useState(true);
    const [isEditble, setisEditble] = useState(false);
    const [isCountryCodeFetched, setIsCountryCodeFetched] = useState(false);
    const [isEditables, setisEditbles] = useState(false);
    const [verifyemailotppage, setVerifyemailotppage] = useState(true);
    const [verifyinputbox, setVerifyinputbox] = useState(false);
    const [verifymobileotppage, setVerifymobileotppage] = useState(false);
    const [verifyinputboxs, setVerifyinputboxs] = useState(false);
    const [otperror, setOtpError] = useState('');
    const [successotp, setOtpsuccess] = useState('');
    const [sendotpverify, setsendotpverify] = useState('');
    const [sendotpverifyError, setOtpsendverifyError] = useState('');
    const [reotpsend, setReotpsend] = useState('');
    const [reotpsendrror, setReotpsendError] = useState('');
    const [newpassword, setNewpassword] = useState(false);
    const [shownewpassword, setShownewpassword] = useState(false);
    const [forgotpassword, setForgotpassword] = useState('');
    const [forgotconfirmpassword, setForgotconfirmpassword] = useState('');
    const [forgotpassworderror, setForgotpassworderror] = useState('');
    const [forgotconfirmpasswordError, setForgotconfirmpasswordError] = useState('');
    const [resetpassword, setResetpassword] = useState('');
    const [resetpasswordError, setResetpasswordError] = useState('');
    const [signupotp, setSignupotp] = useState('');
    const [reotpsend1Error, setReotpsend1Error] = useState('');
    const [reotpsend1, setReotpsend1] = useState('');
    const [showPasswordsconfirm, setShowPasswordsconfirm] = useState(false);
    const [shownewpasswordconfirm, setShownewpasswordconfirm] = useState(false);
    // const [ipCountryCode, setIpCountryCode] = useState('');
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const togglePasswordVisibilitys = () => {
        setShowPasswords(!showPasswords);
    };
    const togglePasswordVisibilityconfirm = () => {
        setShowPasswordsconfirm(!showPasswordsconfirm);
    }
    const passwordvisiblity = () => {
        setShownewpassword(!shownewpassword);
    }
    const passwordvisiblityconfirm = () => {
        setShownewpasswordconfirm(!shownewpasswordconfirm);
    }
    useEffect(() => {
        const rootElement = document.getElementById("root");

        if (rootElement) {
            if (isLoading) {
                rootElement.classList.add("load");
            } else {
                rootElement.classList.remove("load");
            }
        }

        // Cleanup effect on component unmount
        return () => {
            if (rootElement) {
                rootElement.classList.remove("load");
            }
        };
    }, [isLoading]);
    //  showForm1 code 

    const toggleLoginForms = () => {
        setShowForm1(true);
        setIsClicked(!isClicked);
        setShowCountryInput(false)
        setSigninClicked(true);
        setShowForm2(false);
        setShowForm3(false);
        setSignupClicked(false);
        setUsername('');
        setPassword('');
        setUsernameError('');
        setpasswordError('');
        setShowPassword(false);
        setError('');
    }

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setUsername(inputValue);
        setShowCountryInput(/^\+?\d+$/.test(inputValue));
    };
    const handleInputChanges = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        setpasswordError('');
    }
    // const [ipCountryCode, setIpCountryCode] = useState('UG');
    const [countryCodes, setCountryCodes] = useState([]);
    
    const [initialCountryCode, setInitialCountryCode] = useState('IN');
    const [selectedCountryMccId, setSelectedCountryMccId] = useState(null);
    const [showCountryInput, setShowCountryInput] = useState(false);

    // useEffect(() => {
    //     const getUserIpCountryCode = async () => {
    //         try {
    //             const response = await fetch('https://ipapi.co/country/');
    //             if (response.ok) {
    //                 const countryCode = await response.text();
    //                 if (countryCode) {
    //                     setIpCountryCode(countryCode);
    //                 } else {
    //                     setIpCountryCode('UG');
    //                 }
    //             } else {
    //                 setIpCountryCode('UG');
    //             }
    //         } catch (error) {
    //             setIpCountryCode('UG'); // Default country code in case of an error
    //         }
    //     };

    //     getUserIpCountryCode();
    //     setShowCountryInput(/^\+?\d+$/.test(username));
    // }, [username]);
   const [isLoading1 , setIsLoading1] = useState(false);
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await axiosInstance(country_code);
    //             const data = response.data;
    //                 if (data.message === 'Success') {
    //                     const mccList = data.data;
    //                     const codes = mccList.map((mcc) => ({
    //                         country_code: mcc.country_code,
    //                         dial_code: mcc.code,
    //                         mcc_id: mcc.id,
    //                     }));
    //                     setCountryCodes(codes);

    //                     // Set the initial country code to the user's IP address country code
    //                     setInitialCountryCode(ipCountryCode);
    //             } else {
    //                 throw new Error('Failed to fetch country codes');
    //             }
    //         } catch (error) {
    //             handleApiError(error,setIsLoading1,null,true,showErrorToast);
    //         }
    //     };

    //     fetchData();
    // }, [country_code, ipCountryCode]);

    // useEffect(() => {
    //     if (showCountryInput) {
    //         // Initialize the international telephone input
    //         const phoneInputField = document.querySelector('#login_country');
    //         const phoneInput = intlTelInput(phoneInputField, {
    //             initialCountry: initialCountryCode,
    //             separateDialCode: false,
    //             formatOnDisplay: true,
    //             utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
    //         });

    //         const selectedCountryData = countryCodes.find(
    //             (country) => country.country_code === initialCountryCode
    //         );
    //         if (selectedCountryData) {
    //             setSelectedCountryMccId(selectedCountryData.mcc_id);
    //         }

    //         phoneInputField.addEventListener('countrychange', () => {
    //             const selectedCountryData = phoneInput.getSelectedCountryData();
    //             const selectedCountryMccId = countryCodes.find(
    //                 (country) => country.country_code === selectedCountryData.iso2.toUpperCase()
    //             ).mcc_id;
    //             setSelectedCountryMccId(selectedCountryMccId);
    //         });
    //     }
    // }, [showCountryInput, countryCodes, initialCountryCode]);
    // Define fetchData function outside the component or use useCallback to prevent recreation
    const inputRef = useRef(null);
    const mobileInputRef = useRef(null);
    const [mccId, setMccId] = useState('');
    useEffect(() => {
        if (showCountryInput) {
            mobileInputRef.current?.focus();
        } else {
            inputRef.current?.focus();
        }
    }, [showCountryInput]);
    const [mobileChange, setMobileChange] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [countryOptions, setCountryOptions] = useState([]);
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
    const [dialCode, setDialCode] = useState('');
    const [isLoading3,setIsLoading3] = useState(false);
    const updateExampleNumber = (iso2) => {
        if (window.intlTelInputUtils) {
          try {
            const exampleNumber = window.intlTelInputUtils.getExampleNumber(
              iso2,
              true,
              window.intlTelInputUtils.numberFormat.E164
            );
            const cleanedNumber = exampleNumber.replace(/\D/g, '').replace(/^0+/, '');
            const maxLength = cleanedNumber.length;
            return maxLength;
          } catch (error) {
            console.error('Error fetching example number for country code:', iso2, error);
            return 15; // Fallback to a default length
          }
        } else {
          console.error('intlTelInputUtils is not loaded');
          return 15; // Fallback to a default length
        }
      };
    const fetchCountryCode1 = async () => {
        setIsLoading3(true); 
        try {
          const response = await axiosInstance.get(country_code);
          const data = response.data;
    
          if (data.message === 'Success') {
            const mccList = data.data;
            const codes = mccList.map((mcc) => ({
              country_code: mcc.country_code,
              dial_code: mcc.code,
              mcc_id: mcc.id,
              label: `${mcc.name} (+${mcc.code})`,
              value: mcc.country_code,
              flag: `https://flagcdn.com/w320/${mcc.country_code.toLowerCase()}.png`,
            }));
    
            setCountryOptions(codes);
    
            const selectedCountryData = codes.find(
              (country) => country.country_code === ipCountryCode
            );
    
            if (selectedCountryData) {
              setMccId(selectedCountryData.mcc_id);
              setDialCode(`+${selectedCountryData.dial_code}`);
              setCountryCode(selectedCountryData.country_code);
    
              const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
              if (maxLength !== null && mobileInputRef.current) {
                mobileInputRef.current.setAttribute('maxlength', maxLength);
              }
            }
          } else {
            console.error('Failed to fetch country codes');
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading3(false); // Set loading to false after the API call completes
        }
      };
      const handlePhoneNumberChange = (value) => {
        if (value.startsWith('0')) {
            value = value.slice(1);
        }
        const maxLength = mobileInputRef.current.getAttribute('maxlength');
        if (value.length > maxLength) {
            value = value.slice(0, maxLength);
        }
        // Check if the input value is a valid number
        if (/^\+?\d+$/.test(value)) {
            setShowCountryInput(true);
        } else {
            setShowCountryInput(false);
        }
        setMobileChange(value);
        const fullNumber = `${dialCode}${value}`;
        if (fullNumber.length <= maxLength + dialCode.length && isValidNumber(fullNumber)) {
            setIsValidPhoneNumber(true);
        } else {
            setIsValidPhoneNumber(false);
        }
    };
   
      const handlePaste1 = (e) => {
        const pastedText = e.clipboardData.getData('text');
        let numericPastedText = pastedText.replace(/[^0-9]/g, '');
        
        // Remove leading '0' if present
        if (numericPastedText.startsWith('0')) {
          numericPastedText = numericPastedText.slice(1);
        }
        const maxLength = mobileInputRef.current.getAttribute('maxlength');
      
        const truncatedPastedText = numericPastedText.slice(0, maxLength);
        setMobileChange(truncatedPastedText);
        const fullNumber = `${dialCode}${truncatedPastedText}`;
        if (truncatedPastedText.length <= maxLength && isValidNumber(fullNumber)) {
          setIsValidPhoneNumber(true);
        } else {
          setIsValidPhoneNumber(false);
        }
        e.preventDefault();
      };
      const handleCountryChange = (selectedOption) => {
        const selectedCountryData = countryOptions.find(
          (country) => country.value === selectedOption.value
        );
    
        if (selectedCountryData) {
          setMccId(selectedCountryData.mcc_id);
          setDialCode(`+${selectedCountryData.dial_code}`);
          setCountryCode(selectedCountryData.country_code);
    
          const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
          if (maxLength !== null && mobileInputRef.current) {
            mobileInputRef.current.setAttribute('maxlength', maxLength);
          }
        }
      };
      const customSingleValue = ({ data }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={data.flag}
            alt=""
            style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
          />
          {/* {data.label} */}
        </div>
      );
    
      const customOption = (props) => {
        return (
          <components.Option {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={props.data.flag}
                alt=""
                style={{ width: '25px', height: 'auto', marginRight: '10px' }}
              />
              {props.data.label}
            </div>
          </components.Option>
        );
      };
    // useEffect(()=>{
    //     const getUserIpCountryCode = async() =>{
    //      try {
    //        const response = await fetch('https://ipapi.co/country/');
    //        if (response.ok) {
    //          const countryCode = await response.text();
    //          if (countryCode) {
    //            setIpCountryCode(countryCode);
    //          } else {
    //            return "UG";
    //          }
    //        } else {
    //          return "UG";
    //        }
    //      } catch (error) {
    //        return "UG"; // Default country code in case of an error
    //      }
    //    };
    //    getUserIpCountryCode()
    //  },[])
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch(country_code);
    //             const data = await response.json();
    //             if (data.message === 'Success') {
    //                 const mccList = data.data;
    //                 const codes = mccList.map((mcc) => ({
    //                     country_code: mcc.country_code,
    //                     dial_code: mcc.code,
    //                     mcc_id: mcc.id,
    //                 }));
    //                 setCountryCodes(codes);
    //             }
    //         } catch (error) {
    //             if (error.response && error.response.status === 401) {
    //                 window.location.href = ('/')
    //             }
    //         }
    //     };

    //     fetchData();
    // }, []);
    // const [initialCountryCode, setInitialCountryCode] = useState('IN');
    // useEffect(() => {
    //     if (showCountryInput) {
    //         // Initialize the international telephone input
    //         const phoneInputField = document.querySelector('#login_country');
    //         const phoneInput = intlTelInput(phoneInputField, {
    //             initialCountry: initialCountryCode,
    //             separateDialCode: false,
    //             formatOnDisplay: true,
    //             utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
    //         });
    //         const selectedCountryData = countryCodes.find(
    //             (country) => country.country_code === initialCountryCode
    //         );
    //         if (selectedCountryData) {
    //             setSelectedCountryMccId(selectedCountryData.mcc_id);
    //         }
    //         phoneInputField.addEventListener('countrychange', () => {
    //             const selectedCountryData = phoneInput.getSelectedCountryData();
    //             const selectedCountryMccId = countryCodes.find(
    //                 (country) => country.country_code === selectedCountryData.iso2.toUpperCase()
    //             ).mcc_id;
    //             setSelectedCountryMccId(selectedCountryMccId);
    //         });
    //     }
    // }, [showCountryInput, countryCodes, initialCountryCode, countryCodes]);

    const handleUsernameChange = (e) => {
        const inputUsername = e.target.value.replace(/\s/g, '');
        setUsername(inputUsername);
        setUsernameError('');
    
        if (/^\+?\d+$/.test(inputUsername)) {
            if (!isCountryCodeFetched) {
                setShowCountryInput(true);
                fetchCountryCode1();
                setIsCountryCodeFetched(true); // Set the flag to indicate that API call has been made
                if (!ipCountryCode) {
                    getUserIpCountryCode();
                  }
            }
        } else {
            setShowCountryInput(false);
            setIsCountryCodeFetched(false); // Reset the flag if inputUsername is not a valid number
        }
    };
    
    // Rest of your code...
    
    const countrycodechange = (e) => {
        const inputvalue = e.target.value;
        setSelectedCountrycode(inputvalue);
        setSelectedCountrycodeError('');

    }
    const mccid = (e) => {
        const inputValue = e.target.value;
        setSelectedCountryMccId(inputValue);
    }
    const handleRememberMeChange = (e) => {
        setRememberMe(e.target.checked);
    };
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            login(e);
        }
    };

    useEffect(() => {
        const savedUsername = localStorage.getItem('usrname');
        const savedPassword = localStorage.getItem('pass');
        const savedMccId = localStorage.getItem('mcc_id');
        const savedRememberMe = localStorage.getItem('chkbx');

        // Update state with saved values if they exist
        if (savedUsername) setUsername(savedUsername);
        if (savedPassword) setPassword(savedPassword);
        if (savedMccId) setSelectedCountryMccId(savedMccId);
        if (savedRememberMe === 'true') setRememberMe(true);
    }, []);
   
    const login = async (e) => {
        e.preventDefault();
        const gmailUsernamePattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!username && !password) {
            setUsernameError('Required');
            setpasswordError('Required');
            return false;
        } else if (!username) {
            setUsernameError('Required');
            setpasswordError('');
            return false;
        }
        else if (!password) {
            setpasswordError('Required');
            setUsernameError('');
            return false;
        }
        else {
            setUsernameError('');
            setpasswordError('');
        }
        if (username && password) {
            if (/^\+?\d+$/.test(username)) {
                if (!selectedCountryMccId) {
                    setUsernameError('Select Your Country Again');
                    return false;
                }
                var data = JSON.stringify({
                    username: username,
                    password: password,
                    mcc_id: selectedCountryMccId
                });
            } else if (gmailUsernamePattern.test(username)) {
                // Username is a valid Gmail address
                var data = JSON.stringify({
                    username: username,
                    password: password
                });
            } else {
                setUsernameError('Invalid Email Address');
                return false;
            }
            // var token = localStorage.getItem('sid');
            // // const config = {
            // //     headers: {'Content-Type': 'application/json',"X-Request-Agent":"APP", "X-SID":`${token}`,},
            // //     withCredentials: true
            // // };  
            try {
                    setIsLoading(true);
                    const response = await axiosInstance.post(loginUrl,data,);
                    console.log(response);
                if (response.status === 200) {
                    let X_AuthUser = response.data.data.authuser;
                    const redirectUrl = getQueryParameter('redirecturl');
                    if (rememberMe) {
                        localStorage.setItem('usrname', username);
                        localStorage.setItem('pass', password);
                        localStorage.setItem('chkbx', 'true');
                        localStorage.setItem('mcc_id', selectedCountryMccId || '');
                    } else {
                        localStorage.removeItem('usrname');
                        localStorage.removeItem('pass');
                        localStorage.removeItem('chkbx');
                        localStorage.removeItem('mcc_id');
                    }
                        if (redirectUrl) {
                            redirectToWithAuthUser(redirectUrl, X_AuthUser);
                    } else {
                        // makeAjaxCall();
                        navigate(`/u/${X_AuthUser}/services`);
                    }
                    setIsLoading(false);
                    }
            } catch (error) {
               
                if (error.response) {
                    if (error.response.status === 401) {
                        setIsLoading(false);
                        if (!toast.isActive('422Error')) {
                            setIs422ErrorToastActive(is422ErrorToastActive)
                            toast.error(error.response.data.mobile_error, {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                                toastId: '422Error'
                            });
                        }
                    } else if (error.response.status === 422) {
                        setIsLoading(false);
                        if (!toast.isActive('422Error')) {
                            setIs422ErrorToastActive(is422ErrorToastActive)
                            toast.error(error.response.data.mobile_error, {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                                toastId: '422Error'
                            });
                        }
                    }  else if(error.response.status === 409){
                        
                        const X_AuthUser = error.response.data.data.authuser;
                        const redirectUrl = getQueryParameter('redirecturl');
                        if(redirectUrl){
                            redirectToWithAuthUser(redirectUrl, X_AuthUser);
                        }else{
                            // makeAjaxCall();
                            navigate(`/u/${X_AuthUser}/services`);
                        }
                        setIsLoading(false);
                    }

                }

            }
        };

    }

    // showForm2 code

    const namechange = (event) => {
        setName(event.target.value);
        setNameError('');
    }
    const emailchange = (event) => {
        setEmail(event.target.value);
        setEmailError('');
    }
    const mobilechange = (event) => {
        setMobile(event.target.value.replace(/\s/g, ''));
        setMobileError('')
    }
    // const [isFocused, setIsFocused] = useState(true);
    const [isInvalid, setIsInvalid] = useState({
        letter: true,
        capital: true,
        special: true,
        number: true,
        length: true,
    });
    const passwordchange = (event) => {
        const newPasswords = event.target.value;
        setPasswords(newPasswords);
        setPasswordsError('');
        // Password validation checks
        const lowercaseLetters = /[a-z]/g;
        const uppercaseLetters = /[A-Z]/g;
        const specialCharacters = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-|=]/g;
        const numbers = /[0-9]/g;
        setIsInvalid({
            letter: !newPasswords.match(lowercaseLetters),
            capital: !newPasswords.match(uppercaseLetters),
            special: !newPasswords.match(specialCharacters),
            number: !newPasswords.match(numbers),
            length: newPasswords.length < 8,

        });
    }
    const handleFocus = () => {
        setIsFocused(true);
    };
    const getPasswordValidationClass = (isValid) => {
        return isValid ? 'valid' : 'invalid';
    };
    const handleBlur = () => {
        setIsFocused(false);
    };
    const confirmpasswordchange = (event) => {
        setConfirmpassword(event.target.value);
        setConfirmpasswordError('');
    }
    const referralchange = (event) => {
        setReferral(event.target.value);
    }
    const confirm = () => {
        navigate('/profile');
    }
    const toggleLoginForm = () => {
        setShowForm1(false);
        setShowForm2(false);
        setSignupClicked(false);
        setSigninClicked(false);
        setShowForm3(true);
        setVerify(true);
        // setNewpassword(true)
        setShowForm4(false);
    };


    const toggleSignupForm = () => {
        setShowForm2(true);
        setShowForm3(false);
        setSigninClicked(false);
        setIsClicked(!isClicked);
        setShowForm1(false);
        setSignupClicked(!signupClicked);
        setName('');
        setEmail('');
        setMobile('');
        setPasswords('');
        setConfirmpassword('');
        setSelectedCountrycode('');
        setNameError('');
        setEmailError('');
        setMobileError('');
        setPasswordsError('');
        setConfirmpasswordError('');
        setSelectedCountrycodeError('');
        setErrors('');
        setShowPasswords(false);
        fetchCountryCode();
        fetchCountries();
    };
    const [countries, setCountries] = useState([]); // State to hold the list of countries
     
    // Function to fetch the list of countries
    const fetchCountries = async () => {
        try {
            const response = await fetch(countryselect);
            const data = await response.json();
            if (data.message === 'Success') {
                const countryList = data.data;
                setCountries(countryList);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                window.location.href('/');
            }
        }
    };

    const mccid1 = (e) => {
        const inputValue = e.target.value;
        selectedCountryMccId1(inputValue);
    }
    const fetchCountryCode = async () => {
        try {
            const response = await fetch(country_code,{headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                "X-Authuser": 0,
                "X-Request-Agent": "APP",
                "X-SID": "sid_d4aCmraUT4VOMR55UDPEjb",
                "X-MUID": "mut_lacOJt6wKn1kiVpznBGDwRjG6"
            },
            credentials: 'include'});
            const data = await response.json();
            if (data.message === 'Success') {
                const mccList = data.data;
                const codes = mccList.map((mcc) => ({
                    country_code: mcc.country_code,
                    dial_code: mcc.code,
                    mcc_id: mcc.id,
                }));

                // Set the selectedCountryMccId and update the mobile input value
                const selectedCountryData = codes.find(
                    (country) => country.country_code === 'IN' // Set your default country code here
                );
                setSelectedCountryMccId1(selectedCountryData.mcc_id);

                // Initialize intlTelInput when component mounts
                const phoneInputField = document.querySelector('#mobile');
                // phoneInputField.classList.add("iti-allow-dropdownaaaa")
                const phoneInput = intlTelInput(phoneInputField, {
                    initialCountry: selectedCountryData.country_code,
                    separateDialCode: false,
                    formatOnDisplay: true,
                    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
                });

                phoneInputField.addEventListener('countrychange', () => {
                    const selectedCountryData = phoneInput.getSelectedCountryData();
                    const selectedCountryMccId1 = codes.find(
                        (country) => country.country_code === selectedCountryData.iso2.toUpperCase()
                    ).mcc_id;
                    setSelectedCountryMccId1(selectedCountryMccId1);
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                window.location.href = ('/')
            }
        }
    };

    const togglePasswordform = () => {
        setShowForm1(true);
        setShowForm2(false);
        setShowForm3(false);
        setSigninClicked(false);
        setSignupClicked(false);
    }

    const isPasswordValids = () => {
        return (
            !isInvalid.letter &&
            !isInvalid.capital &&
            !isInvalid.special &&
            !isInvalid.number &&
            !isInvalid.length
        );
    };
    const sign = async (e) => {

        e.preventDefault();
        if (name == "") {
            setNameError('Please Enter Your Name');
            return false;
        }
        else {
            setNameError('');
        }
        const specialCharacterPattern = /[!@#$%^&*(),.?":{}|<>]/;
        if (specialCharacterPattern.test(name)) {
            setNameError('Symbols are not allowed in a name');
            return false;
        }
        else {
            setNameError('');
        }

        if (email == "") {
            setEmailError('Please Enter Your Email');
            return false;
        }
        else {
            setEmailError('')
        }
        if (mobile == "") {
            setMobileError('Please Enter Your Mobile Number');
            return false;
        }
        else {
            setMobileError('')
        }
        if (/^\+?\d+$/.test(mobile)) {
            const mobileNumber = parseInt(mobile, 10);
            if (mobileNumber < 100000000 || mobileNumber > 999999999999999) {
                setMobileError('Mobile number must be between 9 and 15 digits.');
                return false;
            }
        } else {
            setMobileError('Mobile number must be numeric.');
            return false;
        }
        if (passwords == "") {
            setPasswordsError('Password Should Be 8 Characters');
            return false;
        }
        else {
            setPasswordsError('');
        }
        if (!isPasswordValids()) {
            setPasswordsError('Password does not meet the criteria.')
            return;
        }
        else {
            setPasswordsError('');
        }
        if (confirmpassword == "") {
            setConfirmpasswordError('Please Confirm Correct Password');
            return false;
        }
        else {
            setConfirmpasswordError('');
        }
        if (passwords != confirmpassword) {
            setConfirmpasswordError('Sorry, Passwords do not match');
            return false;
        }
        else {
            setConfirmpasswordError('');
        }
        if (selectedCountrycode == "") {
            setSelectedCountrycodeError('Please Select Country');
            return false;
        }
        else {
            setSelectedCountrycodeError('');
        }
        var data = JSON.stringify({
            name: name,
            email: email,
            mobile: mobile,
            mcc_id: selectedCountryMccId1,
            country_id: selectedCountrycode,
            password: passwords,
            confirmpassword: confirmpassword,
            verify: 'email',

        })
        try {
            setIsLoading(true)
            const response = await axiosInstance.post(
                signupUrl, data
            );
            if (response.status === 200) {
                var token = response.data.data.access_token;
                localStorage.removeItem('access_token');
                localStorage.setItem('access_token', token);
                const existingDetails = JSON.parse(localStorage.getItem('user_details')) || [];
                existingDetails.forEach(user => {
                    user.is_login = 0;
                });
                const newUserDetails = {
                    access_token: token,
                    is_active: 1,
                    is_login: 1,
                };
                const updatedUserDetails = [...existingDetails, newUserDetails];
                localStorage.setItem('user_details', JSON.stringify(updatedUserDetails));
                setShowForm4(true);
                startCountdown1();
                setShowForm2(false);
                setVerify(!verify);
                setIsLoading(false)
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setIsLoading(false);
                    toast.error(error.response.data.mobile_error, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else if (error.response.status === 422) {
                    setIsLoading(false);
                    toast.error(error.response.data.mobile_error, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }
        }
        finally {
            setIsLoading(false);
        }
    }
    const signupotpchange = (e) => {
        const inputvalue = e.target.value;
        setSignupotp(inputvalue);
    }
    const startCountdown1 = () => {
        let seconds = 120;
        const intervalId = setInterval(() => {
            if (seconds > 0) {
                seconds--;
                setCountdown1(seconds);
            } else {
                clearInterval(intervalId);
            }
        }, 1000);
    };
    const resendotp1 = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('access_token');
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        };
        const data = {
            verify: /^\+?\d+$/.test(forgetmail) ? 'mobile' : 'email'
        };

        try {
            const response = await axiosInstance.post(resendOTP, data, { headers });
            if (response.status === 200) {
                const token = response.data.data.access_token;
                localStorage.setItem('access_token', token);
                toast.success("Resend OTP sent successfully...", {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    backgroundColor: '#02CD8D'
                });
                startCountdown1();
            }
        }
        catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    toast.error(error.response.data.mobile_error, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else if (error.response.status === 422) {
                    toast.error(error.response.data.mobile_error, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }
        }
    }
    const signupverifyotp = async (e) => {
        e.preventDefault();
        var token = localStorage.getItem('access_token')
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        };
        var data = JSON.stringify({
            otp: signupotp
        })
        try {
            const response = await axiosInstance.post(signupverify, data, { headers });
            if (response.status === 200) {
                var token = response.data.data.access_token;
                localStorage.removeItem('access_token');
                localStorage.setItem('access_token', token);
                startCountdown1();
                localStorage.setItem('is_signup', 1);
                navigate('/profile');
            }
        }
        catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    toast.error(error.response.data.mobile_error, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else if (error.response.status === 422) {
                    toast.error(error.response.data.mobile_error, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }
        }
    }
    const [emailVerified, setEmailVerified] = useState(false);
    const [mobileVerified, setMobileVerified] = useState(false);
    //   forgetpage
    const forgetpage = () => {
        navigate('/forgotpage');
        setShowForm5(false);
        setShowforgototp(false);
        setNewpassword(false);
        setShowForm1(false);
        setShowForm2(false);
    }
    const forgetsign = () => {
        setShowForm5(false);
        setShowForm1(true);
    }
    const forgetsignup = () => {
        setShowForm2(true);
        setShowForm5(false);
    }
    // useEffect(() => {
    //     const fetchData1 = async () => {
    //         try {
    //             const response = await fetch(country_code);
    //             const data = await response.json();
    //             if (data.message === 'Success') {
    //                 const mccList = data.data;
    //                 const codes = mccList.map((mcc) => ({
    //                     country_code: mcc.country_code,
    //                     dial_code: mcc.code,
    //                     mcc_id: mcc.id,
    //                 }));
    //                 setCountryCodes(codes);
    //             }
    //         } catch (error) {
    //             if (error.response && error.response.status === 401) {
    //                 window.location.href = ('/');
    //             }
    //         }
    //     };

    //     fetchData1();
    // }, []);
    // const [initialCountryCode1, setInitialCountryCode1] = useState('IN');
    // useEffect(() => {
    //     if (showCountryInputforget) {
    //         // Initialize the international telephone input
    //         const phoneInputField = document.querySelector('#login_country1');
    //         const phoneInput = intlTelInput(phoneInputField, {
    //             initialCountry: initialCountryCode1,
    //             separateDialCode: false,
    //             formatOnDisplay: false,
    //             utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
    //         });
    //         const selectedCountryData = countryCodes.find(
    //             (country) => country.country_code === initialCountryCode1
    //         );
    //         if (selectedCountryData) {
    //             setSelectedCountryMccId2(selectedCountryData.mcc_id);
    //         }
    //         phoneInputField.addEventListener('countrychange', () => {
    //             const selectedCountryData = phoneInput.getSelectedCountryData();
    //             const selectedCountryMccId2 = countryCodes.find(
    //                 (country) => country.country_code === selectedCountryData.iso2.toUpperCase()
    //             ).mcc_id;
    //             setSelectedCountryMccId2(selectedCountryMccId2);
    //         });
    //     }
    // }, [showCountryInputforget, countryCodes, initialCountryCode1, countryCodes]);
    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(country_code);
            if (response.status === 200) {
                const mccList = response.data.data;
                const codes = mccList.map((mcc) => ({
                    country_code: mcc.country_code,
                    dial_code: mcc.code,
                    mcc_id: mcc.id,
                }));
                var ip = ipCountryCode
                const selectedCountryData = codes.find(
                    (country) => country.country_code === ip
                );
                if (selectedCountryData) {
                    setSelectedCountryMccId2(selectedCountryData.mcc_id);
                }
                // Wait for DOM to load, then initialize intlTelInput
                const phoneInputField = document.querySelector('#login_country1');
                const phoneInput = intlTelInput(phoneInputField, {
                    initialCountry: selectedCountryData.country_code,
                    separateDialCode: false,
                    formatOnDisplay: true,
                    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
                });

                phoneInputField.addEventListener('countrychange', () => {
                    const selectedCountryData = phoneInput.getSelectedCountryData();
                    const mcc_idchange = codes.find(
                        (country) => country.country_code === selectedCountryData.iso2.toUpperCase()
                    ).mcc_id;
                    setSelectedCountryMccId2(mcc_idchange)
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                window.location.href = '/';
            }
            else if (error.response.status === 422) {
                toast.error(error.response.data.mobile_error, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }
    }
    useEffect(() => {
        if (selectedOption === 'mobile') {
            fetchData();
        }
    }, [selectedOption, ipCountryCode])
    const mccid2 = (e) => {
        const inputValue = e.target.value;
        setSelectedCountryMccId2(inputValue);
    }
    const forgetchange = (e) => {
        const inputUsername = e.target.value;
        setForgetmail(inputUsername);

        if (/^\+?\d+$/.test(inputUsername)) {
            setShowCountryInputforget(true);
        } else {
            setShowCountryInputforget(false);
        }
    };
    // const [loading, setLoading] = useState(false);
    const resotp = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);

            let data = {};

            if (selectedOption === 'email') {
                // Handle email case
                data = {
                    username: forgotpassemail,
                    data_mode: 'email'
                };
            } else if (selectedOption === 'mobile') {
                // Handle mobile case
                data = {
                    username: forgotpassmobile,
                    mcc_id: selectedCountryMccId2,
                    data_mode: 'mobile'
                };
            }
            console.log(data);
            const response = await axiosInstance.post(forgetotp, data);

            if (response.status === 200) {
                setIsLoading(false);
                const token = response.data.data.access_token;
                localStorage.setItem('auth_token', token);
                // setResendhide(true);
                // setOtphide(true);
                // setIsreadonly(true);
                // setSendverifybutton(true);
                // setSendotpbutton(false);
                // startCountdown();
                // setDoyousign(false);
                toast.success(response.data.message, {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    backgroundColor: '#02CD8D'
                });
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setIsLoading(false);
                    toast.error(error.response.data.mobile_error, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else if (error.response.status === 422) {
                    setIsLoading(false);
                    if (!toast.isActive('422Error')) {
                        setIs422ErrorToastActive(is422ErrorToastActive)
                        toast.error(error.response.data.mobile_error, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            toastId: '422Error'
                        });
                    }
                }
            }
        }
    };
     
    
    const handleInputChangeotp = (event) => {
        // Allow only numeric characters
        const isNumber = /^\d*$/.test(event.key);

        if (!isNumber) {
            event.preventDefault();
        }
    };
    
    const handlePaste = (event) => {
        // Handle pasted text and allow only numeric characters
        const pastedText = event.clipboardData.getData('text/plain');
        const isNumeric = /^\d*$/.test(pastedText);

        if (!isNumeric) {
            event.preventDefault();
        } else {
            // Update the state with the pasted numeric characters
            setOtp(pastedText);
        }
    };
    // const resotp = async (e) => {
    //     e.preventDefault();
    //     if (!forgetmail) {
    //         setResendhide(false);
    //         setOtphide(false);
    //     } else {
    //         try {
    //             setIsLoading(true);
    //             const data = {
    //                 username: forgetmail,
    //                 mcc_id: selectedCountryMccId2,
    //                 data_mode: /^\+?\d+$/.test(forgetmail) ? 'mobile' : 'email'
    //             };

    //             const response = await axios.post(forgetotp, data);
    //             if (response.status === 200) {
    //                 setIsLoading(false);
    //                 const token = response.data.data.access_token;
    //                 localStorage.setItem('auth_token', token);
    //                 setResendhide(true);
    //                 setOtphide(true);
    //                 setIsreadonly(true);
    //                 setSendverifybutton(true);
    //                 setSendotpbutton(false);
    //                 startCountdown();
    //                 setDoyousign(false);
    //                 toast.success(" OTP sent successfully...", {
    //                     autoClose: 2000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                     theme: "colored",
    //                     backgroundColor: '#02CD8D'
    //                 });
    //                 setOtpsuccess('OTP sent successfully...');
    //             }
    //         } catch (error) {
    //             if (error.response) {
    //                 if (error.response.status === 401) {
    //                     setIsLoading(false);
    //                     toast.error(error.response.data.mobile_error, {
    //                         position: "top-right",
    //                         autoClose: 2000,
    //                         hideProgressBar: false,
    //                         closeOnClick: true,
    //                         pauseOnHover: true,
    //                         draggable: true,
    //                         progress: undefined,
    //                         theme: "colored",
    //                     });
    //                 } else if (error.response.status === 422) {
    //                     setIsLoading(false);
    //                     toast.error(error.response.data.mobile_error, {
    //                         position: "top-right",
    //                         autoClose: 2000,
    //                         hideProgressBar: false,
    //                         closeOnClick: true,
    //                         pauseOnHover: true,
    //                         draggable: true,
    //                         progress: undefined,
    //                         theme: "colored",
    //                     });
    //                 }
    //             }
    //         }
    //     }
    // };
    const otpchange = (e) => {
        const otp = e.target.value;
        setOtpfield(otp);
    }
    const startCountdown = () => {
        let seconds = 120;
        const intervalId = setInterval(() => {
            if (seconds > 0) {
                seconds--;
                setCountdown(seconds);
            } else {
                clearInterval(intervalId);
                setResendhide(true);
            }
        }, 1000);
    };
    const resendotp = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('auth_token');
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        };
        const data = {
            verify: /^\+?\d+$/.test(forgetmail) ? 'mobile' : 'email'
        };

        try {
            setIsLoading(true);
            const response = await axiosInstance.post(resendOTP, data, { headers });
            if (response.status === 200) {
                setIsLoading(false);
                const token = response.data.data.access_token;
                localStorage.setItem('auth_token', token);
                toast.success("Resend OTP sent successfully...", {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    backgroundColor: '#02CD8D'
                });
                startCountdown();
            }
        }
        catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setIsLoading(false);
                    toast.error(error.response.data.mobile_error, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else if (error.response.status === 422) {
                    setIsLoading(false);
                    toast.error(error.response.data.mobile_error, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }

        }

    }
    const otpverify = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('auth_token')
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        };
        var data = JSON.stringify({
            otp: otpfield
        })
        try {
            const response = await axiosInstance.post(verifyotp, data, { headers });
            if (response.status === 200) {
                const token = response.data.data.access_token;
                localStorage.setItem('auth_token', token);
                toast.success(" OTP verify sent successfully...", {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    backgroundColor: '#02CD8D'
                });
                setNewpassword(true);
                setShowForm5(false);
                setShowForm1(false);
            }
        }
        catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    toast.error(error.response.data.mobile_error, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else if (error.response.status === 422) {
                    toast.error(error.response.data.mobile_error, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }
        }
    }
    const forgotpasswordchange = (e) => {
        const inputvalue = e.target.value;
        setForgotpassword(inputvalue);
        const lowercaseLetters = /[a-z]/g;
        const uppercaseLetters = /[A-Z]/g;
        const specialCharacters = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-|=]/g;
        const numbers = /[0-9]/g;
        setIsInvalid1({
            letter: !inputvalue.match(lowercaseLetters),
            capital: !inputvalue.match(uppercaseLetters),
            special: !inputvalue.match(specialCharacters),
            number: !inputvalue.match(numbers),
            length: inputvalue.length < 8,

        });
    }
    const forgotconfirmpasswordchange = (e) => {
        const inputvalue = e.target.value;
        setForgotconfirmpassword(inputvalue);
    }
    const handleFocus1 = () => {
        setIsFocused1(true);
    };
    const getPasswordValidationClass1 = (isValid) => {
        return isValid ? 'valid' : 'invalid';
    };
    const handleBlur1 = () => {
        setIsFocused1(false);
    };
    const [isInvalid1, setIsInvalid1] = useState({
        letter: true,
        capital: true,
        special: true,
        number: true,
        length: true,
    });
    const newpasswordconfirm = async (e) => {
        e.preventDefault();
        if (!isPasswordValid()) {
            setForgotpassworderror('Password does not meet the criteria.')
            return false;
        }
        else {
            setForgotpassworderror('');
        }
        if (!forgotpassword) {
            setForgotpassworderror('Please Enter Your New Password')
            return false;
        }
        else {
            setForgotpassworderror('');
        }
        if (forgotpassword != forgotconfirmpassword) {
            setForgotconfirmpasswordError('Sorry, Passwords do not match');
            return false;
        }
        else {
            setForgotconfirmpasswordError('');
        }

        const token = localStorage.getItem('auth_token')
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        };
        var data = JSON.stringify({
            password: forgotpassword
        })
        try {
            const response = await axiosInstance.post(resetpasswordset, data, { headers });
            if (response.status === 200) {
                setShowForm1(true);
                setNewpassword(false);
                setShowForm5(false);
                toast.success("Password change successfully...", {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    backgroundColor: '#02CD8D'
                });
                const token = response.data.data.access_token;
                localStorage.setItem('auth_token', token);
            }
        }
        catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    toast.error(error.response.data.mobile_error, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else if (error.response.status === 422) {
                    toast.error(error.response.data.mobile_error, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }
        }
    }
    const isPasswordValid = () => {
        return (
            !isInvalid1.letter &&
            !isInvalid1.capital &&
            !isInvalid1.special &&
            !isInvalid1.number &&
            !isInvalid1.length
        );
    };
    const verifyemailedit = () => {
        setisEditble(!isEditble);
    }
    const verifymobileedit = () => {
        setisEditbles(!isEditables);
    }
    const verifyemailchange = (e) => {
        // setVerifyemail(email);
        setVerifyemail(e.target.value)
    }
    const handleSelectChange = (e) => {
        setSelectedOption(e.target.value);
    };
    const con = signupClicked ? 'img-logo1' : 'img-logo'
    const canProceed = emailVerified || mobileVerified;
    const containerClass = signupClicked ? 'expanded-container' : '480px';
    const Containerfluidclass = verify ? 'expended-containerfluid' : '330px';
    return (
        <>
            {/* <div className="container-fluid d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}> */}
            {/* <ToastContainer
// position="top-right"
   position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
onOpen={() => {
    // Scroll to the top of the page when a toast is displayed
    window.scrollTo(0, 0);
  }}
className="toast"
/>     */}
            {isLoading && (
                <>
                    <div className="loading-overlay style-2">
                        <div className="loading-wheel">
                        </div>
                    </div>
                </>
            )}
               <div className={`${con}`}>             
      <img src={logo} alt="logo" className="logo"></img>
      </div>
            <div className={`container ${containerClass}`} style={{ maxWidth: '30rem', position: "relative", zIndex: "-1", width: '100%', padding: "0.1875rem", borderRadius: '1.4375rem', backgroundColor: "#02CD8D" }}>
                <div className={`container-fluid align-items-center ${Containerfluidclass}`} style={{ width: '100%', backgroundColor: 'white', borderTopLeftRadius: '1.25rem', borderTopRightRadius: '1.25rem', marginTop: '0px' }}>

                    {showForm1 && (
                        <form className="formdsaas" action=''  >
                            <div className='text-center'>
                                <h6 className='pt-3'>EVzone</h6>
                            </div>
                            <div className='mb-1 row' style={{ padding: '0px 1.5rem 1.5rem 1.5rem' }}>
                                <div className='signinsignup'>
                                    <div className='col-12 text-center'>
                                        <button type='button' className={`btn2 ${showForm1 ? 'activeButton' : 'inactiveButton'}`} onClick={toggleLoginForms}>Sign In</button><button type='button' className={`btn2 ${showForm2 ? 'activeButton' : 'inactiveButton'}`} onClick={toggleSignupForm}>Sign Up</button>
                                    </div>
                                </div>
                            </div>
                            {!showCountryInput ? (
                            <input
                                            type='text'
                                            className='form-control  logininputbox'
                                            id='username'
                                            name='username'
                                            ref={inputRef}
                                            placeholder='Email or Mobile'
                                            autoComplete='off'
                                            value={username}
                                            onChange={handleUsernameChange}
                                            onKeyPress={handleKeyPress}
                                        />
                            ) : (
                                <>
                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
        <div style={{ position: 'absolute', left: 0, zIndex: 1 }}>
          <Select
            options={countryOptions}
            onChange={handleCountryChange}
            value={countryOptions.find(option => option.value === countryCode)}
            components={{ SingleValue: customSingleValue, Option: customOption }}
            placeholder=""
            isLoading={isLoading} // Pass the loading state to Select
            styles={{
              control: (provided) => ({
                ...provided,
                width:'80px',
                height: '100%',
                border: 'none',
                boxShadow: 'none',
                background: 'transparent',
                display: 'flex',
                alignItems: 'center',
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                padding: '0 8px',
              }),
              indicatorSeparator: () => ({
                display: 'none',
              }),
              singleValue: (provided) => ({
                ...provided,
                display: 'flex',
                alignItems: 'center',
              }),
              valueContainer: (provided) => ({
                ...provided,
                padding: '0',
                display: 'flex',
                alignItems: 'center',
              }),
              input: (provided) => ({
                ...provided,
                margin: '0',
                padding: '0',
              }),
              placeholder: (provided) => ({
                ...provided,
                margin: '0',
                padding: '0',
              }),
              menu: (provided) => ({
                ...provided,
                minWidth: '300px',
              }),
            }}
          />
        </div>
        <p style={{ position: 'absolute', left:'78px', top: '51%', transform: 'translateY(-50%)', cursor: 'pointer',color:'hsl(0, 0%, 20%)',fontSize:'14px',lineHeight:'1.5',marginBottom:'0px',fontWeight:'400' }}>{dialCode}</p>
        <input
          type="text"
          className={`form-control docform ${username ? 'docform-active' : ''}`}
          id="mobile"
          ref={mobileInputRef}
          style={{ paddingLeft: '120px', width: '100%' }}
          placeholder="Mobile Number"
          value={username}
          name="mobile"
          onPaste={(e) => handlePaste1(e)}
        //   onKeyPress={handleKeyPress}
          onChange={handleUsernameChange}
        />
      </div>
      <input
        type="hidden"
        className="form-control signupemalichange"
        placeholder="Mobile Number"
        value={mccId}
        onChange={(e) => setMccId(e.target.value)}
      />
      </>
        )}
        {usernameError && (<div className='errors'>{usernameError}</div>)}
                            {/* <div className='mb-3' style={{ position: 'relative', width: "100%", maxWidth: "444px", margin: '0 auto' }}>
                                <div className='row'>
                                    {showCountryInput && (
                                        <div style={{ flex: '1', display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type='text'
                                                className='form-control'
                                                id='login_country'
                                                name='login_country'
                                                onChange={mccid}
                                                value={selectedCountryMccId}
                                                style={{ padding: '0px', height: '46px' }}
                                            />
                                        </div>

                                    )}
                                    <div style={{ flex: showCountryInput ? '11' : '11', padding: showCountryInput ? '0px 12px 0px 0px' : '0 12px', display: 'flex', flexDirection: 'column', }} >
                                        <input
                                            type='text'
                                            className='form-control  logininputbox'
                                            id='username'
                                            name='username'
                                            placeholder='Email or Mobile '
                                            autoComplete='off'
                                            value={username}
                                            onChange={handleUsernameChange}
                                            onKeyPress={handleKeyPress}
                                        />
                                        <div style={{ position: 'absolute', right: '10px', top: '70%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                            <p onClick={toggleLoginForm} style={{ fontSize: '10px', fontWeight: "500", color:'#02CD8D'}}>Login With OTP</p>
                                        </div>
                                    </div>
                                    {usernameError && (<div className='errors'>{usernameError}</div>)}
                                </div>
                            </div> */}
                            <div className="mb-2 mt-3 password">
                                <div style={{ position: "relative" }}>
                                    <input type={showPassword ? 'text' : 'password'} className="form-control logininputbox" id="userpassword" style={{ position: "relative", border: '1px solid #838383', height: '46px' }}
                                        placeholder="Password" name="password" value={password} onChange={handleInputChanges} onKeyPress={handleKeyPress} />
                                    <p className="toggle-password" onClick={togglePasswordVisibility} style={{ position: 'absolute', marginRight: '20px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                        {showPassword ? (
                                            <img src={eye} className='eyeslash' alt="Hide Password" />
                                        ) : (
                                            <img src={eyeslash} className='eyeslash' alt="Show Password" />
                                        )}
                                    </p>
                                </div>
                                {passwordError && <div className="error  mt-0">{passwordError}</div>}
                            </div>
                            <div className=" row mt-3 loginforget" style={{ margin: '0 auto',padding:'0px 5px 0px 0px' }}>
                                {/* <div className="col-6">
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input loginpagecheckbox" checked={rememberMe} onChange={handleRememberMeChange}
                                            id="customControlInline" style={{ width: '15.22px', height: '15.22px', boxShadow: 'none', marginTop: '-1px', backgroundColor: 'white' }} />
                                        <label className="form-check-label" style={{ fontSize: "12px", fontWeight: "600", lineHeight: "18px", color: '#3E3E3E' }} htmlFor="customControlInline">Remember me
                                        </label>
                                    </div>
                                </div> */}
                                <div className="col-12 text-end forget">
                                    <p style={{ fontSize: "12px", fontWeight: "600", lineHeight: "18px", marginTop: '2px', color: '#3E3E3E', cursor: 'pointer' }} onClick={forgetpage}>Forgot Password?</p>
                                </div>
                            </div>

                            {error && (
                                <div className="mb-2 text-center text-danger">{error}</div>
                            )}
                            <div className="mb-0 mt-1 text-center" >
                                <button className={`btn mb-2 ${(!username || !password) ? 'grey' : ''}`}
                                    type="submit" disabled={!username || !password || isLoading || is422ErrorToastActive} onClick={login}>Sign in</button>
                            </div>
                        </form>
                    )}

                    {showForm2 && (
                        <form className="form-horizontal" action='' onSubmit={sign} >
                            <div className='text-center'>
                                <h6 className='pt-3'>EVzone</h6>
                            </div>
                            <div className='mb-1  row' style={{ padding: '0px 1.5rem 1.5rem 1.5rem' }}>
                                <div className='signinsignup'>
                                    <div className='col-12 text-center'>
                                        <button type='button' className={`btn2 ${showForm1 ? 'activeButton' : 'inactiveButton'}`} onClick={toggleLoginForms}>Sign In</button><button type='button' className={`btn2 ${showForm2 ? 'activeButton' : 'inactiveButton'}`} onClick={toggleSignupForm}>Sign Up</button>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3  signupbody text-center" style={{ margin: '0 10px' }}>
                                <input type="text" className="form-control signupinputbox" id="username"
                                    placeholder="Name" name="username" value={name} onChange={namechange} onkeyPress={handleKeyPress} />
                                {nameerror && <div className="errors  mt-0">{nameerror}</div>}
                            </div>
                            <div className="mb-3 row " style={{ margin: '0 auto' }}>
                                <div className="col-12 col-md-6 col-sm-6 mb-3 mb-sm-0 mb-lg-0 mb-md-0 mb-xl-0 mb-xl-0" >
                                    <input type="text" className="form-control signupemalichange" id="email"
                                        placeholder="Email Address " name="email" value={email} onChange={emailchange} onkeyPress={handleKeyPress} />
                                    {emailerror && <div className="errors  mt-0">{emailerror}</div>}
                                </div>
                                <div className="col-12 col-md-6 col-sm-6">
                                    <input type="text" className="form-control signupemalichange  mobilechange" id="mobile" 
                                        placeholder="Mobile Number " name="mobile" value={mobile} onChange={mobilechange} onkeyPress={handleKeyPress} />
                                    <input type="hidden" className="form-control signupemalichange "
                                        placeholder="Mobile Number" value={selectedCountryMccId1} onChange={mccid1} />
                                    {mobileerror && <div className="errors  mt-0">{mobileerror}</div>}
                                </div>

                            </div>
                            <div className="mb-3 row  passwords" style={{ margin: '0 auto' }} >

                                <div className="col-12 col-md-6 col-sm-6 mb-3 mb-sm-0 mb-lg-0 mb-md-0 mb-xl-0 mb-xl-0 " style={{ position: 'relative' }}>
                                    <div style={{ position: "relative" }}>
                                        <input type={showPasswords ? 'text' : 'password'} className="form-control signupemalichange" id="userpassword"
                                            placeholder="Password" name="password" value={passwords} onChange={passwordchange} onFocus={handleFocus} onBlur={handleBlur} onkeyPress={handleKeyPress} />
                                        <p className="toggle-passwords" onClick={togglePasswordVisibilitys} style={{ position: 'absolute', marginRight: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                            {showPasswords ? (
                                                <img src={eye} className='eye' style={{ width: '20px', height: 'auto' }} alt="Hide Password" />
                                            ) : (
                                                <img src={eyeslash} className='eye' style={{ width: '20px', height: 'auto' }} alt="Show Password" />
                                            )}
                                        </p>
                                    </div>
                                    {passwordserror && <div className="errors  mt-0">{passwordserror}</div>}
                                </div>

                                <div className="col-12 col-md-6 col-sm-6 passwordss" style={{ position: 'relative' }}>
                                    <div style={{ position: "relative" }}>
                                        <input type={showPasswordsconfirm ? 'text' : 'password'} className="form-control signupemalichange" id="confirmpassword"
                                            placeholder="Confirm Password" name="password" value={confirmpassword} onChange={confirmpasswordchange} onkeyPress={handleKeyPress} />
                                        <p className="toggle-passwordss" onClick={togglePasswordVisibilityconfirm} style={{ position: 'absolute', right: '20px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                            {showPasswordsconfirm ? (
                                                <img src={eye} className='eye' style={{ width: '20px', height: 'auto' }} alt="Hide Password" />
                                            ) : (
                                                <img src={eyeslash} className='eye' style={{ width: '20px', height: 'auto' }} alt="Show Password" />
                                            )}
                                        </p>
                                    </div>
                                    {confirmpassworderror && <div className="errors  mt-0">{confirmpassworderror}</div>}
                                </div>

                                {isFocused && (
                                    <div id="message" className={`password-message ${isFocused ? "initial-focus" : ""}`}>
                                        <h6>Password must contain the following:</h6>
                                        <p className={getPasswordValidationClass(isInvalid.letter)}>
                                            At least a Lowercase
                                        </p>
                                        <p className={getPasswordValidationClass(isInvalid.capital)}>
                                            At least a Capital (Uppercase)
                                        </p>
                                        <p className={getPasswordValidationClass(isInvalid.special)}>
                                            At least Special Character
                                        </p>
                                        <p className={getPasswordValidationClass(isInvalid.number)}>A number</p>
                                        <p className={getPasswordValidationClass(isInvalid.length)}>
                                            Minimum 8 Characters
                                        </p>
                                    </div>
                                )}

                            </div>
                            <div className="mb-2 row  " style={{ margin: '0 auto' }} >
                                <div className="col-6 col-md-6 mb-3 mb-sm-0 mb-lg-0 mb-md-0 mb-xl-0 mb-xl-0">
                                    <select className="form-control signupinputbox" value={selectedCountrycode} onChange={countrycodechange} onkeyPress={handleKeyPress} id="countryselectbox"
                                        name="countryselectbox">
                                        <option value=''> Select Country</option>
                                        {countries.map((country) => (

                                            <option key={country.id} value={country.id}>
                                                {country.name}
                                            </option>
                                        ))}
                                    </select>
                                    {selectedCountrycodeerror && <div className="errors  mt-0">{selectedCountrycodeerror}</div>}
                                </div>
                                <div className="mb-1 col-6 col-md-6">
                                    <input type="text" className="form-control signupinputbox" id="referral"
                                        placeholder="Referral code" name="mobile" value={referral} onChange={referralchange} onkeyPress={handleKeyPress} />
                                </div>
                            </div>

                            <div className="form-check mb-2" style={{ margin: '0 10px' }}>
                                <input type="checkbox" className="form-check-input"
                                    id="customControlInline" style={{ width: '15px', height: '15px', marginTop: '-2px', boxShadow: 'none', outline: 'black' }} />
                                <label className="form-check-label" for="customControlInline" style={{ fontWeight: '500', fontSize: '10px', lineHeight: '15px', color: '#696969' }}>You must agree our terms and conditions
                                </label>
                            </div>
                            {errors && (
                                <div className=" text-center text-danger">{errors}</div>
                            )}
                            <div className=" text-center">
                                <button className="btn mb-2"
                                    type="submit">Submit</button>
                            </div>

                        </form>
                    )}
                    {showForm3 && (
                        // otp confirm
                        <form className="form" action='#' method="post">
                            <div className='text-center'>
                                <h6 className='pt-3'>EVzone</h6>
                            </div>
                            <div className='mb-1  row' style={{ padding: '0px 1.5rem 1.5rem 1.5rem' }}>
                                <div className='signinsignup' >
                                    <div className='col-12  text-center'>
                                        <button type='button' className={`btn2 ${showForm3 ? 'activeButton' : 'inactiveButton'}`} onClick={toggleLoginForms}  >Sign In </button><button type='button' className={`btn2 ${showForm2 ? 'activeButton' : 'inactiveButton'}`} onClick={toggleSignupForm}>Sign Up</button>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 " style={{ position: 'relative' }}>

                                <input type="text" className="form-control logininputbox" id="mobile" style={{ position: 'relative' }}
                                    placeholder="Email or Mobile " name="mobile"
                                    onChange={handleInputChange} required />
                                <p style={{ position: 'absolute', right: '10px', top: '70%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                    <p onClick={togglePasswordform} style={{ fontSize: '10px', fontWeight: "500",color:'#02CD8D' }}>Login With Password</p>
                                </p>
                                {/* <span onClick={togglePasswordform} style={{ fontSize: '10px', position: 'absolute', right: '10px', left: "70%", marginTop: '-19px', transform: 'translateY(-50%)', cursor: 'pointer' }}>Login With Password </span> */}
                            </div>
                            <div className="mb-3 mt-4 password">
                                <input type='text' className="form-control logininputbox" id="userpassword"
                                    placeholder="Enter OTP" name="password" />

                            </div>

                            <div className="mb-0 ">
                                <button className="btn mb-2"
                                    type="submit" onClick={confirm} >Confirm</button>
                            </div>
                        </form>
                    )}
                    {showForm4 && (
                        <>
                            <form action='' >
                                <div className="text-center pt-2">
                                    <img src={user} alt="Profile" style={{ width: "102px", height: "102px", borderRadius: "50%" }} />
                                </div>
                                <div className='text-center mt-3 mb-3'>
                                    <button type='button' className='verifycontacts'>Verify Your Contacts</button>
                                </div>
                                {verifyinputbox && (
                                    <div className=" text-center mb-3" style={{ position: 'relative', margin: '0px 10px' }}>
                                        <input type="text" className='verifyinputboxs form-control' readOnly={!isEditble} value={verifyemail} onChange={verifyemailchange} />
                                        <p>
                                            <img src={edit} width='18px' height='18px' onClick={verifyemailedit} style={{ position: 'absolute', top: '50%', right: '35%', transform: 'translateY(-50%)', cursor: 'pointer' }} alt="Edit" ></img>
                                        </p>
                                        <p className={`verifyemail ${emailVerified ? 'verified' : ''}`} style={{ position: 'absolute', top: '50%', right: '2%', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} value={verifyemail}>Verify</p>
                                    </div>
                                )}
                                {verifyemailotppage && (
                                    <>
                                        <div className=" text-center mb-3" style={{ position: 'relative', margin: '0px 10px' }}>
                                            <input type="text" className='verifyinputboxs form-control' readOnly value={verifyemail} onChange={verifyemailchange} />
                                            <p style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                                <img src={greentick} width='25px' height='25px'></img>
                                            </p>
                                        </div>
                                        <div className=" text-center mb-3 " style={{ position: 'relative', margin: '0px 10px' }}>
                                            <input type="text" className='verifyinputboxs form-control' value={verifyemail} onChange={(e) => setVerifyemail(e.target.value)} placeholder='Enter OTP send to you' />
                                            <p className={`verifyconfirmemail ${emailVerified ? 'verified' : ''}`} style={{ position: 'absolute', top: '50%', right: '2%', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} value={verifyemail}>Confirm Email</p>
                                        </div>
                                    </>
                                )}
                                {verifyinputboxs && (
                                    <div className=" text-center mb-3 " style={{ position: 'relative', margin: '0px 10px' }}>
                                        <input type="text" className='verifyinputboxs form-control' readOnly={!isEditables} value={verifymobile} onChange={(e) => setVerifymobile(e.target.value)} onKeyPress={(e) => { const isValidInput = /^[0-9]+$/.test(e.key); if (!isValidInput) { e.preventDefault(); } }} />
                                        <p>
                                            <img src={edit} width='18px' height='18px' onClick={verifymobileedit} style={{ position: 'absolute', top: '50%', right: '35%', transform: 'translateY(-50%)', cursor: 'pointer' }} alt="Edit" ></img>
                                        </p>
                                        <p className={`verifyemail ${mobileVerified ? 'verified' : ''}`} style={{ position: 'absolute', top: '50%', right: '2%', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} value={verifymobile}>Verify</p>
                                    </div>
                                )}
                                <div className=' resendhide' style={{ margin: "0px 10px" }}>
                                    <button onClick={countdown1 <= 0 ? resendotp1 : null}
                                        className={`btn mb-2 resendotp ${countdown1 > 0 ? 'inactiveotpsss' : 'activeotpsss'
                                            }`}
                                        type="button"
                                        style={{ cursor: countdown1 > 0 ? 'not-allowed' : 'pointer' }}
                                    // onClick={handleResendClick}

                                    > {countdown1 > 0 ? `Resend OTP (${countdown1}s)` : 'Resend OTP'}
                                    </button>
                                </div>
                                {reotpsend1 && <div className="successotp">{reotpsend1}</div>}
                                {reotpsend1Error && <div className="error">{reotpsend1Error}</div>}
                                {errors && (
                                    <div className=" text-center text-danger">{errors}</div>
                                )}
                                <div className='text-center'>
                                    <button className={`proceed mb-2 ${signupotp ? 'enabled' : ''}`} onClick={signupverifyotp} disabled={!signupotp}>Proceed</button>
                                </div>
                            </form>
                        </>
                    )}
                    {showForm5 && (
                        <form className="formdsaas" action=''  >
                            <div className='d-flex flex-column justify-content-center align-items-center pt-3'>
                                <img src={resetpasswordlock} alt='image' className='resetppasslock '></img>
                                <h6 className='mt-1 resetpassh6'>Reset Your Password</h6>
                                <p className='resetpassp'>To reset your password, you need to provide the email or phone number with which it was registered.</p>
                            </div>
                            <div className='resetpasspad'>
                                <label className='resetpasslabel'>Enter your email address or phone number</label>
                                <div className="select-custom-container3">
                                    <select className="resetpassselect  mt-2 select-custom3" onChange={handleSelectChange} value={selectedOption}>
                                        <option value="email">Email</option>
                                        <option value="mobile">Mobile</option>
                                    </select>
                                </div>
                                {selectedOption === 'email' && (
                                    <input type='email' className='resetemailinput mt-3 form-control' value={forgotpassemail} onChange={(e) => setForgetpassemail(e.target.value)} placeholder='Enter your email'></input>
                                )}

                                {selectedOption === 'mobile' && (
                                    <>
                                        <div className='mb-3  loginpagebody' style={{ position: 'relative', width: "100%", margin: '20px 0' }}>
                                            <div className='row'>
                                                <div style={{ flex: '1', display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        type='text'
                                                        className='form-control resetemailinput'
                                                        id='login_country1'
                                                        name='login_country1'
                                                        onChange={mccid2}
                                                        value={selectedCountryMccId2}
                                                        style={{ padding: '0px' }}
                                                    />
                                                </div>
                                                <div style={{ flex: '11', padding: '0px 12px 0px 0px', display: 'flex', flexDirection: 'column', }}>
                                                    <input type='text' className='col-9 resetemailinput  form-control' value={forgotpassmobile} onChange={(e) => setForgotpassmobile(e.target.value)} placeholder='Enter your mobile number'></input>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className='text-end mt-3'>
                                    <button className='resetcancelbutton me-3'>Cancel</button>
                                    <button className='resetsendcodebutton' onClick={resotp}>Send Code</button>
                                </div>
                            </div>
                            {/* {otphide && (
                                <div className='mb-2 mt-3 '>
                                    <input type='text' className="form-control logininputbox" value={otpfield} onChange={otpchange} id="userpassword" style={{ position: "relative", border: '1px solid #838383', height: '46px' }}
                                        placeholder="OTP" name="OTP" onKeyPress={(e) => { const isValidInput = /^[0-9]+$/.test(e.key); if (!isValidInput) { e.preventDefault(); } }} />
                                </div>
                            )} */}
                            {/* 
                            {resendhide && (
                                <div className='mt-3 resendhide' style={{ margin: "0px 10px" }}>
                                    <button onClick={countdown <= 0 ? resendotp : null}
                                        className={`btn mb-2 resendotp ${countdown > 0 ? 'inactiveotpsss' : 'activeotpsss'
                                            }`}
                                        type="button"
                                        style={{ cursor: countdown > 0 ? 'not-allowed' : 'pointer' }}
                                    // onClick={handleResendClick}

                                    > {countdown > 0 ? `Resend OTP (${countdown}s)` : 'Resend OTP'}
                                    </button>
                                </div>
                            )} */}
                            {/* {doyousign && (
                                <div className=" text-end  forget" style={{ margin: '0px 10px' }}>
                                    <p style={{ fontSize: "12px", fontWeight: "600", lineHeight: "18px", marginTop: '5px', color: '#3E3E3E', cursor: 'pointer' }}>Do you want ?<b className='ms-2' onClick={forgetsign} style={{ color: '#FF7A1A' }}>Sign In</b> / <b onClick={forgetsignup} style={{ color: '#FF7A1A' }} >Sign up</b></p>
                                </div>
                            )}  */}
                            {/* {otperror && <div className="error">{otperror}</div>}
                            {successotp && <div className="successotp">{successotp}</div>}
                            {reotpsend && <div className="successotp">{reotpsend}</div>}
                            {reotpsendrror && <div className="error ">{reotpsendrror}</div>}
                            {sendotpverify && <div className="successotp">{sendotpverify}</div>}
                            {sendotpverifyError && <div className="error ">{sendotpverifyError}</div>}
                            {sendverifybutton && ( 
                                <div className="mb-0 mt-1 text-center">
                                    <button className={`btn mb-2 ${otpfield ? 'activeotp' : 'inactiveotp'} newButton`}
                                        type="submit" style={{ cursor: otpfield ? 'pointer' : 'not-allowed', color: otpfield ? 'white' : 'white', backgroundColor: otpfield ? '#FF7A1A ' : '#838383', }} disabled={!otpfield} onClick={otpverify} >Verify OTP</button>
                                </div>
                            )} */}
                        </form>
                    )}
                    {showforgototp && (
                        <>
                            <form action=''>
                                <div className='d-flex flex-column justify-content-center align-items-center pt-5'>
                                    <img src={resetpasswordotp} alt='image' className='resetppasslock '></img>
                                    <h6 className='mt-2 resetpassh6'>Enter OTP Code</h6>
                                    <p className='resetpassp'>Enter the 6-didit code sent</p>
                                    <p className='resetpassp'>to +251 *************56. Did not receive</p>
                                    <p className='resetpassp'>OTP?<strong className={`${countdown > 0 ? 'inactiveotpsssp' : 'activeotpsssp'}`} style={{ cursor: countdown > 0 ? 'not-allowed' : 'pointer' }} onClick={countdown <= 0 ? resendotp : null}>Resend OTP</strong></p>
                                    <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    renderInput={(props) => <input {...props}  onPaste={handlePaste} className="custom-inputotp mt-3" onKeyPress={handleInputChangeotp} />}
                                />
                                 <div className='mt-3  d-flex justify-content-center w-100'>
                                    <button type="button" className='resendotp'> {countdown > 0 ? `${countdown}S` : `${countdown}S`}
                                    </button>
                                </div>
                                <p className='mt-3 mb-3'>Having any trouble?<strong className='activeotpsssp forgotpad'>Get Help</strong></p>
                                </div>
                            </form>
                        </>
                    )}
                    {newpassword && (
                        <>
                            <form action='' onSubmit={newpasswordconfirm}>
                            <div className='d-flex flex-column justify-content-center align-items-center pt-5'>
                            <img src={resetpasswordkey} alt='image' className='resetppasslock '></img>
                            <h6 className='mt-2 resetpassh6'>Set your new password</h6>
                            <p className='resetpassp resetpassp1'>Your new password should be different from passwords previously used</p>
                                </div>
                                <div className='pt-3 ' style={{ margin: "0px 10px" }}>
                                    <label className='mb-2 resetlabelpass'>New Password</label>
                                    <div style={{ position: 'relative' }}>
                                        <input type={shownewpassword ? 'text' : 'password'} class="form-control resetemailinput1" id="confirmpasswordsss"
                                            placeholder="New Password" name="password" value={forgotpassword} onChange={forgotpasswordchange} onFocus={handleFocus1} onBlur={handleBlur1} />
                                        <p className="toggle-passwordss" onClick={passwordvisiblity} style={{ position: 'absolute', right: '20px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                            {shownewpassword ? (
                                                <img src={blueeye} className='eye' style={{ width: '20px', height: 'auto' }} alt="Hide Password" />
                                            ) : (
                                                <img src={blueeyeslash} className='eye' style={{ width: '20px', height: 'auto' }} alt="Show Password" />
                                            )}
                                        </p>
                                    </div>
                                </div>
                                {forgotpassworderror && <div className="error ">{forgotpassworderror}</div>}
                                {isFocused1 && (
                                    <div id="message3" className={`password-message ${isFocused1 ? "initial-focus" : ""}`}>
                                        <h6>Password must contain the following:</h6>
                                        <p className={getPasswordValidationClass1(isInvalid1.letter)}>
                                            At least a Lowercase
                                        </p>
                                        <p className={getPasswordValidationClass1(isInvalid1.capital)}>
                                            At least a Capital (Uppercase)
                                        </p>
                                        <p className={getPasswordValidationClass1(isInvalid1.special)}>
                                            At least Special Character
                                        </p>
                                        <p className={getPasswordValidationClass1(isInvalid1.number)}>A number</p>
                                        <p className={getPasswordValidationClass1(isInvalid1.length)}>
                                            Minimum 8 Characters
                                        </p>
                                    </div>
                                )}
                                <div className='mt-3 ' style={{ margin: "0px 10px" }}>
                                    <label className='mb-2 resetlabelpass'>Confirm Password</label>
                                    <div style={{ position: 'relative' }}>
                                        <input type={shownewpasswordconfirm ? 'text' : 'password'} className="form-control resetemailinput1 " id="confirmpasswordss"
                                            placeholder="Confirm New Password" name="password" value={forgotconfirmpassword} onChange={forgotconfirmpasswordchange} />
                                        <p className="toggle-passwordss" onClick={passwordvisiblityconfirm} style={{ position: 'absolute', right: '20px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                            {shownewpasswordconfirm ? (
                                                <img src={blueeye} className='eye' style={{ width: '20px', height: 'auto' }} alt="Hide Password" />
                                            ) : (
                                                <img src={blueeyeslash} className='eye' style={{ width: '20px', height: 'auto' }} alt="Show Password" />
                                            )}
                                        </p>
                                    </div>
                                </div>
                                {forgotconfirmpasswordError && <div className="error ">{forgotconfirmpasswordError}</div>}
                                {resetpasswordError && <div className="error ">{resetpasswordError}</div>}
                                {resetpassword && <div className="successotp ">{resetpassword}</div>}
                                <div className="text-center mt-4">
                                    <button
                                        className={`forgotsave mb-3 ${forgotpassword && forgotconfirmpassword ? 'activatebuttonstyle' : ' inactivebuttonstyle'}`}
                                        style={!forgotpassword || !forgotconfirmpassword ? { cursor: 'not-allowed' } : {}}
                                        disabled={!forgotpassword && !forgotconfirmpassword}
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </>
                    )}</div>

                <div className="mb-4 pt-3 fw-800 text-light text-center">

                    <p>Sign in with</p>

                </div>
                <div className='mb-3 login-icons  aligin-items-center justify-content-center row '>
                    <div className='col-4 col-md-2 '>
                        <img src={google} alt='google' style={{ width: "45px", height: 'auto', color: "white", border: '1px solid white', backgroundColor: "rgb(20, 212, 148)", borderRadius: '5px', padding: '8px' }} ></img>
                    </div>
                    <div className='col-4 col-md-2'>
                        <img src={facebook} alt='google' style={{ width: "45px", height: 'auto', color: "white", border: '1px solid white', backgroundColor: "rgb(20, 212, 148)", borderRadius: '5px', padding: '8px' }} ></img>
                    </div>
                    <div className='col-4 col-md-2'>
                        <img src={twitter} alt='google' style={{ width: "45px", height: 'auto', color: "white", border: '1px solid white', backgroundColor: "rgb(20, 212, 148)", borderRadius: '5px', padding: '8px' }} ></img>
                    </div>
                </div>
            </div>
            <Modal show={showedit1} dialogClassName="document-dialog1" contentClassName="document-content1" centered>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                </Modal.Body>
            </Modal>
            {/* </div> */}
        </>
    );
}

export default WithToast(Log);
