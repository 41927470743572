import React, { useEffect, useState} from "react";
import { useNavigate ,useLocation} from "react-router-dom";
import Navbars from "../navbar";
import leftarrow from '../images/arrow-left-01.png';
import '../home.css';
import { genderapi } from "../api/url";
// import axios from "axios";
import axiosInstance from "../api/common/axiosinstand";
// import { UseToast } from "../api/common/toastcontext";
// import {toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { extractLoginUser,handleApiError } from "../api/common/commonapi";
import WithToast from "../api/common/withtoast";
const namechangeurl = genderapi();
function Namechange({showErrorToast}) {
  const login_user = extractLoginUser();
  const location = useLocation();
  // const { is422ErrorToastActive, showErrorToast,showSuccessToast } = UseToast();
  const { state } = location;
  const name = state && state.userName ? state.userName : '';
  const [namechange,setNamechange] = useState(name);
  const [nameerror , setNameError] = useState('');
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [error422,setError422] = useState('');
  const [isLoading4,setIsLoading4] = useState(false);
  const navigate = useNavigate();
  const back = () => {
    navigate(`/u/${login_user}/profile`);
  }
  const cancel = () => {
    navigate(`/u/${login_user}/profile`);
  }
  const handleinputchange = (event) => {
    setNamechange(event.target.value);
    setNameError('');
  }
  const checck = () => {
    const valid = (
      namechange !== ''
    )
    setIsSaveButtonEnabled(valid);
  }
  useEffect(()=>{
    checck();
  },[namechange])
  const save = async() => {
    if(!namechange){
       setNameError('Required');
       return false
    }
    else{
      setNameError('');
    }
    const specialCharacterPattern = /[!@#$%^&*(),.?":{}|<>]/;
    if (specialCharacterPattern.test(namechange)) {
        setNameError('Name should not contain special characters');
        return false;
     }
     else{
         setNameError('');
     }
    if(!namechange.trim()){
      return
    }
    var data = JSON.stringify({
      name : namechange
    })
    try{
      setIsLoading4(true);
    const response = await axiosInstance.post(namechangeurl,data);
    if(response.status === 200){
      setNameError('');
      setError422('');
      setIsLoading4(false);
       navigate(`/u/${login_user}/profile`);
    }
    }
    catch(error){
      handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
    }
  }

  return (
    <>
     {isLoading4 && (
                <>   
                    <div className="loaderover">
                    <div className="loader ">
                    </div>
                    </div>
                </>
            )}
    <div>
      <div className="container-fluid" style={{height:'100px'}}>
        <Navbars/>
      </div>
      <div className="name_div">
       <div className="name_div1">
       <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={back} alt="leftarrow"  className="name_arrow "></img>
                <h5 className="nameh5">Name</h5>
         </div>
         <div className="name-input">
                <label className="mb-2 "style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#000000'}}>Enter your Name</label>
                <input type="text" value={namechange} onChange={handleinputchange} className={`form-control docform ${namechange ? 'docform-active' : ''}`} placeholder="Please Enter Your Name" />
                <div className="error-message">{nameerror}</div>
                <div className="error-message">{error422}</div>
                <div className="name_button_div">
                <button type="button" onClick={cancel} className="profilecancel me-4">
                  Cancel
                </button>
                <button type="button" onClick={save} style={{ backgroundColor: isSaveButtonEnabled ? '#02CD8D' : '#EAEAEA', color: isSaveButtonEnabled ? '#FFF' : '#333' , cursor: isSaveButtonEnabled ? 'pointer' : 'not-allowed' }} disabled={!isSaveButtonEnabled} className={`profilesave`}>
                  Save
                </button>
              </div>
              </div>
       </div>
      </div>
    </div>
      {/* <Navbars />
      <div className="container-fluid name-change">
        <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="col-lg-7 col-md-8 col-sm-10">
            <div className="box " style={{ width:'100%',maxWidth:'619px',minHeight:'302px',borderRadius:'18px',border:'1px solid #ABA7A7',backgroundColor:'white',padding:'40px 30px' }}>
              <div className="d-flex align-items-center mb-3">
              <img src={leftarrow} onClick={back} alt="leftarrow" width='19.5px' height='15.5px' className="mb-3 me-4"style={{cursor:'pointer',marginLeft:'-10px'}}></img>
                <h5 className="mb-3" style={{fontWeight:'500',fontSize:'24px',lineHeight:'36px',color:'#232323'}}>Name</h5>
              </div>
              <div className="mb-3 name-input me-4 mb-4">
                <label className="mb-2 "style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#000000'}}>Enter your Name</label>
                <input type="text" value={namechange} onChange={handleinputchange}
                style={{width:'100%',maxWidth:'469px',height:'40px',borderRadius:'6px',border:'1px solid #B9B9B9'}}
                className="form-control namechangeinput" placeholder="Please Enter Your Name" />
                {nameerror && (<div className="error mt-3">{nameerror}</div>)}
              </div>
              {error && (<div className="mb-2 text-center text-danger">{error}</div>)}
              <div className="d-flex justify-content-end mx-4">
                <button type="button" onClick={cancel} className=" profilecancel me-4">
                  Cancel
                </button>
                <button type="button" onClick={save} className={`profilesave ${!namechange.trim() ? 'disabledmobile' : ''}`}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );

}
export default WithToast(Namechange);