import React from "react";
import Navbars from "./navbar";
import Systemsidebar from "./systemsidebar";
import plus from './images/ApplePay.png';
function Contribution(){
    return(
        <>
             {/* <Navbars/>
            <div className="container-fluid " style={{ backgroundColor: '#F8F8F8', width: '100%', height: '100%' }}>
                <div className="row collectionrow">
                    <div className="col-12 col-lg-3 col-md-4 col-xl-3 col-xxl-3 col_corre_2">
                        <Systemsidebar/>
                    </div> */}
                    <div className="col-12 col-lg-9 col-md-8 col-xl-9 col-xxl-9 col_corr_1"  >
                        <main className="mobile_height " style={{ backgroundColor: '#FFFFFF', width: '100%', boxShadow: "0px 0px 5px 0px #0000001A", height: '100%',minHeight:'500px' }}>
                            <div className="max-width mob_alignment_correction">
                                <h5 className="ps-4 pt-4">Contributions</h5>
                                <p className="cardsp   ps-4 mb-4">All your fundraising, donations, give backs, tithes, offertory  and charity offerings appear here</p>
                                <div className="massage_box_corr mobile_maxheight ">
                                    <div className="purchaserow22 mar-20 after-one mob_alignment_correction ">
                                        {/* <div className="d-flex justify-content-between align-items-center">
                                        
                                        </div> */}
                                        <div className="_row">
                                            <div className="col col_87">
                                                <div className="d-flex align-items-start">
                                                    <div className="mobile_co_img">
                                                        <img src={plus} alt="plus" width='93px' height="70.67px"></img>
                                                    </div>
                                                    <div className="se-corr mar-0">
                                                    <h5 className="productnameh5 mb-2">School : Student Bus Fees</h5>
                                                        <ul className="d-flex">
                                                            <li>
                                                                <p>Amount Paid :<b><strong> UGX 2000</strong></b></p>
                                                                <p>Paid at :<strong> 14 June,2023</strong></p>
                                                                <p>Expires at :<strong> 12 June,2024</strong></p>
                                                            </li>
                                                            <li>
                                                                <p>Subscription ID :<strong> 68679658964</strong></p>
                                                                <p>Beneficiary :<strong> James Charles</strong></p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                {/* </div>
            </div> */}
        </>
    );
}
export default Contribution;