import React from "react";
import Navbars from "./navbar";
import leftarrow from './images/arrow-left-01.png';
import nationalcard from './images/nationalcard.png';
import menu from './images/menu-01.png';
import { useState } from "react";
import eye from './images/eye-01.png';
import rename from './images/rename-01.png';
import filedetails from './images/File_details-01.png';
import download from './images/download-01.png';
import trash from './images/delete-01.png'
import './home.css';
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
function Personaldocuments (){
    const [showDropdown1, setShowDropdown1] = useState(false);
    const [showDropdown2, setShowDropdown2] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [show , setShow ] = useState(false);
    const [showfiledetail , setShowfiledetail] = useState(false);
    const [clickedMenu2, setClickedMenu2] = useState(false);
    const [clickedMenu1, setClickedMenu1] = useState(false);
    const navigate = useNavigate();
    // previewmodal
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
     
    //  renamemodal   
    const handleClose = () =>{
        setShow(false);
    }
    const renamemodal = () => {
        setShow(true);
    }
    //filedetailmodal
    const filedetail = () =>{
        setShowfiledetail(true)
    }
    const handleCloses = () => {
        setShowfiledetail(false);
    }
    //dropdowns
    const toggleDropdown1 = () => {
        setShowDropdown1(!showDropdown1);
        setClickedMenu1(!clickedMenu1);
        setShowDropdown2(false);
        setClickedMenu2(false);
    };
    const toggleDropdown2 = () => {
        setShowDropdown2(!showDropdown2);
        setClickedMenu2(!clickedMenu2);
        setShowDropdown1(false);
        setClickedMenu1(false);
    };
    const back = () => {
     navigate('/personaldocument')
    }
     const adddocument = () => {
        navigate('/adddocument')
     }
    return(
        <>
         <Navbars/>
        <div className="container-fluid personaldocuments">
        <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="col-lg-7 col-md-10 col-sm-12">
            <div className="box personaldocumentsbox p-5  mt-5 " style={{ backgroundColor: "white",minHeight:'400px',border:'1px solid #ABA7A7',borderRadius:'18px' }}>
              <div className="d-flex  mb-4 align-items-center">
                <img onClick={back} src={leftarrow} alt="leftarrow" width='19.5px' height='15.5px' className="mb-3 me-4"style={{cursor:'pointer'}}></img>
                <h5 className='mb-3'style={{fontFamily:"Poppins",fontWeight:'500',color:'#232323',fontSize:'24px',verticalAlign:'cap-height',lineHeight:'36px'}}>Personal Documents</h5>
              </div>
              <div className="row documentaddrow me-5 ">
                <div className="col-lg-4 col-md-4">
               <div className="documentadd">
                <img src={nationalcard} width='234.84px' height='141.49'></img>
                <div className="green-box d-flex align-items-center  justify-content-between">
                <p style={{color:'white',color:'white',paddingTop:'13px',paddingLeft:'15px',fontSize:'14px',fontWeight:'500',verticalAlign:'cap-height',lineHeight:'21px'}}>Aadhar_card_1.JPG</p>
                 <img src={menu}  className={`menu ${clickedMenu1 ? 'clicked-menu1' : ''}`} alt="menu" width='16px' height='16px' style={{marginTop:'-3px',marginRight:'10px',cursor:'pointer' }}onClick={toggleDropdown1} ></img>
                 {showDropdown1 && (
                            <div className="dropdown-menu">
                            <p style={{cursor:'pointer'}} onClick={() => openModal(nationalcard)}><img src={eye} alt="eye" width='12px' height='12px' style={{marginLeft:'5px'}} className="me-3" ></img>Preview</p>
                            <p style={{cursor:'pointer'}} onClick={renamemodal}><img src={rename} alt="eye" width='12px' height='auto' style={{marginLeft:'5px'}} className="me-3"></img>Rename</p>
                            <hr/>
                            <p style={{cursor:'pointer'}} onClick={filedetail}><img src={filedetails} alt="eye" width='12px' height='auto' style={{marginLeft:'5px'}} className="me-3"></img>File Details</p>
                            <p style={{cursor:'pointer'}}><img src={download} alt="eye" width='12.63px' height='auto' style={{marginLeft:'5px'}} className="me-3"></img>Download</p>
                            <hr/>
                            <p style={{cursor:'pointer'}}><img src={trash} alt="eye" width='12px' height='auto' style={{marginLeft:'5px'}} className="me-3"></img>Remove</p>
                        </div>                   
                       )}

                </div>
               </div>
               </div>
               <div className="col-lg-4 col-md-4">
               <div className="documentadds">
               <img src={nationalcard} width='234.84px' height='141.49'></img>
               <div className="green-box d-flex align-items-center  justify-content-between">
                <p style={{color:'white',paddingTop:'13px',paddingLeft:'15px',fontSize:'14px',fontWeight:'500',verticalAlign:'cap-height',lineHeight:'21px'}} >Aadhar_card_1.JPG</p>
                <img src={menu} className={`menu ${clickedMenu2 ? 'clicked-menu' : ''}`}  alt="menu" width='16px' height='16px' style={{marginTop:'-3px',marginRight:'10px',cursor:'pointer' }}onClick={toggleDropdown2}></img>
                {showDropdown2 && (
                            <div className="dropdown-menu">
                            <p style={{cursor:'pointer'}} onClick={() => openModal(nationalcard)}><img src={eye} alt="eye" width='12px' height='12px' style={{marginLeft:'5px'}} className="me-3" ></img>Preview</p>
                            <p style={{cursor:'pointer'}} onClick={renamemodal}><img src={rename} alt="eye" width='12px' height='auto' style={{marginLeft:'5px'}} className="me-3"></img>Rename</p>
                            <hr/>
                            <p style={{cursor:'pointer'}} onClick={filedetail}><img src={filedetails} alt="eye" width='12px' height='auto' style={{marginLeft:'5px'}} className="me-3"></img>File Details</p>
                            <p style={{cursor:'pointer'}}><img src={download} alt="eye" width='12.63px' height='auto' style={{marginLeft:'5px'}} className="me-3"></img>Download</p>
                            <hr/>
                            <p style={{cursor:'pointer'}}><img src={trash} alt="eye" width='12px' height='auto' style={{marginLeft:'5px'}} className="me-3"></img>Remove</p>
                        </div>  
                        )}
               </div>
               </div>
               </div>
               <div className="col-12 text-start ps-5 ">
               <button className="adddocument" onClick={adddocument}>add document</button>
               </div>
               </div>
              </div>
              </div>
              </div>
              </div>
                  {/* modal image */}
               
                  <Modal  show={showModal} contentClassName="modal-conten" onHide={closeModal}>
                <Modal.Header style={{ textDecoration: "none", border: 'none' }} >
                </Modal.Header>
                <Modal.Body style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}>
                    <img src={nationalcard} alt="Preview" className="img-fluid max-width-100 max-height-100" style={{ width: '100%', height: 'auto', maxWidth: '80%', maxHeight: '80%' }}/>
                </Modal.Body>
                <Modal.Footer style={{ textDecoration: "none", border: 'none' }}>
        
                </Modal.Footer>
                
            </Modal>

            {/* modal rename */}

            <Modal dialogClassName="custom-dial"  contentClassName="modal-con" show={show} onHide={handleClose}>
        <Modal.Header style={{ textDecoration: "none", border: 'none' }} closeButton>
          <div className="d-flex align-items-center">
            <h5 className="mb-0 ps-4"style={{fontWeight:'500',fontSize:'24px',verticalAlign:'cap-height',lineHeight:'36px'}}>Rename</h5>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-start mb-3 ps-4">
            <input type="text" placeholder="" value='' />
          </div>
          <div className="text-end">
          <button className="modalclose me-4" >
            Cancel
          </button>
          <button className="modalrequest" >
            Ok
          </button>
          </div>
         </Modal.Body>
        
      </Modal>

      {/* filedetails */}
      <Modal dialogClassName="custom-dialo" contentClassName="modal-cont" show={showfiledetail} onHide={handleCloses}>
        <Modal.Header style={{ textDecoration: "none", border: 'none' }} closeButton>
          <div className="d-flex align-items-center">
            <h5 className="mb-3 ps-4" style={{fontWeight:'500',fontSize:'24px',verticalAlign:'Cap-height',lineHeight:'36px',height:'17px'}}>File Details</h5>
          </div>
        </Modal.Header>
        <Modal.Body>
        <div className="row ps-4">
            <div className="col-md-3">
                <p style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#000000',height:'10px'}}>Type</p>
                <h4 style={{fontWeight:'500',fontSize:'20px',verticalAlign:'Cap-height',lineHeight:'30px',height:'14px',color:'#000000'}}>JPEG</h4>
            </div>
            <div className="col-md-3">
             <p style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#000000',height:'10px'}}>Size</p>
             <h4 style={{fontWeight:'500',fontSize:'20px',verticalAlign:'Cap-height',lineHeight:'30px',height:'14px',color:'#000000'}}>36 KB</h4>
            </div>
            <div className="col-md-6">
                <p style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#000000',height:'10px'}}>Created</p>
                <h4 style={{fontWeight:'500',fontSize:'20px',verticalAlign:'Cap-height',lineHeight:'30px',height:'14px',color:'#000000'}}>April 10,2023</h4>
            </div>
            </div>
        </Modal.Body>
        
      </Modal>

        </>
    );
}
export default Personaldocuments;