import {React,useState} from "react";
import Navbars from "./navbar";
import Systemsidebar from "./systemsidebar";
import folderexport from './images/folder-export-01.png';
import wallettowallet from './images/wallet_to_wallet-01.png';
import Modal from 'react-bootstrap/Modal';
import timecircle from './images/times-circle-01.png';
import info1 from './images/info_icon/info-03.png';
import eye from './images/eye-01.png';
import eyeslash from './images/eye_slash-01.png';
import process from './images/Payment_Process.gif';
import success from './images/Payment_Successful (1).gif';
import searchicon from './images/wallet_icons/No_registered_green-01.png';
import search01 from './images/search-01.png';
function Wallettobank(){
    const [show1, setShow1] = useState(false);
    const handleCloses1 = () => {
        setShow1(false);
    }
    const initiate = () => {
        setShow1(true);
    }
    const close1 = () => {
        setShow1(false);
    }
    const register = () => {
        setShow1(false);
        setShow2(true);
    }

    const [show2 , setShow2] = useState(false);
    const close2 = () => {
        setShow2(false);
        setAmount('');
    }
    const [amount , setAmount] = useState('');
    const getButtonClass1 = () => {
            return amount > " " ? 'withdrawamounbutton active3 ' : 'withdrawamounbutton ';
        };
    const handleCloses2 = () => {
        setShow2(false);
        setAmount('');
    }
    const transfer = () => {
            setShow2(false); 
            setShow4(true);
    }
    // const [show3 , setShow3] = useState(false);
    // const handleCloses3 = ()  => {
    //     setShow3(false);
    // }
    // const transfernow = () => {
    //     setShow3(false); 
    //         setShow4(true);
    // }
    const [passcode ,setPasscode] = useState('');
    const [enterpasscode , setEnterpasscode] = useState('');
    const getButtonClass3 = () => {
        
    }
    const handleInputChange3 = (e) => {
        setEnterpasscode(e.target.value);
    }
    const togglePasswordVisibility = () => {
        setPasscode(!passcode);

    }
    
    const getButtonClass2 = () => {

        return enterpasscode > " " ? 'withdrawamounbutton active3' : 'withdrawamounbutton ';
    };
   
    // const close3 = () => {
    //     setShow3(false);
    // }
    const withdrawqr3 = () => {
        if (enterpasscode !== "") {
            setShow4(false); // Close Modal 4
            setShow5(true);
            setTimeout(() => {
                setApiStatus('processing');
                setTimeout(() => {
                    setApiStatus('success');
                }, 2000)
            }, 10000);
        }
    }

    const [show4 , setShow4 ] = useState(false);
    const [apiStatus , setApiStatus] = useState('processing');
    const handleCloses5 = () => {
        setShow5(false);
        setEnterpasscode('');
        setAmount('');
    }
    const handleCloses4 = () => {
        setShow4(false);
        setEnterpasscode('');
        setAmount('');
    }
    const close4 = () => {
        setShow4(false);
        setEnterpasscode('');
        setAmount('');
    }
    const [show5 , setShow5] = useState(false);
    const paynowsuccess = () => {
        setShow5(false);
        setEnterpasscode('');
        setAmount('');
    }
    return(
        <>
            {/* <Navbars/>
            <div className="container-fluid " style={{ backgroundColor: '#F8F8F8', width: '100%', height: '100%' }}>
                <div className="row collectionrow">
                    <div className="col-12 col-lg-3 col-md-4 col-xl-3 col-xxl-3 col_corre_2">
                        <Systemsidebar/>
                    </div> */}
                    <div className="col-12 col-lg-9 col-md-8 col-xl-9 col-xxl-9 col_corr_1" >
                        <main className="mobile_height" style={{ backgroundColor: '#FFFFFF', width: '100%', boxShadow: "0px 0px 5px 0px #0000001A", height: '100%', }}>
                            <h5 className="ps-4 pt-4">Wallet to Bank</h5>
                            <p className="cardsp ps-4 mb-4">This is when app user transfers funds from his wallet  to Bank account</p>
                            <div className="massage_box_corr mobile_maxheight ">
                                <div className="purchaserow22 purchase23 after-one">
                                    <h5 className="refundhistory ps-4 pt-3">All Transactions</h5>
                                    <div className="d-flex justify-content-between align-items-center ps-4 pb-3">
                                    <div style={{position:"relative",width:'100%'}}>
                                    <input type="search" className="serchinputbox" placeholder="Search"></input>
                                        <p  style={{ position: 'absolute',marginLeft:'5px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}><img src={search01} alt="search" width='19.5px' height='19.5px'></img></p> 
                                        </div> 
                                 <div className="b__n">       
                                    <p className="walletinitiate">Initiate Transfer</p>
                                    <div className="button_column">
                                        <button className="export_pdf1 me-md-4"onClick={initiate}>Ineligable</button>
                                        <button className="export_pdf me-4"><img src={folderexport} className="me-2 mb-1" alt="folderexport" width="15px" height='14px'></img>Export to PDF</button>
                                </div>
                            </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive">
                                            <table className="table   nowrap custom-table">
                                            <colgroup>
                                                <col style={{ width: '14.28%' }} /> {/* Adjust the width as needed */}
                            <col style={{ width: '14.28%' }} />
                            <col style={{ width: '14.28%' }} />
                            <col style={{ width: '14.28%' }} />
                            <col style={{ width: '14.28%' }} />
                            <col style={{ width: '14.28%' }} />
                            <col style={{ width: '14.28%' }} />
                                                </colgroup>
                                                <thead style={{backgroundColor: '#E2FFF6'}}>
                                                    <tr style={{ backgroundColor: '#E2FFF6', border: 'none'}}>
                                                    <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000' }}></th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000',textAlign:'left' }}>Description</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000' }}>Date</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000' }}>Amount</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000' }}>Security Check</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000' }}>Status</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000' }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                <tr className="bord"> 
                                                    <td colSpan="7">
                                                  <p className="mar-25" style={{textAlign:'start'}}>Today</p></td>
                                                  </tr>
                                                    <tr className="padtr">
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}><img src={wallettowallet} alt="collect" className="collectimg"></img></td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >
                                                        <div style={{textAlign:'left'}}>
                                                        <p className="desctabname" style={{marginBottom:'0px'}}>Bank of Uganda</p>
                                                        <p className="desctabgmail"  style={{marginBottom:'0px'}}>*****1234</p>
                                                        </div>
                                                        </td>
                                                        <td className="desctabdate">01 Sep,2023</td>
                                                        <td className="descamount">UGX 3000</td>
                                                        <td className="desctabcheck">Passed</td>
                                                        <td className="desctabdate">Sent</td>
                                                        <td className="desctabsucc">Sent Success</td>
                                                    </tr>
                                                    <tr className="padtr">
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}><img src={wallettowallet} alt="collect" className="collectimg"></img></td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >
                                                        <div style={{textAlign:'left'}}>
                                                        <p className="desctabname" style={{marginBottom:'0px'}}>Bank of Uganda</p>
                                                        <p className="desctabgmail"  style={{marginBottom:'0px'}}>*****1234</p>
                                                        </div>
                                                        </td>
                                                        <td className="desctabdate">01 Sep,2023</td>
                                                        <td className="descamount">UGX 3000</td>
                                                        <td className="desctabcheck desctabcheckred ">Blocked</td>
                                                        <td className="desctabdate">Cancelled</td>
                                                        <td><button className="retrybut">Retry</button></td>
                                                    </tr>
                                                    <tr className="bord"> 
                                                    <td colSpan="7">
                                                  <p className="mar-25" style={{textAlign:'start'}}>Yesterday</p></td>
                                                  </tr>
                                                    <tr className="padtr">
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}><img src={wallettowallet} alt="collect" className="collectimg"></img></td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >
                                                        <div style={{textAlign:'left'}}>
                                                        <p className="desctabname" style={{marginBottom:'0px'}}>Bank of Uganda</p>
                                                        <p className="desctabgmail"  style={{marginBottom:'0px'}}>*****1234</p>
                                                        </div>
                                                        </td>
                                                        <td className="desctabdate">01 Sep,2023</td>
                                                        <td className="descamount">UGX 3000</td>
                                                        <td className="desctabcheck desctabcheckred ">Blocked</td>
                                                        <td className="desctabdate">Failed</td>
                                                        <td className="desctabsucc desctabinsuff">Insufficient funds</td>
                                                    </tr>
                                                    <tr className="padtr">
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}><img src={wallettowallet} alt="collect" className="collectimg"></img></td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >
                                                        {/* <div className="d-flex"> */}
                                                        {/* <img src={wallettowallet} className="me-2"  width='24px' height="24px"></img> */}
                                                        <div style={{textAlign:'left'}}>
                                                        <p className="desctabname" style={{marginBottom:'0px'}}>Bank of Uganda</p>
                                                        <p className="desctabgmail"  style={{marginBottom:'0px'}}>*****1234</p>
                                                        {/* </div> */}
                                                        </div>
                                                        </td>
                                                        <td className="desctabdate">01 Sep,2023</td>
                                                        <td className="descamount">UGX 3000</td>
                                                        <td className="desctabcheck  ">Checking</td>
                                                        <td className="desctabdate">Pending</td>
                                                        <td className="desctabsucc desctabfund">Sending Funds</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                {/* </div>
            </div> */}
            <Modal show={show1} dialogClassName="example-dialog24" contentClassName="example-content24" onHide={handleCloses1} centered>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                    <div className="text-end p-1">
                        <img onClick={close1} className="poptimes" src={timecircle} alt="times"></img>
                    </div>
                    <div style={{ display: 'flex' ,justifyContent: 'center' }}>
                        <img src={searchicon} alt="circle" className="poptime1"></img>
                    </div>
                    <div className="initpopcont" style={{ padding: '0 30px' }}>
                        <h6 className="initpoph5">No Registered sources!</h6>
                        <p className="initpopp">To proceed with Transfers, click the button below to add your source (bank cards, Bank account number, Mobile money, paypal)</p>
                    </div>
                    <div className="initregbutton initregbutton1 ">
                        <button onClick={register}>Register</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={show2} dialogClassName="example-dialog25" contentClassName="example-content25" onHide={handleCloses2} centered>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                    <div className="text-end p-1">
                        <img onClick={close2} className="poptimes" src={timecircle} alt="times"></img>
                    </div>
                    <div style={{margin:'0 8%'}}>
                    <h6>Transfer Amount</h6>
                    <select className="form-select initpopselect mb-4">
                        <option disabled>Select Name</option>
                    </select>
                    <div className="input-container mb-5" style={{ position: 'relative'}}>
                            <input type="text" value={amount} onChange={(e)=>setAmount(e.target.value)} className="form-control withdrawamountinputs" placeholder="Amount" style={{ height: '40px', }} />
                            <p className="currancysel1" style={{  position: 'absolute', right: '0px',transform: 'translateY(-50%)', cursor: 'pointer', fontWeight: "500", }}>UGX</p>
                            {/* <div className="custom-select">
                                <select className="deposit-select "
                                    style={{ width: '100px', fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#FFFFFF', height: '40px', padding: '8px', borderRadius: '0px 6px 6px 0px', border: 'none', backgroundColor: '#02CD8D', position: 'relative', caretWidth: "100px", caretHeight: "100px", caretColor: 'white' }}
                                >
                                    <option value="">UGX</option>
                                </select>
                            </div> */}
                        </div>
                        <div className="text-end mb-4">
                        <button className={getButtonClass1()} onClick={transfer}>Transfer Now</button>
                        </div>
                        </div>
                </Modal.Body>
            </Modal>
             
            {/* <Modal show={show3} dialogClassName="example-dialog3" contentClassName="example-content27" onHide={handleCloses3} centered>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                <div className="text-end p-1">
                        <img onClick={close3} className="poptimes" src={timecircle} alt="times"></img>
                    </div>
                  <div style={{margin:'0 7%'}}>  
                 <label className="mb-3" style={{fontWeight:'600',fontSize:"16px",lineHeight:'24px',color:'#000000'}}>Gift Card</label>   
                <input type="text" value={amount} onChange={(e)=>setAmount(e.target.value)} className="form-control withdrawamountinputs mb-3" placeholder="Amount" style={{ height: '40px', }} />
                <input type="text" value={amount} onChange={(e)=>setAmount(e.target.value)} className="form-control withdrawamountinputs mb-3" placeholder="Amount" style={{ height: '40px', }} />
                <input type="text" value={amount} onChange={(e)=>setAmount(e.target.value)} className="form-control withdrawamountinputs mb-3" placeholder="Amount" style={{ height: '40px', }} />
                <input type="text" value={amount} onChange={(e)=>setAmount(e.target.value)} className="form-control withdrawamountinputs mb-3" placeholder="Amount" style={{ height: '40px', }} />
                <input type="text" value={amount} onChange={(e)=>setAmount(e.target.value)} className="form-control withdrawamountinputs mb-3" placeholder="Amount" style={{ height: '40px', }} />
                </div>
                <div className="text-center mb-4" style={{margin:'0 7%'}}>
                <button className={getButtonClass1()} onClick={transfernow}>Transfer Now</button>
                        </div>
               
                </Modal.Body>   
             </Modal>        */}

            <Modal show={show4} dialogClassName="example-dialog30" contentClassName="example-content30" onHide={handleCloses4} centered>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                <div className="d-flex justify-content-between align-items-center p-1">
                    <h6 className="initpoptransh6">Transfer Amount</h6>
                        <img onClick={close4} className="poptimes" src={timecircle} alt="times"></img>
                    </div>
                    <div className="d-flex mt-4 align-items-center ">
                        <div className="d-flex flex-column flex-fill ml-md-2">
                            <p className="agentname mb-2">Charles David</p>
                            <p className="agentemail" >charlesdavid22@wallet.com</p>
                        </div>
                        <div className="d-flex flex-column flex-fill ml-md-2 text-end">
                            <p className={getButtonClass3()} style={{marginBottom:'0px'}}>Amount</p>
                            <p className="agentamount">UGX 1000</p>
                        </div>
                    </div>
                    <hr className="full-widthhr"></hr>
                    <label className="enterpasscodelabel mb-3">Enter Passcode</label>
                    <div style={{position:'relative'}}>
                    <input type={passcode ? 'text' : 'password'} value={enterpasscode} onChange={handleInputChange3} className="form-control withdrawpasscode"></input>
                    <p onClick={togglePasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                        {passcode ? (
                            <img src={eyeslash} className='eyeslash' alt="Hide Password" />
                        ) : (
                            <img src={eye} className='eyeslash' alt="Show Password" />
                        )}
                    </p>
                    </div>
                    <div className="box mt-4 mb-5" style={{ width: '100%', maxWidth: '380px', minHeight: '72px', borderRadius: '6px', backgroundColor: '#C5FFED', padding: '0px' }}>
                        <div className="d-flex align-items-center">
                            <img className="ms-2" src={info1} alt="info1" width="24px" height="24px"></img>
                            <p className="infocomments infocomments1  mx-auto mt-2">You are about to Transfer USD 10000 to your account: Floyd Miles. Fee: USD 1000 (2% of transaction amount) After transaction, your wallet balance will be: USD 50000</p>
                        </div>
                    </div>

                    <div className="text-center">
                        <button className={getButtonClass2()} onClick={withdrawqr3}>
                            Transfer Now
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={show5} dialogClassName="example-dialog4" contentClassName="example-content4" onHide={handleCloses5} centered>
                <Modal.Body style={{ margin: '0', padding: '0',width:'100%',display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center'}}>
                    {apiStatus === 'processing' && (
                        <>
                            <img src={process} alt="process" width="200px" height="200px" />
                            <p className="processwithdraw">Processing Withdraw</p>
                        </>
                    )}
                    {apiStatus === 'success' && (
                        <>  
                            <div>
                            <img src={success}  alt="success" width="200px" height="200" />
                            </div>
                            <div style={{textAlign:'center'}}>
                            <p className="withdrawsuceessname mb-0">Floyd Miles</p>
                            <p className="withdrawagentsuccess mb-0">Floyd Miles@domain.com</p>
                            <p className="withdrawsuccessamount withdrawsuccessamountgreen mb-0">UGX 1000</p> </div>
                            <div className="text-center mt-3 mb-3" style={{display:'flex',justifyContent:'center',width:'100%'}}>
                                <button className="withdrawdonebutton withdrawdonebuttonsss " onClick={paynowsuccess}>Done</button>
                            </div>
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
}
export default Wallettobank;