import {React,useState} from "react";
import deposit from './images/deposit-01.png';
import { Link, useLocation, useNavigate } from "react-router-dom";
import withdrawal from './images/withdrawl-01.png';
import incomeing from './images/wallet_to_wallet-01.png';
import './transactions.css';
import Modal from 'react-bootstrap/Modal';
import leftarrow from './images/arrow-left-01.png';
import Navbars from "./navbar";
function RequestPage() {
    const navigate = useNavigate();
    const [shows, setShows] = useState(false);
    const handleCloses = () => setShows(false);
    const back = () => {
        navigate('/transactions');
    }
    const sendmoney = () => {
        navigate('/incomeingpage');
    }
    const pay = () => {
        setShows(true);
    }
    const location = useLocation();
    const isLinkActive = (linkPath) => {
        return location.pathname === linkPath;
    };
    return (
        <>
            <Navbars/>
             <div className="container-fluid requestpage">
                <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <div className="col-lg-6 col-md-8 col-sm-10">
                        <div className="box p-4 border" style={{ width: '100%',backgroundColor:'white',maxWidth:'551px',minHeight:'775px',border:'1px solid ABA7A7' }}>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="d-flex  align-items-center">
                                <img onClick={back} className="me-4" style={{cursor:'pointer', width: '19.5px', height: '15.5px' }} src={leftarrow} alt="edit"></img>
                                    <h5 className=''style={{fontWeight:'600',fontSize:'20px',lineHeight:'30px'}}>Wallet to Wallet transfer</h5>
                                </div>
                            </div>
                            <ul className="d-flex list-unstyled ">
                <li style={{ marginRight: '20px' }}>
                  <Link to="/incomeingpage" className={isLinkActive("/incomeingpage") ? "active" : ""}>
                    <span className="incoming" style={{ fontSize: '16px',fontWeight:'500',lineHeight:'24px' }}>Incoming</span>
                  </Link>
                </li>
                <li style={{ marginRight: '20px'}}>
                  <Link to="/outgoingpage" className={isLinkActive("/outgoingpage") ? "active" : ""}>
                    <span className="incoming" style={{ fontSize: '16px',fontWeight:'500',lineHeight:'24px' }}>Outgoing</span>
                  </Link>
                </li>
                <li >
                  <Link to="/requestspage" className={isLinkActive("/requestspage") ? "active" : ""}>
                    <span className="incoming" style={{ fontSize: '16px',fontWeight:'500',lineHeight:'24px'}}>Requests</span>
                  </Link>
                </li>
              </ul> 
              <div className="d-flex mt-2 align-items-center">
                                        <div className="mb-3" >
                                            <img
                                                className="me-md-3 me-sm-3 me-lg-4 mb-md-0"
                                                src={deposit} // Replace 'yourImageSourceHere' with the actual image source URL
                                                alt="wallet"
                                                width="42px"
                                                height="42px"
                                            />
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2  text-start">
                                                <p style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>Deposit</p>
                                                <p style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>Bank transfer</p>
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2 me-lg-4 text-end">
                                                <p style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>UGX 3000</p>
                                                <p style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>20.05.2023</p>
                                            </div>
                                            <div className="d-flex flex-column  ml-md-2 mx-2 text-end" style={{marginTop:'-4%'}}>
                                            <button className="unlocked " style={{ border: '1px solid #02CD8D', color: '#02CD8D', backgroundColor: '#FFFFFF', borderRadius: '6px', fontSize: '14px',lineHeight:'18px', width: '80px',height:'26px', fontSize: '14px' }}>Paid</button>
                                            </div>
                                            </div>
              <hr className="mb-3 mt-0" style={{ marginLeft: '12%' }}></hr>
              <div className="d-flex mt-2 align-items-center">
                                        <div className="mb-3" >
                                            <img
                                                className="me-md-3 me-sm-3 me-lg-4 mb-md-0"
                                                src={withdrawal} // Replace 'yourImageSourceHere' with the actual image source URL
                                                alt="wallet"
                                                width="42px"
                                                height="42px"
                                            />
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2  text-start">
                                                <p style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>Withdrawal</p>
                                                <p style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>Bank transfer</p>
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2 me-lg-4 text-end">
                                                <p style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>UGX 3000</p>
                                                <p style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>20.05.2023</p>
                                            </div>
                                            <div className="d-flex flex-column  ml-md-2 mx-2 text-end" style={{marginTop:'-4%'}}>
                                            <button className="unlocked mb-1" style={{ border: '1px solid #02CE8E', color: '#FFFFFF', backgroundColor: '#02CE8E', borderRadius: '6px', fontSize: '14px',lineHeight:'18px', width: '80px',height:'26px', }}onClick={pay}>Pay</button>
                                            <button className="unlocked " style={{ border: '1px solid #EA4335', color: '#FFFFFF', backgroundColor: '#EA4335', borderRadius: '6px', fontSize: '14px',lineHeight:'18px', width: '80px',height:'26px', }}>Deny</button>
                                            </div>
                                            </div>
              <hr className="mb-3 mt-0" style={{ marginLeft: '12%' }}></hr>
              <div className="d-flex mt-2 align-items-center">
                                        <div className="mb-3" >
                                            <img
                                                className="me-md-3 me-sm-3 me-lg-4 mb-md-0"
                                                src={incomeing} // Replace 'yourImageSourceHere' with the actual image source URL
                                                alt="wallet"
                                                width="42px"
                                                height="42px"
                                            />
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2  text-start">
                                                <p style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>Wallet to Wallet</p>
                                                <p style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>Bank transfer</p>
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2 me-lg-4 text-end">
                                                <p style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>UGX 3000</p>
                                                <p style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>20.05.2023</p>
                                            </div>
                                            <div className="d-flex flex-column  ml-md-2 mx-2 text-end" style={{marginTop:'-4%'}}>
                                            <button className="unlocked mb-1" style={{ border: '1px solid #02CE8E', color: '#FFFFFF', backgroundColor: '#02CE8E', borderRadius: '6px', fontSize: '14px',lineHeight:'18px', width: '80px',height:'26px', }} onClick={pay}>Pay</button>
                                            <button className="unlocked " style={{ border: '1px solid #EA4335', color: '#FFFFFF', backgroundColor: '#EA4335', borderRadius: '6px', fontSize: '14px',lineHeight:'18px', width: '80px',height:'26px', }}>Deny</button>
                                            </div>
                                            </div>
              <hr className="mb-3 mt-0" style={{ marginLeft: '12%' }}></hr>
               <div className="d-flex mt-2 align-items-center">
                                        <div className="mb-3" >
                                            <img
                                                className="me-md-3 me-sm-3 me-lg-4 mb-md-0"
                                                src={deposit} // Replace 'yourImageSourceHere' with the actual image source URL
                                                alt="wallet"
                                                width="42px"
                                                height="42px"
                                            />
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2  text-start">
                                                <p style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>Deposit</p>
                                                <p style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>Bank transfer</p>
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2 me-lg-4 text-end">
                                                <p style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>UGX 3000</p>
                                                <p style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>20.05.2023</p>
                                            </div>
                                            <div className="d-flex flex-column  ml-md-2 mx-2 text-end" style={{marginTop:'-4%'}}>
                                            <button className="unlocked mb-1" style={{ border: '1px solid #02CE8E', color: '#FFFFFF', backgroundColor: '#02CE8E', borderRadius: '6px', fontSize: '14px',lineHeight:'18px', width: '80px',height:'26px', }}onClick={pay}>Pay</button>
                                            <button className="unlocked " style={{ border: '1px solid #EA4335', color: '#FFFFFF', backgroundColor: '#EA4335', borderRadius: '6px', fontSize: '14px',lineHeight:'18px', width: '80px',height:'26px', }}>Deny</button>
                                            </div>
                                            </div>
              <hr className="mb-3 mt-0" style={{ marginLeft: '12%' }}></hr>
              <div className="d-flex mt-2 align-items-center">
                                        <div className="mb-3" >
                                            <img
                                                className="me-md-3 me-sm-3 me-lg-4 mb-md-0"
                                                src={withdrawal} // Replace 'yourImageSourceHere' with the actual image source URL
                                                alt="wallet"
                                                width="42px"
                                                height="42px"
                                            />
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2  text-start">
                                                <p style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>Withdrawal</p>
                                                <p style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>Bank transfer</p>
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2 me-lg-4 text-end">
                                                <p style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>UGX 3000</p>
                                                <p style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>20.05.2023</p>
                                            </div>
                                            <div className="d-flex flex-column  ml-md-2 mx-2 text-end" style={{marginTop:'-4%'}}>
                                            <button className="unlocked " style={{ border: '1px solid #02CD8D', color: '#02CD8D', backgroundColor: '#FFFFFF', borderRadius: '6px', fontSize: '14px',lineHeight:'18px', width: '80px',height:'26px', fontSize: '14px' }}>Paid</button>
                                            </div>
                                            </div>
              <hr className="mb-3 mt-0" style={{ marginLeft: '12%' }}></hr>
              <div className="d-flex mt-2 align-items-center">
                                        <div className="mb-3" >
                                            <img
                                                className="me-md-3 me-sm-3 me-lg-4 mb-md-0"
                                                src={incomeing} // Replace 'yourImageSourceHere' with the actual image source URL
                                                alt="wallet"
                                                width="42px"
                                                height="42px"
                                            />
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2  text-start">
                                                <p style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>Wallet to Wallet</p>
                                                <p style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>Bank transfer</p>
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2 me-lg-4 text-end">
                                                <p style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>UGX 3000</p>
                                                <p style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>20.05.2023</p>
                                            </div>
                                            <div className="d-flex flex-column  ml-md-2 mx-2 text-end" style={{marginTop:'-4%'}}>
                                            <button className="unlocked mb-1" style={{ color:'#FFFFFF',border: '1px solid #EA4335', color: '#EA4335', backgroundColor: '#FFFFFF', borderRadius: '6px', fontSize: '14px',lineHeight:'18px', width: '80px',height:'26px' }}>Denied</button>
                                            </div>
                                            </div>
              <hr className="mb-3 mt-0" style={{ marginLeft: '12%' }}></hr>
              <div className="d-flex mt-2 align-items-center">
                                        <div className="mb-3" >
                                            <img
                                                className="me-md-3 me-sm-3 me-lg-4 mb-md-0"
                                                src={incomeing} // Replace 'yourImageSourceHere' with the actual image source URL
                                                alt="wallet"
                                                width="42px"
                                                height="42px"
                                            />
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2  text-start">
                                                <p style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>Wallet to Wallet</p>
                                                <p style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>Bank transfer</p>
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2 me-lg-4 text-end">
                                                <p style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>UGX 3000</p>
                                                <p style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>20.05.2023</p>
                                            </div>
                                            <div className="d-flex flex-column  ml-md-2 mx-2 text-end" style={{marginTop:'-4%'}}>
                                            <button className="unlocked mb-1" style={{ color:'#FFFFFF',border: '1px solid #EA4335', color: '#EA4335', backgroundColor: '#FFFFFF', borderRadius: '6px', fontSize: '14px',lineHeight:'18px', width: '80px',height:'26px' }}>Denied</button>
                                            </div>
                                            </div>
                            <hr className="mb-4 mt-1" style={{ marginLeft: '10%' }}></hr>
                        </div>
                    </div>
                </div>
            </div>

            {/* modal pay */}

            <Modal show={shows} onHide={handleCloses} contentClassName="modal-pay">
        <Modal.Header style={{ textDecoration: "none", border: 'none' }} closeButton>
          <div className="d-flex align-items-center ">
          <img src={leftarrow} onClick={back} alt="leftarrow" width='19.5px' height='15.5px' className="mb-0 me-3"style={{cursor:'pointer',marginLeft:'-5%'}}></img>
            <h5 className="mb-0"style={{fontWeight:'500',fontSize:'24px',lineHeight:'36px',color:'#232323'}}>New Transfer From Jane Doe </h5>
          </div>
        </Modal.Header>
        <Modal.Body>
        <div className="d-flex justify-content-start mb-3 ps-4"> {/* New div for label */}
                <label htmlFor="user-name" style={{ fontWeight: '500' ,fontSize:'16px',lineHeight:'24px',color:'#000000' }}>Recipient ID</label>
              </div>
              <div className="d-flex justify-content-start mb-3 ps-4">
            <input className="sourceid"
              type="text"
              placeholder="Email or Phone"
              style={{
                width: "100%",
                maxWidth:'469px',
                height: "49px",
                padding: "8px",
                borderRadius: "10px",
                border: "1px solid #DCDCDC",
              }}
            />
          </div>
          <div className="d-flex justify-content-start mb-3 ps-4">
            <label htmlFor="user-name" style={{ fontWeight: "500",fontSize:'16px',lineHeight:'24px',color:'#494949' }}>
             Reason
            </label>
          </div>
          <div className="d-flex justify-content-start mb-3 ps-4">
            <input
              className="sourcereason"
              type='password'
              placeholder="Enter value"
              style={{
                position:'relative',
                width: "100%",
                maxWidth:'469px',
                height: "49px",
                padding: "8px",
                borderRadius: "10px",
                border: "1px solid #DCDCDC",
              }}
            />
          </div>
          <div className="d-flex justify-content-start mb-3 ps-4">
            <label htmlFor="user-name" style={{ fontWeight: "500",fontSize:'16px',lineHeight:'24px',color:'#494949' }}>
            Requested amount
            </label>
          </div>
          <div className="d-flex justify-content-start  ps-4 ">
              <div className="input-container mb-5" style={{  position: 'relative', width: '100%',maxWidth:'469px', display: 'flex' }}>
                <input type="text" className="depsoit-input" placeholder="1000" value='1000' style={{  width: 'calc(100% - 100px)', height: '49px', padding: '8px', borderRadius:'10px 0px 0px 10px', border: '1px solid #DCDCDC', borderColor: '#DCDCDC' }} />
                <select className="deposit-select"
                  style={{ width:'100px',fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#FFFFFF', height: '49px',borderRadius:'0px 10px 10px 0px', padding: '8px', border: 'none', backgroundColor: '#02CD8D', color: 'white', position: 'relative',caretWidth: "100px",caretHeight: "100px",caretColor:'black'}}
                >
                  <option value="">UGX</option>
                </select>
              </div>
              </div>  
              <div className="row depositbuttons  ps-sm-4  mb-4">
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <button type="button" className=" currancybutton  btn-block"style={{fontWeight:'500',fontSize:'12px',lineHeight:'18px',color:'#000000'}}>+ UGX 100</button>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <button type="button" className=" currancybutton btn-block"style={{fontWeight:'500',fontSize:'12px',lineHeight:'18px',color:'#000000'}}>+ UGX 250</button>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <button type="button" className=" currancybutton btn-block"style={{fontWeight:'500',fontSize:'12px',lineHeight:'18px',color:'#000000'}}>+ UGX 500</button>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <button type="button" className=" currancybutton  btn-block"style={{fontWeight:'500',fontSize:'12px',lineHeight:'18px',color:'#000000'}}>+ UGX 1000</button>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end">
          <button className="modalclose me-3" onClick={handleCloses}>
            Cancel
          </button>
          <button className="modalrequest" onClick={sendmoney}>
            Send
          </button>
         </div>

        </Modal.Body>
          
      </Modal>
        </>
    );
}
export default RequestPage;