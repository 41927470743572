import React, { useEffect, useState ,useRef } from "react";
import "./navbar.css";
import navlogo from "./images/evzone(1).png";
import user from "./images/user-3.jpg";
import bell from "./images/bell-01.png";
import dot from "./images/product.png";
import apps1 from "./images/school_orange-01.png";
import apps from './images/school_green-01.png';
import exports from './images/export-01.png';
import { useSelector , useDispatch } from "react-redux";
import { fetchUserProfile} from "./api/redux/userProfiles/actions";
import { useTranslation } from 'react-i18next';
import { LANGUAGE_STORAGE_KEY } from './i18n';
import { useNavigate } from "react-router-dom";
import WithToast from "./api/common/withtoast";
function Navbars({showErrorToast}) {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userProfile.userProfile); // Use useSelector to access userProfile from Redux state
  useEffect(() => {
      dispatch(fetchUserProfile(showErrorToast));
    }, [dispatch]);
    const navigate = useNavigate();
  const [isAllAppsOpen, setIsAllAppsOpen] = useState(false);

  const allapps = () => {
    setIsAllAppsOpen(!isAllAppsOpen);
  };
  const [isAppRowVisible, setIsAppRowVisible] = useState(false);

  const toggleAppRow = () => {
    setIsAppRowVisible(!isAppRowVisible);
  };
  const [isJobPostingsChecked, setIsJobPostingsChecked] = useState(false);

  const handleJobPostingsCheckbox = () => {
    setIsJobPostingsChecked(!isJobPostingsChecked);
    toggleAppRow();
  };
  const [isAccountdetailsOpen, setIsAccountdetailsOpen] = useState(false);
  const isAccountdetailsOpenRef = useRef(false);
  const accountsdetails = () => {
    isAccountdetailsOpenRef.current = !isAccountdetailsOpenRef.current;
  setIsAccountdetailsOpen(isAccountdetailsOpenRef.current);
  }
  const userProfileImageRef = useRef(null);
  const accountDetailsRef = useRef(null);
  const dotDetailRef = useRef(null);
  useEffect(() => {
    const handleClickOutside1 = (event) => {
      if (
        dotDetailRef.current &&
        !dotDetailRef.current.contains(event.target)
      ) {
        setIsAllAppsOpen(false)
      }
    };

    // Add a mousedown event listener to the window
    window.addEventListener("mousedown", handleClickOutside1);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("mousedown", handleClickOutside1);
    };
  }, []);
  useEffect(() => {
    const handleScroll1 = () => {
      // Check the scroll position or any other condition to determine if you want to open/close apps
      //  const shouldOpenApps = window.scrollY > 100; // Adjust the value based on your requirements
      if(isAllAppsOpen){
      // Update the state based on the condition
      setIsAllAppsOpen(false);
      }
    };
  
    // Add a scroll event listener to the window
    window.addEventListener("scroll", handleScroll1);
  
    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll1);
    };
  }, [isAllAppsOpen]); // Note: Empty dependency array means this effect runs once after the initial render
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        accountDetailsRef.current &&
        userProfileImageRef.current &&
        !accountDetailsRef.current.contains(event.target) &&
        !userProfileImageRef.current.contains(event.target)
      ) {
        setIsAccountdetailsOpen(false); // Close the dropdown when clicking outside
      }
    };

    // Add a mousedown event listener to the window
    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (isAccountdetailsOpen) {
        setIsAccountdetailsOpen(false);
      }
    };

    // Add a scroll event listener to the window
    window.addEventListener("scroll", handleScroll);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isAccountdetailsOpen]);

  const {t, i18n } = useTranslation();
  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage).then(() => {
      localStorage.setItem(LANGUAGE_STORAGE_KEY, selectedLanguage);
    });
  };
  const Manageaccount = () => {
    navigate('/profile');
  }

  return (
    <>
    
      <nav className='navbar'>
        <div className="navbar-left">
          <img src={navlogo} alt="Left " />
          <div>
          <select className="langselects" onChange={changeLanguage} value={i18n.language}>
        <option value="en">English</option>
        <option value="ch">中文</option>
      </select>
      </div>
        </div>
        <div className="navbar-right">
          <img
            src={bell}
            alt="Right  1"
            className="nav-bell"
            style={{ marginTop: "20px", marginRight: "30px", minWidth: "21.54px", maxHeight: "24px" }}
          />
          <img
            onClick={allapps}
            src={dot}
            alt="Right  2"
            className="nav-dot"
            ref={dotDetailRef}
            style={{ marginTop: "20px", marginRight: "66px", width: "24px", height: "24px" }}
          />
        </div>
        {isAllAppsOpen && (
          <div className="all-apps-container">
            <label className="userapp-label mb-1" style={{ fontWeight: '500', fontSize: '14px', lineHeight: '21px', color: '#000000' }}>User App</label>
            <div className="apps-row">

              <div className="userapp-item apps">
                <img src={apps} alt="App 1" width="32px" height="32px" />
                <p style={{ fontWeight: '400', fontSize: '12px', lineHeight: '28.8px', color: '#5E5E5E' }}>School</p>
              </div>
              <div className="userapp-item apps">
                <img src={apps} alt="App 2" width="32px" height="32px" />
                <p className="evmarket" style={{ position: 'absolute', fontWeight: '400', fontSize: '12px', lineHeight: '28.8px', color: '#5E5E5E' }}>EvMarke...</p>
                <p className="evplace" style={{ fontWeight: '400', fontSize: '12px', color: '#5E5E5E' }}>EvMarket Place</p>
              </div>
              <div className="userapp-item apps">
                <img src={apps} alt="App 3" width="32px" height="32px" />
                <p style={{ fontWeight: '400', fontSize: '12px', lineHeight: '28.8px', color: '#5E5E5E' }}>FaithHub</p>
              </div>
            </div>
            <hr className="divider mt-3"></hr>
            <label className="userapp-label mb-1" style={{ fontWeight: '500', fontSize: '14px', lineHeight: '21px', color: '#000000' }}>Provider App</label>
            <div className="apps-row">
              <div className="userapp-item apps1">
                <img src={apps1} alt="App 4" width="32px" height="32px" />
                <p className="educations" style={{ position: 'absolute', fontWeight: '400', fontSize: '12px', lineHeight: '28.8px', color: '#5E5E5E' }}>Educat...</p>
                <p className="education" style={{ fontWeight: '400', fontSize: '12px', lineHeight: '28.8px', color: '#5E5E5E' }}>Education</p>
              </div>
              <div className="userapp-item apps1">
                <img src={apps1} alt="App 5" width="32px" height="32px" />
                <p className="ecommerces" style={{ position: 'absolute', fontWeight: '400', fontSize: '12px', lineHeight: '28.8px', color: '#5E5E5E' }}>E_Comm...</p>
                <p className="ecommerce" style={{ fontWeight: '400', fontSize: '12px', color: '#5E5E5E' }}>E_Commerce Place</p>
              </div>
              <div className="userapp-item apps1">
                <img src={apps1} alt="App 6" width="32px" height="32px" />
                <p style={{ fontWeight: '400', fontSize: '12px', lineHeight: '28.8px', color: '#5E5E5E' }}>FaithHub</p>
              </div>
            </div>
            <hr className="divider mt-3"></hr>
            <label className="userapp-label mb-1" style={{ fontWeight: '500', fontSize: '14px', lineHeight: '21px', color: '#000000' }}>Web Services</label>
            <div className="apps-row">
              <div className="userapp-item apps">
                <img src={apps} alt="App 7" width="32px" height="32px" />
                <p style={{ position: 'absolute', fontWeight: '400', fontSize: '12px', lineHeight: '28.8px', color: '#5E5E5E' }} className="webeducations">Educat....</p>
                <p className="webeducation" style={{ fontWeight: '400', fontSize: '12px', lineHeight: '28.8px', color: '#5E5E5E' }}>Educations</p>
              </div>
              <div className="userapp-item apps" >
                <img src={apps} alt="App 8" width="32px" height="32px" />
                <p style={{ fontWeight: '400', fontSize: '12px', lineHeight: '28.8px', color: '#5E5E5E' }}>School</p>
              </div>
              <div className="userapp-item">
              </div>
            </div>
            {isAppRowVisible && (
              <div className="apps-row">
                <div className="userapp-item apps">
                  <img src={apps} alt="App 7" width="32px" height="32px" />
                  <p style={{ fontWeight: '400', fontSize: '12px', lineHeight: '28.8px', color: '#5E5E5E' }}>Pilot</p>
                </div>
                <div className="userapp-item apps" >

                </div>
                <div className="userapp-item">
                </div>
                <hr className="divider" />
              </div>
            )}


          </div>
        )}
        {isAccountdetailsOpen && (
          <div className="box accountdetails"  ref={accountDetailsRef} style={{ width: '100%', maxWidth: '349px', minHeight: '388px', border: '1px solid #EAEDF2', backgroundColor: '#FFFFFF', borderRadius: '28px' }}>
            <div className="box accountdetailsbox1">
              <div className="d-flex  align-items-center  ">
                <div className='col-md-4 mb-1 ' style={{ flexBasis: '25%' }} >
                  <img
                    src={user}
                    className="rounded-circle header-profile-user border-dark text-start"
                    style={{ width: "56px", height: "56px" }}
                    alt="Right 3"
                  />
                </div>
                <div className='col-md-4  text-start ' style={{ flexBasis: '50%' }}>
                  <p className="mb-1 mt-2" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>{userProfile.name}</p>
                  <p style={{ fontWeight: '400', fontSize: '14px', lineHeight: '21px', color: '#636363',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap' }}>{userProfile.email}</p>
                </div>
                <div className='col-md-4  mt-0 mb-0 d-flex justify-content-end' style={{ flexBasis: '25%' }}>
                  <img  style={{ cursor: 'pointer', width: '19.5px', height: '19.5px' }} src={exports} alt="edit"></img>
                </div>
              </div>
              <div className="text-center ps-5">
              <button className="accountsettings text-center" onClick={Manageaccount}>Manage Account</button>
              </div>
            </div>
            <div className="box accountdetailsbox2" >
            <div className="d-flex  align-items-center  ">
                <div className='col-md-4 mb-1 ' style={{ flexBasis: '20%' }} >
                  <img
                    src={user}
                    className="rounded-circle header-profile-user border-dark text-start"
                    style={{ width: "32px", height: "32px" }}
                    alt="Right 3"
                  />
                </div>
                <div className='col-md-4  text-start ' style={{ flexBasis: '50%' }}>
                  <p className="mb-1 mt-2" style={{ fontWeight: '500', fontSize: '12px', lineHeight: '18px', color: '#000000' }}>John Doe</p>
                  <p style={{ fontWeight: '400', fontSize: '10px', lineHeight: '15px', color: '#636363' }}>Johndoe07@gmail.com</p>
                </div>
                <div className='col-md-4  mt-0 mb-0 d-flex justify-content-end' style={{ flexBasis: '30%' }}>
                  <img style={{ cursor: 'pointer', width: '19.5px', height: '19.5px' }} src={exports} alt="edit"></img>
                </div>
              </div>
              <hr className="mt-0 mb-0" style={{color:'#A6A4A4'}}></hr>
              <div className="d-flex  align-items-center  ">
                <div className='col-md-4 mb-1 ' style={{ flexBasis: '20%' }} >
                  <img
                    src={user}
                    className="rounded-circle header-profile-user border-dark text-start"
                    style={{ width: "32px", height: "32px" }}
                    alt="Right 3"
                  />
                </div>
                <div className='col-md-4  text-start ' style={{ flexBasis: '50%' }}>
                  <p className="mb-1 mt-2" style={{ fontWeight: '500', fontSize: '12px', lineHeight: '18px', color: '#000000' }}>John Doe</p>
                  <p style={{ fontWeight: '400', fontSize: '10px', lineHeight: '15px', color: '#636363' }}>Johndoe07@gmail.com</p>
                </div>
                <div className='col-md-4  mt-0 mb-0 d-flex justify-content-end' style={{ flexBasis: '30%' }}>
                  <img style={{ cursor: 'pointer', width: '19.5px', height: '19.5px' }} src={exports} alt="edit"></img>
                </div>
              </div>
              <hr className="mt-0 mb-4" style={{color:'#A6A4A4'}}/>
              <div className="d-flex mb-2">
              <img className="me-3" src={exports} alt="exports" width='19.5px' height='19.5px'></img>
              <p style={{fontWeight:'500',fontSize:'14px',lineHeight:'21px',color:'#000000'}}>Sign out from all accounts</p>
              </div>
            </div>
          </div>
        )}
      </nav>
    </>
  );
}

export default WithToast(Navbars);
