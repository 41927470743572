import { React, useState, useEffect } from "react";
import male from '../images/Male-01.png';
import female from '../images/female-01.png';
import other from '../images/Others-01.png';
import { useNavigate , useLocation} from "react-router-dom";
import leftarrow from '../images/arrow-left-01.png';
import Navbars from "../navbar";
import { genderapi } from "../api/url";
// import axios from "axios";
import { extractLoginUser,handleApiError } from "../api/common/commonapi";
import axiosInstance from "../api/common/axiosinstand";
import WithToast from "../api/common/withtoast";
const genderurl = genderapi();
function Genderchange({showErrorToast}) {
  const login_user = extractLoginUser();
  const location = useLocation();
  const { state } = location;
  const userGender = state && state.userGender ? state.userGender : '';
  const userGendervisiblity = state && state.userGendervisiblity ? state.userGendervisiblity : '';
  const [selectedGender, setSelectedGender] = useState("");
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [error422,setError422] = useState('');
  const [isLoading4,setIsLoading4] = useState(false);
  const [selectedGendererror , setSelectedGenderError] = useState('');
  const navigate = useNavigate();
  const back = () => {
    navigate(`/u/${login_user}/profile`);
  }
  const cancel = () => {
    navigate(`/u/${login_user}/profile`);
  }
  const buttonData = [
    { id: "M", label: "Male" },
    { id: "F", label: "Female" },
    { id: "O", label: "Others" },
  ];

  const [activeButton, setActiveButton] = useState(userGender ? buttonData.find(button => button.id === userGender) : '');

  useEffect(() => {
    setSelectedGender(activeButton ? activeButton.id : '');
  }, [activeButton]);
  
  const handleButtonClick = (buttonType) => {
    const selectedButton = buttonData[buttonType];
    setActiveButton(selectedButton);
    setSelectedGender(selectedButton.id); 
  }
  
  
  const [activeButtons, setActiveButtons] = useState(userGendervisiblity === 'private' ? 'only-you' : 'any-one');
  const handleButtonClicks = (buttonType) => {
    setActiveButtons(buttonType);
  }
  useEffect(() => {
    setIsSaveButtonEnabled(!!selectedGender);
  }, [selectedGender]);
  const save = async () => {
    if (!selectedGender) {
      setSelectedGenderError('Please select atleast one field');
      return false;
    }
    else{
      setSelectedGenderError('');
    }
    var visibility = activeButtons === 'only-you' ? 'private' : 'public';
    var data = JSON.stringify({
      gender_visibility: visibility,
      gender : selectedGender
    })
    // const token = localStorage.getItem('access_token')
    // const headers = {
    //     'Authorization': `Bearer ${token}`,
    //     'Content-Type': 'application/json',
    // };
    try{
      setIsLoading4(true);
    const response = await axiosInstance.post(genderurl,data,)
    if(response.status === 200){
      setError422('');
      setIsLoading4(false);
      
      // localStorage.setItem('access_token',token);
      // showSuccessToast(response.data.message);
       navigate(`/u/${login_user}/profile`);
    }
    }
    catch(error){
      handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
    }
  }
  return (
    <>
     {isLoading4 && (
                <>   
                    <div className="loaderover">
                    <div className="loader ">
                    </div>
                    </div>
                </>
            )}
    <div>
      <div className="container-fluid" style={{height:'100px'}}>
        <Navbars/>
      </div>
      <div className="name_div">
       <div className="name_div1">
       <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={back} alt="leftarrow"  className="name_arrow "></img>
                <h5 className="nameh5">Gender</h5>
         </div>
         <div className="name-input">
         <div className="row mb-4">
                {buttonData.map((button, index) => (
                  <div className="col-lg-4 col-md-4 col-sm-4 col-12 text-center mobile-image-container" key={index}>
                    <button className="mobileimaggecontaine_button"
                      style={{border: activeButton && activeButton.id === button.id ? '1px solid #F77F00' : '1px solid #B9B9B9'}}
                      onClick={() => handleButtonClick(index)}
                    >
                      <div>
                        <img src={button.id === "M" ? male : button.id === "F" ? female : other} alt={button.label} className="img-fluid" width="56px" height="56px" />
                        <div className="mt-3" style={{fontWeight:'400',fontSize:'16px',lineHeight:'24px',color:'#000000'}}>{button.label}</div>
                      </div>
                    </button>
                  </div>
                ))}
              </div>
              {selectedGendererror && (<div className="error mt-3">{selectedGendererror}</div>)}
              <div className="row ps-4">
                <div className="col-12  mb-3 mt-4">
                  <label htmlFor="description" style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#000000'}}>Choose who can see your gender</label>
                </div>
              </div>

              <div className="col-12 mb-4 ps-4 d-flex justify-content-center align-items-center">
                <button
                  type="button"
                  className={`only-you ${activeButtons === "only-you" ? "active-btn" : ""}`}
                  onClick={() => handleButtonClicks("only-you")}
                >
                  Only you
                </button>
                <button
                  type="button"
                  className={`any-one ${activeButtons === "any-one" ? "active-btn" : ""}`}
                  onClick={() => handleButtonClicks("any-one")}
                >
                  Anyone
                </button>
              </div>
              <div className="error-message">{error422}</div>
              <div className="name_button_div">
                <button type="button" onClick={cancel} className="profilecancel me-4">
                  Cancel
                </button>
                <button type="button" onClick={save} style={{ backgroundColor: isSaveButtonEnabled ? '#02CD8D' : '#EAEAEA', color: isSaveButtonEnabled ? '#FFF' : '#333' , cursor: isSaveButtonEnabled ? 'pointer' : 'not-allowed' }} disabled={!isSaveButtonEnabled} className={`profilesave`}>
                  Save
                </button>
              </div>
         </div>
         </div>
         </div>
         </div>
      {/* <Navbars />
      <div className="container-fluid genderpage">
        <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="col-lg-7 col-md-8 col-sm-10">
            <div className="box " style={{ maxWidth: '619px',width:'100%',minHeight:'453px',borderRadius:'18px',backgroundColor: 'white',border:'1px solid #ABA7A7',padding:'20px 40px' }}>
              <div className="d-flex align-items-center mb-4 mt-4">
              <img src={leftarrow} onClick={back} alt="leftarrow" width='19.5px' height='15.5px' className="mb-3 me-4"style={{cursor:'pointer',marginLeft:'-10px'}}></img>
                <h5 className="mb-3" style={{fontWeight:'500',fontSize:'24px',lineHeight:'36px',color:'#232323'}}>Gender</h5>
              </div>

              <div className="row ps-5">
                {buttonData.map((button, index) => (
                  <div className="col-lg-4 col-md-4 col-sm-4 col-12 text-center mobile-image-container" key={index}>
                    <button 
                      style={{border: activeButton && activeButton.id === button.id ? '1px solid #F77F00' : '1px solid #B9B9B9', backgroundColor: 'white',borderRadius:'10px',width:'100%',maxWidth:'130px',height:'130px' }}
                      onClick={() => handleButtonClick(index)}
                    >
                      <div>
                        <img src={button.id === "M" ? male : button.id === "F" ? female : other} alt={button.label} className="img-fluid" width="56px" height="56px" />
                        <div className="mt-3" style={{fontWeight:'400',fontSize:'16px',lineHeight:'24px',color:'#000000'}}>{button.label}</div>
                      </div>
                    </button>
                  </div>
                ))}
              </div>
              {selectedGendererror && (<div className="error mt-3">{selectedGendererror}</div>)}
              <div className="row ps-4">
                <div className="col-12 ps-5 mb-3 mt-4">
                  <label htmlFor="description" style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#000000'}}>Choose who can see your gender</label>
                </div>
              </div>

              <div className="col-12 mb-4 ps-5 d-flex justify-content-center align-items-center">
                <button
                  type="button"
                  className={`only-you ${activeButtons === "only-you" ? "active-btn" : ""}`}
                  onClick={() => handleButtonClicks("only-you")}
                >
                  Only you
                </button>
                <button
                  type="button"
                  className={`any-one ${activeButtons === "any-one" ? "active-btn" : ""}`}
                  onClick={() => handleButtonClicks("any-one")}
                >
                  Anyone
                </button>
              </div>
              {error && (<div className="mb-2 text-center text-danger">{error}</div>)}
              <div className="d-flex justify-content-end mx-2 mt-4 text-end mobile-btn-container">
                <button type="button" className=" me-3 profilecancel" onClick={cancel}>
                  Cancel
                </button>
                <button onClick={save} type="button" className=" profilesave">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
export default WithToast(Genderchange);