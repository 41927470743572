import React from "react";
import loading from './images/Loading.gif'
function Homeaddresschange() {

  return (
    <>
       <div className="loading">
      <img src={loading} alt="Loading..." />
    </div>
    </>
  );
}
export default Homeaddresschange;