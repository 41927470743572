import React from "react";
import Navbars from "./navbar";
import Systemsidebar from "./systemsidebar";
import plus from './images/ApplePay.png';
import { useNavigate } from "react-router-dom";
function Purchase() {
    const navigate = useNavigate();
    const orderdet = () => {
       navigate('/orderdetail')
    }
    return (
        <>
            {/* <Navbars />
            <div className="container-fluid " style={{ backgroundColor: '#F8F8F8', width: '100%', height: '100%' }}>
                <div className="row collectionrow">
                    <div className="col-12 col-lg-3 col-md-4 col-xl-3 col-xxl-3 col_corre_2">
                        <Systemsidebar />
                    </div> */}
                    <div className="col-12 col-lg-9 col-md-8 col-xl-9 col-xxl-9 col_corr_1"  >
                        <main className="mobile_height" style={{ backgroundColor: '#FFFFFF', width: '100%', boxShadow: "0px 0px 5px 0px #0000001A", height: '100%',minHeight:'500px' }}>
                            <h5 className="ps-4 pt-4">Purchases</h5>
                            <p className="cardsp ps-4 mb-4">This is where all your Purchased Products from online e commerce platforms like Marketplace, ShopNow,<br />
                                and Library are billed are recorded</p>
                            <div className="massage_box_corr mobile_maxheight ">
                                <div className="purchaserow22">
                                    <div className="_row border-btm1  purchaserow2">
                                        <div className="border-btm">
                                            <div className="col col_70 ">
                                                <ul className="d-flex pad-0">
                                                    <li className="">
                                                        <img src={plus} alt="asda" width='32px' height='32px'></img>
                                                    </li>
                                                    <li className=" text-start" style={{ wordBreak: 'break-all' }} >
                                                        <p className="purchasep">Merchant:</p>
                                                        <h5 className="purchaseh5">EVzone Marketplace</h5>
                                                    </li>
                                                    <li className=" text-start" style={{ wordBreak: 'break-all' }}>
                                                        <p className="purchasep ">Order Number:</p>
                                                        <h5 className="purchaseh5">63294645586 </h5>
                                                    </li>
                                                    <li className=" text-start">
                                                        <p className="purchasep">Billed on:</p>
                                                        <h5 className="purchaseh5">29 Aug, 2023</h5>
                                                    </li>
                                                    <li className="text-start ">
                                                        <p className="purchasep">Paid on:</p>
                                                        <h5 className="purchaseh5">31 Aug, 2023</h5>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col col_30" >
                                                <div className="padleft">
                                                    <p className="purchasep ">Total Amount:</p>
                                                    <h5 className="purchaseh5">31 Aug, 2023</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="_row mar-30">
                                        <div className="col col_70">
                                            <div className="d-flex align-items-center">
                                                <div className="mobile_co_img">
                                                    <img src={plus} alt="plus" width='93px' height="70.67px"></img>
                                                </div>
                                                <div className="se-corr">
                                                    <h5 className="productnameh5">Tesla</h5>
                                                    <p className="productnamep mobile_co">Model Y 3 Energy High Performance
                                                        Long Range Electric Vehicle charger</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col col_30 text-center  display-block" >
                                            <button className="_order" onClick={orderdet}>Order Details</button>
                                            <button className="_download mx-xxl-4">Download Receipt</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                {/* </div>
            </div> */}

        </>
    );
}
export default Purchase;