import {React,useEffect} from "react";
import './transactions.css';
function Registerservices({setSource, source}) {
    const handleSource = (source_value) => {
         setSource(source_value);
         localStorage.setItem('activeSection', source);
    }
     useEffect(() => {
    const storedActiveSection = localStorage.getItem('activeSection'); // Retrieve the active section from localStorage

    if (storedActiveSection) {
      setSource(storedActiveSection); 
    }
}, []);
    return (
        <>
            {/* <div style={{backgroundColor:'#F8F8F8', width:'100%',height:'100vh',padding:'20px'}}> */}
            <div className="aside">
                <aside>
                    <div className="pt-4 ps-4">
                        <h6 className="registerserviceh61">Registered Services</h6>
                    </div>
                    <hr style={{ width: '100%', maxWidth: '90%', color: '#ADA9A9', margin: '0px 5%', border: 'none' }}></hr>
                    <div className="registerul mt-3">
                        <ul>
                            <li onClick={()=>handleSource("cards")}
                                style={{ backgroundColor: source === "cards" ? '#C5FFED' : 'transparent', borderLeft: source === "cards" ? '6px solid #04D08E' : 'none' }}>
                                <p className="mb-1">Cards</p>
                            </li>
                            <li  onClick={()=>handleSource("paypal")}
                                style={{ backgroundColor: source === "paypal" ? '#C5FFED' : 'transparent', borderLeft: source === "paypal" ? '6px solid #04D08E' : 'none' }}
                            >
                                <p className="mb-1">Paypal</p>
                            </li>
                            <li  onClick={()=>handleSource('mobilemoney')}
                                style={{ backgroundColor: source === "mobilemoney" ? '#C5FFED' : 'transparent', borderLeft: source === "mobilemoney" ? '6px solid #04D08E' : 'none' }}
                            >
                                <p className="mb-1">Mobile Money</p>
                            </li>
                            <li onClick={()=>handleSource('bank')}
                                style={{ backgroundColor: source === "bank" ? '#C5FFED' : 'transparent', borderLeft: source === "bank" ? '6px solid #04D08E' : 'none' }}
                            >
                                <p className="mb-1">Bank</p>
                            </li>
                            <li onClick={()=>handleSource('organization')}
                                style={{ backgroundColor: source === "organization" ? '#C5FFED' : 'transparent', borderLeft: source === "organization" ? '6px solid #04D08E' : 'none' }}
                            >
                                <p className="mb-0">Organization/Company</p>
                            </li>
                        </ul>
                    </div>
                </aside>
            </div>
            {/* </div> */}
        </>
    );
}
export default Registerservices;