import React, { useState } from "react";
import "./transactions.css";
import { useNavigate } from "react-router-dom";
import image1 from './images/text_message_black-01.png';
import image3 from './images/text_message_orange.png';
import image2 from './images/Mail_Black-01.png';
import image4 from './images/Mail_orange-01.png';
import leftarrow from './images/arrow-left-01.png';
import { extractLoginUser } from "./api/common/commonapi";
import Navbars from "./navbar";
function Twofactorauth() {
  const login_user = extractLoginUser();
  const navigate = useNavigate();
  const back = () => {
    navigate(`/u/${login_user}/twofactor`);
  }
  const twofactorauthcancel = () => {
    navigate(`/u/${login_user}/twofactor`);
  }

  const twofactorauthnext = () => {
    if (document.querySelector('.image1').src === image3) {
      navigate(`/u/${login_user}/twofactortext`);
    } else if (document.querySelector('.image2').src === image4) {
      navigate(`/u/${login_user}/twofactoremailauth`);
    }
  };
  const [selectedImage, setSelectedImage] = useState(image1);
  const [selectedOption, setSelectedOption] = useState('');
  const handleRadioChange = (event) => {
    const selectedImageId = event.target.value;
    if (selectedImageId === 'image3') {
      document.querySelector('.image1').src = image3;
      document.querySelector('.image2').src = image2;
      setSelectedOption('sms');
    } else if (selectedImageId === 'image4') {
      setSelectedOption('gmail');
      document.querySelector('.image2').src = image4;
      document.querySelector('.image1').src = image1;

    }
  };
  return (
    <>
      <Navbars />
      <div className="container-fluid twofactorauth">
        <div className="row pt-5 justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="col-lg-7 col-md-8 col-sm-10">
            <div className="box" style={{ backgroundColor: 'white',width:'100%',maxWidth:'620px',minHeight:'504px',borderRadius:'18px',border:'1px solid #ABA7A7',padding:'40px 30px' }}>
              <div className="d-flex align-items-center">
              <img onClick={back} src={leftarrow} alt="leftarrow" width='19.5px' height='15.5px' className="mb-3 me-4"style={{cursor:'pointer'}}></img>
                <h5 className='mb-3'style={{fontWeight:'500',fontSize:'24px',lineHeight:'36px',color:'#232323'}}>Two-Factor Authentication</h5>
              </div>
              <div className="d-flex justify-content-center align-items-center mt-4 flex-wrap">
                
                  <div className={`d-flex flex-column image-container m-2  ${selectedOption === 'sms' ? 'selecteds' : ''}`}>
                    <img src={selectedOption === 'sms' ? image3 : image1} className="image1" alt="smsblack" />
                    <label className="image-text">Text message(sms)</label>
                    <button className={`radiobutton ${selectedOption === 'sms' ? 'selecteds' : ''}`}>
                      <input type="radio"  name="imageSelection" value="image3" className="image3 smsradiobutton" onClick={handleRadioChange} />
                      <br />
                      {selectedImage === image3 && (
                        <img
                          src={image3}
                          alt="smsorange"
                          className="image1"

                        />
                      )}
                    </button>
                  </div>
                  <div className={`d-flex flex-column image-container1 m-2  ${selectedOption === 'gmail' ? 'selecteds' : ''}`}>
                    <img src={selectedOption === 'gmail' ? image4 : image2} className="image2" alt="gmailblack" />
                    <label className="image-text1">Gmail</label>
                    <button className={`radiobutton1 ${selectedOption === 'gmail' ? 'selecteds' : ''}`}>
                      <input type="radio" name="imageSelection" value="image4" className="image4" onClick={handleRadioChange} />
                      <br />
                      {selectedImage === image4 && (
                        <img
                          src={image4}
                          alt="gmailorange"
                          className="image2"
                        />
                      )}
                    </button>
                  </div>
                </div>
              
              <div className="d-flex justify-content-end mt-5 mx-5">
                <button className=" profilecancel me-3" onClick={twofactorauthcancel}>Cancel</button>
                <button className=" profilesave" onClick={twofactorauthnext}>Next</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Twofactorauth;
