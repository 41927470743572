import React from "react";
import leftarrow from './images/arrow-left-01.png';
import Navbars from "./navbar";
import { useDropzone } from "react-dropzone";
import upload from './images/upload-01.png';
import calander from './images/calendar-01.png';
import './home.css';
import {  useNavigate } from "react-router-dom";
function Adddocument() {
    const navigate = useNavigate();
    const back = () => {
      navigate('/personaldocuments');
    }
    const cancel = () => {
        navigate('/personaldocuments');
    }
    const submit = () => {
        navigate('/personaldocuments');
    }
    const onDrop = (acceptedFiles) => {
        // Handle dropped files here
        console.log(acceptedFiles);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <>
            <Navbars />
            <div className="container-fluid incomeingpage">
                <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <div className="col-lg-7 col-md-8 col-sm-10">
                        <div className="box adddocumentbox p-5 border " style={{ width: '100%', backgroundColor: 'white',border:'1px solid #ABA7A7',borderRadius:'18px' }}>
                            <div className="d-flex align-items-center mb-3">
                                <img src={leftarrow} onClick={back} alt="leftarrow" width='19.5px' height='15.5px' className="mb-3 me-4" style={{ cursor: 'pointer' }}></img>
                                <h5 className="mb-3" style={{fontWeight:'500',fontSize:'24px',verticalAlign:'Cap-height',lineHeight:'36px'}}>Upload Document</h5>
                            </div>
                            <div className="mb-3 name-input  ps-5 mb-4">
                                <label className="mb-2" style={{fontWeight:'500',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px'}}>Document Name</label>
                                <input type="text" className="form-control" placeholder="" style={{ width: '100%', maxWidth: '519px', height: '38px', padding: '8px', border: '1px solid #B9B9B9',borderRadius:'6px' }}  />
                            </div>
                             <div className="ps-5"> 
                            <div className={`mb-3  drag-and-drop-box ${isDragActive ? 'active' : ''}`} onClick={() => navigate('/uploaddocument')}  /* {...getRootProps()} */ style={{width:"100%",maxWidth:'519px', border: '1px solid #02CD8D', padding: '2rem', backgroundColor: "#F6FFFC", borderRadius: '14px' }}>
                                <input {...getInputProps()} />
                                <div className="text-center">
                                    {isDragActive ? (
                                        <p className="p-3 border rounded">Drop the files here ...</p>

                                    ) : (
                                        <div className="p-1 ">
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div className="mb-2 d-flex justify-content-center align-items-center" style={{ width: '58.83px', height: '62px', border: 'none', borderRadius: '80%', backgroundColor: '#02CD8D' }}>
                                                    <img src={upload} width="30.52px" height="35px"  ></img>
                                                </div>
                                            </div>
                                            <p style={{fontWeight:'500',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#000000'}}>Drag and Drop file here or <b style={{ textDecoration: 'underline', color: '#02CD8D',fontWeight:'400',verticalAlign:'Cap-height',lineHeight:'21px',fontSize:'14px' }} onClick={() => navigate('/uploaddocument')}>Choose File</b></p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            </div>
                            <div className="">
                            <div className="d-flex  align-items-center  justify-content-around">
                                <p className="" style={{ fontSize: '12px', color: '#797979', fontWeight: '400',verticalAlign:'Cap-height',lineHeight:'18px' }}>Supported Formats: JPG,JPEG,PDF </p>
                                <p className="" style={{ fontSize: '12px', color: '#797979', fontWeight: '400',verticalAlign:'Cap-height',lineHeight:'18px' }}>Maximum File Size: 10MB</p>
                            </div>
                            </div>
                            <div className="row mt-1 ps-5">
                                <div className="col-md-6">
                                    <label className="mb-1" style={{ fontWeight: '500',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#000000' }}>Date of Issue</label>
                                    <div className="input-with-icons">
                                        <input type="text" className="form-control" placeholder="" style={{ border: '1px solid #B9B9B9',width:'100%',maxWidth:'240px',height:'40px' }} />
                                        <img src={calander} className="icons" width="19.5px" height="19.5px" alt="calander"></img>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className="mb-1" style={{  fontWeight: '500',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#000000'  }}>Expiry Date</label>
                                    <div className="input-with-icons">
                                        <input type="text" className="form-control" placeholder="" style={{border: '1px solid #B9B9B9',width:'100%',maxWidth:'240px',height:'40px' }} />
                                        <img src={calander} className="icons" width="19.5px" height="19.5px" alt="upload icon" />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end mb-3 me-5 mt-3 text-end">
                                    <button type="button" onClick={cancel} className=" me-3 documentcancel" >
                                        Cancel
                                    </button>
                                    <button type="button" onClick={submit} className=" documentsubmit">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Adddocument;