import {React,useEffect,useState,useRef} from "react";
import { useNavigate } from "react-router-dom";
import Navbars from "../navbar";
import leftarrow from '../images/arrow-left-01.png'
import Select, { components } from 'react-select';
import axiosInstance from "../api/common/axiosinstand";
import { extractLoginUser,handleApiError } from "../api/common/commonapi";
import { useIpContext } from "../api/common/ipContext";
import { country_codeapi , otpupdatesendapi , recoveryemailapi} from "../api/url";
import WithToast from "../api/common/withtoast";
const countrycodeurl = country_codeapi();
const otpupdatesendapiurl = otpupdatesendapi(); 
const recoveryemailapiurl = recoveryemailapi();
function Recoveryphone({showErrorToast}) {
  const login_user = extractLoginUser();
  const navigate = useNavigate();
  const { ipCountryCode , getUserIpCountryCode } = useIpContext();
  const [recoveryphone , setRecoveryphone] = useState(true);
  const [recoveryphonecode , setRecoveryphonecode] = useState(false);  
  const mobileInputRef = useRef(null);
  const [dialCode, setDialCode] = useState('');
  const [mccId, setMccId] = useState('');
  const [mobileChange, setMobileChange] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [countryOptions, setCountryOptions] = useState([]);
  const [error422, setError422] = useState('');
  const [isLoading4, setIsLoading4] = useState(false);
  const [otp, setOtp] = useState('');
  const [isSaveButtonEnablednextotp, setIsSaveButtonEnablednextotp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveButtonEnablednext, setIsSaveButtonEnablednext] = useState(false);
  const updateExampleNumber = (iso2) => {
    if (window.intlTelInputUtils) {
      try {
        const exampleNumber = window.intlTelInputUtils.getExampleNumber(
          iso2,
          true,
          window.intlTelInputUtils.numberFormat.E164
        );
        const cleanedNumber = exampleNumber.replace(/\D/g, '').replace(/^0+/, '');
        const maxLength = cleanedNumber.length;
        return maxLength;
      } catch (error) {
        console.error('Error fetching example number for country code:', iso2, error);
        return 15; // Fallback to a default length
      }
    } else {
      console.error('intlTelInputUtils is not loaded');
      return 15; // Fallback to a default length
    }
  };
  const fetchCountryCode = async () => {
    setIsLoading(true); 
    try {
      const response = await axiosInstance.get(countrycodeurl);
      const data = response.data;

      if (data.message === 'Success') {
        const mccList = data.data;
        const codes = mccList.map((mcc) => ({
          country_code: mcc.country_code,
          dial_code: mcc.code,
          mcc_id: mcc.id,
          label: `${mcc.name} (+${mcc.code})`,
          value: mcc.country_code,
          flag: `https://flagcdn.com/w320/${mcc.country_code.toLowerCase()}.png`,
        }));

        setCountryOptions(codes);

        const selectedCountryData = codes.find(
          (country) => country.country_code === ipCountryCode
        );

        if (selectedCountryData) {
          setMccId(selectedCountryData.mcc_id);
          setDialCode(`+${selectedCountryData.dial_code}`);
          setCountryCode(selectedCountryData.country_code);

          const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
          if (maxLength !== null && mobileInputRef.current) {
            mobileInputRef.current.setAttribute('maxlength', maxLength);
          }
        }
      } else {
        console.error('Failed to fetch country codes');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // Set loading to false after the API call completes
    }
  };
  
  useEffect(() => {
    if(!ipCountryCode){
      getUserIpCountryCode();
    }
    fetchCountryCode();
  }, [ipCountryCode, countrycodeurl]);

  const handleKeyPress = (e) => {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];

    if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };
  const handlePhoneNumberChange = (value) => {
    setError422('');
    if (value.startsWith('0')) {
      value = value.slice(1);
    }
    const maxLength = mobileInputRef.current.getAttribute('maxlength');
    if (value.length > maxLength) {
      value = value.slice(0, maxLength);
    }
    setMobileChange(value);
  };
  const handlePaste = (e) => {
    setError422('');
    const pastedText = e.clipboardData.getData('text');
    let numericPastedText = pastedText.replace(/[^0-9]/g, '');
    const dialCodeWithoutPlus = dialCode.replace('+', '');

    if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
        numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
    }

    if (numericPastedText.startsWith('0')) {
        numericPastedText = numericPastedText.slice(1);
    }

    const maxLength = mobileInputRef.current.getAttribute('maxlength');
    const truncatedPastedText = numericPastedText.slice(0, maxLength);

    setMobileChange(truncatedPastedText);


    e.preventDefault();
};
  const handleCountryChange = (selectedOption) => {
    const selectedCountryData = countryOptions.find(
        (country) => country.value === selectedOption.value
    );
  
    if (selectedCountryData) {
        setMccId(selectedCountryData.mcc_id);
        setDialCode(`+${selectedCountryData.dial_code}`);
        setCountryCode(selectedCountryData.country_code);
  
        const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
        if (maxLength !== null && mobileInputRef.current) {
            mobileInputRef.current.setAttribute('maxlength', maxLength);
        }
  
        // Truncate the mobile number to the new max length
        const truncatedMobileChange = mobileChange.slice(0, maxLength);
        setMobileChange(truncatedMobileChange);
  
  
    }
  };
  const customSingleValue = ({ data }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={data.flag}
        alt=""
        style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
      />
      {/* {data.label} */}
    </div>
  );

  const customOption = (props) => {
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={props.data.flag}
            alt=""
            style={{ width: '25px', height: 'auto', marginRight: '10px' }}
          />
          {props.data.label}
        </div>
      </components.Option>
    );
  };

  const back = () => {
    navigate(`/u/${login_user}/security`);
  }
  const cancel = () => {
    navigate(`/u/${login_user}/security`);
  }
  const checck = () => {
    const valid = (
      mobileChange !== ''
    )
    setIsSaveButtonEnablednextotp(valid);
  }
  useEffect(()=>{
    checck();
  },[mobileChange]);
  const next = async() => {
    if(mobileChange.length < 9 || mobileChange.length > 15) {
      setError422('The mobile must be between 9 and 15 digits')
      return false;
    }else{
      setError422('')
    }
    const data ={
      mobile: mobileChange,
      mcc_id : mccId,
    }
    try {
      setIsLoading4(true);
      const response = await axiosInstance.post(otpupdatesendapiurl, data);
      if (response.status === 200) {
        setIsLoading4(false);
        const recovery_token = response.data.data.token;
        localStorage.setItem('recovery_token', recovery_token);
        setRecoveryphonecode(true);
        setRecoveryphone(false);
      }
    }
    catch (error) {
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    }
  }
  const handleKeyDown = (e) => {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];
    if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault(); // Prevent the default action
      setError422('Please enter only numbers'); // Set error message
    } else {
      setError422(''); // Clear error if valid key is pressed
    }
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setOtp(value);
  };
  const backs = () => {
    setRecoveryphone(true);
    setRecoveryphonecode(false);
    setOtp('');
    setError422('');
    // navigate(`/u/${login_user}/security`);
  }
  const confirmcancel = () => {
    navigate(`/u/${login_user}/security`);
  }
  const checcks = () => {
    const valid = (
      otp !== ''
    )
    setIsSaveButtonEnablednext(valid);
  }
  useEffect(()=>{
    checcks();
  },[otp]);
  const confirmcode = async() => {
    const recovery_token = localStorage.getItem('recovery_token');
    const data ={
      mobile: mobileChange,
      mcc_id : mccId,
      otp : otp ,
      token : recovery_token
    }
    try {
      setIsLoading4(true);
      const response = await axiosInstance.post(recoveryemailapiurl, data,);
      if (response.status === 200) {
       setIsLoading4(false);
        navigate(`/u/${login_user}/security`);
        setRecoveryphone(false);
        setRecoveryphonecode(false);
      }
    }
    catch (error) {
      if(error.response){
        if(error.response.status === 422){
         const error_code = error.response.data.code;
         if (error_code === 'otp_retry') {
           navigate(`/u/${login_user}/security`);
           setIsLoading4(false);
         }else{
           handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
         }
        } else{
         handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
        }
     }else{
     handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
     }
    }
  }
  return (
    <>
     {isLoading4 && (
        <>
          <div className="loaderover">
            <div className="loader "></div>
          </div>
        </>
      )}
       <div>
        <div className="container-fluid" style={{ height: '100px' }}>
          <Navbars />
        </div>
        <div className="name_div">
        {recoveryphone && (
         <>
          <div className="name_div1">
            <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={back} alt="leftarrow" className="name_arrow "></img>
              <h5 className="nameh5">Recovery Phone</h5>
            </div>
            <div className="name-input name-inputimage name-inputimageemail ">
            <div >
            <label
        htmlFor="inputField"
        className="form-label"
        style={{
          fontWeight: '500',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#000000',
        }}
      >
        Mobile Number
      </label>
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
        <div style={{ position: 'absolute', left: 0, zIndex: 1 }}>
          <Select
            options={countryOptions}
            onChange={handleCountryChange}
            value={countryOptions.find(option => option.value === countryCode)}
            components={{ SingleValue: customSingleValue, Option: customOption }}
            placeholder=""
            isLoading={isLoading} // Pass the loading state to Select
            styles={{
              control: (provided) => ({
                ...provided,
                width:'80px',
                height: '100%',
                border: 'none',
                boxShadow: 'none',
                background: 'transparent',
                display: 'flex',
                alignItems: 'center',
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                padding: '0 8px',
              }),
              indicatorSeparator: () => ({
                display: 'none',
              }),
              singleValue: (provided) => ({
                ...provided,
                display: 'flex',
                alignItems: 'center',
              }),
              valueContainer: (provided) => ({
                ...provided,
                padding: '0',
                display: 'flex',
                alignItems: 'center',
              }),
              input: (provided) => ({
                ...provided,
                margin: '0',
                padding: '0',
              }),
              placeholder: (provided) => ({
                ...provided,
                margin: '0',
                padding: '0',
              }),
              menu: (provided) => ({
                ...provided,
                minWidth: '300px',
              }),
            }}
          />
        </div>
        <p style={{ position: 'absolute', left:'78px', top: '51%', transform: 'translateY(-50%)', cursor: 'pointer',color:'hsl(0, 0%, 20%)',fontSize:'14px',lineHeight:'1.5',marginBottom:'0px',fontWeight:'400' }}>{dialCode}</p>
        <input
          type="text"
          className={`form-control docform ${mobileChange ? 'docform-active' : ''}`}
          id="mobile"
          ref={mobileInputRef}
          style={{ paddingLeft: '120px', width: '100%' }}
          placeholder="Mobile Number"
          value={mobileChange}
          name="mobile"
          onPaste={(e) => handlePaste(e)}
          onKeyPress={handleKeyPress}
          onChange={(e) => handlePhoneNumberChange(e.target.value)}
        />
      </div>
      <input
        type="hidden"
        className="form-control signupemalichange"
        placeholder="Mobile Number"
        value={mccId}
        onChange={(e) => setMccId(e.target.value)}
      />
                </div>
                <div className="error-message error-messageprofile">{error422}</div>
                <div className="name_button_div">
                <button type="button" onClick={cancel} className="me-3  profilecancel">
                  Cancel
                </button>
                <button type="button" onClick={next} style={{ backgroundColor: isSaveButtonEnablednextotp ? '#02CD8D' : '#EAEAEA', color: isSaveButtonEnablednextotp ? '#FFF' : '#333' , cursor: isSaveButtonEnablednextotp ? 'pointer' : 'not-allowed' }} disabled={!isSaveButtonEnablednextotp} className={`profilesave`}>
                  Save
                </button>
              </div>
                </div>
            </div>
            </>
        )}
        {recoveryphonecode &&(
          <>
          <div className="name_div1">
            <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={backs} alt="leftarrow" className="name_arrow "></img>
              <h5 className="nameh5">Confirmation Code</h5>
            </div>
            <div className="name-input name-inputimage name-inputimageemail ">
            <div>
                  <label htmlFor="inputField"  className="form-label" style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#000000'}}>Verification Code</label>
                  <input type="text" value={otp} onChange={handleChange} onKeyDown={handleKeyDown} maxLength={6} className={`form-control docform ${otp ? 'docform-active' : ''}`}  id="inputField" placeholder="Enter Verification Code" />
                </div>
                <div className="error-message error-messageprofile">{error422}</div>
                <div className="name_button_div">
                <button type="button" onClick={confirmcancel} className="me-3  profilecancel">
                  Cancel
                </button>
                <button type="button" onClick={confirmcode} style={{ backgroundColor: isSaveButtonEnablednext ? '#02CD8D' : '#EAEAEA', color: isSaveButtonEnablednext ? '#FFF' : '#333' , cursor: isSaveButtonEnablednext ? 'pointer' : 'not-allowed' }} disabled={!isSaveButtonEnablednext} className={`profilesave`}>
                  Save
                </button>
              </div>
            </div>
            </div>
          </>
        )} 
        </div>
        </div>
    </>
  );
}
export default WithToast(Recoveryphone);