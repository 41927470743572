import React, { useState, useEffect, useRef } from 'react';
import searchIcon from '../../images/address-icons/search.png';
const PlaceAutocomplete = ({ onPlaceSelect }) => {
  const [inputValue, setInputValue] = useState('');
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [placesService, setPlacesService] = useState(null);
  const [autocompleteResults, setAutocompleteResults] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    if (window.google) {
      const autocomplete = new window.google.maps.places.AutocompleteService();
      const places = new window.google.maps.places.PlacesService(document.createElement('div'));
      setAutocompleteService(autocomplete);
      setPlacesService(places);
    }
  }, []);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    if (value.trim() === '') {
      setAutocompleteResults([]);
      return;
    }
    if (autocompleteService) {
      autocompleteService.getPlacePredictions({ input: value }, (results, status) => {
        if (status === 'OK') {
          setAutocompleteResults(results);
        } else {
          console.error('Autocomplete error:', status);
          setAutocompleteResults([]);
        }
      });
    }
  };

  const handlePlaceSelect = (prediction) => {
    if (placesService && prediction.place_id) {
      placesService.getDetails({ placeId: prediction.place_id }, (place, status) => {
        if (status === 'OK' && place && place.geometry) {
          setInputValue(prediction.description);
          setAutocompleteResults([]);
          onPlaceSelect(place); // Send the detailed place to parent component
        } else {
          console.error('Selected place does not have geometry or location information.');
        }
      });
    }
  };

  return (
    <div className="search-input-container" style={{ position: 'relative', marginTop: '10px' }}>
                    <img src={searchIcon} alt="search" className="search-icon" />
                    <input
                      type="text"
                      className="form-control docform-address"
                      placeholder="Search for street name, area, etc."
                      style={{ paddingLeft: '45px' }}
                      ref={inputRef}
                      value={inputValue}
                      onChange={handleInputChange}
                    />
                    {autocompleteResults.length > 0 && (
                      <div className="custom-autocomplete">
                        <div className="search-results-header">Search Results</div>
                        {autocompleteResults.map((result) => (
                          <div
                            key={result.place_id}
                            className="custom-autocomplete-item"
                            onClick={() => handlePlaceSelect(result)}
                          >
                            <span className="pac-icon pac-icon-marker"></span>
                            <span>{result.description}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
  );
};

export default PlaceAutocomplete;
