import React,{useState,useEffect} from "react";
import Navbars from "../navbar";
import Sidebar from "../sidebar";
import Docnav from "./docnav";
import Generalidentification from "./generalidentification";
import Skills from "./skills";
import Acdamichistory from "./acdamichistory";
import Workhistory from "./workhistory";
import Vechicle from "./vechicle";
function Adddocumentresources(){
    const [source, setSource] = useState(() => {
        const storedActiveSection = localStorage.getItem('activeNavItem');
        return storedActiveSection || 'General Identification'; // Set the default section if none is found in localStorage
      });
      useEffect(() => {
        localStorage.setItem('activeNavItem', source);
      }, [source]);
    return(
        <>
         <Navbars />
      <div className="container-fluid bg-white" style={{padding:'0px'}}>
        <div className="documentrow">
          <div className=" documentside">
            <Sidebar />
          </div>
          <div className=" documentmain">
            <div className="text-center">
              <h4 className="mb-3" style={{ fontWeight: '500', fontSize: '24px', verticalAlign: 'cap-height', lineHeight: '36px' }}>Documents</h4>
              <p style={{ fontWeight: '400', fontSize: '16px', verticalAlign: 'cap-height', lineHeight: '24px', color: '#232323' }}>Manage your documents , including personal documents , vehicle documents and professional documents</p>
            </div>
            <div className="document">
                <Docnav setSource={setSource} source={source}/>
                {/* <div> */}
                {source === "General Identification" && 
                <Generalidentification/>}
                {source === "Skills" && 
                <Skills/>}
                {source === "Academic History" && 
                <Acdamichistory/>}
                {source === "Work History" && 
                <Workhistory/>}
                {source === "Vehicle Details" && 
                <Vechicle/>}
                </div>
                </div>
           </div>
           </div>
           {/* </div>  */}
        </>
    );
}
export default Adddocumentresources;