import React from "react";
import Navbars from "./navbar";
import './home.css';
import leftarrow from './images/arrow-left-01.png';
import { useNavigate } from "react-router-dom";
import search from './images/search_black-01.png';
import location01 from './images/location-01.png';
import experince from './images/experience-01.png';
function Jobposting() {
    const navigate = useNavigate();
    const back = () => {
        navigate('/');
    }
    return (
        <>
            <Navbars />
            <div className="container-fluid bg-white jobpostings">
                <div className="row pt-5">
                    <div className="box p-3  border-rounded pt-3 px-md-5 mb-4" style={{ backgroundColor: 'white', marginTop: '50px', width: '95%' }}>
                        <div className="d-flex align-items-center mb-3">
                            <img onClick={back} className="me-4" style={{ cursor: 'pointer', width: '20px', height: '20px' }} src={leftarrow} alt="edit"></img>
                            <h4 className="mb-1">Job Postings</h4>
                        </div>
                        <div className="input-row ps-5" style={{ display: 'flex' }}>
                        <div className="input-with-icon">
                            <input type="text" className="form-control no-border" style={{ flex: 1, marginRight: '0px',borderTopLeftRadius:'8px',borderBottomLeftRadius:'8px' }} placeholder="Search Job" />
                            <img src={search} alt="search"  className="icon"></img>
                            </div>
                            <div className="input-with-icon">
                            <input type="text" className="form-control no-border" style={{ flex: 1, marginRight: '0px' }} placeholder="Location" />
                            <img src={location01} alt="location01" className="icon"></img>
                            </div>
                            <div className="input-with-icon">
                            <select className="form-select no-border " style={{ flex: 1, marginRight: '0px', }} placeholder="Experience">
                                <option>Experience</option>
                            </select>
                            <img src={experince} className="icon"></img>
                            </div>
                            <div className="input-with-icon">
                            <input type="button" className="form-control with-border " style={{ flex: 1, borderRadius: '0',borderLeft:'none',borderTopRightRadius:'8px',borderBottomRightRadius:'8px' }} value="Find Jobs" />   
                        </div>
                        </div>
                        <div className="row ps-5">
                                <div className="col-md-3">
                                <div className="box p-3  border-rounded pt-3 px-md-5 mb-4" style={{ backgroundColor: 'white', }}>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                <div className="box p-3  border-rounded pt-3 px-md-5 mb-4" style={{ backgroundColor: 'white',  }}>
                                </div>
                                </div>
                                <div className="col-md-5">
                                <div className="box p-3  border-rounded pt-3 px-md-5 mb-4" style={{ backgroundColor: 'white' }}>
                                </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Jobposting;