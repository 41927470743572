import {React,useState,useEffect} from "react";
import { useLocation} from "react-router-dom";
function Systemsidebar({setSource2, source2}){
    const handleSource = (source2) => {
        setSource2(source2);
        localStorage.setItem('activeSection', source2);
   }
    const location = useLocation();
  const [activeCategory, setActiveCategory] = useState("System");
  const [activeMenuItem, setActiveMenuItem] = useState(null);
//   const handleCategoryChange = (category) => {
//     setActiveCategory(category)
// };
const handleCategoryChange = (category) => {
    setActiveCategory(category);
    setSource2(category === "Manual" ? "wallettowallet" : "purchase"); // Set the active section based on the selected category
    localStorage.setItem('activeSection', category === "Manual" ? "wallettowallet" : "purchase"); // Update the active section in localStorage
};
const sideMenu = [
    { "title": 'Wallet To Wallet', to: "wallettowallet" },
    { 'title': 'Wallet To Bank', to: 'wallettobank' },
    { 'title': 'Wallet To Mobile Money', to: 'wallettomobilemoney'},
    { 'title': 'Wallet to Paypal', to: 'wallettopaypal' },
    { 'title': 'Gift Cards', to: 'giftcards' },
];
const systemMenu = [
    { "title": 'Purchase', to: "purchase" },
    { 'title': 'Tickets', to: 'tickets' },
    { 'title': 'Subscription', to: 'subscription' },
    { 'title': 'Contribution', to: 'contribution' },
    { 'title': 'Payout', to: 'payout' },
    { 'title': 'Refund', to: 'refund' },
]
useEffect(() => {
    const storedActiveSection = localStorage.getItem('activeSection'); // Retrieve the active section from localStorage

    if (storedActiveSection) {
      setSource2(storedActiveSection); // Set the active section based on localStorage
    }
    const pathname = source2;
    const activeItem = [...sideMenu, ...systemMenu].find(item => item.to === pathname);

    if (activeItem) {
        setActiveMenuItem(activeItem);
        if (sideMenu.some(item => item.to === pathname)) {
            setActiveCategory("Manual");
        } else if (systemMenu.some(item => item.to === pathname)) {
            setActiveCategory("System");
        }
    } else {
        setActiveMenuItem(null);
    }
}, [location]);
    return(
        <>
          <div className="aside">
                <aside>
                <div className="pt-4 ps-5 manual pe-5 d-flex justify-content-between">
                <div onClick={() => handleCategoryChange("System")}>
                            <h6 className={`  ${activeCategory=== "System" ? "registerserviceh62" : "registerserviceh6inactive"}`}>System</h6>
                        </div>
                        <div onClick={() => handleCategoryChange("Manual")}>
                            <h6 className={` ${activeCategory === "Manual" ?  "registerserviceh62" : "registerserviceh6inactive"}`}>Manual</h6>
                        </div>
                    </div>
                    <hr className="manualsidehr" style={{ width: '100%', maxWidth: '90%', color: '#ADA9A9', margin: '5% 5%', border: 'none' }}></hr>
                    <div className="registerul mt-3">
                        <ul>
                        {activeCategory === "System" && systemMenu.map((menuItem) => (
                                <li key={menuItem.to} onClick={() => handleSource(menuItem.to)} style={{ backgroundColor: source2 === menuItem.to ? '#C5FFED' : 'transparent', borderLeft: source2 === menuItem.to ? '6px solid #04D08E' : 'none', }}>
                                    <p className="mb-0">{menuItem.title}</p>
                                </li>
                            ))}   
                         {activeCategory === "Manual" && sideMenu.map((menuItem) => (
                                <li key={menuItem.to} onClick={() => handleSource(menuItem.to)} style={{ backgroundColor:source2 === menuItem.to ? '#C5FFED' : 'transparent',borderLeft: source2 === menuItem.to ? '6px solid #04D08E' : 'none',}}>
                                    <p className="mb-0">{menuItem.title}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                </aside>
            </div>
        </>
    );
}
export default Systemsidebar;