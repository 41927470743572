import { React, useState, useEffect ,useRef} from "react";
import './App.css';
function Compile() {

  // const items = [
  //   <div className="back5 bannerimg" style={{ width: '100%', height: '' }} key={5}></div>,
  //   <div className="back4 bannerimg" style={{ width: '100%', height: '' }} key={4}></div>,
  //   <div className="back3 bannerimg" style={{ width: '100%', height: '' }} key={3}></div>,
  //   <div className="back2 bannerimg" style={{ width: '100%', height: '' }} key={2}></div>,
  //   <div className="back1 bannerimg" style={{ width: '100%', height: '' }} key={1}></div>,
  // ]; 
  

  // const swipeDirectionRef = useRef(0);

  // const handleSwipeStart = (index) => {
  //   swipeDirectionRef.current = index;
  // };

  // const handleSwipeEnd = (index) => {
  //   const delta = index - swipeDirectionRef.current;

  //   if (delta > 0) {
  //     setCurrentIndex((prevIndex) => (prevIndex + delta) % items.length);
  //   } else if (delta < 0) {
  //     setCurrentIndex((prevIndex) => (prevIndex + delta + items.length) % items.length);
  //   }
  // };

  // const items = ["back1", "back2", "back3", "back4", "back5"];
  const [items , setItems] = useState(["back1", "back2", "back3", "back4", "back5"])
  const showCount = 1; // Number of images to show at a time
  const itemsPerLoop = 5; // Number of items to show per loop
  const autoplayInterval = 15000; // Autoplay interval in milliseconds
  const [currentIndex, setCurrentIndex] = useState(0);
  const nextSlide = () => {
    setItems((prev)=>[...prev,prev[currentIndex]]);
    setCurrentIndex(currentIndex + 1);
    // setCurrentIndex(currentIndex === items.length -1 ?0 : currentIndex + 1 );
  };
  const prevSlide = () => {
    setCurrentIndex(currentIndex === 0 ? items.length - 1 : currentIndex - 1);
  };
  

  useEffect(() => {
    const intervalId = setInterval(nextSlide, autoplayInterval);
  
    return () => clearInterval(intervalId);
  }, [currentIndex]); 

  
  return (
    <>   
    <div id="custom-carousel" className="custom-carousel">
    <div
      className="slider-wrapper"
      // style={{ width: `${100 * itemsPerLoop}%` }}
    >
      <div
  className="slider-content"
  style={{
    // transform: `translateX(-${(currentIndex % items.length) * (100 / showCount)}%)`,
    transform: `translateX(-${((currentIndex + 1) * 100)}%)`,

    transition: "transform 0.5s ease-in-out",
    willChange: "transform", // Add this for smoother transitions
    // width: `${100 / itemsPerLoop}%`,
    width: `${100}%`,
  }}
>
        {items.map((item, index) => (
          <div key={index} className={`bannerimg ${item}`}></div>
        ))}
      </div>
    </div>
    {/* <button onClick={prevSlide} className="prev-button">
      &#8249;
    </button>
    <button onClick={nextSlide} className="next-button">
      &#8250;
    </button> */}
  </div>
       {/* <Carousel
       className="Carousel"
        showArrows={true}
        infiniteLoop={true}
        autoPlay={true}
        interval={1000}
        showThumbs={false}
        showStatus={false}
        showIndicators={true}
        stopOnHover={false} 
        onSwipeEnd={handleSwipeEnd}
        selectedItem={currentIndex}
        onSwipeStart={handleSwipeStart}
      onChange={(index) => setCurrentIndex(index)}
        responsive={[
          {
            breakpoint: 480,
            items: 1,
          },
          {
            breakpoint: 768,
            items: 2,
          },
          {
            breakpoint: 1024,
            items: 3,
          },
          {
            breakpoint: 1440,
            items: 4,
          },
        ]}
      >
        {items}
      </Carousel>  */}
    </>
  );
};
export default Compile;


