import { React, useState } from "react";
import Sidebar from "./sidebar";
import Navbars from "./navbar";
import school from './images/school_green-01.png';
import starorange from './images/star_orange-01.png';
import starhalf from './images/star_grey_and_orange-01.png';
import stargrey from './images/star_grey-01.png';
import './review.css';
import user from './images/user-3.jpg';
import Select from 'react-select';
import{ Rate }from"antd";
function Reviewratings() {
    const [activeButtons, setActiveButtons] = useState([]);
    const [rating, setRating] = useState(0);
    const handleButtonClick = (buttonIndex) => {
        const isButtonActive = activeButtons.includes(buttonIndex);

        if (isButtonActive) {
            setActiveButtons((prevActiveButtons) =>
                prevActiveButtons.filter((button) => button !== buttonIndex)
            );
        } else {
            setActiveButtons((prevActiveButtons) => [...prevActiveButtons, buttonIndex]);
        }
    };
    const [selectedOption, setSelectedOption] = useState(null);

    const options = [
        { value: 'most-recent', label: 'Sort by: Most recent' },
        { value: 'review-1', label: 'Sort by: Review 1' },
        { value: 'review-2', label: 'Sort by: Review 2' },
        // Add more options if needed
    ];

    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: '27%',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontWeight: state.data.value === 'most-recent' ? '400' : 'normal',
            color: state.data.value === 'most-recent' ? '' : 'black',
        }),
    };

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };
    const rating1 = 60;
    const rating2 = 40;
    const rating3 = 30;
    const rating4 = 20;
    const rating5 = 10;
    const [selectedRating, setSelectedRating] = useState(0);
    const [hoveredRating, setHoveredRating] = useState(0);

   
    const handleClick = (rating) => {
        if (selectedRating === rating) {
            setSelectedRating(rating - 1);
        } else {
            setSelectedRating(rating);
        }
    };

    const handleMouseOver = (rating) => {
        setHoveredRating(rating);
    };

    const handleMouseOut = () => {
        setHoveredRating(0);
    };

    const getStarImage = (index) => {
        if (selectedRating >= index) {
            return starorange; // Maintain color for selected stars
        }
        if (hoveredRating >= index) {
            return starorange; // Apply hover effect to unselected stars
        }
        return stargrey; // Default color for unselected stars
    };
    return (
        <>
            <Navbars />
            <div className="container-fluid bg-white"style={{padding:'0px'}}>
                <div className="documentrow">
                    <div className="col-12 col-md-4 col-lg-3 documentside">
                        <Sidebar />
                    </div>
                    <div className="col-12  col-md-8 col-lg-9 documentmain">
                        <div className="box p-5 border-rounded pt-5 px-md-4 mb-4" style={{ backgroundColor: 'white',width:'100%',minHeight:'896px',borderRadius:'18px',border:'1px solid #ABA7A7' }}>
                            <div className="d-flex mb-3  align-items-center">

                                <img
                                    src={school}
                                    alt="school"
                                    width="96px"
                                    height="96px"
                                    className="me-4"
                                />
                                <div>
                                    <h6 className="reviewratingh6">School</h6>
                                    <div className="d-flex justify-content-between reviewratings">
                                        <img style={{ marginLeft: '0%' }} src={starorange}  alt='star' ></img>
                                        <img style={{ marginLeft: '5%' }} src={starorange}  alt='star' ></img>
                                        <img style={{ marginLeft: '5%' }} src={starorange}  alt='star' ></img>
                                        <img style={{ marginLeft: '5%' }} src={starorange}  alt='star' ></img>
                                        <img style={{ marginLeft: '5%' }} src={starhalf}  alt='star' ></img>
                                        <div style={{ marginLeft: '10%',fontWeight:'400',fontSize:'16px',lineHeight:'28.8px',color:'#737373',marginTop:'-3%' }}>48.5K</div>
                                    </div>
                                </div>
                            </div>
                            <h5 className="mb-3 reviewratingsh5">Description</h5>
                            <p  className="mb-3 reviewratingscomment">
                            Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al.Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al.Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al.Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al
                            </p>
                            <div>
                                <label className="mb-3 reviewratingsyourreview" >Write Your review:</label>
                                <textarea className="form-control reviewtextarea" rows="9" placeholder=""  />
                            </div>
                            {/* <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-3 mb-3">
                                <button type="button" className={`reviewbutton ${activeButtons.includes(1) ? "active" : ""}`}
                                    onClick={() => handleButtonClick(1)}>Awesome</button>
                                <button type="button" className={`reviewbutton ${activeButtons.includes(2) ? "active" : ""}`}
                                    onClick={() => handleButtonClick(2)}>Good</button>
                                <button type="button" className={`reviewbutton ${activeButtons.includes(3) ? "active" : ""}`}
                                    onClick={() => handleButtonClick(3)}>Super App</button>
                                <button type="button" className={`reviewbutton ${activeButtons.includes(4) ? "active" : ""}`}
                                    onClick={() => handleButtonClick(4)}>Good quality</button>
                                <button type="button" className={`reviewbutton ${activeButtons.includes(5) ? "active" : ""}`}
                                    onClick={() => handleButtonClick(5)}>Work Nice....</button>
                            </div> */}
                            <h6 className="mt-4 mb-3 reviewratingapp">Rate this app</h6>
                            <div className="d-flex  mt-3 reviewratingstargrey">
                            {[1, 2, 3, 4, 5].map((rating) => (
                                <img
                                    key={rating}
                                    src={getStarImage(rating)}
                                    alt="star"
                                    className="stargreylight"
                                    onClick={() => handleClick(rating)}
                                    onMouseOver={() => handleMouseOver(rating)}
                                    onMouseOut={handleMouseOut}
                                    style={{ width: '24px', cursor: 'pointer' }} // Adjust size as needed
                                />
                            ))}
                            </div>
                            <div className="d-flex justify-content-between  mt-3">
                                <div className="d-flex align-items-center">
                                    <input type="checkbox" id="agree" name="agree" className="me-2" style={{ width: '28px', height: '28px',borderRadius:'5px',borderColor:'#25314C' }} />
                                    <label htmlFor="agree"className="reviewratingslabel">Don't Show My Identity</label>
                                </div>

                                <button type="button" className="reviewsubmitbutton ms-3">Submit</button>

                            </div>
                        </div>
                        <div className="box p-5 border-rounded pt-3 px-md-4 mb-5" style={{ backgroundColor: 'white',width:'100%',minHeight:'914px',borderRadius:'18px',border:'1px solid #ABA7A7' }}>
                            <div className="mt-4 mb-3 ms-4">
                                <h4 className="reaviewratingsheadingh4" >Ratings</h4>
                            </div>
                            <div className="row wholecommentcontainer ps-4">
                                <div className="col-4 wholecommentcontainer1" >
                                    <div>
                                    <h2 className=" ratingsvalue" >4.5</h2>
                                    <div className="d-flex ratingsvaluestar">
                                        <img src={starorange} alt="stargrey " />
                                        <img src={starorange} alt="stargrey " />
                                        <img src={starorange} alt="stargrey " />
                                        <img src={starorange} alt="stargrey " />
                                        <img src={starhalf} alt="stargrey " />
                                    </div>
                                    <p className="mt-2 ratings-reviews">60 Ratings & 32 Reviews</p>
                                </div>
                                </div>
                                <div className="col-8 wholecommentcontainer2">
                                <div className="d-flex align-items-center mb-3 ps-4 reviewratingsstars" style={{ width: '100%' }}>
    <div className="star-container">
      <img src={starorange} alt="stargrey" />
      <img src={starorange} alt="stargrey" />
      <img src={starorange} alt="stargrey" />
      <img src={starorange} alt="stargrey" />
      <img src={starorange} alt="stargrey" />
    </div>
    <div style={{ flex: 1, marginLeft: '10px' }}>
      <div className="progress" style={{ width: '100%', height: '5px' }}>
        <div className="progress-bar" role="progressbar" style={{ width: `${rating1}%`, backgroundColor: '#F77F00' }} aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </div>
  <div className="d-flex align-items-center mb-3 ps-4 reviewratingsstars" style={{ width: '100%' }}>
    <div className="star-container ps-2">
      <img src={starorange} alt="stargrey" />
      <img src={starorange} alt="stargrey" />
      <img src={starorange} alt="stargrey" />
      <img src={starorange} alt="stargrey" />
      {/* <img src={starorange} alt="stargrey" /> */}
    </div>
    <div style={{ flex: 1, marginLeft: '10px' }}>
      <div className="progress" style={{ width: '100%', height: '5px' }}>
        <div className="progress-bar" role="progressbar" style={{ width: `${rating2}%`, backgroundColor: '#F77F00' }} aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </div>
  <div className="d-flex align-items-center mb-3 ps-4 reviewratingsstars" style={{ width: '100%' }}>
    <div className="star-container ps-3">
      <img src={starorange} alt="stargrey" />
      <img src={starorange} alt="stargrey" />
      <img src={starorange} alt="stargrey" />
     
    </div>
    <div style={{ flex: 1, marginLeft: '10px' }}>
      <div className="progress" style={{ width: '100%', height: '5px' }}>
        <div className="progress-bar" role="progressbar" style={{ width: `${rating3}%`, backgroundColor: '#F77F00' }} aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </div>
  <div className="d-flex align-items-center mb-3 ps-4 reviewratingsstars" style={{ width: '100%' }}>
    <div className="star-container ps-4">
      <img src={starorange} alt="stargrey" />
      <img src={starorange} alt="stargrey" />
      
    </div>
    <div style={{ flex: 1, marginLeft: '10px' }}>
      <div className="progress" style={{ width: '100%', height: '5px' }}>
        <div className="progress-bar" role="progressbar" style={{ width: `${rating4}%`, backgroundColor: '#F77F00' }} aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </div><div className="d-flex align-items-center mb-3 ps-4 reviewratingsstars" style={{ width: '100%' }}>
    <div className="star-container ps-4 ">
      <img src={starorange} alt="stargrey" style={{marginLeft:'11px'}} />
      
    </div>
    <div style={{ flex: 1, marginLeft: '10px' }}>
      <div className="progress" style={{ width: '100%', height: '5px' }}>
        <div className="progress-bar" role="progressbar" style={{ width: `${rating5}%`, backgroundColor: '#F77F00' }} aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </div>
  </div>
                                <div>
                                    <h6 className="reviewsselectheading">Reviews</h6>
                                    <Select value={selectedOption} onChange={handleChange} options={options} styles={customStyles} className="reviewselect" id="sortSelect"/>
                                </div>
                                <div className="row mt-4"  style={{paddingLeft:'0px'}}>
                                    <div className="col-12 col-md-4 col-sm-4">
                                        <div className="box-container border p-3" style={{ backgroundColor: 'white',width:'100%',minHeight:'156px',borderRadius:'4px',border:'1px solid #EAEDF2' }}>
                                            <div className=" d-flex   align-items-center">
                                                <img
                                                    src={user}
                                                    alt="Profile"
                                                    className="rounded-circle reviewimgs me-3"
                                                />
                                                <p className="reviewername">John Doe</p>
                                            </div>
                                            <div className="d-flex mb-3 reviewerboxstars mt-2">
                                                <img src={starorange}  alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starhalf}   alt="stargrey "  />
                                                <div className="reviewerboxdate" >23 May,2023</div>
                                            </div>
                                            <p className="reviewerboxcomments" >No doubbit's on app which nowadays necessary to keep in phone for many useful purposes. It's now became a part of our day-to-day life</p>
                                           
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-sm-4">
                                        <div className="box-container border p-3" style={{ backgroundColor: 'white',width:'100%',minHeight:'156px',borderRadius:'4px',border:'1px solid #EAEDF2' }}>
                                            <div className=" d-flex   align-items-center">
                                                <img
                                                    src={user}
                                                    alt="Profile"
                                                    className="rounded-circle reviewimgs me-3"
                                                />
                                                <p className="reviewername">John Doe</p>
                                            </div>
                                            <div className="d-flex mb-3 reviewerboxstars mt-2">
                                                <img src={starorange}  alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starhalf}   alt="stargrey "  />
                                                <div className="reviewerboxdate" >23 May,2023</div>
                                            </div>
                                            <p className="reviewerboxcomments" >No doubbit's on app which nowadays necessary to keep in phone for many useful purposes. It's now became a part of our day-to-day life</p>
                                           
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-sm-4">
                                        <div className="box-container border p-3" style={{ backgroundColor: 'white',width:'100%',minHeight:'156px',borderRadius:'4px',border:'1px solid #EAEDF2' }}>
                                            <div className=" d-flex   align-items-center">
                                                <img
                                                    src={user}
                                                    alt="Profile"
                                                    className="rounded-circle reviewimgs me-3"
                                                />
                                                <p className="reviewername">John Doe</p>
                                            </div>
                                            <div className="d-flex mb-3 reviewerboxstars mt-2">
                                                <img src={starorange}  alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starhalf}   alt="stargrey "  />
                                                <div className="reviewerboxdate" >23 May,2023</div>
                                            </div>
                                            <p className="reviewerboxcomments"  >No doubbit's on app which nowadays necessary to keep in phone for many useful purposes. It's now became a part of our day-to-day life</p>
                                           
                                        </div>
                                    </div>
                                    </div>
                                <div className="row mt-4"  style={{paddingLeft:'0px'}}>
                                <div className="col-12 col-md-4">
                                        <div className="box-container border p-3" style={{ backgroundColor: 'white',width:'100%',minHeight:'156px',borderRadius:'4px',border:'1px solid #EAEDF2' }}>
                                            <div className=" d-flex   align-items-center">
                                                <img
                                                    src={user}
                                                    alt="Profile"
                                                    className="rounded-circle reviewimgs me-3"
                                                />
                                                <p className="reviewername">John Doe</p>
                                            </div>
                                            <div className="d-flex mb-3 reviewerboxstars mt-2">
                                                <img src={starorange}  alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starhalf}   alt="stargrey "  />
                                                <div className="reviewerboxdate" >23 May,2023</div>
                                            </div>
                                            <p className="reviewerboxcomments" >No doubbit's on app which nowadays necessary to keep in phone for many useful purposes. It's now became a part of our day-to-day life</p>
                                           
                                        </div>
                                    </div>
                                <div className="col-12 col-md-4">
                                        <div className="box-container border p-3" style={{ backgroundColor: 'white',width:'100%',minHeight:'156px',borderRadius:'4px',border:'1px solid #EAEDF2' }}>
                                            <div className=" d-flex   align-items-center">
                                                <img
                                                    src={user}
                                                    alt="Profile"
                                                    className="rounded-circle reviewimgs me-3"
                                                />
                                                <p className="reviewername">John Doe</p>
                                            </div>
                                            <div className="d-flex mb-3 reviewerboxstars mt-2">
                                                <img src={starorange}  alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starhalf}   alt="stargrey "  />
                                                <div className="reviewerboxdate" >23 May,2023</div>
                                            </div>
                                            <p className="reviewerboxcomments" >No doubbit's on app which nowadays necessary to keep in phone for many useful purposes. It's now became a part of our day-to-day life</p>
                                           
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="box-container border p-3" style={{ backgroundColor: 'white',width:'100%',minHeight:'156px',borderRadius:'4px',border:'1px solid #EAEDF2' }}>
                                            <div className=" d-flex   align-items-center">
                                                <img
                                                    src={user}
                                                    alt="Profile"
                                                    className="rounded-circle reviewimgs me-3"
                                                />
                                                <p className="reviewername">John Doe</p>
                                            </div>
                                            <div className="d-flex mb-3 reviewerboxstars mt-2">
                                                <img src={starorange}  alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starhalf}   alt="stargrey "  />
                                                <div className="reviewerboxdate" >23 May,2023</div>
                                            </div>
                                            <p className="reviewerboxcomments" >No doubbit's on app which nowadays necessary to keep in phone for many useful purposes. It's now became a part of our day-to-day life</p>
                                           
                                        </div>
                                    </div>
                                    </div>
                                    <div className="row mt-4" style={{paddingLeft:'0px'}}>
                                <div className="col-12 col-md-4">
                                        <div className="box-container border p-3" style={{ backgroundColor: 'white',width:'100%',minHeight:'156px',borderRadius:'4px',border:'1px solid #EAEDF2' }}>
                                            <div className=" d-flex   align-items-center">
                                                <img
                                                    src={user}
                                                    alt="Profile"
                                                    className="rounded-circle reviewimgs me-3"
                                                />
                                                <p className="reviewername">John Doe</p>
                                            </div>
                                            <div className="d-flex mb-3 reviewerboxstars mt-2">
                                                <img src={starorange}  alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starhalf}   alt="stargrey "  />
                                                <div className="reviewerboxdate" >23 May,2023</div>
                                            </div>
                                            <p className="reviewerboxcomments" >No doubbit's on app which nowadays necessary to keep in phone for many useful purposes. It's now became a part of our day-to-day life</p>
                                           
                                        </div>
                                    </div>
                                <div className="col-12 col-md-4">
                                        <div className="box-container border p-3" style={{ backgroundColor: 'white',width:'100%',minHeight:'156px',borderRadius:'4px',border:'1px solid #EAEDF2' }}>
                                            <div className=" d-flex   align-items-center">
                                                <img
                                                    src={user}
                                                    alt="Profile"
                                                    className="rounded-circle reviewimgs me-3"
                                                />
                                                <p className="reviewername">John Doe</p>
                                            </div>
                                            <div className="d-flex mb-3 reviewerboxstars mt-2">
                                                <img src={starorange}  alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starhalf}   alt="stargrey "  />
                                                <div className="reviewerboxdate" >23 May,2023</div>
                                            </div>
                                            <p className="reviewerboxcomments" >No doubbit's on app which nowadays necessary to keep in phone for many useful purposes. It's now became a part of our day-to-day life</p>
                                           
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="box-container border p-3" style={{ backgroundColor: 'white',width:'100%',minHeight:'156px',borderRadius:'4px',border:'1px solid #EAEDF2' }}>
                                            <div className=" d-flex   align-items-center">
                                                <img
                                                    src={user}
                                                    alt="Profile"
                                                    className="rounded-circle reviewimgs me-3"
                                                />
                                                <p className="reviewername">John Doe</p>
                                            </div>
                                            <div className="d-flex mb-3 reviewerboxstars mt-2">
                                                <img src={starorange}  alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starorange}   alt="stargrey "  />
                                                <img src={starhalf}   alt="stargrey "  />
                                                <div className="reviewerboxdate" >23 May,2023</div>
                                            </div>
                                            <p className="reviewerboxcomments" >No doubbit's on app which nowadays necessary to keep in phone for many useful purposes. It's now became a part of our day-to-day life</p>
                                           
                                        </div>
                                    </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Reviewratings;