import React from "react";
import Navbars from "./navbar";
import Manualsidebar from "./manualsidebar";
import wallettowallet from "./images/wallet_to_wallet-01.png";
import folderexport from './images/folder-export-01.png';
import search01 from './images/search-01.png';
function Collections(){
    return(
        <> 
         {/* <Navbars/> */}
         {/* <div className="container-fluid " style={{ backgroundColor: '#F8F8F8', width: '100%', height: '100%' }}>
                <div className="row collectionrow">
                    <div className="col-12 col-lg-3 col-md-4 col-xl-3 col-xxl-3 col_corre_2">
                        <Manualsidebar/>
                    </div> */}
                    <div className="col-12 col-lg-9 col-md-8 col-xl-9 col-xxl-9 col_corr_1" >
                        <main className="mobile_height" style={{ backgroundColor: '#FFFFFF', width: '100%', boxShadow: "0px 0px 5px 0px #0000001A", height: '100%', }}>
                            <h5 className="ps-4 pt-4">Collections</h5>
                            <p className="cardsp ps-4 mb-4">This is were money collected from services like payouts, tickets, subscriptions , etc from your businesses, is collected</p>
                            <div className="massage_box_corr mobile_maxheight ">
                                <div className="purchaserow22 purchase23 after-one">
                                    <h5 className="refundhistory ps-4 pt-3 ">All Transactions</h5>
                                        <div className="d-flex justify-content-between align-items-center ps-4 pe-4 pt-2 mb-3">
                                        <div style={{position:'relative',width:'100%'}}>
                                        <input type="search" className="serchinputbox" placeholder="Search"></input>  
                                        <p  style={{ position: 'absolute',marginLeft:'5px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}><img src={search01} alt="search" width='19.5px' height='19.5px'></img></p> 
                                        </div>      
                                    <button className="export_pdf orange me-4"><img src={folderexport} className="me-2 mb-1" alt="folderexport" width="15px" height='14px'></img>Export to PDF</button>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                        <div className="table-responsive">
                                            <table className="table   nowrap custom-table custom-tabrow1" style={{width:'100%',}}>
                                                <colgroup>
                                                <col style={{ width: '14.28%' }} /> {/* Adjust the width as needed */}
                            <col style={{ width: '14.28%' }} />
                            <col style={{ width: '14.28%' }} />
                            <col style={{ width: '14.28%' }} />
                            <col style={{ width: '14.28%' }} />
                            <col style={{ width: '14.28%' }} />
                            <col style={{ width: '14.28%' }} />
                                                </colgroup>
                                                <thead style={{backgroundColor: '#FFEEDC'}}>
                                                    <tr style={{ backgroundColor: '#FFEEDC', border: 'none'}}>
                                                    <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000' }}></th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000',textAlign:'left' }}>From</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000' }}>Type</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000' }}>Amount</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000' }}>Security Check</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000' }}>Reason</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000' }}>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                   <tr className="bord"> 
                                                    <td colSpan="7">
                                                  <p className="mar-25" style={{textAlign:'start'}}>Today</p></td>
                                                  </tr>
                                                    <tr className="padtr">
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}><img src={wallettowallet} alt="collect" className="collectimg"></img></td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >
                                                        <div style={{textAlign:'left'}}>
                                                        <p className="desctabname" style={{marginBottom:'0px'}}>Guy Hawkins</p>
                                                        <p className="desctabgmail"  style={{marginBottom:'0px'}}>diannerussel@do.....</p>
                                                        </div>
                                                        </td>
                                                        <td className="desctabdate">Payout</td>
                                                        <td className="descamount">UGX 3000</td>
                                                        <td className="desctabcheck desctabcheckred ">Locked</td>
                                                        <td className="desctabdate">Sent</td>
                                                        <td className="desctabsucc desctabcheckred">Failed</td>
                                                    </tr>
                                                    <tr className="padtr">
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}><img src={wallettowallet}  alt="collect" className="collectimg"></img></td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >
                                                        <div style={{textAlign:'left'}}>
                                                        <p className="desctabname" style={{marginBottom:'0px'}}>Dianne Russell</p>
                                                        <p className="desctabgmail"  style={{marginBottom:'0px'}}>256*****1234</p>
                                                        </div>
                                                        </td>
                                                        <td className="desctabdate">Contribution</td>
                                                        <td className="descamount">UGX 3000</td>
                                                        <td className="desctabcheck desctabcheckorange ">Unlocked</td>
                                                        <td className="desctabdate">Cancelled</td>
                                                        <td className="desctabsucc">Received</td>
                                                    </tr>
                                                    <tr className="bord"> 
                                                    <td colSpan="7">
                                                  <p className="mar-25" style={{textAlign:'start'}}>Yesterday</p></td>
                                                  </tr>
                                                    <tr className="padtr">
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}><img src={wallettowallet} alt="collect" className="collectimg"></img></td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >
                                                        <div style={{textAlign:'left'}}>
                                                        <p className="desctabname" style={{marginBottom:'0px'}}>Darlene Robertson</p>
                                                        <p className="desctabgmail"  style={{marginBottom:'0px'}}>darleneroberston.......</p>
                                                        </div>
                                                        </td>
                                                        <td className="desctabdate">Subscription</td>
                                                        <td className="descamount">UGX 3000</td>
                                                        <td className="desctabcheck desctabcheckorange ">Unlocked</td>
                                                        <td className="desctabdate">Failed</td>
                                                        <td className="desctabsucc desctabfund">Processing</td>
                                                    </tr>
                                                    <tr className="padtr">
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}><img src={wallettowallet}  alt="collect" className="collectimg"></img></td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >
                                                        {/* <div className="d-flex"> */}
                                                        {/* <img src={wallettowallet} className="me-2"  width='24px' height="24px"></img> */}
                                                        <div style={{textAlign:'left'}}>
                                                        <p className="desctabname" style={{marginBottom:'0px'}}>Bank of Uganda</p>
                                                        <p className="desctabgmail"  style={{marginBottom:'0px'}}>*****1234</p>
                                                        {/* </div> */}
                                                        </div>
                                                        </td>
                                                        <td className="desctabdate">Purchase</td>
                                                        <td className="descamount">UGX 3000</td>
                                                        <td className="desctabcheck desctabcheckorange ">Checking</td>
                                                        <td className="desctabdate">Pending</td>
                                                        <td className="desctabsucc ">Received</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                {/* </div>
            </div> */}
        </>
    );
}
export default Collections;