import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbars from "./navbar";
import eye from './images/eye-01.png';
import eyeslash from './images/eye_slash-01.png';
import leftarrow from './images/arrow-left-01.png';
function Passworcurrent() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }
    const back = () => {
        navigate("/passwordchange");
    }
    const passwordcancel = () => {
        navigate('/passwordchange');
    }
    const passwordconfirm = () => {
        navigate('/passwordsecurity');
    }
    return (
        <>
            <Navbars />
            <div className="container-fluid passwordcurrent">
                <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <div className="col-lg-7 col-md-8 col-sm-10">
                        <div className="box " style={{ backgroundColor: 'white',width:'100%',maxWidth:'620px',minHeight:'424px',border:'1px solid #ABA7A7',borderRadius:'18px',padding:'30px 50px' }}>
                            <div className="d-flex mt-3 align-items-center">
                            <img onClick={back} src={leftarrow} alt="leftarrow" width='19.5px' height='15.5px' className="mb-3 me-4"style={{cursor:'pointer'}}></img>
                                <h5 className=' mb-3' style={{fontWeight:'500',fontSize:'24px',lineHeight:'36px',color:'#232323'}}>Password</h5>
                            </div>
                            <div className="d-flex justify-content-start mb-3 ps-5"> {/* New div for label */}
                                <label htmlFor="new-password" style={{ fontWeight: '500',fontSize:'16px',lineHeight:'24px',color:'#000000' }}>New Password</label>
                            </div>
                            <div className=" mb-4 ps-5" style={{position:'relative'}}> {/* Input group for password input */}
                                <input type={showPassword ? 'text' : 'password'} className="form-control passwordchangeinputbox" id="userpassword"
                                    placeholder="***********" name="password"  />
                                <p className="toggle-password" onClick={togglePasswordVisibility}style={{ position: 'absolute', marginRight: '10px', top: '0%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                    {showPassword ? (
                                        <img src={eyeslash} className='eye' style={{width:'20px',height:'auto'}} alt="Hide Password" />
                                    ) : (
                                        <img src={eye} className='eye'style={{width:'20px',height:'auto'}} alt="Show Password" />
                                    )}
                                </p>
                            </div>
                            <div className="d-flex justify-content-start mb-3 ps-5"> {/* New div for label */}
                                <label htmlFor="new-password" style={{ fontWeight: '500',fontSize:'16px',lineHeight:'24px',color:'#000000' }}>Current Password</label>
                            </div>
                            <div className="mb-4 ps-5" style={{position:'relative'}}> {/* Input group for password input */}
                                <input type={showPassword ? 'text' : 'password'} className="form-control passwordchangeinputbox" id="userpassword"
                                    placeholder="***********" name="password"  />
                                <p className="toggle-password" onClick={togglePasswordVisibility}style={{ position: 'absolute', marginRight: '10px', top: '0%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                    {showPassword ? (
                                        <img src={eyeslash} className='eye' style={{width:'20px',height:'auto'}} alt="Hide Password" />
                                    ) : (
                                        <img src={eye} className='eye' style={{width:'20px',height:'auto'}} alt="Show Password" />
                                    )}
                                </p>
                            </div>
                            <div className="d-flex mt-5 mb-0 justify-content-end align-items-center">
                                <button onClick={passwordcancel} type="button" className="profilecancel  me-2">Cancel</button>
                                <button onClick={passwordconfirm} type="button" className=" profilesave">Confirm</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Passworcurrent;