import { React, useState, useEffect } from "react";
import '../home.css';
import { useNavigate } from "react-router-dom";
import Navbars from "../navbar";
import leftarrow from '../images/arrow-left-01.png';
import trash from '../images/trash-01.png';
import edit from '../images/pen-01.png';
import { genderapi, profileremoveapi } from "../api/url";
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserProfile } from '../api/redux/userProfiles/actions';
import axiosInstance from "../api/common/axiosinstand";
import { extractLoginUser ,handleApiError} from "../api/common/commonapi";
import { Image } from "antd";
import WithToast from "../api/common/withtoast";
const genderurl = genderapi();
const profileremoveapiurl = profileremoveapi();
function Profilechange({showErrorToast}) {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const login_user = extractLoginUser();
  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);
  const preview = () => {
    setIsPreviewVisible(true);
  }
  const [error422, setError422] = useState('');
  const [isLoading4, setIsLoading4] = useState(false);
  const [removeactivebutton, setRemoveactivebutton] = useState(true);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const navigate = useNavigate();
  const back = () => {
    navigate(`/u/${login_user}/profile`);
  }
  const cancel = () => {
    navigate(`/u/${login_user}/profile`);
  }
  const [base64Image, setBase64Image] = useState("");
  const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
  const handleImageChange = (event) => {
    const file = event.target.files[0];
     setRemoveactivebutton(true);
    if (file && allowedFileTypes.includes(file.type)) {
      setError422('');
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64data = e.target.result;
        setBase64Image(base64data);
        setPreviewVisible(true);
      };

      reader.readAsDataURL(file);
    }
    else {
      setError422('Please select a valid image file (JPG, JPEG, or PNG).')
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setRemoveactivebutton(true);
    if (file && allowedFileTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64data = e.target.result;
        setBase64Image(base64data);
        setPreviewVisible(true);
      };
      reader.readAsDataURL(file);
    }
    else {
      setError422('Please select a valid image file (JPG, JPEG, or PNG).')
    }
  };
  
  const [imageRemoved, setImageRemoved] = useState(false);
  const handleImageremove = async () => {
    setImageRemoved(true);
    setBase64Image(userProfile.default_profile);
    setPreviewVisible(true);
    setRemoveactivebutton(false);
  }
  const [radioValue, setRadioValue] = useState(userProfile.profile_image_visibility === 'private' ? 'private' : 'friends');

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  }
  const handleremovesave = async () => {
    // const token = localStorage.getItem('access_token');
    // const headers = {
    //   'Authorization': `Bearer ${token}`,
    //   'Content-Type': 'application/json',
    // };
    if (imageRemoved) {
      try {
        setIsLoading4(true);
        const response = await axiosInstance.post(profileremoveapiurl, null,);
        if (response.status === 200) {
          makeAjaxCall();
          setIsLoading4(false);
          navigate(`/u/${login_user}/profile`);
        }
      } catch (error) {
        handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
      }
    }
  }
  const save = async () => {
    if(error422){
      return;
    }
    var data = {
      profile_image_visibility: radioValue,
      image: base64Image
    }
    if (base64Image || radioValue) {
      try {
        setIsLoading4(true);
        const response = await axiosInstance.post(genderurl, data,);
        if (response.status === 200) {
          dispatch(fetchUserProfile());
          makeAjaxCall();
          setIsLoading4(false);
          navigate(`/u/${login_user}/profile`);
        }
      } catch (error) {
        handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
      }
    }
  }

  
  return (
    <>
     {isLoading4 && (
                <>   
                    <div className="loaderover">
                    <div className="loader ">
                    </div>
                    </div>
                </>
            )} 
            <div>
            <div className="container-fluid" style={{ height: '100px' }}>
          <Navbars />
        </div>
        <div className="name_div">
        <div className="name_div1">
        <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={back} alt="leftarrow" className="name_arrow "></img>
              <h5 className="nameh5">Profile picture</h5>
            </div>
            <div className="name-input name-inputimage" onDragOver={(e) => e.preventDefault()} onDrop={handleDrop} >
            {previewVisible ? (
                  <>
                    <div onClick={preview} className="header-profile-user border-dark user mx-auto mb-2" style={{ width: '86px', height: '86px', borderRadius: '50%', overflow: 'hidden' }}>
                      <img src={base64Image} alt="Profile" style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }} /></div>
                  </>
                ) : (
                  <div onClick={preview} className="header-profile-user border-dark user mx-auto mb-2" style={{ width: '86px', height: '86px', borderRadius: '50%', overflow: 'hidden' }}>
                  <img src={userProfile.image} style={{  width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }} alt="User Profile" /></div>
                )}
                {!userProfile.is_default || (imageRemoved && !removeactivebutton) ?(
                <div className="d-flex justify-content-center">
                  <input type="file" accept="image/*" id="fileInput" style={{ display: 'none' }} onDragOver={(e) => e.preventDefault()} onDrop={handleDrop} onChange={handleImageChange} />
                  <label htmlFor="fileInput" className="profilechange" style={{ cursor: 'pointer' }}>
                    <span className="d-flex align-items-center">
                      <img className="me-2" src={edit} width='16px' height='16px' alt="Edit" />
                      Upload
                    </span>
                  </label>
                </div>
              ) : (
                <div className="d-flex justify-content-center mt-2">
                  <input type="file" accept="image/*" id="fileInput" style={{ display: 'none' }} onDragOver={(e) => e.preventDefault()} onDrop={handleDrop} onChange={handleImageChange} />
                  <label htmlFor="fileInput" className="me-3 profilechange" style={{ cursor: 'pointer' }}>
                    <span className="d-flex align-items-center">
                      <img className="me-2" src={edit} width='16px' height='16px' alt="Edit" />
                      Edit
                    </span>
                  </label>
                  <button type="button" onClick={handleImageremove} className=" profilechange">
                    <span className="d-flex align-items-center">
                      <img className="me-2 " src={trash} width='16px' height='16px' alt="Trash" />
                      Remove
                    </span>
                  </button>
                </div>
              )}
              <div className="d-flex flex-column justify-content-start mt-4" style={{width:'100%',paddingLeft:'45px'}}>
                <label className="mb-2 ps-4" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#323232' }}>Visible to anyone</label>
                <div className="form-check mt-2 ">
                  <input
                    className="formchecks"
                    type="radio"
                    name="visible"
                    id="Anyone"
                    value="private"
                    checked={radioValue === "private"}
                    onChange={handleRadioChange}
                  />
                  <label className="form-check-label " style={{ fontWeight: '400', fontSize: '14px', lineHeight: '21px', color: '#323232' }} htmlFor="Anyone">
                    Anyone
                  </label>
                </div>
                <div className="form-check mt-3">
                  <input
                    className="formchecks"
                    type="radio"
                    name="visible"
                    id="People you interact with"
                    value="friends"
                    checked={radioValue === "friends"}
                    onChange={handleRadioChange}
                  />
                  <label className="form-check-label" style={{ fontWeight: '400', fontSize: '14px', lineHeight: '21px', color: '#323232' }} htmlFor="People you interact with">
                    People you interact with
                  </label>
                </div>
              </div>
              <div className="error-message error-messageprofile">{error422}</div>
              <div className="d-flex justify-content-end mt-4" style={{width:'100%'}}>
                {removeactivebutton ? (
                  <>
                    <button type="button" onClick={cancel} className=" me-3 profilecancel">
                      Cancel
                    </button>
                    <button type="button" onClick={save} className=" profilesave">
                      Save
                    </button>
                  </>
                ) : (
                  <>
                    <button type="button" onClick={cancel} className=" me-3 profilecancel">
                      Cancel
                    </button>
                    <button type="button" onClick={handleremovesave} className=" profilesave">
                      Save
                    </button>
                  </>
                )}
              </div>
                </div>
          </div>
          </div>
            </div>
      {/* <ToastContainer />
      <Navbars />
      <div className="container-fluid profilepage">
        <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="col-lg-7 col-md-8 col-sm-10">
            <div className="box p-5  " style={{ backgroundColor: "white", width: '100%', maxWidth: '619px', minHeight: '510px', borderRadius: '18px', border: '1px solid #ABA7A7' }}>
              <div className="d-flex  align-items-center">
                <img onClick={back} src={leftarrow} alt="leftarrow" width='19.5px' height='15.5px' className="mb-3 me-4" style={{ cursor: 'pointer', marginLeft: '-10px' }}></img>
                <h5 className='mb-3' style={{ fontWeight: '500', fontSize: '24px', lineHeight: '36px', color: '#232323' }}>Profile picture</h5>
              </div>
              <div className="d-flex justify-content-center">
                {previewVisible ? (
                  <>
                    <div onClick={preview} className="header-profile-user border-dark user mx-auto mb-3" style={{ width: '86px', height: '86px', borderRadius: '50%', overflow: 'hidden' }}>
                      <img src={base64Image} alt="Profile" style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }} /></div>
                  </>
                ) : (
                  <div onClick={preview} className="header-profile-user border-dark user mx-auto mb-3" style={{ width: '86px', height: '86px', borderRadius: '50%', overflow: 'hidden' }}>
                  <img src={userProfile.image} style={{  width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }} alt="User Profile" /></div>
                )}
                
              </div>
              {userProfile.is_default || (imageRemoved && !removeactivebutton) ?(
                <div className="d-flex justify-content-center">
                  <input type="file" accept="image/*" id="fileInput" style={{ display: 'none' }} onChange={handleImageChange} />
                  <label htmlFor="fileInput" className="profilechange" style={{ cursor: 'pointer' }}>
                    <span className="d-flex align-items-center">
                      <img className="me-2" src={edit} width='16px' height='16px' alt="Edit" />
                      Upload
                    </span>
                  </label>
                </div>
              ) : (
                <div className="d-flex justify-content-center mt-2">
                  <input type="file" accept="image/*" id="fileInput" style={{ display: 'none' }} onChange={handleImageChange} />
                  <label htmlFor="fileInput" className="me-3 profilechange" style={{ cursor: 'pointer' }}>
                    <span className="d-flex align-items-center">
                      <img className="me-2" src={edit} width='16px' height='16px' alt="Edit" />
                      Edit
                    </span>
                  </label>
                  <button type="button" onClick={handleImageremove} className=" profilechange">
                    <span className="d-flex align-items-center">
                      <img className="me-2 " src={trash} width='16px' height='16px' alt="Trash" />
                      Remove
                    </span>
                  </button>
                </div>
              )}
              <div className="d-flex flex-column mt-4  radio ">
                <label className="mb-2 ps-4" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#323232' }}>Visible to anyone</label>
                <div className="form-check mt-2 ms-4">
                  <input
                    className="formchecks"
                    type="radio"
                    name="visible"
                    id="Anyone"
                    value="private"
                    checked={radioValue === "private"}
                    onChange={handleRadioChange}
                  />
                  <label className="form-check-label " style={{ fontWeight: '400', fontSize: '14px', lineHeight: '21px', color: '#323232' }} htmlFor="Anyone">
                    Anyone
                  </label>
                </div>
                <div className="form-check mt-3 ms-4">
                  <input
                    className="formchecks"
                    type="radio"
                    name="visible"
                    id="People you interact with"
                    value="friends"
                    checked={radioValue === "friends"}
                    onChange={handleRadioChange}
                  />
                  <label className="form-check-label" style={{ fontWeight: '400', fontSize: '14px', lineHeight: '21px', color: '#323232' }} htmlFor="People you interact with">
                    People you interact with
                  </label>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-4  text-end">
                {removeactivebutton ? (
                  <>
                    <button type="button" onClick={cancel} className=" me-3 profilecancel">
                      Cancel
                    </button>
                    <button type="button" onClick={save} className=" profilesave">
                      Save
                    </button>
                  </>
                ) : (
                  <>
                    <button type="button" onClick={cancel} className=" me-3 profilecancel">
                      Cancel
                    </button>
                    <button type="button" onClick={handleremovesave} className=" profilesave">
                      Save
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div> */}
       
            <Image
            src={base64Image || userProfile.image}
            alt="Enlarged Image"
            preview={{
             visible: isPreviewVisible,
             onVisibleChange: setIsPreviewVisible,
             onClose: () => {
              setIsPreviewVisible(false);
             },
           }}
            style={{ maxWidth: '100%', maxHeight: '80vh' ,zIndex:'9999' , display:'none'}}/>
    </>
  );
}
export default WithToast(Profilechange);