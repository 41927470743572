import React,{useState,useRef,useEffect,useCallback} from "react";
import { useNavigate } from "react-router-dom";
import { DatePicker } from 'antd';
import eye from "../images/job_details_icons/eye_green-01.png";
import acdamicdeleteimg from '../images/trash-red-circle-01.png';
import plus1 from '../images/plus.png';
import Modal from 'react-bootstrap/Modal';
import closecircle from '../images/times-circle-01.png';
import axiosInstance from "../api/common/axiosinstand";
import dayjs from 'dayjs';
import adddocument from '../images/document_add-01.png';
// import { UseToast } from "../api/common/toastcontext";
import Swal from 'sweetalert2';
import Select from 'react-select';
import fileopen from '../images/folder-open-01.png';
import workhistoryimg from '../images/Work-01.png'
import edit from '../images/job_details_icons/edit_1-01.png';
import popdeletes from '../images/general-id/delete-01.png';
import { Image } from "antd";
import { formatDateForInput,handleApiError,handleDragOver,handleKeyDown,CustomSuffixIcon,handleEditfilechange,handleFileChange,formatDateForExpiry,formatFileSize,parseFileExtension,handleDrop,handleDrop1,handleDragOver1,customStyles1,extractLoginUser,Error401,transformString,disableFutureDates, disablePastDates } from "../api/common/commonapi";
import { workemploymenttypeapi,worklocationtypeapi,statelistapi,citylistapi,workhistorydeleteapi,workhistorystoreapi,workhistorygetapi,workhistoryupdateapi,publicDomain,countryselectapi } from "../api/url";
import SkeletonLoader from "./skeletonloader";
import WithToast from "../api/common/withtoast";
const worklocationtypeapiurl = worklocationtypeapi();
const workemploymenttypeapiurl = workemploymenttypeapi();
const countryselect = countryselectapi();
const statelistapiurl = statelistapi();
const citylistapiurl = citylistapi();
const publicUrl = publicDomain();
const workhistorystoreapiurl = workhistorystoreapi();
const workhistorygetapiurl = workhistorygetapi();
const workhistoryupdateapiurl = workhistoryupdateapi();
const workhistorydeleteapiurl = workhistorydeleteapi();
function Workhistory({documentRef,showErrorToast}){
    const navigate = useNavigate();
    const login_user = extractLoginUser();
    const [jobTitle, setJobTitle] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [selectededitworkimage, setSelectededitworkimage] = useState(null);
    const [endDate, setEndDate] = useState('');
    const [workType, setWorkType] = useState(null);
    const [employmentType, setEmploymentType] = useState(null);
    const [country, setCountry] = useState(null);
    const [state, setState] = useState(null);
    const [workeditid, setWorkeditid] = useState('');
    const [image, setImage] = useState([]);
    const [city, setCity] = useState(null);
    const [selectedworkimage, setSelectedworkimage] = useState(null);
    const [showWorkImageModal, setShowWorkImageModal] = useState(false);
    const [workhistorys, setWorkhistorys] = useState([]);
    const [employmentTypes, setEmploymentTypes] = useState([]);
    const [statelists, setStatelists] = useState([]);
    const [citylists, setCitylists] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [editImagedetails, setEditImageDetails] = useState(false);
    const [progressColor, setProgressColor] = useState("#28a745");
    const [fileSize, setFileSize] = useState(0);
    const [fileType, setFileType] = useState('');
    const [format, setFormat] = useState('');
    const [editImage, setEditImage] = useState([]);
    const [docname, setDocname] = useState('');
    const [editProgress, setEditProgress] = useState(100);
    const [editProgressColor, setEditProgressColor] = useState('#28a745');
    const [editDocName, setEditDocName] = useState('');
    const [editFileSize, setEditFileSize] = useState(editImage.fileSize);
    const [workTypes, setWorkTypes] = useState([]);
    const [show3, setShow3] = useState(false);
    const [countries, setCountries] = useState([]);
    const [showedit3, setShowedit3] = useState(false);
    const [editjobTitle, setEditJobTitle] = useState('');
    const [editcompanyName, setEditCompanyName] = useState('');
    const [isLoading4,setIsLoading4] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [error422,setError422] = useState('');
    const [editstartDate, setEditStartDate] = useState('');
    const [editendDate, setEditEndDate] = useState('');
    const [editworkType, setEditWorkType] = useState(null);
    const [editemploymentType, setEditEmploymentType] = useState(null);
    const [editworkcountry, setEditWorkCountry] = useState(null);
    const [editstate, setEditState] = useState(null);
    const [editcity, setEditCity] = useState(null);
    const [countryloading,setCountryloading] = useState(false);
    const [stateloading,setStateloading] = useState(false);
    const [cityloading,setCityloading] = useState(false);
    const [workloading,setWorkloading] = useState(false);
    const [employeementloading,setEmployeementloading] = useState(false);
    const [isPreviewVisible, setPreviewVisible] = useState(false);
    const [isPreviewVisible1, setPreviewVisible1] = useState(false);
    const [inlineerror,setInlineError] = useState({
      countryerror : '',
      stateerror : '',
      cityerror : '',
      expiryerror: '',
      expiryediterror:'',
      imageerror:'',
      editimageerror:''
    })
    const workref = useRef({
      country: null,
      city:null,
      state:null
    });
    const [isOpen, setIsOpen] = useState(false);
  const acceptPDF = false;
  const handleOpenChange = (open) => {
    setIsOpen(open);
  }; 
  const handleEditError = (message) => {
    setInlineError((prevState)=>({
      ... prevState,
      editimageerror : message
    }))
}
const handleError = (message) => {
  setInlineError((prevState)=>({
    ... prevState,
    imageerror : message
  }))
}
  const handleExpiryClick = (e) => {
    if (!startDate) {
      setInlineError((prevState) => ({
        ...prevState,
        expiryerror:'Please select Start Date first'
      }));
      setIsOpen(false);
    } else {
      setInlineError((prevState) => ({
        ...prevState,
        expiryerror:''
      }));
      setIsOpen(true);
    }
  };
  const handleEditExpiryClick = (e) => {
    if (!editstartDate) {
      setInlineError((prevState) => ({
        ...prevState,
        editexpirydateerror:'Please select Start Date first'
      }));
      setIsOpen(false);
    } else {
      setInlineError((prevState) => ({
        ...prevState,
        editexpirydateerror:''
      }));
      setIsOpen(true);
    }
  };
    const focusOnInput = (inputName) => {
      const inputRef = workref.current[inputName];
      if (inputRef) {
        inputRef.focus();
      }
    };
    const fetchworktype = async () => {
        try {
          setWorkloading(true);
          const response = await axiosInstance.get(worklocationtypeapiurl);
          if (response.status === 200) {
            const worktypes = response.data.data;
            setWorkTypes(worktypes.map(company => ({ value: company.id, label: company.name })));
            setWorkloading(false);
          }
        }
        catch (error) {
          setWorkloading(false);
          handleApiError(error,setIsLoading1,null,true,showErrorToast);
        }
      }
      const fetchemploymanttype = async () => {
        try {
          setEmployeementloading(true);
          const response = await axiosInstance.get(workemploymenttypeapiurl);
          if (response.status === 200) {
            const workemployementtypes = response.data.data;
            setEmploymentTypes(workemployementtypes.map(company => ({ value: company.id, label: company.name })));
            setEmployeementloading(false);
          }
        }
        catch (error) {
          setEmployeementloading(false);
          handleApiError(error,setIsLoading1,null,true,showErrorToast);
        }
      }
      const fetchstate = useCallback(async () => {
        try {
          const workcountryid =  country ? country?.value : editworkcountry?.value;
          if(workcountryid){
            setStateloading(true);
          const response = await axiosInstance.get(`${statelistapiurl}?country_id=${workcountryid}`,);
          if (response.status === 200) {
            const statelist = response.data.data;
            setStatelists(statelist.map(company => ({ value: company.id, label: company.name })));
            setStateloading(false);
          }
        }
        }
        catch (error) {
          setStateloading(false);
          handleApiError(error,setIsLoading1,null,true,showErrorToast);
        }
      },[editworkcountry,country]);
      const fetchcity = useCallback(async () => {
        try {
            const stateId = state ? state?.value : editstate?.value;
            if(stateId){
              setCityloading(true);
          const response = await axiosInstance.get(`${citylistapiurl}?state_id=${stateId}`);
          if (response.status === 200) {
            const citylist = response.data.data;
            setCitylists(citylist.map(company => ({ value: company.id, label: company.name })));
            setCityloading(false);
          }
        }
        }
        catch (error) {
          setCityloading(false);
          handleApiError(error,setIsLoading1,null,true,showErrorToast);
        }
      },[state,editstate]);
      useEffect(() => {
        if (country || editworkcountry) {
          fetchstate();
        }
      }, [country,editworkcountry,fetchstate]);
      useEffect(() => {
        if (state || editstate) {
          fetchcity();
        }
      }, [state,editstate,fetchcity]);
      const fetchCountries = async () => {
        try {
          setCountryloading(true);
          const response = await axiosInstance.get(countryselect);
          const data = response.data;
          if (data.message === 'Success') {
            const countryList = data.data;
            setCountries(countryList.map(company => ({ value: company.id, label: company.name })));
            setCountryloading(false);
          }
        } catch (error) {
          setCountryloading(false);
          handleApiError(error,setIsLoading1,null,true,showErrorToast);
        }
      };
      const addworkhistory = () => {
        fetchworktype();
        fetchemploymanttype();
        fetchCountries();
        setShowedit3(false);
        setShow3(true);
      }
      const initialStatework = {
        jobTitle: '',
        companyName: '',
        startDate: '',
        endDate: '',
        workType:'',
        employmentType:'',
        country:'',
        state:'',
        city:'',
        uploading: false,
      };
      const handleFileDrop = (selectedFile) => {
        handleFileChange(
          { target: { files: [selectedFile] } },
          setImage,
          setFileSize,
          setFileType,
          setProgress,
          setProgressColor,
          setUploading,
          setFormat,
          setDocname
        );
      };
      const handleeditFileDrop = (selectedFile1) => {
        handleEditfilechange(
          { target: { files: [selectedFile1] } },
          setEditImage,
          setFileSize,
          setFileType,
          setEditProgress,
          setEditProgressColor,
          setEditImageDetails,
          setFormat,
          setEditDocName
        );
      } 
      const handleworktype = (selectedOption) => {
            setWorkType(selectedOption);
            setError422('')
      }
      const handleemployeetype = (selectedOption) => {
        setEmploymentType(selectedOption);
        setError422('');
      }
      const handlecountry = (selectedOption) => {
        setCountry(selectedOption);
        setStatelists([]);
        setState('');
        setInlineError((prevState) => ({
          ...prevState,
          countryerror:''
        }))
        setError422('');
      }
      const handlestatefocus = () => {
        if (!country) {
          setInlineError((prevState) => ({
            ...prevState,
            countryerror: 'Please select a country first.',
          }));
          focusOnInput('country');
        } else {
          setInlineError((prevState) => ({
            ...prevState,
            countryerror: '',
          }));
        }
      };
      const handlecityfocus = () => {
        if (!country) {
          setInlineError((prevState) => ({
            ...prevState,
            countryerror: 'Please select a country first.',
            stateerror: '',
          }));
          focusOnInput('country'); // Ensure 'country' is the correct ID of the input you want to focus on
        } else if (!state) {
          setInlineError((prevState) => ({
            ...prevState,
            countryerror: '',
            stateerror: 'Please select a state first.',
          }));
          focusOnInput('state'); // Ensure 'state' is the correct ID of the input you want to focus on
        } else {
          setInlineError((prevState) => ({
            ...prevState,
            countryerror: '',
            stateerror: '',
          }));
        }
      };
      const editstatefocus = () => {
        if (!editworkcountry) {
          setInlineError((prevState) => ({
            ...prevState,
            countryerror: 'Please select a country first.',
          }));
          focusOnInput('brand'); // Ensure 'brand' is the correct ID of the input you want to focus on
        } else {
          setInlineError((prevState) => ({
            ...prevState,
            countryerror: '',
          }));
        }
      }
      const editcityfocus = () => {
        if (!editworkcountry) {
          setInlineError((prevState) => ({
            ...prevState,
            countryerror: 'Please select a country first.',
            stateerror: '',
          }));
          focusOnInput('country'); // Ensure 'country' is the correct ID of the input you want to focus on
        } else if (!editstate) {
          setInlineError((prevState) => ({
            ...prevState,
            countryerror: '',
            stateerror: 'Please select a state first.',
          }));
          focusOnInput('state'); // Ensure 'state' is the correct ID of the input you want to focus on
        } else {
          setInlineError((prevState) => ({
            ...prevState,
            countryerror: '',
            stateerror: '',
          }));
        }
      }
      const handlestate = (selectedOption) => {
        setState(selectedOption);
        setCitylists([]);
        setCity('');
        setInlineError((prevState) => ({
          ...prevState,
          stateerror:''
        }))
        setError422('');
      }
      const handlecity = (selectedOption) => {
        setCity(selectedOption);
        setError422('');
      }
      const handleeditworktype = (selectedOption) => {
        setEditWorkType(selectedOption);
        setError422('');
      }
      const handleeditemployeetype = (selectedOption) => {
        setEditEmploymentType(selectedOption);
        setError422('');
      }
      const handleeditcountry = (selectedOption) => {
        setEditWorkCountry(selectedOption);
        setError422('');
      }
      const handleeditstate = (selectedOption) => {
        setEditState(selectedOption);
        setError422('');
      }
      const handleeditcity = (selectedOption) => {
        setEditCity(selectedOption);
        setError422('');
      }
      const handleClose3 = () => {
        setShow3(false);
        setJobTitle(initialStatework.jobTitle);
        setCompanyName(initialStatework.companyName);
        setStartDate(initialStatework.startDate);
        setEndDate(initialStatework.endDate);
        setWorkType(initialStatework.workType);
        setEmploymentType(initialStatework.employmentType);
        setCountry(initialStatework.country);
        setState(initialStatework.state);
        setCity(initialStatework.city);
        setUploading(initialStatework.uploading);
        setError422('');
        setInlineError((prevState) => ({
          ...prevState,
          countryerror:''
        }));
        setInlineError((prevState) => ({
          ...prevState,
          stateerror:''
        }))
        setInlineError((prevState) => ({
          ...prevState,
          cityerror:''
        }))
        setInlineError((prevState) => ({
          ...prevState,
           expiryerror:''
        }));
        setInlineError((prevState) => ({
          ...prevState,
          imageerror:''
        }));
        setImage([]);
      }
      const close3 = () => {
        setShow3(false);
        handleClose3();
      }
      const handleworkcancel = () => {
        handleClose3();
      }
      const workfileInputRef = useRef(null);
      const handleworkFileClick = () => {
        workfileInputRef.current.click();
        setError422('');
      }
      const handleworkdelete = () => {
        setImage([]);
        setUploading(false);
        setProgress(0);
      }
      const handleworkeye = (image) => {
        setSelectedworkimage(image);
        setPreviewVisible(true);
        setShow3(false);
      }
      const workhistoryget = async () => {
        try {
          setIsLoading1(true);
          const response = await axiosInstance.get(workhistorygetapiurl,);
          if (response.status === 200) {
            setIsLoading1(false);
            setWorkhistorys(response.data.data);
          }
        } catch (error) {
          handleApiError(error,setIsLoading1,null,true,showErrorToast);
        }
      }
      useEffect(() => {
        workhistoryget();
      }, [])
      const handledeleteorkhistory = async (workhistorys) => {
        try {
          const id = workhistorys.id;
          const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          });
          
          if (result.isConfirmed) {
            setIsLoading4(true);
            const response = await axiosInstance.post(`${workhistorydeleteapiurl}?id=${id}`, {});
            if (response.status === 200) {
              await workhistoryget();
              setIsLoading4(false);
            }
          }
        }
        catch (error) {
          handleApiError(error,setIsLoading4,null,true,showErrorToast);
        }
      }
      const workhistorysdocument = (workhistorys) => {
        const id = workhistorys.id;
        localStorage.setItem('userId',id);
        navigate(`/u/${login_user}/documents/workhistory/document`);
      }
      const [workisFormValid, setWorkisFormValid] = useState(false);
      const [editworkisFormValid, setEditworkisFormValid] = useState(false);
      const checkFormValidity = () => {
        const isValid = (
          jobTitle.trim() !== '' &&
          companyName.trim() !== '' &&
          startDate !== '' &&
          endDate !== '' &&
          startDate !== null &&
          endDate !== null &&
          country !== '' &&
          country !== null &&
          image.length > 0  
        );
        setWorkisFormValid(isValid);
      };
      useEffect(() => {
        checkFormValidity();
      }, [jobTitle, companyName, startDate, endDate, country, image]);
      const handleworksave = async () => {
        try {
           setIsLoading4(true);
          let imagestring = image.toString();
          let data = {
            company: companyName,
            designation: jobTitle,
            start: formatDateForInput(startDate),
            end: formatDateForExpiry(endDate),
            country_id: country.value,
            image: imagestring,
          }
          if (employmentType) {
            data.employment_type_id = employmentType.value;
          }
          if(workType){
            data.experience_location_type_id = workType.value
          }
          if(city){
            data.city_id = city.value;
          }
          if(state){
            data.state_id = state.value;
          }
          const response = await axiosInstance.post(workhistorystoreapiurl, data,);
          if (response.status === 200) {
            setShow3(false);
            await workhistoryget();
            handleClose3();
            setIsLoading4(false);
          }
        } catch (error) {
          handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
        }
      }
      const handleupdatehistory = async (workhistorys) => {
        setShow3(false);
        setShowedit3(true);
        fetchworktype();
        fetchemploymanttype();
        fetchCountries();
        await fetchstate();
        await fetchcity();
        if (workhistorys) {
          setWorkeditid(workhistorys.id);
          setEditJobTitle(workhistorys.designation);
          setEditCompanyName(workhistorys.company);
          const formateedstart = dayjs(workhistorys.start,'YYYY-MM-DD');
          setEditStartDate(formateedstart);
          const formateeend = dayjs(workhistorys.end,'YYYY-MM-DD');
          setEditEndDate(formateeend);
          setEditWorkType(
            workhistorys.experience_location_type 
              ? { label: workhistorys.experience_location_type.name, value: workhistorys.experience_location_type.id }
              : null
          );
          setEditEmploymentType(
            workhistorys.employment_type
              ? { label: workhistorys.employment_type.name, value: workhistorys.employment_type.id }
              : null
          );
          setEditWorkCountry(workhistorys.country ? {label:workhistorys.country.name , value:workhistorys.country.id} : null);
          setEditState(workhistorys.state ? {label:workhistorys.state.name , value:workhistorys.state.id} : null);
          setEditCity(workhistorys.city ? {label:workhistorys.city.name , value:workhistorys.city.id} : null);
          const imageUrl = `${publicUrl}/core/${workhistorys.image}`;
          setEditImage(imageUrl);
          const filesize = formatFileSize(imageUrl.filesize);
          setFileSize(filesize)
          const filename = imageUrl.split('/').pop();
          const experienceType = filename.split('-')[1];
          setFormat(parseFileExtension(experienceType));
          setFileType(experienceType.replace(/\.[^/.]+$/, ""));
        }
      }
      const workfileInputRef1 = useRef(null);
      const handleworkFileClick1 = () => {
        workfileInputRef1.current.click();
        setError422('');
        setInlineError((prevState)=>({
          ...prevState,editimageerror:''
        }));
      }
      const handleeditClose3 = () => {
        setShowedit3(false);
        setEditJobTitle('');
        setEditCompanyName('');
        setEditStartDate('');
        setEditEndDate('');
        setEditWorkType('');
        setEditEmploymentType('');
        setError422('');
        setEditWorkCountry('');
        setEditState('');
        setEditCity('');
        setInlineError((prevState) => ({
          ...prevState,
          countryerror:''
        }));
        setInlineError((prevState) => ({
          ...prevState,
          stateerror:''
        }))
        setInlineError((prevState) => ({
          ...prevState,
          cityerror:''
        }))
        setInlineError((prevState) => ({
          ...prevState,
          editexpirydateerror:''
        }));
        setInlineError((prevState) => ({
          ...prevState,
          editimageerror:''
        }));
        setEditImageDetails(false);
        setEditProgress(100);
        setIseditworkchange(false);
      }
      const editclose3 = () => {
        setShowedit3(false);
        handleeditClose3();
      }
      const handleeditworkcancel = () => {
        handleeditClose3();
      }
      const [iseditworkchange, setIseditworkchange] = useState(false);
      const handleeditworkdelete = () => {
        setEditImage([]);
        setEditProgress(0);
        setEditFileSize(0);
        setEditImageDetails(true);
        setIseditworkchange(true);
      }
      const handleeditworkeye = (editImage) => {
        setSelectededitworkimage(editImage);
        // setShoweditImageModal(true);
        setPreviewVisible1(true);
        setShowedit3(false);
      }
      // const handleeditCloseWorkImageModal = () => {
      //   setShoweditImageModal(false);
      //   setShowedit3(true);
      // }
    
      const checkFormworkValidity = () => {
        const isValid = (
          editjobTitle.trim() !== '' &&
          editcompanyName.trim() !== '' &&
          editstartDate !== '' &&
          editendDate !== '' &&
          editstartDate !== null &&
          editendDate !== null &&
          // editworkType !== '' &&
          // editemploymentType !== '' &&
          editworkcountry !== '' &&
          editworkcountry !== null &&
          // editstate !== '' &&
          // editcity !== '' &&
          editImage.length > 0  
        );
        setEditworkisFormValid(isValid);
      };
      useEffect(() => {
        checkFormworkValidity();
      }, [editjobTitle,editcompanyName,editstartDate,editendDate,editworkcountry,editImage]);
      const handleupdateworksave = async () => {
        try {
          // await handleEditworkImageConversion();
          let data;
          let imagestring = editImage.toString();
         
          if (iseditworkchange) {
            data = {
              id: workeditid,
              company: editcompanyName,
              designation: editjobTitle,
              start: formatDateForInput(editstartDate),
              end: formatDateForExpiry(editendDate),
              // city_id: editcity,
              // state_id: editstate,
              country_id: editworkcountry,
              image: imagestring,
              // employment_type_id: editemploymentType,
              // experience_location_type_id: editworkType
            }
          } else {
            data = {
              id: workeditid,
              company: editcompanyName,
              designation: editjobTitle,
              start: formatDateForInput(editstartDate),
              end: formatDateForExpiry(editendDate),
              // city_id: editcity,
              // state_id: editstate,
              country_id: editworkcountry.value,
              // employment_type_id: editemploymentType,
              // experience_location_type_id: editworkType
            }
          }
          if(editcity){
            data.city_id = editcity.value
          }
          if(editstate){
            data.state_id = editstate.value
          }
          if(editworkType){
            data.experience_location_type_id = editworkType.value
          }
          if(editemploymentType){
            data.employment_type_id = editemploymentType.value
          }
          setIsLoading4(true);
          const response = await axiosInstance.post(workhistoryupdateapiurl, data);
          if (response.status === 200) {
            handleeditClose3();
           await workhistoryget();
            setIsLoading4(false);
            // showSuccessToast(response.data.message,'422Error',);
          }
        } catch (error) {
          handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
        }
      }
    return(
        <>
         {isLoading4 && (
                <>   
                    <div className="loaderover">
                    <div className="loader ">
                    </div>
                    </div>
                </>
            )}
            {workhistorys.length > 0 ? (
                    workhistorys.map((workhistorys, index) => (
                      <div key={index} className="docbox">
                        <div className="d-flex align-items-center" style={{ width: "100%",gap:'10px' }}>
                        <div className="d-flex align-items-center acdamic45" style={{ width: '45%',overflow:'hidden' }}>
                          <div className="me-3">
                            <img src={`${publicUrl}/core/${workhistorys.image}`} alt="car" className="docvehicleimg"></img>
                          </div>
                          <div className="workhistorydiv">
                                <h6 className="workhistoryh6">{workhistorys.designation}</h6>
                                <p className="workhistoryp">{workhistorys.company}</p>
                              </div>
                          </div>
                              <div className="acdamic20" style={{ width: '20%',overflow:'hidden' }}>
                              <div className="text-start">
                                <h6 className="workhistoryh6">{new Date(workhistorys.start).getFullYear()}-{new Date(workhistorys.end).getFullYear()}</h6>
                               </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-end acdamic35" style={{ width: '35%' }}>
                            {/* <div className="d-flex"> */}
                              <div className="docvechilebuttons me-3">
                                <button style={{ position: 'relative' }}  onClick={() => handleupdatehistory(workhistorys)}  className=" docuploadvehicle">
                                  <div className="butimag">
                                    <div className="__col docvechilespan">
                                      <img src={edit} alt="deposits" className="me-2" width='18px' height='auto' />
                                    </div>
                                    <span className="__col docvechilespan">Update Detail</span>
                                  </div>
                                </button>
                                <button style={{ position: 'relative' }} onClick={() => workhistorysdocument(workhistorys)} className=" docuploadviewdocument">
                                  <div className="butimag">
                                    <div className="__col docvechilespan">
                                      <img src={adddocument} alt="deposits" className="me-2" width='18px' height='auto' />
                                    </div>
                                    <span className="__col docvechilespan">Document</span>
                                  </div>
                                </button>
                              </div>
                              <div>
                                <img src={acdamicdeleteimg} alt="delete"  onClick={() => handledeleteorkhistory(workhistorys)} className="docvehicledeletered"></img>
                              </div>
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="d-flex mt-5 justify-content-center align-items-center flex-column generalnew ">
                       {isLoading1 ? (
                        <>
                        <SkeletonLoader documentRef={documentRef}/>
                        </>
                      ) : (
                       <> 
                      <img src={workhistoryimg} alt="document" className="docorangeimage workimg80"></img>
                      <h6 className="addnewgeneralh6 mt-2">Add Work History</h6>
                      <p className="generalp">Tell us about current or previous employment, add any additional details like certificates you earned</p>
                      <button className="addnewgeneralbutton work200" onClick={addworkhistory}>Add  Work Experience</button>
                      </>
                      )}
                    </div>
                  )}
                  {workhistorys.length > 0 && (
                    <div className="text-end mt-3" style={{ width: '97%' }}>
                    <button className="docaddnew" onClick={addworkhistory}>
          <img
            src={plus1}
            className="me-2"
            alt="plus"
            width="15px"
            height="auto"
          ></img>
          New
        </button>
                  </div>
                  )}

                  
      <Modal show={show3} dialogClassName="document-dialog1" contentClassName="document-content1" onHide={handleClose3} centered>
        <Modal.Body style={{ margin: '0', padding: '0' }}>
          <div className="d-flex align-items-center justify-content-between docpop">
            <h6 className="docpoph6">Add Work History</h6>
            <img src={closecircle} onClick={close3} alt="close" className="docpopclose"></img>
          </div>
          <div>
          <div className="error-message1">{error422}</div>
            <div className="mt-3 mb-2">
              <div className="row docpoprow">
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">Job title/Designation<span className="docpopstar">*</span></label>
                  <input className={`form-control docform ${jobTitle ? 'docform-active' : ''}`} placeholder="Enter Job Title" value={jobTitle} onChange={(e) => {setJobTitle(e.target.value);setError422('')}} />
                </div>
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">Company Name<span className="docpopstar">*</span></label>
                  <input className={`form-control docform ${companyName ? 'docform-active' : ''}`} placeholder="Enter Company name" value={companyName} onChange={(e) => {setCompanyName(e.target.value);setError422('')}} />
                </div>
              </div>
              <div className="row docpoprow">
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">Start Date<span className="docpopstar">*</span></label>
                  <DatePicker value={startDate}  disabledDate={disableFutureDates} onChange={(date) => {setStartDate(date);setError422('');setInlineError((prevState)=>({...prevState,expiryerror:''}))}} picker="month" placeholder="MM/YY" className={`${startDate ? 'ant-picker-active' : 'ant-picker'}`} style={{borderColor: startDate ? 'black' : '#d9d9d9',boxShadow:'none' , padding:'.375rem .75rem',borderRadius:'.25rem'}} format="MM/YYYY" getPopupContainer={(trigger) => trigger.parentNode} suffixIcon={<CustomSuffixIcon isActive={startDate}/>}  onKeyDown={handleKeyDown}/>
                  {inlineerror.expiryerror && <div className="error-message">{inlineerror.expiryerror}</div>}
                </div>
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">End Date<span className="docpopstar">*</span></label>
                  <DatePicker value={endDate} onClick={handleExpiryClick}  open={isOpen}  onOpenChange={handleOpenChange} disabledDate={disablePastDates(startDate)}   onChange={(date) => {setEndDate(date);setError422('')}} picker="month" placeholder="MM/YY" className={`${endDate ? 'ant-picker-active' : 'ant-picker'}`} style={{borderColor: endDate ? 'black' : '#d9d9d9',boxShadow:'none' , padding:'.375rem .75rem',borderRadius:'.25rem'}} format="MM/YYYY" getPopupContainer={(trigger) => trigger.parentNode} suffixIcon={<CustomSuffixIcon isActive={endDate}/>}  onKeyDown={handleKeyDown}/>
                </div>
              </div>
              <div className="row docpoprow">
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">Work type</label>
                  <Select options={workTypes} isLoading={workloading} value={workType}  isSearchable={false} placeholder="Select Work type" onChange={handleworktype} styles={customStyles1(Boolean(workType))}  />
                </div>
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">Employment Type</label>
                  <Select options={employmentTypes} isLoading={employeementloading} value={employmentType}  isSearchable={false} placeholder="Select Employment type" onChange={handleemployeetype} styles={customStyles1(Boolean(employmentType))}  />
                </div>
              </div>
              <div className="row docpoprow">
                <div className="col-sm-6 col-xl-4 col-12">
                  <label className="docpoplabel1">Country<span className="docpopstar">*</span></label>
                  <Select options={countries} isLoading={countryloading} ref={(el) => workref.current.country = el} value={country}  isSearchable={true} placeholder="Select Country" onChange={handlecountry} styles={customStyles1(Boolean(country))}  />
                  {inlineerror.countryerror && <div className="error-message">{inlineerror.countryerror}</div>}
                </div>
                <div className="col-sm-6 col-xl-4 col-12">
                  <label className="docpoplabel1">State</label>
                  <Select options={statelists} isLoading={stateloading} ref={(el) => workref.current.state = el}  value={state} onFocus={handlestatefocus} isSearchable={true} placeholder="Select State" onChange={handlestate} styles={customStyles1(Boolean(state))}  />
                  {inlineerror.stateerror && <div className="error-message">{inlineerror.stateerror}</div>}
                </div>
                <div className="col-xl-4 col-12">
                  <label className="docpoplabel1">City</label>
                  <Select options={citylists} isLoading={cityloading} ref={(el) => workref.current.city = el} value={city}  isSearchable={true} onFocus={handlecityfocus} placeholder="Select City" onChange={handlecity} styles={customStyles1(Boolean(city))}  />
                </div>
              </div>
            </div>
            {uploading ? (
              <div className="popprogressbox">
                <div className="d-flex align-items-center " style={{gap:'10px'}}>
                  <div className="d-flex align-items-center mb-2" style={{width:'80%'}}>
                    <img src={image} alt="file" className="popuploadfileimg me-3"></img>
                    <div style={{overflow:'hidden'}}>
                      <p className="popfilefrontp">{transformString(fileType)}{transformString(format)}</p>
                      <p className="popfilekb">{formatFileSize(fileSize)}</p>
                    </div>
                  </div>
                  <div style={{width:'20%',textAlign:'end'}}>
                    {progress === 100 && (
                      <img src={eye} alt="eye" onClick={() => handleworkeye(image)} className="popupeye me-3"></img>
                    )}
                    <img src={popdeletes} alt="delete" className="popupeye" onClick={handleworkdelete}></img>
                  </div>
                </div>
                <div className="progress mt-1 mb-1">
                  <div className="progress-bar" role="progressbar" style={{ width: `${progress}%`, backgroundColor: progressColor }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
                    {``}
                  </div>
                </div>
              </div>
            ) : (
              <div className="popinputbox mt-3" onClick={handleworkFileClick} onDrop={(e) => handleDrop(e, handleFileDrop,handleError,acceptPDF)} onDragOver={handleDragOver}>
                <div className="d-flex align-items-center justify-content-center flex-column p-4">
                  <img src={fileopen} className="popuploadflle" alt="file" width='60px' height='auto'></img>
                  <h5 className="popuploadh5"><span className="popspan">Click to Upload</span>or drag and drop</h5>
                  <p className="popuploadp">File format: .pdf, .png, .jpg</p>
                  <p className="popuploadp">( Max. File size : 20 MB)</p>
                </div>
                <input type="file" ref={workfileInputRef} style={{ display: 'none' }} accept=".jpg, .jpeg, .png" onChange={(e) => handleFileChange(e, setImage, setFileSize, setFileType, setProgress, setProgressColor, setUploading,setFormat,setDocname)} />
              </div>
            )}
            {inlineerror.imageerror && <div className="error-message">{inlineerror.imageerror}</div>}
            <div className="d-flex justify-content-end align-items-center mt-4 mb-2">
              <button className="docpopcancel me-3" onClick={handleworkcancel}>Cancel</button>
              <button className="docpopsave" style={{ backgroundColor: workisFormValid ? '#5D64F2' : '#EAEAEA', color: workisFormValid ? '#FFF' : '#333' , cursor: workisFormValid ? 'pointer' : 'not-allowed' }} disabled={!workisFormValid} onClick={handleworksave}>Save</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* workhistoryeditpopup */}

      <Modal show={showedit3} dialogClassName="document-dialog1" contentClassName="document-content1" onHide={handleeditClose3} centered>
        <Modal.Body style={{ margin: '0', padding: '0' }}>
          <div className="d-flex align-items-center justify-content-between docpop">
            <h6 className="docpoph6">Add Work History</h6>
            <img src={closecircle} onClick={editclose3} alt="close" className="docpopclose"></img>
          </div>
          <div>
          <div className="error-message1">{error422}</div>
            <div className="mt-3 mb-2">
              <div className="row docpoprow">
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">Job title/Designation<span className="docpopstar">*</span></label>
                  <input className={`form-control docform ${editjobTitle ? 'docform-active' : ''}`} placeholder="Enter Job Title" value={editjobTitle} onChange={(e) => {setEditJobTitle(e.target.value);setError422('')}} />
                </div>
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">Company Name<span className="docpopstar">*</span></label>
                  <input className={`form-control docform ${editcompanyName ? 'docform-active' : ''}`} placeholder="Enter Company name" value={editcompanyName} onChange={(e) => {setEditCompanyName(e.target.value);setError422('')}} />
                </div>
              </div>
              <div className="row docpoprow">
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">Start Date<span className="docpopstar">*</span></label>
                  <DatePicker value={editstartDate} disabledDate={disableFutureDates} onChange={(date) => {setEditStartDate(date);setError422('');setInlineError((prevState)=>({...prevState,expiryerror:''}))}} picker="month" placeholder="MM/YY" className={`${editstartDate ? 'ant-picker-active' : 'ant-picker'}`} style={{borderColor: editstartDate ? 'black' : '#d9d9d9',boxShadow:'none' , padding:'.375rem .75rem',borderRadius:'.25rem'}} format="MM/YYYY" getPopupContainer={(trigger) => trigger.parentNode} suffixIcon={<CustomSuffixIcon isActive={editstartDate}/>}  onKeyDown={handleKeyDown}/>
                  {inlineerror.expiryediterror && <div className="error-message">{inlineerror.expiryediterror}</div>}
                </div>
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">End Date<span className="docpopstar">*</span></label>
                  <DatePicker value={editendDate}  onClick={handleEditExpiryClick}  open={isOpen}  onOpenChange={handleOpenChange} disabledDate={disablePastDates(editstartDate)}  onChange={(date) =>{ setEditEndDate(date);setError422('')}} picker="month" placeholder="MM/YY" className={`${editendDate ? 'ant-picker-active' : 'ant-picker'}`} style={{borderColor: editendDate ? 'black' : '#d9d9d9',boxShadow:'none' , padding:'.375rem .75rem',borderRadius:'.25rem'}} format="MM/YYYY" getPopupContainer={(trigger) => trigger.parentNode} suffixIcon={<CustomSuffixIcon isActive={editendDate}/>}  onKeyDown={handleKeyDown}/>
                </div>
              </div>
              <div className="row docpoprow">
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">Work type</label>
                  <Select options={workTypes}  value={editworkType} isLoading={workloading}  isSearchable={true} placeholder="Select Work type" onChange={handleeditworktype} styles={customStyles1(Boolean(editworkType))}  />
                </div>
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">Employment Type</label>
                  <Select options={employmentTypes} isLoading={employeementloading} value={editemploymentType}  isSearchable={true} placeholder="Select Work type" onChange={handleeditemployeetype} styles={customStyles1(Boolean(editemploymentType))}  />
                </div>
              </div>
              <div className="row docpoprow">
                <div className="col-sm-6 col-xl-4 col-12">
                  <label className="docpoplabel1">Country<span className="docpopstar">*</span></label>
                  <Select options={countries}  value={editworkcountry} isLoading={countryloading}  isSearchable={true} placeholder="Select Work type" onChange={handleeditcountry} styles={customStyles1(Boolean(editworkcountry))}  />
                  {inlineerror.countryerror && <div className="error-message">{inlineerror.countryerror}</div>}
                </div>
                <div className="col-sm-6 col-xl-4 col-12">
                  <label className="docpoplabel1">State</label>
                  <Select options={statelists}  value={editstate} onFocus={editstatefocus} isLoading={stateloading} isSearchable={true} placeholder="Select State" onChange={handleeditstate} styles={customStyles1(Boolean(editstate))}  />
                  {inlineerror.stateerror && <div className="error-message">{inlineerror.stateerror}</div>}
                </div>
                <div className="col-xl-4 col-12">
                  <label className="docpoplabel1">City</label>
                  <Select options={citylists} isLoading={cityloading} onFocus={editcityfocus} value={editcity}  isSearchable={true} placeholder="Select City" onChange={handleeditcity} styles={customStyles1(Boolean(editcity))}  />
                  {inlineerror.cityerror && <div className="error-message">{inlineerror.cityerror}</div>}
                </div>
              </div>
            </div>
            {editImagedetails ? (
              <div className="popinputbox mt-3" onClick={handleworkFileClick1} onDrop={(e) => handleDrop1(e, handleeditFileDrop,handleEditError,acceptPDF)}
              onDragOver={handleDragOver1}>
                <div className="d-flex align-items-center justify-content-center flex-column p-4">
                  <img src={fileopen} className="popuploadflle" alt="file" width='60px' height='auto'></img>
                  <h5 className="popuploadh5"><span className="popspan">Click to Upload</span>or drag and drop</h5>
                  <p className="popuploadp">File format: .pdf, .png, .jpg</p>
                  <p className="popuploadp">( Max. File size : 20 MB)</p>
                </div>
                <input type="file" ref={workfileInputRef1} accept=".jpg, .jpeg, .png" style={{ display: 'none' }} onChange={(e) => handleEditfilechange(e, setEditImage, setFileSize, setFileType, setEditProgress, setEditProgressColor, setEditImageDetails, setFormat, setEditDocName)} />
              </div>

            ) : (
              <div className="popprogressbox">
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center mb-2" style={{width:'80%'}}>
                    <img src={editImage} alt="file" className="popuploadfileimg me-3"></img>
                    <div>
                      <p className="popfilefrontp">{`${transformString(fileType)}`}{transformString(format)}</p>
                      {fileSize && fileSize >= 1024 && (
                      <p className="popfilekb">{formatFileSize(fileSize)}</p>
                      )}
                    </div>
                  </div>
                  <div style={{width:'20%',textAlign:'end'}}>
                    {editProgress === 100 && (
                      <img src={eye} alt="eye" onClick={() => handleeditworkeye(editImage)} className="popupeye me-3"></img>
                    )}
                    <img src={popdeletes} alt="delete" className="popupeye" onClick={handleeditworkdelete}></img>
                  </div>
                </div>
                <div className="progress mt-1 mb-1">
                  <div className="progress-bar" role="progressbar" style={{ width: `${editProgress}%`, backgroundColor: editProgressColor }} aria-valuenow={editProgress} aria-valuemin="0" aria-valuemax="100">
                    {``}
                  </div>
                </div>
              </div>
            )}
            {inlineerror.editimageerror && <div className="error-message">{inlineerror.editimageerror}</div>}
            <div className="d-flex justify-content-end align-items-center mt-4 mb-2">
              <button className="docpopcancel me-3" onClick={handleeditworkcancel}>Cancel</button>
              <button className="docpopsave" style={{ backgroundColor: editworkisFormValid ? '#5D64F2' : '#EAEAEA', color: editworkisFormValid ? '#FFF' : '#333' , cursor: editworkisFormValid ? 'pointer' : 'not-allowed' }} disabled={!editworkisFormValid} onClick={handleupdateworksave}>Save</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
       
       {selectedworkimage && (
        <Image
        src={selectedworkimage}
        alt="Enlarged Image"
        preview={{
         visible: isPreviewVisible,
         onVisibleChange: setPreviewVisible,
         onClose: () => {
           setPreviewVisible(false);
           setShow3(true); 
         },
       }}
        style={{ maxWidth: '100%', maxHeight: '80vh' ,zIndex:'9999' , display:'none'}}/>
   )}
    
    {selectededitworkimage && (
        <Image
        src={selectededitworkimage}
        alt="Enlarged Image"
        preview={{
         visible: isPreviewVisible1,
         onVisibleChange:setPreviewVisible1,
         onClose: () => {
           setPreviewVisible1(false);
           setShowedit3(true); 
         },
       }}
        style={{ maxWidth: '100%', maxHeight: '80vh' ,zIndex:'9999' , display:'none'}}/>
    )}
        </>
    );
}
export default WithToast(Workhistory);