import React from "react";
import { useNavigate } from "react-router-dom";
import Navbars from "./navbar";
import leftarrow from './images/arrow-left-01.png';
function Emailedits() {
  const navigate = useNavigate();
  const back = () => {
    navigate('/emailchange');
  }
  const cancel = () => {
    navigate('/emailchange');
  }
  const save = () => {
    navigate('/emailchange');
  }

  return (
    <>
      <Navbars />
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="col-lg-7 col-md-8 col-sm-10">
            <div className="box " style={{ maxWidth: '619px',width:'100%',backgroundColor: 'white',minHeight:'327px',borderRadius:'18px',border:'1px solid #ABA7A7',padding:'50px 30px' }}>
              <div className="d-flex align-items-center mb-4">
                {/* Arrow Icon */}
                <img onClick={back} className="me-4" width='19.5px' height='15.5px' style={{cursor:'pointer' }} src={leftarrow} alt="edit"></img>
                {/* Heading */}
                <h5 className="mb-0" style={{fontWeight:'500',fontSize:'24px',lineHeight:'36px',color:'#232323'}}>Change Email</h5>
              </div>

              {/* Label and Input Box */}
              
                <div className="mb-3  ps-5">
                  <label htmlFor="inputField" className="form-label"style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#000000'}}>Email Address</label>
                  <input type="text" className="form-control emailchangeinput" id="inputField" placeholder="stalin@gmail.com" />
                </div>
              
              <div className="d-flex justify-content-end mb-3 mt-4 text-end pe-md-5 ">
                <button type="button" onClick={cancel} className="btn me-3 profilecancel">
                  Cancel
                </button>
                <button type="button" onClick={save} className="btn profilesave">
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
}
export default Emailedits;