import React from "react";
import Navbars from "./navbar";
import leftarrow from './images/arrow-left-01.png';
import './home.css';
import file from './images/file-01 (1).png';
import eye from './images/eye-01 (1).png';
import exit from './images/cancel-01.png';
import { useNavigate } from "react-router-dom";

function Uploaddocument(){
    const navigate = useNavigate();
    const back = () => {
     navigate('/adddocument');
    }
    const cancel = () => {
        navigate('/adddocument');
    }
    const submit = () => {
        navigate('/adddocument');
    }

    return(
        <>
         <Navbars/>
        <div className="container-fluid personaldocuments">
        <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="col-lg-7 col-md-10 col-sm-12">
            <div className="box uploaddocumentsbox p-4  mt-5 " style={{ backgroundColor: "white",height:'294px',border:'1px solid #ABA7A7',borderRadius:'18px' }}>
              <div className="d-flex  mb-4 align-items-center">
                <img onClick={back} src={leftarrow} alt="leftarrow" width='19.5px' height='15.5px' className="mb-3 me-4"style={{cursor:'pointer'}}></img>
                <h5 className='mb-3'style={{fontFamily:"Poppins",fontWeight:'500',color:'#232323',fontSize:'24px',verticalAlign:'cap-height',lineHeight:'36px'}}>Upload Document</h5>
              </div>
              <div className="ps-5">
              <div className="boup p-3 d-flex align-items-center justify-content-between" style={{width:'100%',maxWidth:'530px',height:'79px',border:'1px solid #595959',borderRadius:'15px'}}>
              <div className="boup-left d-flex align-items-center">
                <div className="fileimage">
              <img src={file} alt="file" width="20px" height="24px"/>
             </div>
             <div className="ms-2">
             <p className="mb-0 documentp" style={{fontWeight:'500', fontSize: '20px', color: '#000000',verticalAlign:'Cap-height',lineHeight:'30px',textAlign:'center' }}>Drivinglicense</p>
             <p className="mb-0 text-start" style={{fontWeight:'500', fontSize: '12px', color: '#696767',verticalAlign:'Cap-height',lineHeight:'18px',textAlign:'center' }}>4 MB</p>
             </div>
             </div>
             <div className="boup-right d-flex align-items-center">
             <img src={eye} alt="Upload Icon" width="25.71px" height="22px" className="me-2" />
             <img src={exit} alt="Upload Icon" width="13.5px" height="13.5px" />
              </div>
              </div>
              <div className="d-flex justify-content-end  me-5 pe-4 mt-4 text-end">
                                    <button type="button" onClick={cancel} className="btn me-3 uploadcancel" >
                                        Cancel
                                    </button>
                                    <button type="button" onClick={submit} className="btn  uploadsubmit">
                                        Submit
                                    </button>
                                </div>
              </div>
              </div>
              </div>
              </div>
              </div>
        </>
    );
}
export default Uploaddocument;