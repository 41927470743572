import React , {useEffect} from "react";
function Docnav({setSource, source}){
    const docnav = [
        { title: 'General Identification',  },
        { title: 'Vehicle Details', },
        { title: 'Work History',  },
        { title: 'Academic History',},
        { title: 'Skills',  },
      ]
    const handleSource = (source_value) => {
        setSource(source_value);
        localStorage.setItem('activeNavItem', source);
   }
    useEffect(() => {
   const storedActiveSection = localStorage.getItem('activeNavItem'); // Retrieve the active section from localStorage

   if (storedActiveSection) {
     setSource(storedActiveSection); 
   }
}, []);
    return(
        <>
             
              <div className="docnav">
                <ul>
                  {docnav.map((item, index) => (
                    <li key={index} style={{ color: source === item.title ? '#F77F00' : 'black', }} onClick={()=>handleSource(item.title)}>{item.title} </li>
                  ))}
                </ul>
              </div>
        
        </>
    );
}
export default Docnav;