import React , {useEffect,useState} from "react";
import axios from "axios";

function Sidapi(){
    function removeCookie(name) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
    const [response, setResponse] = useState(null);
    const sid = () => {
        const config = {
            headers: {'Accept': 'application/json' },
            withCredentials: true
        };  
        return axios.get('https://api.v1.evzone.app/api/v1.0/app/core/auth/sid', config)
            .then(response => {
                setResponse(response.data.sid); // Set the response data to state
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    removeCookie('__SID')
                }
                throw error; // Rethrow the error to be caught by the outer catch block
            });
    }
  localStorage.setItem('sid',response);
    useEffect(() => {
        sid().then(()=>{
            const params = new URLSearchParams(window.location.search);
            const sharedValue = params.get('sidurl');
            
            if(sharedValue){
                const newURL = window.location.pathname;
                window.location.href = (sharedValue);
                window.history.replaceState({}, document.title, newURL);
            }
        }).catch((error)=>{
            if (error.response && error.response.status === 401) {
                removeCookie('__SID')
            }
        });
    },[]);

    return(
        <>

        </>
    )
}

export default Sidapi;
