import React from "react";
import { useNavigate } from "react-router-dom";
import applepay from './images/ApplePay.png';
import googlepay from './images/GPay.png';
import transaction from "./images/Transaction.png";
import paypal from './images/PayPal.png';
import leftarrow from './images/arrow-left-01.png';
import Navbars from "./navbar";
function Withdrawalfunds() {
  const navigate = useNavigate();
  const back = () => {
    navigate('/transactions');
  }
  const withdrawlfundscancel = () => {
    navigate('/transactions');
  }
  const withdrawlfundsnext = () => {
   navigate('/transactions');
  }
  return (
    <>
        <Navbars/>
      <div className="container-fluid depositfund">
        <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="col-lg-6 col-md-8 col-sm-10">
            <div className="box p-5 border" style={{backgroundColor:'white',border:'1px solid #ABA7A7',borderRadius:'18px',width:'100%',maxWidth:'620px',minHeight:'578px'}}>
              <div className="d-flex  align-items-center">
              <img  onClick={back} className=" me-1 mb-4" style={{cursor:'pointer', width: '19.5px', height: '15.5px' }} src={leftarrow} alt="edit"></img>
                <h5 className='ps-4 mb-4'style={{fontWeight:'500',fontSize:'24px',lineHeight:'36px',color:'#232323'}}>Withdraw funds</h5>
              </div>
              <div className="d-flex justify-content-start mb-3 ps-5"> {/* New div for label */}
                <label htmlFor="user-name" style={{ fontWeight: '500' ,fontSize:'16px',lineHeight:'24px',color:'#000000' }}>Withdraw Money</label>
              </div>
              <div className="d-flex justify-content-start mb-4 ps-5">
              <div className="input-container" style={{  position: 'relative', width: '100%',maxWidth:'469px', display: 'flex' }}>
                <input type="text" className="depsoit-input" placeholder="1000" value='1000' style={{  width: 'calc(100% - 100px)', height: '49px', padding: '8px', borderRadius:'10px 0px 0px 10px', border: '1px solid #DCDCDC', borderColor: '#DCDCDC' }} />
                <select className="deposit-select"
                  style={{ width:'100px',fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#FFFFFF', height: '49px',borderRadius:'0px 10px 10px 0px', padding: '8px', border: 'none', backgroundColor: '#02CD8D', color: 'white', position: 'relative',caretWidth: "100px",caretHeight: "100px",caretColor:'black'}}
                >
                  <option value="">UGX</option>
                </select>
              </div>
              </div>
              <div className="row depositbuttons ps-5 ps-sm-5 mb-4">
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <button type="button" className=" currancybutton  btn-block"style={{fontWeight:'500',fontSize:'12px',lineHeight:'18px',color:'#000000'}}>+ UGX 100</button>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <button type="button" className=" currancybutton btn-block"style={{fontWeight:'500',fontSize:'12px',lineHeight:'18px',color:'#000000'}}>+ UGX 250</button>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <button type="button" className=" currancybutton btn-block"style={{fontWeight:'500',fontSize:'12px',lineHeight:'18px',color:'#000000'}}>+ UGX 500</button>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <button type="button" className=" currancybutton  btn-block"style={{fontWeight:'500',fontSize:'12px',lineHeight:'18px',color:'#000000'}}>+ UGX 1000</button>
                </div>
              </div>
              <hr style={{ marginLeft: '8%' , color:'#EAE9E9',border:'1px solid #EAE9E9' }} className="mb-4 mt-4"></hr>
              <label className="ps-5  mb-3" style={{color:'#494949',fontWeight:'600',fontSize:'16px',lineHeight:'24px'}}>Transfer Your Fund To </label>
              <div className="row deposit-images mb-3 ps-5">
                <div className="col-6 col-md-3">
                  <img src={applepay} alt="Apple Pay" width="80px" height="80px" className="img-fluid" />
                </div>
                <div className="col-6 col-md-3">
                  <img src={googlepay} alt="Google Pay" width="80px" height="80px" className="img-fluid" />
                </div>
                <div className="col-6 col-md-3">
                  <img src={transaction} alt="Transaction" width="80px" height="80px"className="img-fluid" />
                </div>
                <div className="col-6 col-md-3">
                  <img src={paypal} alt="PayPal" width="80px" height="80px" className="img-fluid"/>
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center mt-5 mb-0">
                <button onClick={withdrawlfundscancel} type="button" className=" depositcancel  me-2">Cancel</button>
                <button onClick={withdrawlfundsnext} type="button" className=" depositnext">Transfer</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Withdrawalfunds;