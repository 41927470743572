import React from "react";
import { useState } from "react";
import deposit from './images/deposit-01.png';
import { Link, useLocation, useNavigate } from "react-router-dom";
import withdrawal from './images/withdrawl-01.png';
import incomeing from './images/wallet_to_wallet-01.png';
import './transactions.css';
import Modal from 'react-bootstrap/Modal';
import Navbars from "./navbar";
import leftarrow from './images/arrow-left-01.png';
import eye from './images/eye-01.png';
import eyeslash from './images/eye_slash-01.png'

function IncomingPage() {
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloses = () => setShows(false);
  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
};
  const back = () => {
    navigate('/transactions');
  }
  const location = useLocation();
  const isLinkActive = (linkPath) => {
    return location.pathname === linkPath;
  };
  const request = () => {
    setShow(true);
  }
  const unlock = () => {
    setShows(true);
  }
  return (
    <>
    <Navbars/>
      <div className="container-fluid incomeingpage">
        <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="col-lg-7 col-md-10 col-sm-12">
            <div className="box p-4 " style={{ width: '100%',backgroundColor:'white',maxWidth:'551px',minHeight:'775px',border:'1px solid ABA7A7'}}>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex  align-items-center">
                <img onClick={back} className="me-3 mb-2" style={{cursor:'pointer', width: '19.5px', height: '15.5px' }} src={leftarrow} alt="edit"></img>
                  <h5 className='' style={{fontWeight:'600',fontSize:'20px',lineHeight:'30px'}}>Wallet to Wallet transfer</h5>
                </div>
                <button className="request" onClick={request}>Request</button>
              </div>
              <ul className="d-flex list-unstyled ">
                <li style={{ marginRight: '20px' }}>
                  <Link to="/incomeingpage" className={isLinkActive("/incomeingpage") ? "active" : ""}>
                    <span className="incoming" style={{ fontSize: '16px',fontWeight:'500',lineHeight:'24px' }}>Incoming</span>
                  </Link>
                </li>
                <li style={{ marginRight: '20px'}}>
                  <Link to="/outgoingpage" className={isLinkActive("/outgoingpage") ? "active" : ""}>
                    <span className="incoming" style={{ fontSize: '16px',fontWeight:'500',lineHeight:'24px' }}>Outgoing</span>
                  </Link>
                </li>
                <li >
                  <Link to="/requestspage" className={isLinkActive("/requestspage") ? "active" : ""}>
                    <span className="incoming" style={{ fontSize: '16px',fontWeight:'500',lineHeight:'24px'}}>Requests</span>
                  </Link>
                </li>
              </ul> 
              <div className="d-flex align-items-center justify-content-center">
                                        
                                            <img
                                                className="me-md-3 me-sm-3 me-xs-3 me-lg-4 mt-2 depositwallet"
                                                src={deposit} // Replace 'yourImageSourceHere' with the actual image source URL
                                                alt="wallet"
                                                width="42px"
                                                height="42px"
                                            />
                                            
                                            <div className="d-flex flex-column flex-fill ml-md-2  text-start">
                                                <p className="section-title" style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>Deposit</p>
                                                <p className="section-info" style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>Bank transfer</p>
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2 me-lg-4 text-end">
                                                <p className="section-amount" style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>UGX 3000</p>
                                                <p className="section-date" style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>20.05.2023</p>
                                            </div>
                                            <div className="d-flex flex-column  ml-md-2 mx-2 text-end" style={{marginTop:'-4%'}}>
                                            <button className="unlocked unlocked-button " style={{ border: '1px solid #02CD8D', color: '#02CD8D', backgroundColor: '#FFFFFF', borderRadius: '6px', fontSize: '14px',lineHeight:'18px', width: '80px',height:'26px', }}>Unlocked</button>
                                           
                                            </div>
                                            </div>
              <hr className="mb-3 mt-0" style={{ marginLeft: '12%' }}></hr>
              <div className="d-flex mt-2 align-items-center">
                                        <div className="mb-3" >
                                            <img
                                                className="me-md-3 me-sm-3 me-lg-4 mb-md-0 depositwallet"
                                                src={withdrawal} // Replace 'yourImageSourceHere' with the actual image source URL
                                                alt="wallet"
                                                width="42px"
                                                height="42px"
                                            />
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2  text-start">
                                                <p className="section-title" style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>Withdrawal</p>
                                                <p className="section-info"  style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>Bank transfer</p>
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2 me-lg-4 text-end">
                                                <p className="section-amount" style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>UGX 3000</p>
                                                <p className="section-date"  style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>20.05.2023</p>
                                            </div>
                                            <div className="d-flex flex-column  ml-md-2 mx-2 text-end" style={{marginTop:'-4%'}}>
                                            <button className="unlocked unlocked-button " style={{ border: '1px solid #EA4335', color: '#EA4335', backgroundColor: '#FFFFFF', borderRadius: '6px', fontSize: '14px',lineHeight:'18px', width: '80px',height:'26px', borderRadius: '5px', fontSize: '14px' }}>Denied</button>
                                            </div>
                                            </div>
              <hr className="mb-3 mt-0" style={{ marginLeft: '12%' }}></hr>
              <div className="d-flex mt-2 align-items-center">
                                        <div className="mb-3" >
                                            <img
                                                className="me-md-3 me-sm-3 me-lg-4 mb-md-0 depositwallet"
                                                src={incomeing} // Replace 'yourImageSourceHere' with the actual image source URL
                                                alt="wallet"
                                                width="42px"
                                                height="42px"
                                            />
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2  text-start">
                                                <p className="section-title" style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>Incoming</p>
                                                <p className="section-info"  style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>Bank transfer</p>
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2 me-lg-4 text-end">
                                                <p className="section-amount" style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>UGX 3000</p>
                                                <p className="section-date"  style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>20.05.2023</p>
                                            </div>
                                            <div className="d-flex flex-column  ml-md-2 mx-2 text-end" style={{marginTop:'-4%'}}>
                                            <button className="unlocked unlocked-button mb-2 " style={{ color:'#02CD8D',border: '1px solid #02CD8D', backgroundColor: '#FFFFFF', borderRadius: '6px', fontSize: '14px',lineHeight:'18px', width: '80px',height:'26px' }}>Pending</button>
                                            <button className="unlocked  unlocked-button " style={{ border: '1px solid #0897FF', color: '#FFFFFF', backgroundColor: '#000000', borderRadius: '6px', fontSize: '14px',lineHeight:'18px', width: '80px',height:'26px', borderRadius: '5px', fontSize: '14px' }}>Cancel</button>
                                            </div>
                                            </div>
              <hr className="mb-3 mt-0" style={{ marginLeft: '12%' }}></hr>
               <div className="d-flex mt-2 align-items-center">
                                        <div className="mb-3" >
                                            <img
                                                className="me-md-3 me-sm-3 me-lg-4 mb-md-0 depositwallet"
                                                src={incomeing} // Replace 'yourImageSourceHere' with the actual image source URL
                                                alt="wallet"
                                                width="42px"
                                                height="42px"
                                            />
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2  text-start">
                                                <p className="section-title" style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>Money Send from...</p>
                                                <p className="section-info"  style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>Bank transfer</p>
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2 me-lg-4 text-end">
                                                <p className="section-amount" style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>UGX 3000</p>
                                                <p className="section-date"  style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>20.05.2023</p>
                                            </div>
                                            <div className="d-flex flex-column  ml-md-2 mx-2 text-end" style={{marginTop:'-4%'}}>
                                            <button className="unlocked unlocked-button mb-2" onClick={unlock} style={{ color:'#FFFFFF',border: '1px solid #02CE8E', color: 'white', backgroundColor: '#02CE8E', borderRadius: '6px', fontSize: '14px',lineHeight:'18px', width: '80px',height:'26px' }}>Unlock</button>
                                            <button className="unlocked unlocked-button " style={{ border: '1px solid #0897FF', color: 'white', backgroundColor: '#0897FF', borderRadius: '6px', fontSize: '14px',lineHeight:'18px', width: '80px',height:'26px', borderRadius: '5px', fontSize: '14px' }}>Bounce</button>
                                            </div>
                                            </div>
              <hr className="mb-3 mt-0" style={{ marginLeft: '12%' }}></hr>
              <div className="d-flex mt-2 align-items-center">
                                        <div className="mb-3" >
                                            <img
                                                className="me-md-3 me-sm-3 me-lg-4 mb-md-0 depositwallet"
                                                src={withdrawal} // Replace 'yourImageSourceHere' with the actual image source URL
                                                alt="wallet"
                                                width="42px"
                                                height="42px"
                                            />
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2  text-start">
                                                <p className="section-title" style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>Withdrawal</p>
                                                <p className="section-info"  style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>Bank transfer</p>
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2 me-lg-4 text-end">
                                                <p className="section-amount" style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>UGX 3000</p>
                                                <p className="section-date"  style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>20.05.2023</p>
                                            </div>
                                            <div className="d-flex flex-column  ml-md-2 mx-2 text-end" style={{marginTop:'-4%'}}>
                                            <button className="unlocked unlocked-button mb-2" style={{ color:'#FFFFFF',border: '1px solid #02CE8E', color: 'white', backgroundColor: '#02CE8E', borderRadius: '6px', fontSize: '14px',lineHeight:'18px', width: '80px',height:'26px' }} onClick={unlock}>Unlock</button>
                                            <button className="unlocked unlocked-button  " style={{ border: '1px solid #EA4335', color: 'white', backgroundColor: '#EA4335', borderRadius: '6px', fontSize: '14px',lineHeight:'18px', width: '80px',height:'26px', borderRadius: '5px', fontSize: '14px' }}>Deny</button>
                                            </div>
                                            </div>
              <hr className="mb-3 mt-0" style={{ marginLeft: '12%' }}></hr>
              <div className="d-flex mt-2 align-items-center">
                                        <div className="mb-3" >
                                            <img
                                                className="me-md-3 me-sm-3 me-lg-4 mb-md-0 depositwallet"
                                                src={incomeing} // Replace 'yourImageSourceHere' with the actual image source URL
                                                alt="wallet"
                                                width="42px"
                                                height="42px"
                                            />
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2  text-start">
                                                <p className="section-title" style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>Wallet to Wallet</p>
                                                <p className="section-info"  style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>Bank transfer</p>
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2 me-lg-4 text-end">
                                                <p className="section-amount" style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>UGX 3000</p>
                                                <p className="section-date"  style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>20.05.2023</p>
                                            </div>
                                            <div className="d-flex flex-column  ml-md-2 mx-2 text-end" style={{marginTop:'-4%'}}>
                                            <button className="unlocked unlocked-button mb-2" style={{ color:'#FFFFFF',border: '1px solid #02CE8E', color: 'white', backgroundColor: '#02CE8E', borderRadius: '6px', fontSize: '14px',lineHeight:'18px', width: '80px',height:'26px' }}onClick={unlock}>Unlock</button>
                                            <button className="unlocked unlocked-button  " style={{ border: '1px solid #EA4335', color: 'white', backgroundColor: '#EA4335', borderRadius: '6px', fontSize: '14px',lineHeight:'18px', width: '80px',height:'26px', borderRadius: '5px', fontSize: '14px' }}>Deny</button>
                                            </div>
                                            </div>
              <hr className="mb-3 mt-0" style={{ marginLeft: '12%' }}></hr>
              <div className="d-flex mt-2 align-items-center">
                                        <div className="mb-3" >
                                            <img
                                                className="me-md-3 me-sm-3 me-lg-4 mb-md-0 depositwallet"
                                                src={incomeing} // Replace 'yourImageSourceHere' with the actual image source URL
                                                alt="wallet"
                                                width="42px"
                                                height="42px"
                                            />
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2  text-start">
                                                <p className="section-title" style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>Wallet to Wallet</p>
                                                <p className="section-info"  style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>Bank transfer</p>
                                            </div>
                                            <div className="d-flex flex-column flex-fill ml-md-2 me-lg-4 text-end">
                                                <p className="section-amount" style={{fontWeight:'600',fontSize:'16px',verticalAlign:'Cap-height',lineHeight:'24px',color:'#292929'}}>UGX 3000</p>
                                                <p className="section-date"  style={{fontWeight:'400',fontSize:'14px',verticalAlign:'Cap-height',lineHeight:'21px',color:'#7D7878'}}>20.05.2023</p>
                                            </div>
                                            <div className="d-flex flex-column  ml-md-2 mx-2 text-end" style={{marginTop:'-4%'}}>
                                            <button className="unlocked unlocked-button mb-2" style={{ color:'#FFFFFF',border: '1px solid #02CE8E', color: 'white', backgroundColor: '#02CE8E', borderRadius: '6px', fontSize: '14px',lineHeight:'18px', width: '80px',height:'26px' }}onClick={unlock}>Unlock</button>
                                            <button className="unlocked unlocked-button " style={{ border: '1px solid #EA4335', color: 'white', backgroundColor: '#EA4335', borderRadius: '6px',lineHeight:'18px', width: '80px',height:'26px', fontSize: '14px' }}>Deny</button>
                                            </div>
                                            </div>
                                            <hr className="mb-4 mt-1" style={{ marginLeft: '12%' }}></hr>
            </div>
          </div>
        </div>
      </div>

      {/* modal */}

      <Modal show={show} onHide={handleClose}  backdrop="static" keyboard={false} contentClassName="modal-addmoney">
        <Modal.Header style={{ textDecoration: "none", border: 'none' }} closeButton>
          <div className="d-flex align-items-center ">
          <img src={leftarrow} onClick={back} alt="leftarrow" width='19.5px' height='15.5px' className="mb-0 me-3"style={{cursor:'pointer',marginLeft:'-5%'}}></img>
            <h5 className="mb-0"style={{fontWeight:'500',fontSize:'24px',lineHeight:'36px',color:'#232323'}}>Request Money</h5>
          </div>
        </Modal.Header>
        <Modal.Body>
        <div className="d-flex justify-content-start mb-3 ps-4"> {/* New div for label */}
                <label htmlFor="user-name" style={{ fontWeight: '500' ,fontSize:'16px',lineHeight:'24px',color:'#000000' }}>Withdraw Money</label>
              </div>
              <div className="d-flex justify-content-start mb-4 ps-4 ">
              <div className="input-container" style={{  position: 'relative', width: '100%',maxWidth:'469px', display: 'flex' }}>
                <input type="text" className="depsoit-input" placeholder="1000" value='1000' style={{  width: 'calc(100% - 100px)', height: '49px', padding: '8px', borderRadius:'10px 0px 0px 10px', border: '1px solid #DCDCDC', borderColor: '#DCDCDC' }} />
                <select className="deposit-select"
                  style={{ width:'100px',fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#FFFFFF', height: '49px',borderRadius:'0px 10px 10px 0px', padding: '8px', border: 'none', backgroundColor: '#02CD8D', color: 'white', position: 'relative',caretWidth: "100px",caretHeight: "100px",caretColor:'black'}}
                >
                  <option value="">UGX</option>
                </select>
              </div>
              </div>
              <div className="row depositbuttons  ps-sm-4  mb-4">
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <button type="button" className=" currancybutton  btn-block"style={{fontWeight:'500',fontSize:'12px',lineHeight:'18px',color:'#000000'}}>+ UGX 100</button>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <button type="button" className=" currancybutton btn-block"style={{fontWeight:'500',fontSize:'12px',lineHeight:'18px',color:'#000000'}}>+ UGX 250</button>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <button type="button" className=" currancybutton btn-block"style={{fontWeight:'500',fontSize:'12px',lineHeight:'18px',color:'#000000'}}>+ UGX 500</button>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <button type="button" className=" currancybutton  btn-block"style={{fontWeight:'500',fontSize:'12px',lineHeight:'18px',color:'#000000'}}>+ UGX 1000</button>
                </div>
              </div>
            
          <hr style={{ marginLeft: '5%' , height:'2px', color:'#EAE9E9' }} className="mb-3 mt-4"></hr>
          <div className="d-flex justify-content-start mb-3 ps-4">
            <label htmlFor="user-name" style={{ fontWeight: "500",fontSize:'16px',lineHeight:'24px',color:'#494949' }}>
              Source ID
            </label>
          </div>
          <div className="d-flex justify-content-start mb-3 ps-4">
            <input className="sourceid"
              type="text"
              placeholder="Email or Phone"
              style={{
                width: "100%",
                maxWidth:'469px',
                height: "49px",
                padding: "8px",
                borderRadius: "10px",
                border: "1px solid #DCDCDC",
              }}
            />
          </div>
          <div className="d-flex justify-content-start mb-3 ps-4">
            <label htmlFor="user-name" style={{ fontWeight: "500",fontSize:'16px',lineHeight:'24px',color:'#494949' }}>
              Reason
            </label>
          </div>
          <div className="d-flex justify-content-start mb-3 ps-4">
            <input
              className="sourcereason"
              type="text"
              placeholder="Enter value"
              style={{
                width: "100%",
                maxWidth:'469px',
                height: "49px",
                padding: "8px",
                borderRadius: "10px",
                border: "1px solid #DCDCDC",
              }}
            />
          </div>

        </Modal.Body>
        <Modal.Footer className="mx-3" style={{ textDecoration: "none", border: 'none' }}>
          <button className="modalclose" onClick={handleClose}>
            Cancel
          </button>
          <button className="modalrequest" onClick={handleClose}>
            Request
          </button>
        </Modal.Footer>
      </Modal>
    
      {/* modal unlock     */}
      <Modal show={shows} onHide={handleCloses} contentClassName="modal-received">
        <Modal.Header style={{ textDecoration: "none", border: 'none' }} closeButton>
          <div className="d-flex align-items-center ">
          <img src={leftarrow} onClick={back} alt="leftarrow" width='19.5px' height='15.5px' className="mb-0 me-3"style={{cursor:'pointer',marginLeft:'-5%'}}></img>
            <h5 className="mb-0"style={{fontWeight:'500',fontSize:'24px',lineHeight:'36px',color:'#232323'}}>New Transfer From Jane Doe </h5>
          </div>
        </Modal.Header>
        <Modal.Body>
        <div className="d-flex justify-content-start mb-3 ps-4"> {/* New div for label */}
                <label htmlFor="user-name" style={{ fontWeight: '500' ,fontSize:'16px',lineHeight:'24px',color:'#000000' }}>Received amount</label>
              </div>
              <div className="d-flex justify-content-start  ps-4 ">
              <div className="input-container mb-5" style={{  position: 'relative', width: '100%',maxWidth:'469px', display: 'flex' }}>
                <input type="text" className="depsoit-input" placeholder="1000" value='1000' style={{  width: 'calc(100% - 100px)', height: '49px', padding: '8px', borderRadius:'10px 0px 0px 10px', border: '1px solid #DCDCDC', borderColor: '#DCDCDC' }} />
                <select className="deposit-select"
                  style={{ width:'100px',fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#FFFFFF', height: '49px',borderRadius:'0px 10px 10px 0px', padding: '8px', border: 'none', backgroundColor: '#02CD8D', color: 'white', position: 'relative',caretWidth: "100px",caretHeight: "100px",caretColor:'black'}}
                >
                  <option value="">UGX</option>
                </select>
              </div>
              </div>  
          <div className="d-flex justify-content-start mb-3 ps-4">
            <label htmlFor="user-name" style={{ fontWeight: "500",fontSize:'16px',lineHeight:'24px',color:'#494949' }}>
              Source ID
            </label>
          </div>
          <div className="d-flex justify-content-start mb-3 ps-4">
            <input className="sourceid"
              type="text"
              placeholder="Email or Phone"
              style={{
                width: "100%",
                maxWidth:'469px',
                height: "49px",
                padding: "8px",
                borderRadius: "10px",
                border: "1px solid #DCDCDC",
              }}
            />
          </div>
          <div className="d-flex justify-content-start mb-3 ps-4">
            <label htmlFor="user-name" style={{ fontWeight: "500",fontSize:'16px',lineHeight:'24px',color:'#494949' }}>
            Unlock Key
            </label>
          </div>
          <div className="d-flex justify-content-start mb-3 ps-4">
          <div className="input-container" style={{ position: 'relative', width: '100%', maxWidth: '469px' }}>
            <input
              className="sourcereasons"
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter value"
              style={{
                width: "100%",
                maxWidth:'469px',
                height: "49px",
                padding: "8px",
                borderRadius: "10px",
                border: "1px solid #DCDCDC",
              }}
            />
            <p className="toggle-passwords" onClick={togglePasswordVisibility}>
                                    {showPassword ? (
                                        <img src={eye} className='eyeslash' alt="Show Password" />
                                    ) : (
                                        
                                        <img src={eyeslash}  className='eyeslash' alt="Hide Password" />
                                    )}
                                </p>
          </div>
          </div>

        </Modal.Body>
        <Modal.Footer className="mx-3" style={{ textDecoration: "none", border: 'none' }}>
          <button className="modalclose" onClick={handleCloses}>
            Cancel
          </button>
          <button className="modalrequest" onClick={handleCloses}>
            Unlock
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default IncomingPage;