import React, { useEffect, useState,useCallback } from 'react';
import Sidebar from './sidebar';
import './dashboard.css';
import Navbars from './navbar';
import { useNavigate } from 'react-router-dom';
import userCircle from './images/user-circle.png';
import pencil from './images/edit-01.png';
import trash from './images/delete-01.png';
import location from './images/add_address-01.png';
import emergency1 from './images/emergency_call-01.png';
import camera from './images/camera-01.png';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import plus from './images/plus (1).png';
import home from './images/address-icons/home-01.png';
import friends from './images/address-icons/friends-01.png';
import office from './images/address-icons/office-01.png';
import other from './images/address-icons/other-01.png';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserLanguage, fetchUserProfile } from './api/redux/userProfiles/actions';
import { useTranslation } from 'react-i18next';
import { extractLoginUser, handleApiError,} from './api/common/commonapi';
import { getemergencyapi,publicDomain,deleteemergencyapi ,getuserdeleteapi,getuserlocationapi} from './api/url';
import WithToast from './api/common/withtoast';
import axiosInstance from './api/common/axiosinstand';
const getemergencyapiurl = getemergencyapi();
const publicDomainurl = publicDomain();
const deleteemergencyapiurl = deleteemergencyapi();
const deletelocationurl = getuserdeleteapi();
const getuserlocationapiurl = getuserlocationapi();
const ContentSection = ({showErrorToast}) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [emerget,setEmerget] = useState([]);
    const [addressline_1,setAddressline_1] = useState([]);
    const [isLoading4,setIsLoading4] = useState(false);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const userProfile = useSelector((state) => state.userProfile.userProfile);
    const userLanguage = useSelector((state) => state.userProfile.languageData);
    const login_user = extractLoginUser();
    useEffect(() => {
        dispatch(fetchUserProfile(showErrorToast)).finally(() => setIsInitialLoading(false));
        dispatch(fetchUserLanguage(showErrorToast))
    }, [dispatch]);
    const emergencyget = useCallback(async () => {
        try {
          const response = await axiosInstance.get(getemergencyapiurl);
          if (response.status === 200) {
            const emergency = response.data.data;
            setEmerget(emergency);
          }
        } catch (error) {
          handleApiError(error, null, null, true, showErrorToast);
        }
      }, [showErrorToast]); 
    
      const locationget = useCallback(async () => {
        try {
          const response = await axiosInstance.get(getuserlocationapiurl);
          if (response.status === 200) {
            const userLocation = response.data.data;
            setAddressline_1(userLocation);
          }
        } catch (error) {
          handleApiError(error, null, null, true, showErrorToast);
        }
      }, [showErrorToast]); 
    
      useEffect(() => {
        emergencyget();
        locationget();
      }, [emergencyget, locationget]); 
    const deleteemergency = async(emergency) => {
          const data = {
          id : emergency.id
          }
          try{
          const result = await Swal.fire({
            title: 'Delete Emergency Contact?',
            text: 'Are you sure you want to delete this Emergency Contact',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          });
          if(result.isConfirmed){
            setIsLoading4(true);
            const response = await axiosInstance.post(deleteemergencyapiurl,data);
            if(response.status === 200){
                await emergencyget();
                setIsLoading4(false);
            }
          }
        }catch(error){
            handleApiError(error,setIsLoading4,null,true,showErrorToast);
        }
    }
    const editemergency = (emergency) => {
       const id = emergency.id
       navigate(`/u/${login_user}/update/${id}/emergencyinfo`);
    }
    function mapGenderToLabel(gender) {
        switch (gender) {
            case 'M':
                return 'Male';
            case 'F':
                return 'Female';
            case 'O':
                return 'Other';
            default:
                return 'Gender';
        }
    }
    function formatDOB(dateOfBirth) {
        if (!dateOfBirth) return 'Birthday';

        const dob = new Date(dateOfBirth);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return dob.toLocaleDateString(undefined, options);
    }
    function calculateAge(dateOfBirth) {
        if (!dateOfBirth) return null;

        const dob = new Date(dateOfBirth);
        const today = new Date();

        let age = today.getFullYear() - dob.getFullYear();
        const monthDiff = today.getMonth() - dob.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
            age--;
        }

        return age;
    }
    const age = calculateAge(userProfile.dob);
    const deleteaddress = async (address) => {
        const data = {
            id: address.id
        };
    
        try {
            const result = await Swal.fire({
                title: 'Delete Address?',
                text: 'Are you sure you want to delete this address?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
            });
    
            if (result.isConfirmed) {
                setIsLoading4(true);
                const response = await axiosInstance.post(deletelocationurl, data);
                if (response.status === 200) {
                    await locationget();
                    setIsLoading4(false);
                }
            }
        } catch (error) {
            handleApiError(error, setIsLoading4, null, true, showErrorToast);
        }
    };
    

    const navigate = useNavigate();
    const profilechange = () => {
        const userPofilevisiblity = userProfile.profile_image_visibility
        navigate(`/u/${login_user}/profilechange`,{ state: { userPofilevisiblity } });
    }
    const namechange = () => {
        const userName = userProfile.name;
        navigate(`/u/${login_user}/namechange`, { state: { userName } });
    }
    const birthdaychange = () => {
        const userDate = userProfile.dob
        const userBday = userProfile.dob_visibility
        navigate(`/u/${login_user}/datechange`, { state: { userDate, userBday } });
    }
    const genderchange = () => {
        const userGender = userProfile.gender
        const userGendervisiblity = userProfile.gender_visibility
        navigate(`/u/${login_user}/genderchange`, { state: { userGender, userGendervisiblity } });
    }
    const languagechange = () => {
        const userLanguages = userLanguage
        navigate(`/u/${login_user}/languagechange`, { state: { userLanguages } });
    }
    const emailchange = () => {
        const userEmail = userProfile.email;
        navigate(`/u/${login_user}/emailchange`, { state: { userEmail } });
    }
    const mobilechange = () => {
        const userMobile = userProfile.mobile;
        const usermcc_id = userProfile.mcc_id;
        const usercountry_code = userProfile.mcc_countrycode;
        navigate(`/u/${login_user}/mobilenumberchange`, { state: { userMobile } }, { state: { usermcc_id } }, { state: { usercountry_code } });
    }
    const updatelocation = (address) => {
        const id = address.id
        // dispatch(setAddress(address));
        // setAddress(address);
        navigate(`/u/${login_user}/update/${id}/address`);
    }
    const addaddress = () => {
        navigate(`/u/${login_user}/addaddress`);
    }
    const emerncyinfo = () => {
        navigate(`/u/${login_user}/emergencyinfo`);
    }
    const getLocationTypeImage = (locationType) => {
        switch (locationType) {
            case 'Home':
                return home;
            case 'Friends':
                return friends;
            case 'Office':
                return office;
            case 'Other':
                return other;
            default:
                return other;
        }
    };
    
    return (
        <>
        {isLoading4 && (
                <>   
                    <div className="loaderover">
                    <div className="loader ">
                    </div>
                    </div>
                </>
            )} 
            <Navbars />
            {/* <script async defer src=''></script> */}
            <div className="container-fluid home"> 
                <ToastContainer />
                <div className=" documentrow">
                    {/* Sidebar Menu (First Column) */}
                    <div className="col-12  col-md-3 col-lg-3 documentside">
                        <Sidebar />
                    </div>

                    {/* Main Content (Second Column) */}
                    <div className="col-12  col-md-9 col-lg-9 documentmain ">
                        <div className="main-content d-flex flex-column align-items-center ">
                            <div className="header-profile-user border-dark user mx-auto " style={{ width: '80px', height: '80px', borderRadius: '50%', overflow: 'hidden' }}>
                                <img
                                    id="login_img_for_profile"
                                    src={userProfile?.image}
                                    alt="Centered Ima"
                                    loading="lazy"
                                    className=" homeprofile"
                                    style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
                                />
                            </div>
                            {/* Text Below Image */}
                            <h5 className="mt-3 homeheadingh5 text-center">
                                {t('Welcome,')} <b className='homeheadingh5'>{t(userProfile?.name)}</b>
                            </h5>

                            {/* Horizontal Line */}
                            <hr className='personhr' />
                            {/* First Centered Heading */}
                            <h5 className="text-center personalinformationh5 mt-3">
                                {t("General Profile")}
                            </h5>
                            {/* Text Below First Heading */}
                            <p className="personalinfop text-center">
                                {t(" Manage your General Profile, including Personal Details, Services, Contact info and Addresses")}
                            </p>
                            <div className="box border-rounded  p-4 mt-3" style={{ width: '100%', minHeight: '432px', backgroundColor: 'white', border: '1px solid #ABA7A7', borderRadius: '18px' }}>
                                {/* Box Content */}
                                <h5 className='basicinfoh5'>{t("Personal Details")}</h5>


                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <p className='basicinfoprofile'>{t("Profile Picture")}</p>
                                    {isInitialLoading ? '' :
                                    <div className="position-relative rounded-circle overflow-hidden" onClick={profilechange} style={{ width: '64px', height: '64px',cursor:'pointer' }}>
                                        <div className="overlay" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'absolute', top: '60%', width: '100%', height: '40%', zIndex: '0' }}></div>
                                        <img src={userProfile?.image} className='img-fluid mx-auto d-block' alt='Profile' style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }} />
                                        <img src={camera} alt='camera' onClick={profilechange} style={{ cursor: 'pointer', position: 'absolute', bottom: '5px', right: '22px', width: '14.55px', height: '13.09px', zIndex: '0' }} />
                                    </div>
                                   }
                                </div>
                                <hr className='mt-4' style={{ border: '1px solid #ABA7A7', marginBottom: '25px' }} />
                                <div className="centered-column-item">
                                    <div className="d-flex dashname align-items-center  ">
                                        <div className='state'>
                                            <div className='col-md-4 mb-1 ' style={{ flexBasis: '30%' }} >
                                                <p className='text-left basicinfoprofile'>{t('Name')}</p>
                                            </div>
                                            {userProfile.name ? (
                                                <div className='col-md-4 mb-1 text-start ' style={{ flexBasis: '30%' }}>
                                                    <p className='basicprofilename'>{userProfile?.name}</p>
                                                </div>
                                            ) : (
                                                <div className='col-md-4 mb-1 text-start ' style={{ flexBasis: '30%' }}>
                                                    <p className='basicprofilename red'>Data not provided</p>
                                                </div>
                                            )}
                                        </div>
                                        {userProfile.name  ? (
                                            <div className='col-md-4 mb-1  d-flex justify-content-end dashnamechange' style={{ flexBasis: '40%', paddingRight: '20px' }}>
                                                {isInitialLoading ? '' :<img onClick={namechange} style={{ cursor: 'pointer', width: '16px', height: '16px' }} src={pencil} alt="edit"></img>}
                                                
                                            </div>

                                        ) : (
                                            <div className='col-md-4 mb-1  d-flex justify-content-end dashnamechange' style={{ flexBasis: '40%', paddingRight: '20px' }}>
                                                {isInitialLoading ? '' : <button className='dataaddbutton'><img onClick={namechange} style={{ cursor: 'pointer', width: '14px', height: 'auto', marginRight: '7px' }} src={plus} alt="edit"></img>Add</button>}
                                                {/* <button className='dataaddbutton'><img onClick={namechange} style={{ cursor: 'pointer', width: '14px', height: 'auto', marginRight: '7px' }} src={plus} alt="edit"></img>Add</button> */}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <hr className='mt-0' style={{ border: '1px solid #ABA7A7', marginBottom: '25px' }} />
                                <div className="centered-column-item">
                                    <div className="d-flex dashname align-items-center ">
                                        <div className='state'>
                                            <div className='col-md-4  mb-1' style={{ flexBasis: '30%' }}>
                                                <p className='text-left basicinfoprofile'>{t("Date of Birth")}</p>
                                            </div>
                                            {userProfile.dob ? (
                                                <div className='col-md-4 mb-1 text-start' style={{ flexBasis: '30%' }}>
                                                    <p className='basicprofilename'>{formatDOB(userProfile?.dob)}</p>
                                                </div>
                                            ) : (
                                                <div className='col-md-4 mb-1 text-start ' style={{ flexBasis: '30%' }}>
                                                    <p className='basicprofilename red'>Data not provided</p>
                                                </div>
                                            )}
                                        </div>
                                        {userProfile?.dob ? (
                                            <div className='col-md-4 dashnamechange text-end ' style={{ flexBasis: '40%', paddingRight: '20px' }}>
                                               {isInitialLoading ? '' : <img onClick={birthdaychange} style={{ cursor: 'pointer', width: '16px', height: '16px' }} src={pencil} alt="edit"></img>}
                                            </div>
                                        ) : (
                                            <div className='col-md-4 mb-1  d-flex justify-content-end dashnamechange' style={{ flexBasis: '40%', }}>
                                               {isInitialLoading ? '' : <button onClick={birthdaychange} className='dataaddbutton'><img onClick={birthdaychange} style={{ cursor: 'pointer', width: '14px', height: 'auto', marginRight: '7px' }} src={plus} alt="edit"></img>Add</button>}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {userProfile?.dob && (
                                    <div className="centered-column-item">
                                        <div className="d-flex dashname align-items-center ">
                                            <div className='state'>
                                                <div className='col-md-4  mb-1' style={{ flexBasis: '30%' }}>
                                                    <p className='text-left basicinfoprofile'>Age</p>
                                                </div>
                                                <div className='col-md-4 mb-1 text-start' style={{ flexBasis: '30%' }}>
                                                    <p className='basicprofilename'>{age} Years old</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <hr className='mt-0' style={{ border: '1px solid #ABA7A7', marginBottom: '25px' }} />
                                <div className="centered-column-item">
                                    <div className="d-flex dashname  align-items-center">
                                        <div className='state'>
                                            <div className='col-md-4 mb-1' style={{ flexBasis: '30%' }}>
                                                <p className='text-start basicinfoprofile'>{t("Gender")}</p>
                                            </div>
                                            {userProfile?.gender ? (
                                                <div className='col-md-4 mb-1 text-start ' style={{ flexBasis: '30%' }}>
                                                    <p className='basicprofilename'>{mapGenderToLabel(userProfile?.gender)}</p>
                                                </div>
                                            ) : (
                                                <div className='col-md-4 mb-1 text-start ' style={{ flexBasis: '30%' }}>
                                                    <p className='basicprofilename red'>Data not provided</p>
                                                </div>
                                            )}
                                        </div>
                                        {userProfile?.gender ? (
                                            <div className='col-md-4 mb-1 text-end dashnamechange' style={{ flexBasis: '40%', paddingRight: '20px' }}>
                                               {isInitialLoading ? '' : <img onClick={genderchange} style={{ cursor: 'pointer', width: '16px', height: '16px' }} src={pencil} alt="edit"></img> }
                                            </div>
                                        ) : (
                                            <div className='col-md-4 mb-1  d-flex justify-content-end dashnamechange' style={{ flexBasis: '40%', }}>
                                               {isInitialLoading ? '' : <button onClick={genderchange} className='dataaddbutton'><img onClick={genderchange} style={{ cursor: 'pointer', width: '14px', height: 'auto', marginRight: '7px' }} src={plus} alt="edit"></img>Add</button> }
                                            </div>
                                        )}

                                    </div>
                                </div>
                                <hr className='mt-0' style={{ border: '1px solid #ABA7A7', marginBottom: '25px' }} />
                                <div className="centered-column-item">
                                    <div className="d-flex dashname align-items-center">
                                        <div className='state'>
                                            <div className='col-md-4 mb-1 ' style={{ flexBasis: '30%' }}>
                                                <p className='text-start basicinfoprofile'>{t('Language')}</p>
                                            </div>
                                            {Array.isArray(userLanguage.data) && userLanguage.data.length > 0 ? (
                                                <div className='col-md-4 mb-1 text-start ' style={{ flexBasis: '30%' }}>
                                                        <p className='basicprofilename'>{userLanguage.data.find(lang => lang.preferred === 1)?.name}</p>
                                                </div>
                                            ) : (
                                                <div className='col-md-4 mb-1 text-start ' style={{ flexBasis: '30%' }}>
                                                    <p className='basicprofilename red'>Data not provided</p>
                                                </div>
                                            )}
                                        </div>
                                        {Array.isArray(userLanguage.data) && userLanguage.data.length > 0 ? (
                                            <div className='col-md-4 mb-1 dashnamechange text-end' style={{ flexBasis: '40%', paddingRight: '20px' }}>
                                               {isInitialLoading ? '' : <img onClick={languagechange} style={{ cursor: 'pointer', width: '16px', height: '16px' }} src={pencil} alt="edit"></img> }
                                            </div>
                                        ) : (
                                            <div className='col-md-4 mb-1  d-flex justify-content-end dashnamechange' style={{ flexBasis: '40%', }}>
                                               {isInitialLoading ? '' : <button onClick={languagechange} className='dataaddbutton'><img onClick={languagechange} style={{ cursor: 'pointer', width: '14px', height: 'auto', marginRight: '7px' }} src={plus} alt="edit"></img>Add</button> }
                                            </div>
                                        )}

                                    </div>
                                </div>
                                {Array.isArray(userLanguage.data) && userLanguage.data.length > 0 && (
                                    <div className="centered-column-item">
                                        <div className="d-flex dashname align-items-center">
                                            <div className='state'>
                                                <div className='col-md-4 mb-1 ' style={{ flexBasis: '30%' }}>
                                                    <p className='text-start  basicinfoprofile'>Other Languages</p>
                                                </div>
                                                <div className='col-md-4 mb-1 text-start ' style={{ flexBasis: '30%' }}>
                                                    <p className='basicprofilename'> {userLanguage.data
                                                        .filter(lang => lang.preferred === 0)
                                                        .map((language) => (language.name)).join(', ')}</p>
                                                </div>
                                            </div>
                                            <div className='col-md-4 mb-1 dashnamechange text-end' style={{ flexBasis: '40%', paddingRight: '20px' }}>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                        <div className="box border-rounded p-4 mt-3" style={{ width: '100%', minHeight: '100px', backgroundColor: 'white', border: '1px solid #ABA7A7', borderRadius: '18px' }}>
                            <h5 className='mb-4 mt-4 basicinfoh5'>Contact info</h5>
                            <div className="centered-column-item">
                                <div className="d-flex dashname align-items-center ">
                                    <div className='state'>
                                        <div className='col-md-4 mb-1 ' style={{ flexBasis: '30%', flexShrink: 0, flexGrow: 1 }}>
                                            <p className='text-start basicinfoprofile'>Email</p>
                                        </div>
                                        <div className='col-md-4 mb-1 text-start ' style={{ flexBasis: '30%', flexShrink: 0, flexGrow: 1, }}>
                                            <p className='basicprofilename'>{userProfile?.email}</p>
                                            {/* <p className='basicprofilename'>{userProfile?.email}</p> */}
                                        </div>
                                    </div>
                                    <div className='col-md-4 mb-1 dashnamechange text-end' style={{ flexBasis: '40%', flexShrink: 1, flexGrow: 1, paddingRight: '10px' }}>
                                    {isInitialLoading ? '' : <button className='emailbutton' onClick={emailchange} style={{ width: '140px', height: "40px", fontWeight: '400', backgroundColor: 'white', border: '1px solid grey', borderRadius: '30px', }}>
                                            edit email<img style={{ width: '16px', height: '16px', marginLeft: '8px' }} src={pencil} alt="edit" />
                                        </button> }
                                        <img onClick={emailchange} className='pencil-icon text-end' style={{ display: 'none' }} src={pencil} width='16px' height='16px' alt="edit" /> 
                                    </div>
                                </div>
                            </div>
                            <hr className='mt-0' style={{ border: '1px solid #ABA7A7', marginBottom: '25px' }} />
                            <div className="centered-column-item">
                                <div className="d-flex dashname align-items-center">
                                    <div className='state'>
                                        <div className='col-md-4 mb-1 ' style={{ flexBasis: '30%' }}>
                                            <p className='text-start basicinfoprofile'>Phone</p>
                                        </div>
                                        <div className='col-md-4 mb-1 text-start ' style={{ flexBasis: '30%' }}>
                                            <p className='basicprofilename'>{`+${userProfile?.code} ${userProfile?.mobile}`}</p>
                                            {/* <p className='basicprofilename'>{userProfile?.mobile}</p> */}
                                        </div>
                                    </div>
                                    <div className='col-md-4 mb-1 dashnamechange text-end' style={{ flexBasis: '40%', paddingRight: '10px' }}>
                                    {isInitialLoading ? '' :
                                        <button className='emailbutton' onClick={mobilechange} style={{ width: '140px', height: "40px", fontWeight: '400', backgroundColor: 'white', border: '1px solid grey', borderRadius: '30px', }}>
                                            edit mobile<img style={{ width: '16px', height: '16px', marginLeft: '8px' }} src={pencil} alt="edit" />
                                        </button> }
                                        <img onClick={mobilechange} className='pencil-icon text-end' style={{ display: 'none' }} src={pencil} width='16px' height='16px' alt="edit" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box border-rounded p-4 mt-3" style={{ width: '100%', minHeight: '40px', backgroundColor: 'white', border: '1px solid #ABA7A7', borderRadius: '18px' }}>
                        <div className="d-flex justify-content-between addressdiv align-items-center">
                            {Array.isArray(addressline_1) && addressline_1.length > 0 && (
                                <h5 className='mb-4 mt-0 basicinfoh5' style={{ flex: 1 }}>Addresses</h5>
                            )}
                        </div>
                        {Array.isArray(addressline_1) && addressline_1.length > 0 ? (
                            addressline_1.map((address, index) => (
                                <div key={index}>
                                     <div className="centered-column-item">
                                            <div className="d-flex dashname align-items-center">
                                                <div className='state'>
                                                    <div className='col-md-4 mb-1' style={{ flexBasis: '30%' }}>
                                                        <p className='text-start basicinfoprofile'>Address  {index+1}</p>
                                                    </div>
                                                    <div className='col-md-4 mb-1 text-start' style={{ flexBasis: '30%',overflow:'hidden' }}>
                                                        <div className='d-flex align-items-baseline'>
                                                            <div className='me-2'>
                                                            <img className='locationimagechage' src={getLocationTypeImage(address.location_type)} alt={address.location_type} />
                                                            </div>
                                                            <div style={{overflow:'hidden'}}>
                                                            <div className='d-flex align-items-center' style={{flexWrap:'wrap'}}>   
                                                            <p className='basicprofilename basicprofilename3 me-2'>{`${address.location_type}`}</p>
                                                            {address.is_primary === 1 && (<button className='defaultaddresslabel'>Default Address</button>)}
                                                            </div>   
                                                            <p className='basicprofilename basicprofilename4'>{`${address.block_number.replace(/,([^,]*)$/, "$1")},${address.address_line_2}${address.city.name ? ` - ${address.city.name}`:''}`}</p>
                                                            <p className='basicprofilename basicprofilename4'>{`${address.state.name}${address.country.name ? `,${address.country.name}` : ''}${address.postal_code ? ` - ${address.postal_code}` : ''}`}</p>
                                                            <p className='basicprofilename basicprofilename4'>{`+${address.mcc.code} ${address.mobile}`}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-4 mb-1 dashnamechange text-end' style={{ flexBasis: '40%', paddingRight: '20px' }}>
                                                    <img onClick={() => deleteaddress(address)} className='me-3 marrig' style={{ cursor: 'pointer', width: '16px', height: '16px' }} src={trash} alt="delete" />
                                                    <img onClick={() => updatelocation(address)} style={{ cursor: 'pointer', width: '16px', height: '16px' }} src={pencil} alt="edit" />
                                                </div>
                                            </div>
                                        </div>
                                        <hr className='mt-0' style={{ border: '1px solid #ABA7A7', marginBottom: '25px' }} />
                                </div>
                            ))
                        ) : (
                            <div className="centered-column-item">
                        <div className="d-flex  align-items-center ">
                            <div className='col-md-4 mt-0 ' style={{ flexBasis: '30%', flexShrink: 0, flexGrow: 1 }}>
                            <h5 className=' mt-0 basicinfoh5' style={{ flex: 1 }}>Addresses</h5>
                            </div>
                            <div className='col-md-4 mb-1 text-start ' style={{ flexBasis: '30%' }}>
                            <p className='basicprofilename  red'>Data not provided</p>
                            </div>
                            <div className='col-md-4 mb-1 text-end' style={{ flexBasis: '40%', flexShrink: 1, flexGrow: 1, paddingRight: '10px' }}>
                            {isInitialLoading ? '' :<button onClick={addaddress} style={{ width: '100%', maxWidth: '250px', height: '40px', borderRadius: '20px', border: '1px solid #CFCDCD', backgroundColor: 'white', fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#323232' }}><img src={location} className='me-2' alt='location' width='17px' height='17px' /> Add address</button>}
                            </div>
                        </div>
                        </div>
                        )}
                         {Array.isArray(addressline_1) && addressline_1.length > 0 && (
                        <div className='col addressbut mt-3'>
                        {isInitialLoading ? '' :<button onClick={addaddress} style={{ width: '100%', maxWidth: '250px', height: '40px', borderRadius: '20px', border: '1px solid #CFCDCD', backgroundColor: 'white', fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#323232' }}><img src={location} className='me-2' alt='location' width='17px' height='17px' /> Add address</button>}
                        </div>
                        )}
                       </div>

                        <div className="box border-rounded p-4 mt-3" style={{ width: '100%', minHeight: '40px', backgroundColor: 'white', border: '1px solid #ABA7A7', borderRadius: '18px' }}>
                        <div className="d-flex justify-content-between addressdiv align-items-center">
                        {emerget.length > 0 && <h5 className='mb-4 mt-0 basicinfoh5' style={{ flex: 1 }}>Emergency Contacts</h5>}
                                </div>   
                        {emerget.length > 0 ? (   
                        emerget.map((emergency, index) => (
                        <>                              
                            <div className="centered-column-item" key={index}>
                                <div className="d-flex dashname align-items-center">
                                <div className='state'>
                                    <div className='col-md-4 mt-0' style={{ flexBasis: '30%', flexShrink: 0, flexGrow: 1 }}>
                                    <div className="header-profile-user border-dark user  " style={{ width: '60px', height: '60px', borderRadius: '50%', overflow: 'hidden' }}>
                                    <img id="login_img_for_profile" src={emergency.image ?`${publicDomainurl}/core/${emergency.image}` : userCircle } alt="Profile" loading="lazy"className=" homeprofile " style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}/></div>
                                    </div>
                                    <div className='col-md-4 mb-1 text-start' style={{ flexBasis: '30%', flexShrink: 0, flexGrow: 1,overflow:'hidden' }}>
                                    <p className='basicprofilename'>{emergency.name}</p>
                                    <p className='basicprofilename'>{emergency.email}</p>
                                    <p className='basicprofilename'>{`+${emergency.code} ${emergency.mobile}`}</p>
                                    </div>
                                </div>
                                <div className='col-md-4 mb-1 dashnamechange text-end' style={{ flexBasis: '40%', paddingRight: '20px' }}>
                                {isInitialLoading ? '' :<img onClick={() => deleteemergency(emergency)} className='me-3 marrig' style={{ cursor: 'pointer'}} src={trash} alt="delete" />}
                                {isInitialLoading ? '' :<img onClick={() => editemergency(emergency)} className='marrig' style={{ cursor: 'pointer',}} src={pencil} alt="edit" />}
                                    </div>
                                </div>
                            </div>
                            <hr className='mt-0' style={{ border: '1px solid #ABA7A7',marginBottom:'25px' }} />
                        </>
                      ))
                    ):( 
                        <div className="centered-column-item">
                        <div className="d-flex  align-items-center ">
                            <div className='col-md-4 mt-0 ' style={{ flexBasis: '30%', flexShrink: 0, flexGrow: 1 }}>
                            <h5 className=' mt-0 basicinfoh5' style={{ flex: 1 }}>Emergency Contacts</h5>
                            </div>
                            <div className='col-md-4 mb-1 text-start ' style={{ flexBasis: '30%' }}>
                            <p className='basicprofilename  red'>Data not provided</p>
                            </div>
                            <div className='col-md-4 mb-1 text-end' style={{ flexBasis: '40%', flexShrink: 1, flexGrow: 1, paddingRight: '10px' }}>
                            {isInitialLoading ? '' :<button onClick={emerncyinfo} style={{ width: '100%', maxWidth: '250px', height: '40px', borderRadius: '20px', border: '1px solid #CFCDCD', backgroundColor: 'white', fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#323232' }}><img src={emergency1} className='me-2' alt='location' width='16px' height='18px' />Add Emergency</button>}
                            </div>
                        </div>
                    </div>
                    )}
                    {emerget.length > 0 && (
                    <div className='col addressbut mt-3'>
                    {isInitialLoading ? '' :<button onClick={emerncyinfo} style={{ width: '100%', maxWidth: '250px', height: '40px', borderRadius: '20px', border: '1px solid #CFCDCD', backgroundColor: 'white', fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#323232' }}><img src={emergency1} className='me-2' alt='location' width='16px' height='18px' />Add Emergency</button>}
                    </div>
                    )}
                    </div>
                    </div>
                </div>
            </div>

        </>

    );
};

export default WithToast(ContentSection);