import React, { useState,useEffect,useRef } from "react";
import Navbars from "../../../navbar";
import { APIProvider, Map, Marker, useMap, } from '@vis.gl/react-google-maps';
import axios from 'axios';
import back from '../../../images/arrow-left-01.png';
import locationcurrent from '../../../images/address-icons/Group 1488.png';
import { GoogleMapurl,getuserlocationapi} from "../../../api/url";
import { useIpContext } from "../../../api/common/ipContext";
import PlaceAutocomplete from "../autocomplete";
import { useNavigate } from "react-router-dom";
import { extractLoginUser ,handleApiError} from "../../../api/common/commonapi";
import Updatesearchlocationstore from "./updatesearchlocationstore";
import Updatesearchlocationconfirm from "./updatesearchlocationconfirm";
import Updatesearchedlocation from "./updatesearchedlocation";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../api/common/axiosinstand";
import WithToast from "../../../api/common/withtoast";
const googlemapkey = GoogleMapurl();
const getuserlocationapiurl = getuserlocationapi();
const MapHandler = ({ place, marker }) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !place || !marker) return;

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry.viewport);
    }

    marker.setPosition(place.geometry?.location);
  }, [map, place, marker]);

  return null;
};
function Updateaddress({showErrorToast}){
  const navigate = useNavigate();
  const login_user = extractLoginUser();
  const [addressline_1, setAddressline_1] = useState([]);
    const {dynamic} = useParams();
    useEffect(() => {
      const getuseraddress = async () => {
        try {
          const response = await axiosInstance.get(getuserlocationapiurl);
          if (response.status === 200) {
            const emergency = response.data.data;
            const matchingEntry = emergency.find(entry => entry.id.toString() === dynamic.toString());
            if (matchingEntry) {
              setAddressline_1(matchingEntry);
            } else {
              navigate(`/u/${login_user}/profile`);
            }
          }
        } catch (error) {
          handleApiError(error, null, null, true, showErrorToast);
        }
      };
  
      getuseraddress();
    }, [dynamic, login_user, navigate, showErrorToast]);

    // const address = useSelector(state => state.usercommon.address);
    const [addressComponents, setAddressComponents] = useState({
      street: "",
      country: "",
      city: "",
      zipcode: "",
      state: "",
      latitude: "",
      longitude: "",
      code:""
    });
    useEffect(() => {
      // Update addressComponents whenever addressline_1 changes
      setAddressComponents({
        street: addressline_1.address_line_1 ? addressline_1.address_line_1 : '',
        country: addressline_1.country && addressline_1.country.name ? addressline_1.country.name : '',
        city: addressline_1.city && addressline_1.city.name ? addressline_1.city.name : '',
        zipcode: addressline_1.postal_code ? addressline_1.postal_code : '',
        state: addressline_1.state && addressline_1.state.name ? addressline_1.state.name : '',
        latitude: addressline_1.latitude ? addressline_1.latitude : '',
        longitude: addressline_1.longitude ? addressline_1.longitude : '',
        code:addressline_1.country && addressline_1.country.country_code ? addressline_1.country.country_code : '' 
      });
      setFormValues({
        Name: addressline_1.first_name ? addressline_1.first_name : '',
        mobileNumber: addressline_1.mobile ? addressline_1.mobile : '',
        countrycode: '',
        countryoptions: [],
        mcc_id: addressline_1.mcc && addressline_1.mcc.id ? addressline_1.mcc.id : '' ,
        id: addressline_1.id,
        dialcode: '',
        code: addressline_1.mcc && addressline_1.mcc.country_code ? addressline_1.mcc.country_code : '',
        houseNumber: addressline_1.block_number ? addressline_1.block_number : '',
        apartment: addressline_1.apartment_number ? addressline_1.apartment_number : '',
        addressLine2: addressline_1.address_line_2 ? addressline_1.address_line_2 : '',
        location_type: addressline_1.location_type ? addressline_1.location_type : '',
        addressType: addressline_1.location_type ? addressline_1.location_type : '',
        otherType: '',
        default: addressline_1.is_primary ? true : false,
        city: '',
        postcode: '',
        fetchcondition:false
      });
    }, [addressline_1]);
    const [formValues, setFormValues] = useState({
        Name:addressline_1.first_name ? addressline_1.first_name : '',
        mobileNumber:addressline_1.mobile ? addressline_1.mobile : '',
        countrycode:'',
        countryoptions:[],
        mcc_id:addressline_1.mcc_id,
        id:addressline_1.id,
        // isvalid:false,
        dialcode:'',
        code: addressline_1.mcc && addressline_1.mcc.country_code ? addressline_1.mcc.country_code : '',
        houseNumber: addressline_1.block_number ? addressline_1.block_number : '',
        apartment: addressline_1.apartment_number ? addressline_1.apartment_number : '',
        addressLine2: addressline_1.address_line_2 ? addressline_1.address_line_2 : '',
        location_type: addressline_1.location_type ? addressline_1.location_type : '',
        addressType: '',
        otherType: '',
        default:addressline_1.is_primary ? true : false, 
        city:'',
        postcode:'',
      });
      const { getUserIpCountryCode,latitude,longitude} = useIpContext();
    const [locationState, setLocationState] = useState({ searchLocation: false, searchedLocation: false,searchedLocationconfirm :false,searchedLocationstore:true });
  const [cameraProps, setCameraProps] = useState({center: { lat:123.456, lng: 789.012 },zoom: 15});
  const [loading, setLoading] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [error,setError] = useState('');
  const markerRef = useRef(null);
  const [markerPosition, setMarkerPosition] = useState({lat:parseFloat(addressline_1.latitude),lng:parseFloat(addressline_1.longitude)});
  useEffect(() => {
    if (addressline_1.latitude && addressline_1.longitude) {
      setCameraProps({
        center: { lat: parseFloat(addressline_1.latitude), lng: parseFloat(addressline_1.longitude) },
        zoom: 15
      });
      setMarkerPosition({lat:parseFloat(addressline_1.latitude),lng:parseFloat(addressline_1.longitude)})
    }
  }, [addressline_1]);
  useEffect(() => {
    if(!formValues.fetchcondition){
    if (latitude && longitude) {
      setCameraProps({
        center: { lat: latitude, lng: longitude },
        zoom: 10
      });
    }
  }
  }, [latitude, longitude,formValues.fetchcondition]);
  const fetchLocation = async () => {
    setLoading(true);
    try {
      await getUserIpCountryCode();
    } catch (error) {
      console.error("Error fetching user IP location:", error);
    } finally {
      setLoading(false);
    }
  };

  

  const handleMarkerClick = (event) => {
    if (event) {
      const clickedLatLng = {
        lat: event.detail.latLng.lat,
        lng: event.detail.latLng.lng
      };
      setCameraProps({ center: clickedLatLng, zoom: 15 });
      setMarkerPosition(clickedLatLng);
      fetchAddress(clickedLatLng);
    }
  };

  const fetchAddress = async (latLng) => {
    try {
      const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
          latlng: `${latLng.lat},${latLng.lng}`,
          key: googlemapkey
        }
      });

      if (response.data.results.length > 0) {
        const addressData = response.data.results[0];
        const components = addressData.address_components;

        let street = "";
        let country = "";
        let city = "";
        let zipcode = "";
        let state = "";
        let code = "";
        components.forEach(component => {
          if (component.types.includes("route")) {
            street = component.long_name;
          }
          if (component.types.includes("country")) {
            country = component.long_name;
            code = component.short_name;
          }
          if (component.types.includes("locality")) {
            city = component.long_name;
          }
          if (component.types.includes("postal_code")) {
            zipcode = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
        });

        setAddressComponents({
          street,
          country,
          city,
          zipcode,
          state,
          latitude: latLng.lat,
          longitude: latLng.lng,
          code
        });
        setLocationState((prevstate) => ({
          ...prevstate,
          searchLocation: false,
          searchedLocation: true
        }));
      } else {
        console.log("No address found for clicked location.");
      }
    } catch (error) {
      console.error("Error fetching geocode address:", error);
    }
  };

  const handleUseCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const currentLatLng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        setCameraProps({ center: currentLatLng, zoom: 15 });
        setLocationState((prevstate) => ({
          ...prevstate,
          searchLocation: false,
          searchedLocation: true
        }));
        setMarkerPosition(currentLatLng);
        fetchAddress(currentLatLng);
      }, (error) => {
        console.error("Error getting current location:", error);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const handleCameraChange = (ev) => {
    setCameraProps(ev.detail);
  };

  const handlePlaceSelect = (place) => {
    setSelectedPlace(place);
    setCameraProps({ center: place.geometry.location, zoom: 15 });
    setMarkerPosition(place.geometry.location);
    fetchAddress({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    });
  };
  const backprofile = () => {
    navigate(`/u/${login_user}/profile`);
  }
  const confirmAndProceed = () => {
    setFormValues((prevstate)=>({
      ...prevstate,fetchcondition:true,addressType:'',otherType:''
    }))
    setLocationState(prevstate => ({
        ...prevstate,
        searchedLocation: false,
        searchLocation: false,
        searchedLocationconfirm: true,
        searchedLocationstore: false
      }));
  }
  const confirmAndProceed1 = () => {
    setLocationState(prevstate => ({
        ...prevstate,
        searchedLocation: false,
        searchLocation: false,
        searchedLocationconfirm: false,
        searchedLocationstore: true
      }));
  }
  const emptynull = () => {
    setMarkerPosition(null);
    setAddressComponents((prevstate)=>({
      ...prevstate,street:'', country: "",city: "",zipcode: "",state: "",latitude: "",longitude: ""
    }));

  }
  const Change = async() =>{
    setLocationState((prevstate) => ({
      ...prevstate,
      searchedLocation: false,
      searchLocation:true,
      searchedLocationconfirm: false,
      searchedLocationstore:false,
    }));
    await fetchLocation();
    setFormValues((prevstate)=>({
      ...prevstate,
      Name:'',
      mobileNumber:'',
      // isvalid:false,
      dialcode:'',
      countrycode:'',
      mcc_id:'',
      houseNumber: '',
      apartment: '',
      addressLine2: '',
      addressType: '',
      otherType: '',
      default:false,
      city:'',
      postcode:'',
      fetchcondition:true,
      countryoptions:[],
    }));
    setAddressComponents((prevstate)=>({
      ...prevstate,
      street: "",
      country: "",
      city: "",
      zipcode: "",
      state: "",
      latitude: "",
      longitude: "",
      code:""
    }));
    setMarkerPosition(null);
  }
  const back1 = async () => {
    setLoading(true);
    emptynull();
    await fetchLocation();
    setLocationState(prevstate => ({
      ...prevstate,
      searchedLocation: false,
      searchLocation: true,
      searchedLocationconfirm: false,
      searchedLocationstore: false
    }));
    setLoading(false);
  };
  
  const back2 = () => {
    setLocationState((prevstate) => ({
        ...prevstate,
        searchedLocation: true,
        searchLocation:false,
        searchedLocationconfirm: false,
        searchedLocationstore:false,
      }));
      setFormValues((prevstate)=>({
        ...prevstate,
        Name:'',
        mobileNumber:'',
        // isvalid:false,
        dialcode:'',
        countrycode:'',
        mcc_id:'',
        houseNumber: '',
        apartment: '',
        addressLine2: '',
        addressType: '',
        otherType: '',
        default:false,
        city:'',
        postcode:'',
        fetchcondition:true,
        countryoptions:[]
      }));
  }
  const back3 = () => {
    setLocationState((prevstate) => ({
        ...prevstate,
        searchedLocation: false,
        searchLocation:false,
        searchedLocationconfirm: true,
        searchedLocationstore:false,
      }));
      setFormValues((prevstate)=>({
        ...prevstate,default:false,fetchcondition:false
      }));
  }
    return(
        <>
        <div>
        <div className="container-fluid" style={{ height: '100px' }}>
          <Navbars/>
        </div>
        <div className="address_div">
          <div className="address_div1">
          {locationState.searchLocation && (
              <>
              <div className="address_divhalf1">
                <div className="d-flex align-items-center">
                  <img src={back} onClick={backprofile} style={{cursor:'pointer'}} className="me-2" alt="back" width="17px" height="14px" />
                  <h6 className="addaddressh6">Add Address</h6>
                </div>
                <div>
                  <p className="addaddressSearchp">Search Location</p>
                  <button onClick={handleUseCurrentLocation} className="addaddresscurrentbutton">
                    <img src={locationcurrent} className="locationcurrent" alt="upload" />
                    Use my current location
                  </button>
                  <PlaceAutocomplete onPlaceSelect={handlePlaceSelect} />
                </div>
              </div>
              <div className="address_divhalf2">
              {loading ? (
                    <div className="skeleton-loader1"></div>
                  ) : (
                    <APIProvider apiKey={googlemapkey} solutionChannel="GMP_devsite_samples_v3_rgmbasicmap">
                      <Map {...cameraProps} onCameraChanged={handleCameraChange} gestureHandling="greedy" onClick={handleMarkerClick}>
                        {markerPosition && <Marker position={markerPosition} onClick={handleMarkerClick} ref={markerRef} />}
                      </Map>
                      <MapHandler place={selectedPlace} marker={markerRef.current} />
                    </APIProvider>
                  )}
            </div>
            </>
           )}
           {locationState.searchedLocation && (
            <Updatesearchedlocation
              addressComponents={addressComponents}
              cameraProps={cameraProps}
              markerPosition={markerPosition}
              selectedPlace={selectedPlace}
              googlemapkey={googlemapkey}
              onCameraChanged={handleCameraChange}
              confirmAndProceed={confirmAndProceed}
              back1={back1}
              Change={Change}
              // handleMarkerClick={handleMarkerClick}
            //   handleCameraChange={handleCameraChange}/>
            />
           )}
           {locationState.searchedLocationconfirm && (
            <Updatesearchlocationconfirm
            addressComponents={addressComponents}
            cameraProps={cameraProps}
            back2={back2}
            markerPosition={markerPosition}
            selectedPlace={selectedPlace}
            googlemapkey={googlemapkey}
            onCameraChanged={handleCameraChange}
            confirmAndProceed1={confirmAndProceed1}
            handleCameraChange={handleCameraChange}
            formValues={formValues}
            setFormValues={setFormValues}
            Change={Change}
            error={error}
            setError={setError}
            />
           )}
           {locationState.searchedLocationstore && (
            <Updatesearchlocationstore
            addressComponents={addressComponents}
                cameraProps={cameraProps}
                back3={back3}
                markerPosition={markerPosition}
                selectedPlace={selectedPlace}
                googlemapkey={googlemapkey}
                onCameraChanged={handleCameraChange}
                handleCameraChange={handleCameraChange}
                formValues={formValues}
                setFormValues={setFormValues}
                Change={Change}
            />
           )}
            </div>
          </div>
        </div>
        </>
    );
}
export default WithToast(Updateaddress);