import React from "react";
import { useState, useEffect } from "react";
import navlogo from "./images/evzone(1).png";
import resetpasswordlock from './images/forgot-passoward/Reset_Password.png';
import resetpasswordotp from './images/forgot-passoward/Enter-Otp-code.png';
import resetpasswordkeyset from './images/forgot-passoward/New-Password.png';
import blueeye from './images/eye.png';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import blueeyeslash from './images/eye-slash.png';
import OtpInput from 'react-otp-input';
// import axios from "axios";
import axiosInstance from "./api/common/axiosinstand";
import { useIpContext } from "./api/common/ipContext";
import { toast } from "react-toastify";
import { getMaskedPhoneNumber , getMaskedEmail} from "./api/common/commonapi";
import { resendotpapi , country_codeapi , forgototpapi , verifyotpapi,resetpasswordsetapi } from "./api/url";
import { useNavigate } from "react-router-dom";
const resendOTPapiurl = resendotpapi();
const country_codeapiurl = country_codeapi();
const forgototpapiurl = forgototpapi();
const verifyotpapiurl = verifyotpapi();
const resetpasswordsetapiurl = resetpasswordsetapi();
function Forgotpage() {
    const navigate = useNavigate();
    useEffect(() => {
        const url = '/forgotpage';
        const route = window.location.pathname;
        if (url === route) {
            const elements = document.getElementsByClassName('header-profile-user1');
            const elementsArray = Array.from(elements);
            
            elementsArray.forEach(element => {
                element.style.display = 'none';
            });
    
            return () => {
                elementsArray.forEach(element => {
                    element.style.display = ''; // Revert display property to default (inherited from CSS)
                });
            };
        }
    }, []);
    const {ipCountryCode} = useIpContext();
    const [selectedOption, setSelectedOption] = useState('email');
    const [forgotpassemail, setForgetpassemail] = useState('');
    const [forgotpassmobile, setForgotpassmobile] = useState('');
    const [selectedCountryMccId2, setSelectedCountryMccId2] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [countdown, setCountdown] = useState(120);
    const [responseemail, setResponseEmail] = useState('');
    const [responsephoneNumber, setResponsePhoneNumber] = useState('');
    const [responsecountrycode , setResponsecountrycode] = useState('');
    const [is422ErrorToastActive, setIs422ErrorToastActive] = useState(false);
    const [otp, setOtp] = useState('');
    const [shownewpassword, setShownewpassword] = useState(false);
    const [otpVerificationError, setOtpVerificationError] = useState(false)
    const [forgotpassword, setForgotpassword] = useState('');
    const [forgotconfirmpassword, setForgotconfirmpassword] = useState('');
    const [forgotpassworderror, setForgotpassworderror] = useState('');
    const [showForm5, setShowForm5] = useState(true);
    const [forgotconfirmpasswordError, setForgotconfirmpasswordError] = useState('');
    const [showPasswordsconfirm, setShowPasswordsconfirm] = useState(false);
    const [newpassword, setNewpassword] = useState(false);
    const [showforgototp, setShowforgototp] = useState(false);
    const [shownewpasswordconfirm, setShownewpasswordconfirm] = useState(false);
    const [isFocused1, setIsFocused1] = useState(false);
    const handleSelectChange = (e) => {
        setSelectedOption(e.target.value);
    };
    useEffect(() => {
        const rootElement = document.getElementById("root");

        if (rootElement) {
            if (isLoading) {
                rootElement.classList.add("load");
            } else {
                rootElement.classList.remove("load");
            }
        }

        // Cleanup effect on component unmount
        return () => {
            if (rootElement) {
                rootElement.classList.remove("load");
            }
        };
    }, [isLoading]);
    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(country_codeapiurl);
            if (response.status === 200) {
                const mccList = response.data.data;
                const codes = mccList.map((mcc) => ({
                    country_code: mcc.country_code,
                    dial_code: mcc.code,
                    mcc_id: mcc.id,
                }));
                var ip = ipCountryCode
                const selectedCountryData = codes.find(
                    (country) => country.country_code === ip
                );
                if (selectedCountryData) {
                    setSelectedCountryMccId2(selectedCountryData.mcc_id);
                }
                // Wait for DOM to load, then initialize intlTelInput
                const phoneInputField = document.querySelector('#login_country1');
                const phoneInput = intlTelInput(phoneInputField, {
                    initialCountry: selectedCountryData.country_code,
                    separateDialCode: false,
                    formatOnDisplay: true,
                    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
                });

                phoneInputField.addEventListener('countrychange', () => {
                    const selectedCountryData = phoneInput.getSelectedCountryData();
                    const mcc_idchange = codes.find(
                        (country) => country.country_code === selectedCountryData.iso2.toUpperCase()
                    ).mcc_id;
                    setSelectedCountryMccId2(mcc_idchange)
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                window.location.href = '/';
            }
            else if (error.response && error.response.status === 422) {
                toast.error(error.response.data.mobile_error, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }
    }
    useEffect(() => {
        if (selectedOption === 'mobile') {
            fetchData();
        }
    }, [selectedOption, ipCountryCode])
    const resotp = async(e) => {
        e.preventDefault();
        // const gmailUsernamePattern = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (selectedOption === 'email') {
            if (!forgotpassemail.trim()) {
                if (!toast.isActive('422Error')) {
                    setIs422ErrorToastActive(is422ErrorToastActive);
                    toast.error('Please Enter Your Email', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        toastId: '422Error'
                    });
                }
                return false;
            }
    
            if (!emailPattern.test(forgotpassemail)) {
                if (!toast.isActive('422Error')) {
                    setIs422ErrorToastActive(is422ErrorToastActive);
                    toast.error('Please Enter a valid email address', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        toastId: '422Error'
                    });
                }
                return false;
            }
        }

        // Check if the mobile number field is empty or contains non-numeric characters
        if (selectedOption === 'mobile' && (!forgotpassmobile.trim() || !/^\d+$/.test(forgotpassmobile))) {
            if (!toast.isActive('422Error')) {
                setIs422ErrorToastActive(is422ErrorToastActive);
                toast.error(selectedOption === 'mobile' ? 'Please Enter a valid Mobile number' : 'Please Enter Your Mobile Number', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    toastId: '422Error'
                });
            }
            return false;
        }
        try {
            setIsLoading(true);

            let data = {};

            if (selectedOption === 'email') {
                // Handle email case
                data = {
                    username: forgotpassemail,
                    data_mode: 'email'
                };
            } else if (selectedOption === 'mobile') {
                // Handle mobile case
                data = {
                    username: forgotpassmobile,
                    mcc_id: selectedCountryMccId2,
                    data_mode: 'mobile'
                };
            }
            const response = await axiosInstance.post(forgototpapiurl, data);

            if (response.status === 200) {
                setIsLoading(false);
                setShowForm5(false);
                setShowforgototp(true);
                startCountdown();
                const token = response.data.data.access_token;
                localStorage.setItem('auth_token', token);
                const email = response.data.data.email;
                setResponseEmail(email)
                const phone = response.data.data.mobile;
                setResponsePhoneNumber(phone);
                const code = response.data.data.mcc.code;
                setResponsecountrycode(code);
                setOtpVerificationError(false);
                // setResendhide(true);
                // setOtphide(true);
                // setIsreadonly(true);
                // setSendverifybutton(true);
                // setSendotpbutton(false);
                // setDoyousign(false);
                // toast.success(response.data.message, {
                //     autoClose: 2000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "colored",
                //     backgroundColor: '#02CD8D'
                // });
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setIsLoading(false);
                    toast.error(error.response.data.mobile_error, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else if (error.response.status === 422) {
                    setIsLoading(false);
                    if (!toast.isActive('422Error')) {
                        setIs422ErrorToastActive(is422ErrorToastActive)
                        toast.error(error.response.data.mobile_error, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            toastId: '422Error'
                        });
                    }
                }
            }
        }
    }
    const startCountdown = () => {
        let seconds = 120;
        const intervalId = setInterval(() => {
            if (seconds > 0) {
                seconds--;
                setCountdown(seconds);
            } else {
                clearInterval(intervalId);
            }
        }, 1000);
    };
    const handleInputChangeotp = (event) => {
        // Allow only numeric characters
        const isNumber = /^\d*$/.test(event.key);

        if (!isNumber) {
            event.preventDefault();
        }
    };
    
    const handlePaste = (event) => {
        // Handle pasted text and allow only numeric characters
        const pastedText = event.clipboardData.getData('text/plain');
        const isNumeric = /^\d*$/.test(pastedText);

        if (!isNumeric) {
            event.preventDefault();
        } else {
            // Update the state with the pasted numeric characters
            setOtp(pastedText);
        }
    };
    const resendotp = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('auth_token');
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        };
        let data = {};

    if (selectedOption === 'email') {
        data = {
            verify: 'email',
        };
    } else if (selectedOption === 'mobile') {
        data = {
            verify: 'mobile',
        };
    } 
        try {
            setIsLoading(true);
            const response = await axiosInstance.post(resendOTPapiurl, data, { headers });
            if (response.status === 200) {
                setIsLoading(false);
                const token = response.data.data.access_token;
                localStorage.setItem('auth_token', token);
                toast.success(response.data.message, {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    backgroundColor: '#02CD8D'
                });
                setOtp('');
                startCountdown();
            }
        }
        catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setIsLoading(false);
                    toast.error(error.response.data.mobile_error, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else if (error.response.status === 422) {
                    setIsLoading(false);
                    if (!toast.isActive('422Error')) {
                        setIs422ErrorToastActive(is422ErrorToastActive)
                        toast.error(error.response.data.mobile_error, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            toastId: '422Error'
                        });
                    }
                }
            }

        }

    }
    const verifyOtp = async() => {
        try {
        const token = localStorage.getItem('auth_token')
        // const headers = {
        //     'Authorization': `Bearer ${token}`,
        //     'Content-Type': 'application/json',
        // };
        var data = JSON.stringify({
            otp: otp,
            token:token
        })
            const response = await axiosInstance.post(verifyotpapiurl, data,);
            if (response.status === 200) {
                setShowforgototp(false);
                setNewpassword(true);
                setOtpVerificationError(false);
                const token = response.data.data.access_token;
                localStorage.setItem('auth_token', token);
                // toast.success(response.data.message, {
                //     autoClose: 2000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "colored",
                //     backgroundColor: '#02CD8D'
                // });

            }
        }
        catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setIsLoading(false);
                    toast.error(error.response.data.mobile_error, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else if (error.response.status === 422) {
                    setIsLoading(false);
                    setOtpVerificationError(true);
                    if (!toast.isActive('422Error')) {
                        setIs422ErrorToastActive(is422ErrorToastActive)
                        toast.error(error.response.data.mobile_error, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            toastId: '422Error'
                        });
                    }
                }
            }
        }

    }
    useEffect(() => {
        // Check if the OTP has 6 digits
        if (otp.length === 6) {
            verifyOtp();
        }
        if(otp.length < 6){
            setOtpVerificationError(false);
        }

    }, [otp]);
    
    const passwordvisiblity = () => {
        setShownewpassword(!shownewpassword);
    }
    const passwordvisiblityconfirm = () => {
        setShownewpasswordconfirm(!shownewpasswordconfirm);
    }
    const forgotpasswordchange = (e) => {
        const inputvalue = e.target.value;
        setForgotpassword(inputvalue);
        const lowercaseLetters = /[a-z]/g;
        const uppercaseLetters = /[A-Z]/g;
        const specialCharacters = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-|=]/g;
        const numbers = /[0-9]/g;
        setIsInvalid1({
            letter: !inputvalue.match(lowercaseLetters),
            capital: !inputvalue.match(uppercaseLetters),
            special: !inputvalue.match(specialCharacters),
            number: !inputvalue.match(numbers),
            length: inputvalue.length < 8,

        });
    }
    const forgotconfirmpasswordchange = (e) => {
        const inputvalue = e.target.value;
        setForgotconfirmpassword(inputvalue);
    }
    const handleFocus1 = () => {
        setIsFocused1(true);
    };
    const getPasswordValidationClass1 = (isValid) => {
        return isValid ? 'valid' : 'invalid';
    };
    const handleBlur1 = () => {
        setIsFocused1(false);
    };
    const isPasswordValid = () => {
        return (
            !isInvalid1.letter &&
            !isInvalid1.capital &&
            !isInvalid1.special &&
            !isInvalid1.number &&
            !isInvalid1.length
        );
    };
    const [isInvalid1, setIsInvalid1] = useState({
        letter: true,
        capital: true,
        special: true,
        number: true,
        length: true,
    });
    const resetpasswordkey = async(e) => {
       e.preventDefault();
       if (!isPasswordValid()) {
        setForgotpassworderror('Password does not meet the criteria.')
        return false;
    }
    else {
        setForgotpassworderror('');
    }
    if (!forgotpassword) {
        setForgotpassworderror('Please Enter Your New Password')
        return false;
    }
    else {
        setForgotpassworderror('');
    }
    if (forgotpassword != forgotconfirmpassword) {
        setForgotconfirmpasswordError('Sorry, Passwords do not match');
        return false;
    }
    else {
        setForgotconfirmpasswordError('');
    }
    const token = localStorage.getItem('auth_token')
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        };
        var data = JSON.stringify({
            password: forgotpassword
        })
        try {
            const response = await axiosInstance.post(resetpasswordsetapiurl, data, { headers });
            if (response.status === 200) {
                navigate('/');
                setNewpassword(false);
                const token = response.data.data.access_token;
                localStorage.setItem('auth_token', token);
            }
        }
        catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    toast.error(error.response.data.mobile_error, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else if (error.response.status === 422) {
                    if (!toast.isActive('422Error')) {
                        setIs422ErrorToastActive(is422ErrorToastActive)
                        toast.error(error.response.data.mobile_error, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            toastId: '422Error'
                        });
                    }
                }
            }
        }   
    }
    return (
        <>
         {isLoading && (
            <></>
         )}
            <div className="container-fluid forgot">
                <nav className="forgotnav">
                    <img className="mt-4 ps-3" src={navlogo} alt="nav-logo"></img>
                </nav>
                <div className="d-flex align-items-center justify-content-center">
                    <div className="forgotbox mt-4">
                        {showForm5 &&(
                        <form className="formdsaas" action=''  >
                            <div className='resetpasspad'>
                                <div className='d-flex flex-column justify-content-center align-items-center pt-5'>
                                    <img src={resetpasswordlock} alt='image' className='resetppasslock '></img>
                                    <h6 className='mt-2 resetpassh6'>Reset Your Password</h6>
                                    <p className='resetpassp'>To reset your password, you need to provide the email or phone number with which it was registered.</p>
                                </div>
                                <label className='resetpasslabel'>Enter your email address or phone number</label>
                                <div className="select-custom-container3">
                                    <select className="resetpassselect  mt-2 select-custom3" onChange={handleSelectChange} value={selectedOption}>
                                        <option value="email">Email</option>
                                        <option value="mobile">Mobile</option>
                                    </select>
                                </div>
                                {selectedOption === 'email' && (
                                    <input type='email' className='resetemailinput mt-3 form-control' value={forgotpassemail} onChange={(e) => setForgetpassemail(e.target.value)} placeholder='Email address'></input>
                                )}

                                {selectedOption === 'mobile' && (
                                    <>
                                        <div className='mb-3  loginpagebody' style={{ position: 'relative', width: "100%", margin: '20px 0' }}>
                                            <div className='row'>
                                                <div style={{ flex: '1', display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        type='text'
                                                        className='form-control resetemailinput'
                                                        id='login_country1'
                                                        name='login_country1'
                                                        onChange={(e) => setSelectedCountryMccId2(e.target.value)}
                                                        value={selectedCountryMccId2}
                                                        style={{ padding: '0px' }}
                                                    />
                                                </div>
                                                <div style={{ flex: '11', padding: '0px 12px 0px 0px', display: 'flex', flexDirection: 'column', }}>
                                                    <input type='text' className='col-9 resetemailinput  form-control' value={forgotpassmobile} onChange={(e) => setForgotpassmobile(e.target.value)} placeholder='Enter your mobile number'></input>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className='text-end mt-3'>
                                    <button className='resetcancelbutton me-3'>Cancel</button>
                                    <button className='resetsendcodebutton' onClick={resotp}>Send Code</button>
                                </div>
                            </div>
                        </form>
                        )}
                        {showforgototp && (
                              <form className="formdsaas" action=''  >
                              <div className='resetpasspad'>
                                  <div className='d-flex flex-column justify-content-center align-items-center pt-5'>
                                      <img src={resetpasswordotp} alt='image' className='resetppasslock '></img>
                                      <h6 className='mt-2 resetpassh6'>Enter OTP Code</h6>
                                      <p className='resetpassp resetpassp1'>Enter the 6-didit code sent<br></br>
                                          to {selectedOption === 'email' ? `${getMaskedEmail(responseemail)}` : `+${responsecountrycode}${getMaskedPhoneNumber(responsephoneNumber)}`} Did not receive<br></br>
                                          OTP?<strong className={`${countdown > 0 ? 'inactiveotpsssp' : 'activeotpsssp'}`} style={{ cursor: countdown > 0 ? 'not-allowed' : 'pointer' }} onClick={countdown <= 0 ? resendotp : null}>Resend OTP</strong></p>
                                      <OtpInput
                                          value={otp}
                                          onChange={setOtp}
                                          numInputs={6}
                                          renderInput={(props) => <input {...props} onPaste={handlePaste} className={`custom-inputotp mt-3 ${otpVerificationError ? 'error-border' : ''}`} onKeyPress={handleInputChangeotp} />}
                                      />
                                      <div className='mt-3  d-flex justify-content-center w-100'>
                                          <button type="button" className='resendotp'> {countdown > 0 ? `${countdown}S` : `${countdown}S`}
                                          </button>
                                      </div>
                                      <p className='mt-5 mb-3'>Having any trouble?<strong className='activeotpsssp forgotpad'>Get Help</strong></p>
                                  </div>
                              </div>
                          </form>
                        )}
                        {newpassword && (
                             <form className="formdsaas" action=''  >
                             <div className='resetpasspad1'>
                                 <div className='d-flex flex-column justify-content-center align-items-center pt-5'>
                                     <img src={resetpasswordkeyset} alt='image' className='resetppasslock '></img>
                                     <h6 className='mt-2 resetpassh6'>Set your new password</h6>
                                     <p className='resetpassp'>Your new password should be different from <br></br>passwords previously used</p>
                                     <div className='pt-3 ' style={{width:'100%'}}>
                                     <label className='mb-2 resetlabelpass'>New Password</label>
                                     <div className="mb-3" style={{ position: 'relative'  }}>
                                         <input type={shownewpassword ? 'text' : 'password'} class="form-control resetemailinput1" id="confirmpasswordsss"
                                             placeholder="New Password" name="password" value={forgotpassword} onChange={forgotpasswordchange} onFocus={handleFocus1} onBlur={handleBlur1} />
                                         <p className="toggle-passwordss" onClick={passwordvisiblity} style={{ position: 'absolute', right: '20px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                             {shownewpassword ? (
                                                 <img src={blueeye} className='eye' style={{ width: '20px', height: 'auto' }} alt="Hide Password" />
                                             ) : (
                                                 <img src={blueeyeslash} className='eye' style={{ width: '20px', height: 'auto' }} alt="Show Password" />
                                             )}
                                         </p>
                                     </div>
                                 
                                 {forgotpassworderror && <div className="error ">{forgotpassworderror}</div>}
                                 {isFocused1 && (
                                     <div id="message3" className={`password-message ${isFocused1 ? "initial-focus" : ""}`}>
                                         <h6>Password must contain the following:</h6>
                                         <p className={getPasswordValidationClass1(isInvalid1.letter)}>
                                             At least a Lowercase
                                         </p>
                                         <p className={getPasswordValidationClass1(isInvalid1.capital)}>
                                             At least a Capital (Uppercase)
                                         </p>
                                         <p className={getPasswordValidationClass1(isInvalid1.special)}>
                                             At least Special Character
                                         </p>
                                         <p className={getPasswordValidationClass1(isInvalid1.number)}>A number</p>
                                         <p className={getPasswordValidationClass1(isInvalid1.length)}>
                                             Minimum 8 Characters
                                         </p>
                                     </div>
                                 )}
                                     <label className='mb-2 resetlabelpass'>Confirm Password</label>
                                     <div style={{ position: 'relative' }}>
                                         <input type={shownewpasswordconfirm ? 'text' : 'password'} className="form-control resetemailinput1 " id="confirmpasswordss"
                                             placeholder="Confirm New Password" name="password" value={forgotconfirmpassword} onChange={forgotconfirmpasswordchange} />
                                         <p className="toggle-passwordss" onClick={passwordvisiblityconfirm} style={{ position: 'absolute', right: '20px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                             {shownewpasswordconfirm ? (
                                                 <img src={blueeye} className='eye' style={{ width: '20px', height: 'auto' }} alt="Hide Password" />
                                             ) : (
                                                 <img src={blueeyeslash} className='eye' style={{ width: '20px', height: 'auto' }} alt="Show Password" />
                                             )}
                                         </p>
                                 </div>
                                 {forgotconfirmpasswordError && <div className="error ">{forgotconfirmpasswordError}</div>}
                                 <div className="d-flex justify-content-end align-items-center mt-4">
                                     <button onClick={resetpasswordkey}
                                         className={`forgotsave ${forgotpassword && forgotconfirmpassword ? 'activatebuttonstyle' : ' inactivebuttonstyle'}`}
                                         style={!forgotpassword || !forgotconfirmpassword ? { cursor: 'not-allowed' } : {}}
                                         disabled={!forgotpassword || !forgotconfirmpassword}
                                         type="submit"
                                     >
                                         Submit
                                     </button>
                                     </div>
                                 </div>
                                 </div>
                             </div>
                         </form>
                        )}
                    </div>
                    {/* <div className="forgotbox mt-4">
                       
                    </div>
                    <div className="forgotbox mt-4">
                        
                    </div> */}
                </div>
            </div>

        </>
    );
}
export default Forgotpage;