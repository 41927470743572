import React from "react";
import Navbars from "./navbar";
import Transactionside from "./transactionside";
import graph from './images/Graph.png';
import wallettowallet from "./images/wallet_to_wallet-01.png";
import search01 from './images/search-01.png';
function Agentrecord() {
    return (
        <>
            {/* <Navbars />
            <div className="container-fluid ">
                <div className="row agentrecordrow">
                    <div className="sd  col_corre_3" >
                        <Transactionside />
                    </div> */}
                    <div className="main  col_corre_4" >
                        <div className="agentrec">
                            <img src={graph} alt="graph" ></img>
                        </div>
                    </div>
                    <div className="sd  col_corre_3"></div>
                    <div className="main  col_corre_4">
                        <div className="agentrectable" style={{marginTop:'20px'}}>
                            <div className="d-flex align-items-center agenttranss justify-content-between p-4">
                                <h6>Recent Transactions</h6>
                                <div className="agentgroup">
                                    <div className="agentpad" style={{position:'relative'}}>
                                        <input type="search" className="form-control agentsearch" placeholder="Search"></input>
                                        <p  style={{ position: 'absolute',marginLeft:'5px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}><img src={search01} alt="search" width='19.5px' height='19.5px'></img></p>
                                    </div>
                                    <div className="agentpad">
                                        <select className="agentselect" >
                                            <option>Filter</option>
                                        </select>
                                    </div>
                                    <div className="agentpad">
                                        <button className="agentexport">Export</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table className="table   nowrap custom-table custom-tables" style={{ width: '100%' }}>
                                            <colgroup>
                                                <col style={{ width: '11.11%' }} /> {/* Adjust the width as needed */}
                                                <col style={{ width: '11.11%' }} />
                                                <col style={{ width: '11.11%' }} />
                                                <col style={{ width: '11.11%' }} />
                                                <col style={{ width: '11.11%' }} />
                                                <col style={{ width: '11.11%' }} />
                                                <col style={{ width: '11.11%' }} />
                                                <col style={{ width: '11.11%' }} />
                                                <col style={{ width: '11.11%' }} />
                                            </colgroup>
                                            <thead style={{ backgroundColor: '#E2FFF6' }}>
                                                <tr style={{ border: 'none' }}>
                                                    <th style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#000000' }}>Agent name</th>
                                                    <th style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#000000' }}>Transaction ID</th>
                                                    <th style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#000000' }}>Method</th>
                                                    <th style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#000000' }}>Type</th>
                                                    <th style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#000000' }}>Amount</th>
                                                    <th style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#000000' }}>Charge</th>
                                                    <th style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#000000' }}>Date</th>
                                                    <th style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#000000' }}>Security check</th>
                                                    <th style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#000000' }}>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <p className="mar-25 padd-left right">Today</p>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex">
                                                            <img src={wallettowallet} className="me-2" width='24px' height="24px"></img>
                                                            <p className="desctabname" style={{ marginBottom: '0px' }}>Zane Barber</p>
                                                        </div>
                                                    </td>
                                                    <td className="agendtrans">TRAN2563563</td>
                                                    <td className="agendtrans">QR Code</td>
                                                    <td className="agendtrans">Deposit</td>
                                                    <td className="agendtrans">UGX1000</td>
                                                    <td className="agendtrans">UGX100</td>
                                                    <td className="agenddate">01 Sep,2023</td>
                                                    <td className="desctabcheck">Passed</td>
                                                    <td className="agenddate">Sent</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex">
                                                            <img src={wallettowallet} className="me-2" width='24px' height="24px"></img>
                                                            <p className="desctabname" style={{ marginBottom: '0px' }}>Zane Barber</p>
                                                        </div>
                                                    </td>
                                                    <td className="agendtrans">TRAN2563563</td>
                                                    <td className="agendtrans">QR Code</td>
                                                    <td className="agendtrans">Deposit</td>
                                                    <td className="agendtrans">UGX1000</td>
                                                    <td className="agendtrans">UGX100</td>
                                                    <td className="agenddate">01 Sep,2023</td>
                                                    <td className="desctabcheck desctabcheckred">Blocked</td>
                                                    <td className="agenddate">Cancelled</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex">
                                                            <img src={wallettowallet} className="me-2" width='24px' height="24px"></img>
                                                            <p className="desctabname" style={{ marginBottom: '0px' }}>Zane Barber</p>
                                                        </div>
                                                    </td>
                                                    <td className="agendtrans">TRAN2563563</td>
                                                    <td className="agendtrans">QR Code</td>
                                                    <td className="agendtrans">Deposit</td>
                                                    <td className="agendtrans">UGX1000</td>
                                                    <td className="agendtrans">UGX100</td>
                                                    <td className="agenddate">01 Sep,2023</td>
                                                    <td className="desctabcheck">Checking</td>
                                                    <td className="agenddate">Sent</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex">
                                                            <img src={wallettowallet} className="me-2" width='24px' height="24px"></img>
                                                            <p className="desctabname" style={{ marginBottom: '0px' }}>Zane Barber</p>
                                                        </div>
                                                    </td>
                                                    <td className="agendtrans">TRAN2563563</td>
                                                    <td className="agendtrans">QR Code</td>
                                                    <td className="agendtrans">Deposit</td>
                                                    <td className="agendtrans">UGX1000</td>
                                                    <td className="agendtrans">UGX100</td>
                                                    <td className="agenddate">01 Sep,2023</td>
                                                    <td className="desctabcheck desctabcheckred">Blocked</td>
                                                    <td className="agenddate">Sent</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </div>
            </div> */}
        </>
    );
}
export default Agentrecord;