import React, { useEffect, useRef,useState,useCallback} from "react";
import back from '../../../images/arrow-left-01.png';
import searchedlocationicon from '../../../images/address-icons/Group 1858.png';
import { APIProvider, Map, Marker, useMap } from '@vis.gl/react-google-maps';
import Select, { components } from 'react-select';
import axiosInstance from "../../../api/common/axiosinstand";
import { country_codeapi } from "../../../api/url";
import { useIpContext } from "../../../api/common/ipContext";
const countrycodeurl = country_codeapi();
const MapHandler = ({ place, marker }) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !place || !marker) return;

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry.viewport);
    }

    marker.setPosition(place.geometry?.location);
  }, [map, place, marker]);

  return null;
};

function Updatesearchlocationconfirm({
    addressComponents,
    cameraProps,
    markerPosition,
    selectedPlace,
    back2,
    googlemapkey,
    confirmAndProceed1,
    formValues,
    setFormValues,
    Change,error,setError,handleCameraChange
}){
  const { ipCountryCode , getUserIpCountryCode } = useIpContext();
  const mobileInputRef = useRef(null);
  const [dialCode, setDialCode] = useState('');
  const [mobileChange, setMobileChange] = useState(formValues.mobileNumber ? formValues.mobileNumber : '');
  const [isLoading, setIsLoading] = useState(false);
  const updateExampleNumber = useCallback((iso2) => {
    if (window.intlTelInputUtils) {
      try {
        const exampleNumber = window.intlTelInputUtils.getExampleNumber(
          iso2,
          true,
          window.intlTelInputUtils.numberFormat.E164
        );
        const cleanedNumber = exampleNumber.replace(/\D/g, '').replace(/^0+/, '');
        const maxLength = cleanedNumber.length;
        return maxLength;
      } catch (error) {
        console.error('Error fetching example number for country code:', iso2, error);
        return 15; // Fallback to a default length
      }
    } else {
      console.error('intlTelInputUtils is not loaded');
      return 15; // Fallback to a default length
    }
  }, []);
  
  const fetchCountryCode = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(countrycodeurl);
      const data = response.data;
      if (data.message === 'Success') {
        const mccList = data.data;
        const codes = mccList.map((mcc) => ({
          country_code: mcc.country_code,
          dial_code: mcc.code,
          mcc_id: mcc.id,
          label: `${mcc.name} (+${mcc.code})`,
          value: mcc.country_code,
          flag: `https://flagcdn.com/w320/${mcc.country_code.toLowerCase()}.png`,
        }));
  
        setFormValues((prevstate) => ({
          ...prevstate,
          countryoptions: codes,
        }));
  
        let code = '';
        if(formValues.fetchcondition){
            code = ipCountryCode
        }
        else{
           code = formValues.code
        }
        const selectedCountryData = codes.find(
          (country) => country.country_code === code
        );
  
        if (selectedCountryData) {
          setFormValues((prevstate) => ({
            ...prevstate,
            mcc_id: selectedCountryData.mcc_id,
            dialcode: `+${selectedCountryData.dial_code}`,
            countrycode: selectedCountryData.country_code,
          }));
  
          const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
          if (maxLength !== null && mobileInputRef.current) {
            mobileInputRef.current.setAttribute('maxlength', maxLength);
          }
        }
      } else {
        console.error('Failed to fetch country codes');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // Set loading to false after the API call completes
    }
  }, [formValues.code,ipCountryCode,setFormValues, updateExampleNumber,formValues.fetchcondition]);
  
  useEffect(() => {
    if (formValues.fetchcondition) {
      if (!ipCountryCode) {
        getUserIpCountryCode();
      }
      fetchCountryCode();
    }else{
      fetchCountryCode();
    }
    if (!formValues.fetchcondition) {
    if (['Home', 'Friends', 'Office'].includes(formValues.location_type)) {
      setFormValues((prevState) => ({
        ...prevState,
        addressType: formValues.location_type,
      }));
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        addressType: 'Others',
        otherType: formValues.location_type, 
      }));
    }
  }
  }, [fetchCountryCode, formValues.location_type, setFormValues,getUserIpCountryCode,ipCountryCode,formValues.fetchcondition]);
  const handleKeyPress = (e) => {
    // Allow only numeric characters (0-9) and some specific control keys
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];

    if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };
  const handlePhoneNumberChange = (value) => {
    if (value.startsWith('0')) {
      value = value.slice(1);
    }
    const maxLength = mobileInputRef.current.getAttribute('maxlength');
    if (value.length > maxLength) {
      value = value.slice(0, maxLength);
    }
    setMobileChange(value);
    setFormValues((prevstate)=>({
      ...prevstate,mobileNumber:value
    }));
  };
  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData('text');
    let numericPastedText = pastedText.replace(/[^0-9]/g, '');

    // Define the dial code without the '+'
    const dialCodeWithoutPlus = dialCode.replace('+', '');

    // Remove dial code if the pasted text starts with '+'
    if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
        numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
    }

    // Remove leading '0' if present
    if (numericPastedText.startsWith('0')) {
        numericPastedText = numericPastedText.slice(1);
    }

    // Calculate the max length and truncate the number if necessary
    const maxLength = mobileInputRef.current.getAttribute('maxlength');
    const truncatedPastedText = numericPastedText.slice(0, maxLength);

    setMobileChange(truncatedPastedText);
    setFormValues((prevstate)=>({
      ...prevstate,mobileNumber:truncatedPastedText
    }));
    e.preventDefault();
};
const handleCountryChange = (selectedOption) => {
  const selectedCountryData = formValues.countryoptions.find(
      (country) => country.value === selectedOption.value
  );

  if (selectedCountryData) {
      setFormValues((prevstate)=>({
        ...prevstate,mcc_id:selectedCountryData.mcc_id,
        dialcode:`+${selectedCountryData.dial_code}`,
        countrycode:selectedCountryData.country_code
      }));
      setDialCode(`+${selectedCountryData.dial_code}`);

      const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
      if (maxLength !== null && mobileInputRef.current) {
          mobileInputRef.current.setAttribute('maxlength', maxLength);
      }

      // Truncate the mobile number to the new max length
      const truncatedMobileChange = mobileChange.slice(0, maxLength);
      setMobileChange(truncatedMobileChange);
      setFormValues((prevstate)=>({
        ...prevstate,mobileNumber:truncatedMobileChange
      }));
  }
};
const customSingleValue = ({ data }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <img
      src={data.flag}
      alt=""
      style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
    />
    {/* {data.label} */}
  </div>
);

const customOption = (props) => {
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={props.data.flag}
          alt=""
          style={{ width: '25px', height: 'auto', marginRight: '10px' }}
        />
        {props.data.label}
      </div>
    </components.Option>
  );
};
    const handleInputChange = (e) => {
      const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
    };
  const markerRef = useRef(null);
  const handleKeyDown = (e) => {
    // Regular expression to allow only alphabets
    const regex = /^[a-zA-Z\s]*$/;
    
    // Check if the input character is valid
    if (!regex.test(e.key)) {
      e.preventDefault();
      setError('Only alphabets are allowed')
    } else {
      setError('')
    }
  };
  const { street, city, state, country, zipcode } = addressComponents;
  const [checkbutton,setCheckbutton]=useState(false);
  const checkFormValidity = useCallback(() => {
    const check1 = formValues.city !== '' || city !== '';
    const check2 = formValues.postcode !== '' || zipcode !== '';
  
    let addressTypeIsValid = true;
  
    if (formValues.addressType === 'Others') {
      addressTypeIsValid = formValues.otherType.trim() !== '';
    } else {
      addressTypeIsValid = formValues.addressType !== '';
    }
  
    const valid =
      check1 &&
      check2 &&
      addressTypeIsValid &&
      formValues.mobileNumber !== '' &&
      formValues.houseNumber !== '' &&
      formValues.addressLine2 !== '';
  
    setCheckbutton(valid);
  }, [formValues.city, formValues.postcode, formValues.mobileNumber, formValues.houseNumber, formValues.addressLine2, formValues.addressType, formValues.otherType, city, zipcode]);
  
  useEffect(() => {
    checkFormValidity();
  }, [checkFormValidity, formValues.city, formValues.postcode, formValues.mobileNumber, formValues.houseNumber, formValues.addressLine2, formValues.addressType, formValues.otherType, city, zipcode]);
    return(
        <>
            <div className="address_divhalf1 address_divhalf1confirm d-flex flex-column justify-content-between">
        <div>
          <div className="d-flex align-items-center">
            <img src={back} onClick={back2} style={{cursor:'pointer'}} className="me-2" alt="back" width="17px" height="14px" />
            <h6 className="addaddressh6">Add Address</h6>
          </div>
          <div>
            <p className="addaddressSearchp">Confirm your address</p>
            <div className='combinedaddressdiv mb-3'>
              <div className="d-flex align-items-center justify-content-between w-100">
                <div className="combinedaddressdivflex w-100">
                  <img src={searchedlocationicon} className='searchedlocationimage' alt='searchlocate' ></img>
                  <div>
                  {street && <p className="confirmaddressp">{street},</p>}
                    {(city || state ) && <p className="confirmaddressp">{`${city || formValues.city}${state ? ` - ${state}` : ''}`},</p>}
                    {(country || zipcode) && <p className="confirmaddressp">{`${country}${zipcode || formValues.postcode ? ` - ${zipcode || formValues.postcode}` : ''}`}</p>}
                  </div>
                </div>
                <button onClick={Change} className="combinedaddressdivflexbuuton">Change</button>
              </div>
            </div>
            <div className='combinedaddressdiv mb-3'>
            <label className="combinedaddressdivlabel">Name</label>
            <input type="text"  name="Name" className="form-control combinedaddressdivlabelinput" value={formValues.Name} onKeyDown={handleKeyDown} onChange={handleInputChange}></input>
            {error && <p className="error-message">{error}</p>}
            <label className="combinedaddressdivlabel">Mobile Number</label>
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center',height:'36px',top:'-3px' }}>
            <div style={{ position: 'absolute', left: 0, zIndex: 1,top:'-3px' }}>
              <Select
                options={formValues.countryoptions}
                onChange={handleCountryChange}
                name="countrycode"
                value={formValues.countryoptions.find(option => option.value === formValues.countrycode)}
                components={{ SingleValue: customSingleValue, Option: customOption }}
                placeholder=""
                isLoading={isLoading} // Pass the loading state to Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width:'80px',
                    minHeight:'36px',
                    height: '100%',
                    border: 'none',
                    boxShadow: 'none',
                    background: 'transparent',
                    display: 'flex',
                    alignItems: 'center',
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    padding: '0 8px',
                  }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    display: 'flex',
                    alignItems: 'center',
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    padding: '0',
                    display: 'flex',
                    alignItems: 'center',
                  }),
                  input: (provided) => ({
                    ...provided,
                    margin: '0',
                    padding: '0',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    margin: '0',
                    padding: '0',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    minWidth: '300px',
                  }),
                }}
              />
            </div>
            <p style={{ position: 'absolute', left:'78px', top: '45%', transform: 'translateY(-50%)', cursor: 'pointer',color:'hsl(0, 0%, 20%)',fontSize:'14px',lineHeight:'1.5',marginBottom:'0px',fontWeight:'400' }}>{formValues.dialcode}</p>
            <input
              type="text"
              className={`form-control combinedaddressdivlabelinput`}
              id="mobile"
              ref={mobileInputRef}
               name='mobileNumber'
              style={{ paddingLeft: '120px', width: '100%' }}
              placeholder="Mobile Number"
              value={formValues.mobileNumber}
              onPaste={(e) => handlePaste(e)}
              onKeyPress={handleKeyPress}
              onChange={(e) => handlePhoneNumberChange(e.target.value)}
            />
          </div>
          <input
            type="hidden"
            className="form-control signupemalichange"
            name="mcc_id"
            placeholder="Mobile Number"
            value={formValues.mcc_id}
            onChange={handleInputChange}
          />
           {!city && (
            <>  
           <label className="combinedaddressdivlabel">City</label>
           <input type="text"  name="city" className="form-control combinedaddressdivlabelinput" value={formValues.city} onChange={handleInputChange}></input>
           </>
            )}
            {!zipcode && (
            <>
           <label className="combinedaddressdivlabel">postal Code</label>
           <input type="text" onKeyPress={handleKeyPress}  name="postcode" className="form-control combinedaddressdivlabelinput" value={formValues.postcode} onChange={handleInputChange}></input>
           </>
            )}
             <label className="combinedaddressdivlabel">House/Flat/Block No</label>
             <input type="text"  name="houseNumber" className="form-control combinedaddressdivlabelinput" value={formValues.houseNumber} onChange={handleInputChange}></input>
             <label className="combinedaddressdivlabel">Apartment/Road/Area(Optional)</label>
             <input type="text" name="apartment" className="form-control combinedaddressdivlabelinput" value={formValues.apartment} onChange={handleInputChange}></input>
             <label className="combinedaddressdivlabel">Address Line 2</label>
             <input type="text"  name="addressLine2" className="form-control combinedaddressdivlabelinput" value={formValues.addressLine2} onChange={handleInputChange}></input>
             <div className="address-type-container">
      <label className={`address-type ${formValues.addressType === 'Others' ? 'd-none' : ''}`}>
        <input
          type="radio"
          name="addressType"
          value="Home"
          checked={formValues.addressType === 'Home'}
          onChange={handleInputChange}
        />
        Home
      </label>
      <label className={`address-type ${formValues.addressType === 'Others' ? 'd-none' : ''}`}>
        <input
          type="radio"
          name="addressType"
          value="Office"
          checked={formValues.addressType === 'Office'}
          onChange={handleInputChange}
        />
        Office
      </label>
      <label className={`address-type ${formValues.addressType === 'Others' ? 'd-none' : ''}`}>
        <input
          type="radio"
          name="addressType"
          value="Friends"
          checked={formValues.addressType === 'Friends'}
          onChange={handleInputChange}
        />
        Friends
      </label>
      <label className="address-type">
        <input
          type="radio"
          name="addressType"
          value="Others"
          checked={formValues.addressType === 'Others'}
          onChange={handleInputChange}
        />
        Others
      </label>
      {
        formValues.addressType === 'Others' ? <div style={{position:'relative',width:'100%'}}><input type="text" name="otherType" value={formValues.otherType} onChange={handleInputChange} className="form-control combinedaddressdivlabelinput"></input><p onClick={() => setFormValues((prev) => ({ ...prev, otherType: '',addressType:'' }))} style={{position: 'absolute', marginRight: '15px',right:'0px', top: '45%', transform: 'translateY(-50%)', cursor: 'pointer',fontWeight:'400',fontSize:'14px',lineHeight:'18px',color:'#F77F00'}}>Cancel</p></div>: ""
      }
    </div>
            </div>
          </div>
        </div>
        <button onClick={confirmAndProceed1} className={`combinedaddressdivconfirmButtonprceed ${!checkbutton ? 'combinedaddressdivconfirmButtonprceeddisabled' : 'combinedaddressdivconfirmButtonprceed'}`} disabled={!checkbutton}>Confirm & Proceed</button>
      </div>
      <div className="address_divhalf2">
        <APIProvider apiKey={googlemapkey} solutionChannel="GMP_devsite_samples_v3_rgmbasicmap">
          <Map {...cameraProps} onCameraChanged={handleCameraChange} gestureHandling="greedy">
            {markerPosition && <Marker position={markerPosition} ref={markerRef} />}
          </Map>
          <MapHandler place={selectedPlace} marker={markerRef.current} />
        </APIProvider>
      </div>
        </>
    )
}
export default Updatesearchlocationconfirm;