import { React, useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Navbars from "../navbar";
import leftarrow from '../images/arrow-left-01.png';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import Select, { components } from 'react-select';
import eye from '../images/eye-01.png';
import {isValidNumber} from 'libphonenumber-js';
import eyegrey from '../images/eye-01pass.png';
import eyeslashgrey from '../images/eye-slash-01passw.png';
import eyeslash from '../images/eye_slash-01.png';
import { country_codeapi, profileupdateotpapi, profileupdateauthapi,passwordverifyapi} from "../api/url";
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserProfile } from '../api/redux/userProfiles/actions';
// import axios from "axios";
import axiosInstance from "../api/common/axiosinstand";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { extractLoginUser ,handleApiError} from "../api/common/commonapi";
import { useIpContext } from "../api/common/ipContext";
import WithToast from "../api/common/withtoast";
const countrycodeurl = country_codeapi();
const profileupdateotpapiurl = profileupdateotpapi();
const profileupdateauthapiurl = profileupdateauthapi();
const passwordverifyapiurl = passwordverifyapi();
function Mobilenumberchange({showErrorToast}) {
  const login_user = extractLoginUser();
  const location = useLocation();
  const { state } = location;
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userProfile.userProfile); // Use useSelector to access userProfile from Redux state
  useEffect(() => {
    dispatch(fetchUserProfile(showErrorToast));
  }, [dispatch]);
  const { ipCountryCode ,getUserIpCountryCode} = useIpContext();
  const id = userProfile.mcc_countrycode;
  const id1 = userProfile.mcc_id;
  // const mcc_id = state && state.usermcc_id ? state.usermcc_id : '';
  const mobile = state && state.userMobile ? state.userMobile : '';
  const [mcc_idchange, setMcc_idchange] = useState('');
  const [mobilechange, setMobilechange] = useState('');
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [isSaveButtonEnablednext, setIsSaveButtonEnablednext] = useState(false);
  const [isSaveButtonEnablednextotp, setIsSaveButtonEnablednextotp] = useState(false);
  const [showmobileedit, setShowmobileedit] = useState(false);
  const [showmobileedits, setShowmobileedits] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dialCode, setDialCode] = useState('');
  const [mccId, setMccId] = useState('');
  const [mobileChange, setMobileChange] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [countryOptions, setCountryOptions] = useState([]);
  const [error422, setError422] = useState('');
  const [isLoading4, setIsLoading4] = useState(false);
  const [showmobileotppage, setShowmobileotppage] = useState(false);
  const [showemailpasswordedit , setShowmobilepasswordedit] = useState(true);
  const [otpchange, setOtpchange] = useState("");
  const [password , setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [dial_code , setDial_code] = useState('');
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  // const [selectedCountryMccId1, setSelectedCountryMccId1] = useState('');
  const mobileInputRef = useRef(null);
  const mobileInputRef1 = useRef(null);
  const navigate = useNavigate();
  const mobilenumberedit = () => {
    setShowmobileedit(false);
    setShowmobileedits(true);
    setShowmobileotppage(false);
    // fetchCountryCode1();
  }
  const updateExampleNumber = (iso2) => {
    if (window.intlTelInputUtils) {
      try {
        const exampleNumber = window.intlTelInputUtils.getExampleNumber(
          iso2,
          true,
          window.intlTelInputUtils.numberFormat.E164
        );
        const cleanedNumber = exampleNumber.replace(/\D/g, '').replace(/^0+/, '');
        const maxLength = cleanedNumber.length;
        return maxLength;
      } catch (error) {
        console.error('Error fetching example number for country code:', iso2, error);
        return 15; // Fallback to a default length
      }
    } else {
      console.error('intlTelInputUtils is not loaded');
      return 15; // Fallback to a default length
    }
  };

  const handlePhoneNumberChange = (value) => {
    setError422('');
    if (value.startsWith('0')) {
      value = value.slice(1);
    }
    const maxLength = mobileInputRef.current.getAttribute('maxlength');
    if (value.length > maxLength) {
      value = value.slice(0, maxLength);
    }
    setMobileChange(value);
    const fullNumber = `${dialCode}${value}`;
    if (fullNumber.length <= maxLength + dialCode.length && isValidNumber(fullNumber)) {
      setIsValidPhoneNumber(true);
    } else {
      setIsValidPhoneNumber(false);
    }
  };
  // const handlePaste = (e) => {
  //   const pastedText = e.clipboardData.getData('text');
  //   let numericPastedText = pastedText.replace(/[^0-9]/g, '');
    
  //   // Remove leading '0' if present
  //   if (numericPastedText.startsWith('0')) {
  //     numericPastedText = numericPastedText.slice(1);
  //   }
  //   const maxLength = mobileInputRef.current.getAttribute('maxlength');
  
  //   const truncatedPastedText = numericPastedText.slice(0, maxLength);
  //   setMobileChange(truncatedPastedText);
  //   const fullNumber = `${dialCode}${truncatedPastedText}`;
  //   if (truncatedPastedText.length <= maxLength && isValidNumber(fullNumber)) {
  //     setIsValidPhoneNumber(true);
  //   } else {
  //     setIsValidPhoneNumber(false);
  //   }
  //   e.preventDefault();
  // };
  const handlePaste = (e) => {
    setError422('');
    const pastedText = e.clipboardData.getData('text');
    let numericPastedText = pastedText.replace(/[^0-9]/g, '');

    // Define the dial code without the '+'
    const dialCodeWithoutPlus = dialCode.replace('+', '');

    // Remove dial code if the pasted text starts with '+'
    if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
        numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
    }

    // Remove leading '0' if present
    if (numericPastedText.startsWith('0')) {
        numericPastedText = numericPastedText.slice(1);
    }

    // Calculate the max length and truncate the number if necessary
    const maxLength = mobileInputRef.current.getAttribute('maxlength');
    const truncatedPastedText = numericPastedText.slice(0, maxLength);

    setMobileChange(truncatedPastedText);

    const fullNumber = `${dialCode}${truncatedPastedText}`;
    if (isValidNumber(fullNumber)) {
        setIsValidPhoneNumber(true);
    } else {
        setIsValidPhoneNumber(false);
    }

    e.preventDefault();
};

const handleCountryChange = (selectedOption) => {
  const selectedCountryData = countryOptions.find(
      (country) => country.value === selectedOption.value
  );

  if (selectedCountryData) {
      setMccId(selectedCountryData.mcc_id);
      setDialCode(`+${selectedCountryData.dial_code}`);
      setCountryCode(selectedCountryData.country_code);

      const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
      if (maxLength !== null && mobileInputRef.current) {
          mobileInputRef.current.setAttribute('maxlength', maxLength);
      }

      // Truncate the mobile number to the new max length
      const truncatedMobileChange = mobileChange.slice(0, maxLength);
      setMobileChange(truncatedMobileChange);

      // Validate the new truncated number
      const fullNumber = `${selectedCountryData.dial_code}${truncatedMobileChange}`;
      if (isValidNumber(fullNumber)) {
          setIsValidPhoneNumber(true);
      } else {
          setIsValidPhoneNumber(false);
      }
  }
};
  useEffect(()=>{
    if(ipCountryCode){
      fetchCountryCode()
    }
  },[ipCountryCode])

  const customSingleValue = ({ data }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={data.flag}
        alt=""
        style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
      />
      {/* {data.label} */}
    </div>
  );

  const customOption = (props) => {
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={props.data.flag}
            alt=""
            style={{ width: '25px', height: 'auto', marginRight: '10px' }}
          />
          {props.data.label}
        </div>
      </components.Option>
    );
  };

  const back = () => {
    navigate(`/u/${login_user}/profile`);
  }
  const backs = () => {
    setShowmobilepasswordedit(true);
    setPassword('');
    setShowmobileedit(false);
    setShowmobileedits(false);
    setShowmobileotppage(false);
  }
  const cancel = () => {
    navigate(`/u/${login_user}/profile`);
  }
  const check = () => {
    const valid = (
      password !== ''
    )
    setIsSaveButtonEnabled(valid);
  }
  useEffect(()=>{
    check();
  },[password])

  const fetchCountryCode = async () => {
    setIsLoading(true); 
    try {
      const response = await axiosInstance.get(countrycodeurl);
      const data = response.data;

      if (data.message === 'Success') {
        const mccList = data.data;
        const codes = mccList.map((mcc) => ({
          country_code: mcc.country_code,
          dial_code: mcc.code,
          mcc_id: mcc.id,
          label: `${mcc.name} (+${mcc.code})`,
          value: mcc.country_code,
          flag: `https://flagcdn.com/w320/${mcc.country_code.toLowerCase()}.png`,
        }));

        setCountryOptions(codes);

        const selectedCountryData = codes.find(
          (country) => country.country_code === ipCountryCode
        );

        if (selectedCountryData) {
          setMccId(selectedCountryData.mcc_id);
          setDialCode(`+${selectedCountryData.dial_code}`);
          setCountryCode(selectedCountryData.country_code);

          const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
          if (maxLength !== null && mobileInputRef.current) {
            mobileInputRef.current.setAttribute('maxlength', maxLength);
          }
        }
      } else {
        console.error('Failed to fetch country codes');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // Set loading to false after the API call completes
    }
  };

  
const toggleVisibility = () => {
  setShowPassword(!showPassword);
}
const goBack = () => {
  navigate(`/u/${login_user}/profile`);
}
const cancelPasswordChange = () => {
  navigate(`/u/${login_user}/profile`);
}

const goToNextStep = async() => {
//   const token = localStorage.getItem('access_token');
//   const headers = {
//     'Authorization': `Bearer ${token}`,
//     'Content-Type': 'application/json',
// };

  const data = {
    password : password,
  }
  try{
    setIsLoading4(true);
  const response = await axiosInstance.post(passwordverifyapiurl,data);
  if (response.status === 200) {
    const password_verify = response.data.data.password_verify;
    localStorage.setItem('password_verify', password_verify);
    await getUserIpCountryCode();
    fetchCountryCode();
    setIsLoading4(false);
    setError422('');
  //   toast.success(response.data.message, {
  //    autoClose: 2000,
  //        hideProgressBar: false,
  //        closeOnClick: true,
  //        pauseOnHover: true,
  //        draggable: true,
  //        progress: undefined,
  //        theme: "colored",
  //        backgroundColor :'#02CD8D'
  //  });
   setShowmobilepasswordedit(false);
  setShowmobileedits(true);
  setShowmobileotppage(false);
  // startCountdown1();
}
  }
  catch(error){
    handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
  }
} 


const handleKeyPress = (e) => {
  // Allow only numeric characters (0-9) and some specific control keys
  const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];

  if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
};
const checks = () => {
  const valid = (
    mobileChange !== ''
  );
  setIsSaveButtonEnablednext(valid);
 }
 useEffect(()=>{
  checks();
 },[mobileChange]);
  const next = async () => {
    if(mobileChange.length < 9 || mobileChange.length > 15) {
      setError422('The mobile must be between 9 and 15 digits')
      return false;
    }else{
      setError422('')
    }
    if(!isValidPhoneNumber){
      setError422('Please enter a valid phone number');
      return false;
    }else{
      setError422('');
    }
    // const token = localStorage.getItem('access_token');
    const password_verify = localStorage.getItem('password_verify');
    // const headers = {
    //   'Authorization': `Bearer ${token}`,
    //   'Content-Type': 'application/json',
    // };
    const data = {
      mobile: mobileChange,
      mcc_id : mccId,
      password_verify_token : password_verify ,
      verify: 'mobile'
    }
    try {
      setIsLoading4(true);
      const response = await axiosInstance.post(profileupdateotpapiurl, data);
      if (response.status === 200) {
        const otptoken = response.data.data.token;
        localStorage.setItem('otp_token', otptoken);
        setIsLoading4(false);
        setError422('');
        setIsLoading(false);
        setShowmobileedit(false);
        setShowmobileedits(false);
        setShowmobileotppage(true);
        // fetchCountryCode2();
      }
    }
    catch (error) {
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    }
  }
  const checkotp = () => {
    const valid = (
      otpchange !== ''
    );
    setIsSaveButtonEnablednextotp(valid);
  }
  useEffect(()=>{
    checkotp();
  },[otpchange]);
  const handleKeyDown = (e) => {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];
    if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault(); // Prevent the default action
      setError422('Please enter only numbers'); // Set error message
    } else {
      setError422(''); // Clear error if valid key is pressed
    }
  };


  const handleChange = (e) => {
    const value = e.target.value;

    // Remove non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, '');
  
    if (value !== numericValue) {
      setError422('Please enter only numbers');
    } else {
      setError422('');
    }
    if (value.length > 6) {
      setError422('OTP must be 6 digits');
    } else {
      setError422('');
    }
    setOtpchange(numericValue); 
  };
  const save = async() => {
    // const token = localStorage.getItem('access_token');
    const password_verify = localStorage.getItem('password_verify');
  //   const headers = {
  //     'Authorization': `Bearer ${token}`,
  //     'Content-Type': 'application/json',
  // };
  if (!otpchange.trim()) {
    return;
  }
  const otptoken = localStorage.getItem('otp_token')
  const data = JSON.stringify({
    otp : otpchange,
    token : otptoken,
    password_verify_token : password_verify,
  })
  try{
    setIsLoading4(true);
  const response = await axiosInstance.post(profileupdateauthapiurl,data);
  if (response.status === 200) {
    setIsLoading4(false);
    setError422('');
      //  error401();
    // const token = response.data.data.access_token;
    // localStorage.setItem('access_token', token);
    navigate(`/u/${login_user}/profile`);
}    
  }
  catch(error){
    if(error.response){
       if(error.response.status === 422){
        const error_code = error.response.data.code;
        if (error_code === 'otp_retry') {
          navigate(`/u/${login_user}/profile`);
          setIsLoading4(false);
        }else{
          handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
        }
       } else{
        handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
       }
    }else{
    handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    }
    }  
  }
  return (
    <>
    {isLoading4 && (
        <>
          <div className="loaderover">
            <div className="loader "></div>
          </div>
        </>
      )}
       <div>
        <div className="container-fluid" style={{ height: '100px' }}>
          <Navbars />
        </div>
        <div className="name_div">
        {showemailpasswordedit && (
         <>
          <div className="name_div1">
            <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={goBack} alt="leftarrow" className="name_arrow "></img>
              <h5 className="nameh5">Edit Mobile</h5>
            </div>
            <div className="name-input name-inputimage">
            <p style={{ fontWeight: '500', fontSize: '16px', lineHeight: '30px', color: '#292929' }}>To modify Mobile, Kindly enter your password</p>
            <div className="header-profile-user border-dark user mx-auto " style={{ width: '80px', height: '80px', borderRadius: '50%', overflow: 'hidden' }}>
            <img id="login_img_for_profile" src={userProfile?.image} alt="Profile" loading="lazy"className=" homeprofile mb-3" style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}/></div>
            <p style={{ fontWeight: '500', fontSize: '20px', lineHeight: '30px', color: '#292929' }}>{userProfile?.name}</p>
            <div className="text-start" style={{width:'100%'}}>
                <label htmlFor="password" className="mb-2" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Current Password</label>
              <div className="password " style={{ position: 'relative' }}>
                <input type={showPassword ? 'text' : 'password'} value={password} onChange={(e)=>{setPassword(e.target.value);setError422('')}} className={`form-control docform ${password ? 'docform-active' : ''}`}  id="userpassword" placeholder="Enter Your Current Password" name="password"/>
                <p className="toggle-password" onClick={toggleVisibility} style={{ position: 'absolute', marginRight: '15px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                  {showPassword ? (
                    password ? (
                    <img src={eye} className='eye' width='19.5px' height='19.5px' alt="Show Password"/>
                    ) : (
                      <img src={eyegrey} className='eye' width='19.5px' height='19.5px' alt="Show Password"/>
                    )
                  ) : (
                    password ? (
                    <img src={eyeslash} className='eye' width='19.5px' height='19.5px' alt="Hide Password" />
                    ) : (
                      <img src={eyeslashgrey} className='eye' width='19.5px' height='19.5px' alt="Hide Password" />
                    )
                  )}
                </p>
              </div>
              </div>
              <div className="error-message error-messageprofile">{error422}</div>
              <div className="name_button_div" style={{width:'100%'}}>
                <button type="button" onClick={cancelPasswordChange} className="profilecancel me-4">
                  Cancel
                </button>
                <button type="button" onClick={goToNextStep} style={{ backgroundColor: isSaveButtonEnabled ? '#02CD8D' : '#EAEAEA', color: isSaveButtonEnabled ? '#FFF' : '#333' , cursor: isSaveButtonEnabled ? 'pointer' : 'not-allowed' }} disabled={!isSaveButtonEnabled} className={`profilesave`}>
                  Next
                </button>
              </div>
            </div>
            </div>
            </>
            )}
            {showmobileedits && (
            <>
             <div className="name_div1">
            <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={goBack} alt="leftarrow" className="name_arrow "></img>
              <h5 className="nameh5">Change Mobile</h5>
            </div>
            <div className="name-input name-inputimage name-inputimageemail ">
            <div >
            <label
        htmlFor="inputField"
        className="form-label"
        style={{
          fontWeight: '500',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#000000',
        }}
      >
        Mobile Number
      </label>
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
        <div style={{ position: 'absolute', left: 0, zIndex: 1 }}>
          <Select
            options={countryOptions}
            onChange={handleCountryChange}
            value={countryOptions.find(option => option.value === countryCode)}
            components={{ SingleValue: customSingleValue, Option: customOption }}
            placeholder=""
            isLoading={isLoading} // Pass the loading state to Select
            styles={{
              control: (provided) => ({
                ...provided,
                width: isLoading ? '70px' : '80px',
                height: '100%',
                border: 'none',
                boxShadow: 'none',
                background: 'transparent',
                display: 'flex',
                alignItems: 'center',
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                padding: '0 8px',
              }),
              indicatorSeparator: () => ({
                display: 'none',
              }),
              singleValue: (provided) => ({
                ...provided,
                display: 'flex',
                alignItems: 'center',
              }),
              valueContainer: (provided) => ({
                ...provided,
                padding: '0',
                display: 'flex',
                alignItems: 'center',
              }),
              input: (provided) => ({
                ...provided,
                margin: '0',
                padding: '0',
              }),
              placeholder: (provided) => ({
                ...provided,
                margin: '0',
                padding: '0',
              }),
              menu: (provided) => ({
                ...provided,
                minWidth: '300px',
              }),
            }}
          />
        </div>
        <p style={{ position: 'absolute', left:'78px', top: '51%', transform: 'translateY(-50%)', cursor: 'pointer',color:'hsl(0, 0%, 20%)',fontSize:'14px',lineHeight:'1.5',marginBottom:'0px',fontWeight:'400' }}>{dialCode}</p>
        <input
          type="text"
          className={`form-control docform ${mobileChange ? 'docform-active' : ''}`}
          id="mobile"
          ref={mobileInputRef}
          style={{ paddingLeft: '120px', width: '100%' }}
          placeholder="Mobile Number"
          value={mobileChange}
          name="mobile"
          onPaste={(e) => handlePaste(e)}
          onKeyPress={handleKeyPress}
          onChange={(e) => handlePhoneNumberChange(e.target.value)}
        />
      </div>
      <input
        type="hidden"
        className="form-control signupemalichange"
        placeholder="Mobile Number"
        value={mccId}
        onChange={(e) => setMccId(e.target.value)}
      />
      </div>
            {/* <div style={{position:'relative'}}>
                  <label htmlFor="inputField" className="form-label" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Mobile Number</label>
                  <input type="text" className={`form-control docform ${mobilechange ? 'docform-active' : ''}`} id="mobile" ref={mobileInputRef} style={{paddingLeft:'90px'}}
                    placeholder="Mobile Number" value={mobilechange} name="mobile"  onKeyPress={handleKeyPress}  onPaste={(e) => handlePaste(e)} onChange={(e) => handlePhoneNumberChange(e.target.value)}/>
                    <p style={{ position: 'absolute', left:'50px', top: '73%', transform: 'translateY(-50%)', cursor: 'pointer',color:'hsl(0, 0%, 20%)',fontSize:'14px',lineHeight:'1.5',marginBottom:'0px',fontWeight:'400' }}>{`+${dial_code}`}</p>
                  <input type="hidden" className="form-control signupemalichange "
                    placeholder="Mobile Number" value={id1} onChange={(e) => setMcc_idchange(e.target.value)} />
                </div> */}
                <div className="error-message error-messageprofile">{error422}</div>
                <div className="name_button_div">
                <button type="button" onClick={cancel} className="me-3  profilecancel">
                  Cancel
                </button>
                <button type="button" onClick={next} style={{ backgroundColor: isSaveButtonEnablednext ? '#02CD8D' : '#EAEAEA', color: isSaveButtonEnablednext ? '#FFF' : '#333' , cursor: isSaveButtonEnablednext ? 'pointer' : 'not-allowed' }} disabled={!isSaveButtonEnablednext} className={`profilesave`}>
                  Next
                </button>
              </div>
                </div>
            </div>
            </>
            )}
            {showmobileotppage && (
              <>
                <div className="name_div1">
            <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={back} alt="leftarrow" className="name_arrow "></img>
              <h5 className="nameh5">Verify Mobile</h5>
            </div>
            <div className="name-input name-inputimage name-inputimageemail ">
            <div>
                  <label htmlFor="inputField"  className="form-label" style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#000000'}}>Verification Code</label>
                  <input type="text"  value={otpchange} onChange={handleChange} onKeyDown={handleKeyDown}  maxLength={6} className={`form-control docform ${otpchange ? 'docform-active' : ''}`}  id="inputField" placeholder="Enter Verification Code" />
                </div>
                <div className="error-message error-messageprofile">{error422}</div>
                <div className="name_button_div">
                <button type="button" onClick={cancel} className="me-3  profilecancel">
                  Cancel
                </button>
                <button type="button" onClick={save} style={{ backgroundColor: isSaveButtonEnablednextotp ? '#02CD8D' : '#EAEAEA', color: isSaveButtonEnablednextotp ? '#FFF' : '#333' , cursor: isSaveButtonEnablednextotp ? 'pointer' : 'not-allowed' }} disabled={!isSaveButtonEnablednextotp} className={`profilesave`}>
                  Save
                </button>
              </div>
              </div>
            </div>
              </>
             )}
            </div>
            </div>
      {/* <Navbars />
      {showemailpasswordedit && (
      <div className="container-fluid passwordchange">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-7 col-md-8 col-sm-10">
            <div className="box p-4" style={{ backgroundColor: 'white', width: '100%', maxWidth: '620px', minHeight: '424px', borderRadius: '18px', border: '1px solid #ABA7A7' }}>
              <div className="d-flex align-items-center">
                <img onClick={goBack} src={leftarrow} alt="leftarrow" width='19.5px' height='15.5px' className="mb-3 me-4" style={{ cursor: 'pointer' }} />
                <h5 className='mb-3' style={{ fontWeight: '500', fontSize: '24px', lineHeight: '36px', color: '#232323' }}>Edit Mobile</h5>
              </div>
              <div className="d-flex justify-content-center">
              <p style={{ fontWeight: '500', fontSize: '16px', lineHeight: '30px', color: '#292929' }}>To modify Mobile, Kindly enter your password</p>
              </div>
              <div className="d-flex flex-column align-items-center">
                <img src={userProfile?.image} className="rounded-circle header-profile-user border-dark user img-fluid mx-auto d-block mb-3" style={{ maxWidth: '80px', height: '80px' }} alt="Right 3" />
                <p style={{ fontWeight: '500', fontSize: '20px', lineHeight: '30px', color: '#292929' }}>{userProfile?.name}</p>
              </div>
              <div className="d-flex justify-content-start mb-3 ps-5">
                <label htmlFor="password" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Current Password</label>
              </div>
              <div className="password mb-3 ps-5" style={{ position: 'relative' }}>
                <input type={showPassword ? 'text' : 'password'} value={password} onChange={(e)=>setPassword(e.target.value)} className="form-control passwordchangeinputbox" id="userpassword" placeholder="Enter Your Current Password" name="password"/>
                <p className="toggle-password" onClick={toggleVisibility} style={{ position: 'absolute', marginRight: '15px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                  {showPassword ? (
                    <img src={eye} className='eye' width='19.5px' height='19.5px' alt="Show Password"/>
                  ) : (
                    <img src={eyeslash} className='eye' width='19.5px' height='19.5px' alt="Hide Password" />
                  )}
                </p>
              </div>
                  <div className="d-flex align-items-center justify-content-end  mx-md-2 d-block">
                    <button onClick={cancelPasswordChange} type="button" className=" profilecancel me-4">Cancel</button>
                    <button onClick={goToNextStep} disabled={is422ErrorToastActive ||!password.trim()} type="button" className={`profilesave ${!password.trim() ? 'disabledmobile' : ''}`}>Next</button>
                  </div>
            </div>
          </div>
        </div>
      </div>
      )}
      {showmobileedit && (
        <div className="container-fluid emailchangepage">
          <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className="col-lg-7 col-md-8 col-sm-10">
              <div className="box " style={{ maxWidth: '619px', width: '100%', backgroundColor: 'white', minHeight: '255px', borderRadius: '18px', border: '1px solid #ABA7A7', padding: '40px 40px' }}>
                <div className="d-flex align-items-center mb-4">
                  <img onClick={back} className="me-4 mb-2" width="19.5px" height="15.5px" style={{ cursor: 'pointer', marginLeft: '-10px' }} src={leftarrow} alt="edit"></img>

                  <h5 className="" style={{ fontWeight: '500', fontSize: '24px', lineHeight: '36px', color: '#232323' }}>Phone</h5>
                </div>

                <div className="ps-2">
                  <div className="mb-3 position-relative ps-md-5">
                    <label htmlFor="inputField" className="form-label" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Mobile Number</label>
                    <input type="text" readOnly className="form-control emailchangeinput mobilechange" id="mobile"
                      placeholder="Mobile Number" value={mobilechange}  name="mobile" onPaste={(e) => handlePaste(e)} onKeyPress={handleKeyPress} onChange={(e) => handlePhoneNumberChange(e.target.value)} />
                    <p style={{ position: 'absolute', right: '25px', top: '70%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                      <img onClick={mobilenumberedit} style={{ width: '16px', height: '16px', cursor: 'pointer' }} src={pencil} alt="edit"></img>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showmobileedits && (
        <div className={`container-fluid emailchangepage ${isLoading ? 'overflow-hidden' : ''}`}>
          <div className="row d-flex justify-content-center align-items-center emailchange" >
            <div className="col-lg-7 col-md-8 col-sm-10">
              <div className="box " style={{ maxWidth: '619px', width: '100%', backgroundColor: 'white', minHeight: '297px', borderRadius: '18px', border: '1px solid #ABA7A7', padding: '40px 40px' }}>
                <div className="d-flex align-items-center mb-4">
                  <img onClick={backs} className="me-4" width='19.5px' height='15.5px' style={{ cursor: 'pointer' }} src={leftarrow} alt="edit"></img>
                  <h5 className="mb-0" style={{ fontWeight: '500', fontSize: '24px', lineHeight: '36px', color: '#232323' }}>Change Phone</h5>
                </div>


                <div className="mb-3  ps-5">
                  <label htmlFor="inputField" className="form-label" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Mobile Number</label>
                  <input type="text" className="form-control emailchangeinput mobilechange" id="mobile" ref={mobileInputRef}
                    placeholder="Mobile Number" value={mobilechange} name="mobile"  onKeyPress={handleKeyPress}  onPaste={(e) => handlePaste(e)} onChange={(e) => handlePhoneNumberChange(e.target.value)}/>
                  <input type="hidden" className="form-control signupemalichange "
                    placeholder="Mobile Number" value={id1} onChange={(e) => setMcc_idchange(e.target.value)} />
                </div>
                <div className="d-flex justify-content-end  mt-4 mx-3 text-end ps-4">
                  <button type="button" onClick={cancel} className="me-3  profilecancel">
                    Cancel
                  </button>
                  <button type="button" onClick={next} disabled={is422ErrorToastActive ||!mobilechange.trim()} className={`profilesave ${!mobilechange.trim() ? 'disabledmobile' : ''}`}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showmobileotppage && (
        <div className={`container-fluid emailchangepage ${isLoading ? 'overflow-hidden' : ''}`}>
          <div className="row d-flex justify-content-center align-items-center emailchange" >
            <div className="col-lg-7 col-md-8 col-sm-10">
              <div className="box " style={{ maxWidth: '619px', width: '100%', backgroundColor: 'white', minHeight: '297px', borderRadius: '18px', border: '1px solid #ABA7A7', padding: '40px 40px' }}>
                <div className="d-flex align-items-center mb-4">
                  <img onClick={back} className="me-4" width='19.5px' height='15.5px' style={{ cursor: 'pointer' }} src={leftarrow} alt="edit"></img>
                  <h5 className="mb-0" style={{ fontWeight: '500', fontSize: '24px', lineHeight: '36px', color: '#232323' }}>Verify Mobile Number</h5>
                </div>
                <div className="mb-3  ps-5">
                  <label htmlFor="inputField" className="form-label" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Verification Code</label>
                  <input type="text" value={otpchange} onChange={(e) => setOtpchange(e.target.value)} className="form-control emailchangeinput" id="inputField" placeholder="Enter Verification Code" />
                </div>
                <div className="d-flex justify-content-end  mt-4 mx-3 text-end ps-4">
                  <button type="button" onClick={cancel} className="me-3  profilecancel">
                    Cancel
                  </button>
                  <button type="button" onClick={save} disabled={is422ErrorToastActive ||!otpchange.trim()} className={`profilesave ${!otpchange.trim() ? 'disabledmobile' : ''}`}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
}
export default WithToast(Mobilenumberchange);