import {React,useState} from "react";
import Registerservices from "./registerservices";
import Navbars from "./navbar";
import plus from './images/plus.png';
import Modal from 'react-bootstrap/Modal';
import closeicon from './images/Icons (1)/close-01.png';
import trash  from './images/paypal/trash-01.png';
import edit from './images/paypal/pen-01.png';
function Organization (){
    const [show21 , setShow21] = useState(false);
    const [enterorganizationname , setEnteroraganzationname] = useState('');
    const [enterorganizationid , setEnteroraganzationid] = useState('');
    const [enterorganizationemail , setEnteroraganzationemail] = useState('');
    
    const addneworganization = () => {
       setShow21(true);
    }
    const handleCloses21 = () => {
        setShow21(false);
        setEnteroraganzationemail('');
        setEnteroraganzationid('');
        setEnteroraganzationname('');
    }
    const closedialog21 = () => {
        setShow21(false);
        setEnteroraganzationemail('');
        setEnteroraganzationid('');
        setEnteroraganzationname('');
    }
    const getButtonClasss21 = () => {
        return enterorganizationname && enterorganizationid && enterorganizationemail > '' ? 'addnewsaveafter' : 'addnewsave';
    }
    const save = () => {
        setShow21(false);
        setEnteroraganzationemail('');
        setEnteroraganzationid('');
        setEnteroraganzationname('');
    }
    return(
        <>
          {/* <Navbars/>
        <div className="container-fluid " style={{backgroundColor:'#F8F8F8',width:'100%',height:'100%'}}>
            <div className="row pt-4">
                <div className="col-lg-3 col_corre_2">
                    <Registerservices/>
                </div> */}
                <div className="col-lg-9 col_corr_1"  >
                    <main className="" style={{backgroundColor:'#FFFFFF',width:'100%',boxShadow:"0px 0px 5px 0px #0000001A",height:'100%',minHeight:'400px'}}> 
                    <h5 className="ps-4 pt-4">Organization/Company</h5>
                       <p className="cardsp ps-4 mb-4">This is a system "D Transfer" that are awarded to the app user after engaging in some activity on the app, or website</p>
                       <div className="massage_box_corr" style={{width:'100%',margin:'0 2.5%',maxWidth:'95%',minHeight:'400px',border:'1px solid #7D7878', }}>
                            <div className="d-flex align-items-center justify-content-between p-4">
                         <h5 style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#494949'}}>Organization/Company</h5>
                        <button className="addnewcardss" onClick={addneworganization}><img src={plus} className="me-2" alt="plus" width='15px' height='15px'></img>Add Organization/Company</button>
                    </div>
                    <div className="d-flex radio align-items-center w-100" style={{ padding: '0px 30px' }}>
                                    <input type="radio" id="myRadio" style={{  marginRight: '20px', }}></input>
                                    <div className="paybox">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="bankcol-70">
                                                <div className="d-flex justify-content-between">
                                                    <div className="">
                                                        <p className="paymobilename">Company Name</p>
                                                        <p className="paybankname">Torora Cement Ltd</p>
                                                    </div>
                                                    <div className="">
                                                        <p className="paymobilename">Company ID</p>
                                                        <p className="paybankname">TOR456</p>
                                                    </div>
                                                    <div className="">
                                                        <p className="paymobilename">Email</p>
                                                        <p className="paybankname">johndoe@gmail.com</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bankcol-30">
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <img src={edit} className="me-3" width='16px' height='16px' alt="edit"></img>
                                                        <img src={trash} width='16px' height='16px' alt="trash"></img>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                       </div>
                    </main>
                </div>
            {/* </div>
           </div> */}

           <Modal show={show21} dialogClassName="example-dialog21" contentClassName="example-content21" onHide={handleCloses21} centered>
                <Modal.Body style={{ margin: '0', padding: '0'}}>
                <div className="text-end">
                        <img src={closeicon} onClick={closedialog21} style={{cursor:'pointer'}} className="closeicon mx-2 mt-2" alt="closeicon" width="32px" height="32px"></img>
                    </div>
                    <div className="p-2">
                        <h5 className="addnewwh5 ps-4">Add Organization/Company</h5>
                    </div>
                    <div className="mt-2 mb-3" style={{margin:'0 8%'}}>
                        <label htmlFor="cardNumber" className="cardlabel">Enter Organization/Company Name</label>
                        <input type="text" id="cardNumber" value={enterorganizationname} onChange={(e) => setEnteroraganzationname(e.target.value)}  name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div className="mt-2 mb-3" style={{margin:'0 8%'}}>
                        <label htmlFor="cardNumber" className="cardlabel">Enter Organization/Company ID</label>
                        <input type="text" id="cardNumber"   value={enterorganizationid} onChange={(e) => setEnteroraganzationid(e.target.value)}  name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div className="mt-2 mb-4" style={{margin:'0 8%'}}>
                        <label htmlFor="cardNumber" className="cardlabel">Enter your Company Email</label>
                        <input type="text" id="cardNumber"  value={enterorganizationemail} onChange={(e) => setEnteroraganzationemail(e.target.value)}   name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div style={{margin:'0 8%'}}>
                        <button  className={getButtonClasss21()} onClick={save}>Save</button>
                    </div>
                </Modal.Body> 
            </Modal>
        </>
    );
}
export default Organization;