import React from "react";
import Navbars from "./navbar";
import wallettowallet from "./images/wallet_to_wallet-01.png";
function Orderdetail(){
    return(
        <>
        <Navbars/>
        <div className="container-fluid " style={{ backgroundColor: '#F8F8F8', width: '100%', height: '100%' }}>
            <div className="____row order-details" style={{paddingTop:'135px'}}>
            <div className="col col_75">
                <div className="box-sh">
                    <h5 className="">Order Details</h5>
                    <div className="list-parent-of">
                        <ul className="list-parent d-flex">
                           <li className="list">
                                <p>Merchant:</p>
                                <span>EVzone Marketplace</span>
                            </li>
                            <li className="list">
                                <p>Merchant:</p>
                                <span>EVzone Marketplace</span>
                            </li>
                            <li className="list">
                                <p>Merchant:</p>
                                <span>EVzone Marketplace</span>
                            </li>
                            <li className="list">
                                <p>Merchant:</p>
                                <span>EVzone Marketplace</span>
                            </li>
                        </ul>
                    </div>
                    <div className="image-desc-text d-flex">
                        <div className="image">
                            <img src={wallettowallet} alt="wallet" ></img>
                        </div>
                        <div className="order-details">
                            <h4>Tesla</h4>
                            <p>Retractable EV Charger Cable </p>
                        </div>
                        <div className="ugx-text">
                            <p>UGX 1000</p>
                        </div>
                        <div className="quentity">
                            <p>Qty</p>
                            <span>2</span>
                        </div>
                        <div className="ugx-text">
                            <p>UGX 2000</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col col_25">
                <div className="paddding-align-ment">
                    <img src={wallettowallet} alt="wallet" ></img>
                    <h4 className="padh4">Tesla</h4>
                    <div className="d-flex">
                        <p className="pademailp">Email : </p>
                        <span className="pademailp"> press@tesla.com</span>
                    </div>
                    <div className="d-flex">
                        <p className="pademailp">Phone Number : </p>
                        <span className="pademailp"> 3564789646 </span>
                    </div>
                </div>
                <div className="paddding-align-ment">
                    <h4 className="padh5">Order Summary</h4>
                    <div className="d-flex">
                        <p className="padflexp">Order ID :</p>
                        <span className="padflexp">23564686458126 </span>
                    </div>
                    <hr style={{marginTop:'0'}}></hr>
                    <div className="d-flex justify-content-between align-baseline">
                        <p className="padflexp">Product(s) Price</p>
                        <span className="padflexp1">UGX 52,360</span>
                    </div>
                    <div className="d-flex justify-content-between align-baseline">
                        <p className="padflexp">Discount(s)</p>
                        <span className="padflexp1">0</span>
                    </div>
                    <div className="d-flex justify-content-between align-baseline">
                        <p className="padflexp">Packaging fee</p>
                        <span className="padflexp1">UGX 52,360</span>
                    </div>
                    <div className="d-flex justify-content-between align-baseline">
                        <p className="padflexp">Shipping Fees:</p>
                        <span className="padflexp1"></span>
                    </div>
                    <div className="d-flex justify-content-between align-baseline">
                        <p className="padflexp2 ">Freight Costs</p>
                        <span className="padflexp1">UGX 1,000</span>
                    </div>
                    <div className="d-flex justify-content-between align-baseline">
                        <p className="padflexp2">Port fees</p>
                        <span className="padflexp1">UGX 1,000</span>
                    </div>
                    <div className="d-flex justify-content-between align-baseline">
                        <p className="padflexp2">Toll Charges</p>
                        <span className="padflexp1">UGX 1,000</span>
                    </div>
                    <div className="d-flex justify-content-between align-baseline mt-2">
                        <p className="padflexp">Customs and duties:</p>
                        <span className="padflexp1"></span>
                    </div>
                    <div className="d-flex justify-content-between align-baseline">
                        <p className="padflexp2 ">import duties</p>
                        <span className="padflexp1">UGX 1,000</span>
                    </div>
                    <div className="d-flex justify-content-between align-baseline">
                        <p className="padflexp2">Custom Clearance fees</p>
                        <span className="padflexp1">UGX 1,000</span>
                    </div>
                    <div className="d-flex justify-content-between align-baseline">
                        <p className="padflexp2">Border fees</p>
                        <span className="padflexp1">UGX 1,000</span>
                    </div>
                    <div className="d-flex justify-content-between align-baseline mt-2">
                        <p className="padflexp">Taxes & Levies:</p>
                        <span className="padflexp1"></span>
                    </div>
                    <div className="d-flex justify-content-between align-baseline" >
                        <p className="padflexp2" style={{wordBreak:'break-all'}}>VAT(Value Added Tax)</p>
                        <span className="padflexp1">UGX 1,000</span>
                    </div>
                    <div className="d-flex justify-content-between align-baseline" >
                        <p className="padflexp2" style={{wordBreak:'break-all'}}> Excise duty</p>
                        <span className="padflexp1">UGX 1,000</span>
                    </div>
                    <div className="d-flex justify-content-between align-baseline mt-2">
                        <p className="padflexp">Handling Fees</p>
                        <span className="padflexp1">UGX 1,000</span>
                    </div>
                    <div className="d-flex justify-content-between align-baseline mt-2">
                        <p className="padflexp">Insurance Costs</p>
                        <span className="padflexp1">UGX 1,000</span>
                    </div>
                    <div className="d-flex justify-content-between align-baseline mt-2">
                        <p className="padflexp">Last Mile Delivery Fees</p>
                        <span className="padflexp1">UGX 1,000</span>
                    </div>
                    <div className="d-flex justify-content-between align-baseline mt-2">
                        <p className="padflexp">Storage Fees</p>
                        <span className="padflexp1">UGX 1,000</span>
                    </div>
                    <div className="d-flex justify-content-between align-baseline mt-2" style={{wordBreak:'break-all'}}>
                        <p className="padflexp">Environment/Emission Fees</p>
                        <span className="padflexp1">UGX 1,000</span>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </>
    );
}
export default Orderdetail;