import {React,useState,useEffect} from "react";
import Navbars from "./navbar";
import Manualsidebar from "./manualsidebar";
import Collections from "./collection";
import Systemrefund from "./systemrefund";
import Cardtowallet from "./cardtowallet";
import Banktowallet from "./banktowallet";
import Mobilemoneytowallet from "./mobilemoneytowallet";
import Paypaltowallet from "./paypaltowallet";
import Manualgiftcard from "./manualgiftcard";
function Dtransfer(){
    // const [source1, setSource1] = useState('collection');
    const [source1, setSource1] = useState(() => {
        const storedActiveSection = localStorage.getItem('activeSection');
        return storedActiveSection || 'collection'; // Set the default section if none is found in localStorage
      });
      useEffect(() => {
        localStorage.setItem('activeSection', source1);
      }, [source1]);
    return(
        <>
            <Navbars/>
        <div className="container-fluid " style={{backgroundColor:'#F8F8F8',width:'100%',height:'100%'}}>
            <div className="row collectionrow">
                <div className="col-12 col-lg-3 col-md-4 col-xl-3 col-xxl-3 col_corre_2">
                    <Manualsidebar setSource1={setSource1} source1={source1}/>
                </div>
                {source1 === "collection" && 
                <Collections />}
                 {source1 === "systemrefund" && 
                <Systemrefund />}
                {source1 === "cardtowallet" && 
                <Cardtowallet />}
                 {source1 === "banktowallet" && 
                <Banktowallet />}
                 {source1 === "mobilemoneytowallet" && 
                <Mobilemoneytowallet />}
                 {source1 === "paypaltowallet" && 
                <Paypaltowallet />}
                 {source1 === "manualgiftcard" && 
                <Manualgiftcard />}
            </div>
           </div>
        </>
    );
}
export default Dtransfer;