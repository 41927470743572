import { createStore, applyMiddleware ,combineReducers } from 'redux';
import thunk from 'redux-thunk';
import userProfile from './userProfiles/reducers';

const rootReducer = combineReducers({
  userProfile: userProfile,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;

