import React ,{useState,useRef,useEffect, useCallback} from "react";
import {useParams } from "react-router-dom";
import leftarrow from '../images/arrow-left-01.png';
import userCircle from '../images/user-circle.png';
import { useNavigate } from "react-router-dom";
import { customStyles1, extractLoginUser, handleApiError} from "../api/common/commonapi";
import upload from '../images/upload-alt 1.png'
import delteicon from "../images/general-id/delete-01.png";
import editicon from '../images/job_details_icons/edit_1-01.png';
import Select, { components } from 'react-select';
// import {isValidNumber} from 'libphonenumber-js';
import {country_codeapi,publicDomain, Updateemergencyapi,getemergencyapi, getemergencyrelationlistapi} from "../api/url";
import axiosInstance from "../api/common/axiosinstand";
import Navbars from "../navbar";
import WithToast from "../api/common/withtoast";
const storeemergencyapiurl = Updateemergencyapi();
const countrycodeurl = country_codeapi();
const publicDomainurl = publicDomain();
const getemergencyapiurl = getemergencyapi();
const getemergencyrelationlistapiurl = getemergencyrelationlistapi();
function Updateemergencyinfo({showErrorToast}){
    const {dualdynamic} = useParams(); 
    const [emerget,setEmerget] = useState([]);
    const login_user = extractLoginUser();
    const navigate = useNavigate();
    const [changedimage,setChangedimage] = useState(emerget.image ? true : false);
    const [error, setError] = useState({nameerror:'',mobileerror:'',emailerror:''});
    const [name,setName] = useState(emerget.name ? emerget.name : '');
    const [isSaveButtonEnablednext, setIsSaveButtonEnablednext] = useState(false);
    const [mobileChange,setMobileChange] = useState(emerget.mobile ? emerget.mobile : '');
    const [email ,setEmail] = useState(emerget.email ? emerget.email : '');
    const [profileImage, setProfileImage] = useState(emerget.image ? emerget.image : null);
    const [isNextOfKin, setIsNextOfKin] = useState('');
    const [isLoading4, setIsLoading4] = useState(false);
    const [notchangedprofile,setNotchangedprofile] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const mobileInputRef = useRef(null);
    const [dialCode, setDialCode] = useState('');
    const [mccId, setMccId] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [relationoptions,setRelationoptions] = useState([]);
    const [relation,setRelation] = useState(null);
    const [countryOptions, setCountryOptions] = useState([]);
    const [error422,setError422] = useState('');
    const [initialloading,setInitialloading] = useState(true);
    useEffect(() => {
      const getEmergencyData = async () => {
        try {
          const response = await axiosInstance.get(getemergencyapiurl);
          if (response.status === 200) {
            const emergency = response.data.data;
            const matchingEntry = emergency.find(entry => entry.id.toString() === dualdynamic.toString());
            if (matchingEntry) {
              setEmerget(matchingEntry);
              setName(matchingEntry.name || '');
              setMobileChange(matchingEntry.mobile || '');
              setEmail(matchingEntry.email || '');
              setChangedimage(matchingEntry.image ? true : false);
              setProfileImage(matchingEntry.image || null);
              setIsNextOfKin(matchingEntry.kin_status === 1);
              setCountryCode(matchingEntry.country_code || 'UG');
              setRelation(matchingEntry.relation_id ? {value:matchingEntry.relation_id,label:matchingEntry.relation_name} : null)
            } else {
              navigate(`/u/${login_user}/profile`);
            }
          }
        } catch (error) {
          handleApiError(error, null, null, true, showErrorToast);
        }
      };
      getEmergencyData().finally(()=>setInitialloading(false));
    }, [dualdynamic, login_user, navigate, showErrorToast]);

    useEffect(()=>{ 
      const getrelationlist = async() => {
        if(relationoptions.length > 0) return
        try{
           const response = await axiosInstance.get(getemergencyrelationlistapiurl);
           if(response.status === 200){
              const relationlist = response.data.data;   
              setRelationoptions(relationlist.map(relat => ({value:relat.id,label:relat.name})))         
           }
        }catch(error){
         handleApiError(error, null, null, true, showErrorToast);
        }
      };
      getrelationlist();
      },[relationoptions.length,showErrorToast])
    const back = () => {
    navigate(`/u/${login_user}/profile`);
    }
    const cancel = () => {
    navigate(`/u/${login_user}/profile`);
    }
    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setChangedimage(true);
                setNotchangedprofile(true);
                setProfileImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const deleteimage = () => {
      setProfileImage(null);
      setChangedimage(false);
    }
    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
        setError422('');
    };
    const updateExampleNumber = (iso2) => {
      if (window.intlTelInputUtils) {
        try {
          const exampleNumber = window.intlTelInputUtils.getExampleNumber(
            iso2,
            true,
            window.intlTelInputUtils.numberFormat.E164
          );
          const cleanedNumber = exampleNumber.replace(/\D/g, '').replace(/^0+/, '');
          const maxLength = cleanedNumber.length;
          return maxLength;
        } catch (error) {
          console.error('Error fetching example number for country code:', iso2, error);
          return 15; // Fallback to a default length
        }
      } else {
        console.error('intlTelInputUtils is not loaded');
        return 15; // Fallback to a default length
      }
    };
    useEffect(() => {
      const fetchCountryCode = async () => {
        if(countryOptions.length > 0){
          return;
        }
        setIsLoading(true);
        try {
          const response = await axiosInstance.get(countrycodeurl);
          const data = response.data;
  
          if (data.message === 'Success') {
            const mccList = data.data;
            const codes = mccList.map((mcc) => ({
              country_code: mcc.country_code,
              dial_code: mcc.code,
              mcc_id: mcc.id,
              label: `${mcc.name} (+${mcc.code})`,
              value: mcc.country_code,
              flag: `https://flagcdn.com/w320/${mcc.country_code.toLowerCase()}.png`,
            }));
  
            setCountryOptions(codes);
             let code = countryCode
            const selectedCountryData = codes.find(
              (country) => country.country_code === code
            );
  
            if (selectedCountryData) {
              setMccId(selectedCountryData.mcc_id);
              setDialCode(`+${selectedCountryData.dial_code}`);
              setCountryCode(selectedCountryData.country_code);
  
              const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
              if (maxLength !== null && mobileInputRef.current) {
                mobileInputRef.current.setAttribute('maxlength', maxLength);
              }
            }
          } else {
            console.error('Failed to fetch country codes');
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };
      if(!initialloading){
      fetchCountryCode();
      }
    }, [countryOptions.length,countryCode,initialloading]);
      const handleKeyPress = (e) => {
        // Allow only numeric characters (0-9) and some specific control keys
        const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];
    
        if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
          e.preventDefault();
        }
      };
      const handlePhoneNumberChange = (value) => {
        if (value.startsWith('0')) {
          value = value.slice(1);
        }
        const maxLength = mobileInputRef.current.getAttribute('maxlength');
        if (value.length > maxLength) {
          value = value.slice(0, maxLength);
        }
        setMobileChange(value);
        // const fullNumber = `${dialCode}${value}`;
        // if (fullNumber.length <= maxLength + dialCode.length && isValidNumber(fullNumber)) {
        //   setIsValidPhoneNumber(true);
        // } else {
        //   setIsValidPhoneNumber(false);
        // }
      };
      const handlePaste = (e) => {
        const pastedText = e.clipboardData.getData('text');
        let numericPastedText = pastedText.replace(/[^0-9]/g, '');
    
        // Define the dial code without the '+'
        const dialCodeWithoutPlus = dialCode.replace('+', '');
    
        // Remove dial code if the pasted text starts with '+'
        if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
            numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
        }
    
        // Remove leading '0' if present
        if (numericPastedText.startsWith('0')) {
            numericPastedText = numericPastedText.slice(1);
        }
    
        // Calculate the max length and truncate the number if necessary
        const maxLength = mobileInputRef.current.getAttribute('maxlength');
        const truncatedPastedText = numericPastedText.slice(0, maxLength);
    
        setMobileChange(truncatedPastedText);
    
        // const fullNumber = `${dialCode}${truncatedPastedText}`;
        // if (isValidNumber(fullNumber)) {
        //     setIsValidPhoneNumber(true);
        // } else {
        //     setIsValidPhoneNumber(false);
        // }
    
        e.preventDefault();
    };
    
    const handleCountryChange = (selectedOption) => {
      const selectedCountryData = countryOptions.find(
          (country) => country.value === selectedOption.value
      );
    
      if (selectedCountryData) {
          setMccId(selectedCountryData.mcc_id);
          setDialCode(`+${selectedCountryData.dial_code}`);
          setCountryCode(selectedCountryData.country_code);
    
          const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
          if (maxLength !== null && mobileInputRef.current) {
              mobileInputRef.current.setAttribute('maxlength', maxLength);
          }
    
          // Truncate the mobile number to the new max length
          const truncatedMobileChange = mobileChange.slice(0, maxLength);
          setMobileChange(truncatedMobileChange);
    
          // Validate the new truncated number
          // const fullNumber = `${selectedCountryData.dial_code}${truncatedMobileChange}`;
          // if (isValidNumber(fullNumber)) {
          //     setIsValidPhoneNumber(true);
          // } else {
          //     setIsValidPhoneNumber(false);
          // }
      }
    };
    
    const customSingleValue = ({ data }) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={data.flag}
          alt=""
          style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
        />
        {/* {data.label} */}
      </div>
    );
    
    const customOption = (props) => {
      return (
        <components.Option {...props}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={props.data.flag}
              alt=""
              style={{ width: '25px', height: 'auto', marginRight: '10px' }}
            />
            {props.data.label}
          </div>
        </components.Option>
      );
    };
    const handleKeyDown = (e) => {
      // Regular expression to allow only alphabets
      const regex = /^[a-zA-Z\s]*$/;
      
      // Check if the input character is valid
      if (!regex.test(e.key)) {
        e.preventDefault();
        setError((prevstate)=>({
          ...prevstate,nameerror:'Only alphabets are allowed'
        }))
        // setError('Only alphabets are allowed');
      } else {
        setError((prevstate)=>({
          ...prevstate,nameerror:''
        }))
      }
    };
    const check = useCallback(()=>{
      const valid = (
        name !== '' && mobileChange !== '' && relation !=='' && relation !== null
      )
      setIsSaveButtonEnablednext(valid);
    },[name,mobileChange,relation])
    useEffect(()=>{
      check();
    },[check])
       const save = async () => {
        if(mobileChange.length < 9 || mobileChange.length > 15) {
          setError((prevstate)=>({
            ...prevstate,mobileerror:'The mobile must be between 9 and 15 digits'
          }))
          // setError422('The mobile must be between 9 and 15 digits')
          return false;
        }else{
          setError((prevstate)=>({
            ...prevstate,mobileerror:''
          }))
        }
        // if(!isValidPhoneNumber){
        //   setError((prevstate)=>({
        //     ...prevstate,mobileerror:'Please enter a valid phone number'
        //   }))
        //   // setError422('Please enter a valid phone number');
        //   return false;
        // }else{
        //   setError((prevstate)=>({
        //     ...prevstate,mobileerror:''
        //   }))
        // }
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email && !emailPattern.test(email)) {
          setError((prevstate)=>({
            ...prevstate,emailerror:'Please enter a valid email address.'
          }))
          return;
        }else{
          setError((prevstate)=>({
            ...prevstate,emailerror:''
          }))
        }
        const data = {
          id:emerget.id,  
          name: name,
          mobile: mobileChange,
          mcc_id: mccId,
          kin_status : isNextOfKin ? 1 : 0,
          email : email,
          relation_id : relation.value,
          // image :  profileImage,
        };
        if (notchangedprofile) {
            data.image = profileImage;
        }else if(!changedimage){
          data.image = null
        }
        try{
          setIsLoading4(true);
          const response = await axiosInstance.post(storeemergencyapiurl,data);
          if(response.status === 200){
            setIsLoading4(false);
            navigate(`/u/${login_user}/profile`);
          }
        }catch(error){
           handleApiError(error,setIsLoading4,setError422,false,showErrorToast)
        }
      }
      const customstyle = {
        control: (provided) => ({
          ...provided,
          width:'80px',
          height: '100%',
          border: 'none',
          boxShadow: 'none',
          background: 'transparent',
          display: 'flex',
          alignItems: 'center',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          padding: '0 8px',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        singleValue: (provided) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: '0',
          display: 'flex',
          alignItems: 'center',
        }),
        input: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
        }),
        placeholder: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
        }),
        menu: (provided) => ({
          ...provided,
          minWidth: '300px',
        }),
      }
    return(
        <>
        {isLoading4 && (
    <>
      <div className="loaderover">
        <div className="loader "></div>
      </div>
    </>
  )}
  <div>
    <div className="container-fluid" style={{ height: '100px' }}>
      <Navbars />
    </div>
    <div className="name_div">
    <div className="name_div1">
        <div className="d-flex align-items-center">
          <img src={leftarrow} onClick={back} alt="leftarrow" className="name_arrow "></img>
          <h5 className="nameh5">Emergency Contacts</h5>
        </div>
        <div className="name-input">
        <div className="d-flex align-items-center mb-3" style={{gap:'35px'}}> 
        <div className="header-profile-user border-dark user" style={{ width: '70px', height: '70px', borderRadius: '50%', overflow: 'hidden' }}>
        {changedimage ? (
          <img id="login_img_for_profile" src={profileImage.startsWith('data:') ? `${profileImage}` : `${publicDomainurl}/core/${profileImage}`} alt="Profile" loading="lazy"className=" homeprofile mb-3" style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}/>
        ) : (
          <img id="login_img_for_profile" src={userCircle} alt="Profile" loading="lazy"className=" homeprofile mb-3" style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}/>
        )} 
        </div>
        <div>
          <h6 className="emerh6">Update your Picture<span className="emerspan">(optional)</span></h6>
          <p className="emerp">Upload a photo under 2 MB</p>
          {changedimage ? (
            <div className="d-flex align-items-center">
            <button className="emerreplace" onClick={triggerFileInput}><img className="emeruploadreplace" src={editicon} alt="upload" />Replace</button>
            <button className="emerdeleteimage" onClick={deleteimage}><img className="emeruploaddelete" src={delteicon} alt="upload" />Delete</button>
            </div>
          ) : (
            <button className="emerreplace" onClick={triggerFileInput}><img className="emerupload" src={upload} alt="upload" />Upload</button>
          )}  
           <input type="file" accept="image/png,image/jpg,image/jpeg"  id="fileInput" style={{ display: 'none' }} onChange={handleImageUpload}/>
        </div>
        </div>
        <div className="mb-3">
              <label htmlFor="inputField" className="form-label"style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#000000'}}>Name<span className="docpopstar docpopstaremer ">*</span></label>
              <input type="text" className={`form-control docform ${name ? 'docform-active' : ''}`} value={name} onKeyDown={handleKeyDown} onChange={(e) => {setName(e.target.value);setError422('')}} placeholder="Enter Name" id="inputField"  />
              {error.nameerror && <p className="error-message">{error.nameerror}</p>}
            </div>
            <div className="mb-3">
              <label htmlFor="inputField" className="form-label"style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#000000'}}>Phone number<span className="docpopstar docpopstaremer">*</span></label>
              {/* <input type="text" className={`form-control docform ${mobileChange ? 'docform-active' : ''}`} value={mobileChange} onChange={(e) => {setMobileChange(e.target.value)}} placeholder="Enter Name" id="inputField"  /> */}
              <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
            <div style={{ position: 'absolute', left: 0, zIndex: 1 }}>
            <Select options={countryOptions} onChange={handleCountryChange} value={countryOptions.find(option => option.value === countryCode)} components={{ SingleValue: customSingleValue, Option: customOption }} placeholder="" isLoading={isLoading} styles={customstyle}/>
            </div>
            <p style={{ position: 'absolute', left:'78px', top: '51%', transform: 'translateY(-50%)', cursor: 'pointer',color:'hsl(0, 0%, 20%)',fontSize:'14px',lineHeight:'1.5',marginBottom:'0px',fontWeight:'400' }}>{dialCode}</p>
           <input type="text" className={`form-control docform ${mobileChange ? 'docform-active' : ''}`} id="mobile" ref={mobileInputRef} style={{ paddingLeft: '120px', width: '100%' }} placeholder="Mobile Number" value={mobileChange} name="mobile" onPaste={(e) => handlePaste(e)} onKeyPress={handleKeyPress} onChange={(e) => {handlePhoneNumberChange(e.target.value);setError422('')}}/>
           </div>
           <input type="hidden" className="form-control signupemalichange" placeholder="Mobile Number" value={mccId} onChange={(e) => setMccId(e.target.value)}/>
            </div>
            {error.mobileerror && <p className="error-message">{error.mobileerror}</p>}
            <div className="mb-3">
              <label htmlFor="inputField" className="form-label"style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#000000'}}>Email Address</label>
              <input type="email" className={`form-control docform ${email ? 'docform-active' : ''}`} value={email} onChange={(e) => {setEmail(e.target.value);setError422('')}} placeholder="Enter Name" id="inputField"  />
            </div>
            {error.emailerror && <p className="error-message">{error.emailerror}</p>}
            <div className="mb-3">
                  <label htmlFor="inputField" className="form-label"style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#000000'}}>Relation<span className="docpopstar docpopstaremer">*</span></label>
                  <Select name="relation" options={relationoptions} value={relation} onChange={(selectOption)=>setRelation(selectOption)} className='mt-1' placeholder="Select Relation"  styles={customStyles1(Boolean(relation))}  />
                </div>
            <div className="d-flex align-items-center">
              <input type="checkbox" checked={isNextOfKin} onChange={(e) => setIsNextOfKin(e.target.checked)} className="form-check-input emer-check"></input>
              <p className="emercheckp">Make Next of Kin</p>
            </div>
            {error422 && <p className="error-message">{error422}</p>}
            <div className="name_button_div">
            <button type="button" onClick={cancel} className="me-3  profilecancel">
              Cancel
            </button>
            <button type="button" onClick={save} style={{ backgroundColor: isSaveButtonEnablednext ? '#02CD8D' : '#EAEAEA', color: isSaveButtonEnablednext ? '#FFF' : '#333' , cursor: isSaveButtonEnablednext ? 'pointer' : 'not-allowed' }} disabled={!isSaveButtonEnablednext} className={`profilesave`}>
              Save
            </button>
          </div>
        </div>
        </div>
      </div>
    </div>
    </>
    );
}
export default WithToast(Updateemergencyinfo);