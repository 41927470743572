import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Select from 'react-select';
import '../home.css';
import leftarrow from '../images/arrow-left-01.png';
import Navbars from "../navbar";
import { dateofbirthapi } from "../api/url";
import axiosInstance from "../api/common/axiosinstand";
import { useDispatch } from 'react-redux';
import { extractLoginUser, handleApiError ,customStyles1} from "../api/common/commonapi";
import { fetchUserProfile } from '../api/redux/userProfiles/actions';
import WithToast from "../api/common/withtoast";

const dateofbirth = dateofbirthapi();

function Datechange({ showErrorToast }) {
  const login_user = extractLoginUser();
  const location = useLocation();
  const { state } = location;
  const dob_visibility = state && state.userBday ? state.userBday : '';
  const date = state && state.userDate ? state.userDate : '';
  const [selectedYears, selectedMonths, selectedDays] = date.split('-');
  const dispatch = useDispatch();
  const [errors, setErrors] = useState('');
  const [error422, setError422] = useState('');
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [isLoading4, setIsLoading4] = useState(false);
  const navigate = useNavigate();
  const back = () => {
    navigate(`/u/${login_user}/profile`);
  };
  const cancel = () => {
    navigate(`/u/${login_user}/profile`);
  };
  const [activeButton, setActiveButton] = useState(dob_visibility === 'private' ? 'only-you' : 'any-one');
  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };
  const months = [
    "January", "February", "March", "April",
    "May", "June", "July", "August",
    "September", "October", "November", "December"
  ];
  const monthOptions = months.map((month, index) => ({
    value: index + 1,
    label: month
  }));
  const currentYear = new Date().getFullYear();
  const startYear = 1900;
  const years = [];

  for (let year = currentYear; year >= startYear; year--) {
    years.push(year);
  }

  const yearOptions = years.map((year) => ({
    value: year,
    label: year.toString()
  }));

  const generateDayOptions = (selectedMonth, selectedYear) => {
    const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
    const dayOptions = [];

    for (let day = 1; day <= daysInMonth; day++) {
      const formattedDay = day < 10 ? `0${day}` : `${day}`;
      dayOptions.push({
        value: formattedDay,
        label: formattedDay
      });
    }

    return dayOptions;
  };
  const [selectedMonth, setSelectedMonth] = useState(selectedMonths ? parseInt(selectedMonths, 10) : null);
  const [selectedMonthError, setSelectedMonthError] = useState('');
  const [selectedDay, setSelectedDay] = useState(selectedDays ? selectedDays : null);
  const [selectedDayError, setSelectedDayError] = useState('');
  const [selectedYear, setSelectedYear] = useState(selectedYears ? parseInt(selectedYears, 10) : null);
  const [selectedYearError, setSelectedYearError] = useState('');

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption.value);
  };

  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption.value);
  };

  const handleDayChange = (selectedOption) => {
    setSelectedDay(selectedOption.value);
  };
  const check = () => {
    const valid = (
       selectedMonth !== '' &&
       selectedDay !== '' &&
       selectedYear !== '' &&
       selectedMonth !== null &&
       selectedDay !== null &&
       selectedYear !== null
    )
    setIsSaveButtonEnabled(valid);
  }
  useEffect(() => {
    check();
  }, [selectedMonth, selectedDay, selectedYear]);
  const save = async () => {
    if (!selectedMonth) {
      setSelectedMonthError('Required');
      return false;
    } else {
      setSelectedMonthError('');
    }
    if (!selectedDay) {
      setSelectedDayError('Required');
      return false;
    } else {
      setSelectedDayError('');
    }
    if (!selectedYear) {
      setSelectedYearError('Required');
      return false;
    } else {
      setSelectedYearError('');
    }
    if (selectedYear && selectedMonth && selectedDay) {
      const formattedMonth = selectedMonth < 10 ? `0${selectedMonth}` : `${selectedMonth}`;
      var data1 = `${selectedYear}-${formattedMonth}-${selectedDay}`;
    }
    var visibility = activeButton === 'only-you' ? 'private' : 'public';
    var data = JSON.stringify({
      dob_visibility: visibility,
      dob: data1
    });
    try {
      setIsLoading4(true);
      const response = await axiosInstance.post(dateofbirth, data);
      if (response.status === 200) {
        setIsLoading4(false);
        setError422('');
        dispatch(fetchUserProfile());
        setErrors('');
        navigate(`/u/${login_user}/profile`);
      }
    } catch (error) {
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    } finally {
      setIsLoading4(false);
    }
  };

  return (
    <>
      {isLoading4 && (
        <>
          <div className="loaderover">
            <div className="loader">
            </div>
          </div>
        </>
      )}
      <div>
        <div className="container-fluid" style={{ height: '100px' }}>
          <Navbars />
        </div>
        <div className="name_div">
          <div className="name_div1">
            <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={back} alt="leftarrow" className="name_arrow "></img>
              <h5 className="nameh5">Update Date of Birth</h5>
            </div>
            <div className="name-input">
              <div className="row mb-3">
                <div className="col-md-4">
                  <label htmlFor="monthSelect" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Month:</label>
                  <Select id="monthSelect" value={monthOptions.find(option => option.value === selectedMonth)} onChange={handleMonthChange} options={monthOptions} styles={customStyles1(Boolean(selectedMonth))} placeholder="Select Month"/>
                  {selectedMonthError && (<div className='errors'>{selectedMonthError}</div>)}
                </div>
                <div className="col-md-4">
                  <label htmlFor="daySelect" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Day:</label>
                  <Select id="daySelect" value={selectedDay ? { value: selectedDay, label: selectedDay } : null} onChange={handleDayChange} options={generateDayOptions(selectedMonth, selectedYear)} styles={customStyles1(Boolean(selectedDay))} placeholder="Select Day"/>
                  {selectedDayError && (<div className='errors'>{selectedDayError}</div>)}
                </div>
                <div className="col-md-4">
                  <label htmlFor="yearSelect" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Year:</label>
                  <Select id="yearSelect" value={yearOptions.find(option => option.value === selectedYear)} onChange={handleYearChange} options={yearOptions} styles={customStyles1(Boolean(selectedYear))} placeholder="Select Year" />
                  {selectedYearError && (<div className='errors'>{selectedYearError}</div>)}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12 mb-3 birthdaylabel">
                  <label style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Choose who can see your birthday</label>
                </div>
                <div className="col-12 mb-3 d-flex justify-content-center align-items-center">
                  <button
                    type="button"
                    className={`only-you ${activeButton === "only-you" ? "active-btn" : ""}`}
                    onClick={() => handleButtonClick("only-you")}
                  >
                    Only you
                  </button>
                  <button
                    type="button"
                    className={`any-one ${activeButton === "any-one" ? "active-btn" : ""}`}
                    onClick={() => handleButtonClick("any-one")}
                  >
                    Anyone
                  </button>
                </div>
              </div>
              <div className="error-message">{error422}</div>
              <div className="name_button_div">
                <button type="button" onClick={cancel} className="profilecancel me-4">
                  Cancel
                </button>
                <button type="button" onClick={save} style={{ backgroundColor: isSaveButtonEnabled ? '#02CD8D' : '#EAEAEA', color: isSaveButtonEnabled ? '#FFF' : '#333' , cursor: isSaveButtonEnabled ? 'pointer' : 'not-allowed' }} disabled={!isSaveButtonEnabled} className={`profilesave`}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Navbars />
      <div className="container-fluid datechange">
        <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="col-lg-7 col-md-8 col-sm-10">
            <div className="box " style={{ width: '100%', maxWidth: '700px', minHeight: '397px', borderRadius: '18px', border: '1px solid #ABA7A7', backgroundColor: 'white', padding: '40px 30px' }}>
              <div className="d-flex align-items-center mb-3">
                <img src={leftarrow} onClick={back} alt="leftarrow" width='19.5px' height='15.5px' className="mb-3 me-4" style={{ cursor: 'pointer' }}></img>
                <h5 className="mb-3" style={{ fontWeight: '500', fontSize: '24px', lineHeight: '36px', color: '#232323' }}>Update Date of Birth</h5>
              </div>
              <div className="row  mb-3 ps-5">
                <div className="col-md-4">
                  <label htmlFor="monthSelect" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Month:</label>
                  <select className="form-select dayselect custom-input-width" id="monthSelect" value={selectedMonth} placeholder="Month" onChange={handleMonthChange}>
                    {monthOptions}
                  </select>
                  {selectedMonthError && (<div className='errors'>{selectedMonthError}</div>)}
                </div>
                <div className="col-md-4">
                  <div className=" d-flex flex-column">
                    <label htmlFor="dayInput" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Day:</label>
                    <select
                      className="form-select dayselect custom-input-width"
                      id="dayInput"
                      value={selectedDay}
                      onChange={handleDayChange}
                    >
                      <option value="" disabled>Select Day</option>
                      {generateDayOptions(selectedMonth, selectedYear)}
                    </select>
                  </div>
                  {selectedDayError && (<div className='errors'>{selectedDayError}</div>)}
                </div>
                <div className="col-md-4">
                  <div className=" d-flex flex-column">
                    <label htmlFor="yearInput" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Year:</label>
                    <select className="form-select dayselect custom-input-width" id="yearInput" value={selectedYear} onChange={handleYearChange}>
                      <option value="" disabled>Select Year</option>
                      {yearOptions}
                    </select>
                  </div>
                </div>
                {selectedYearError && (<div className='errors'>{selectedYearError}</div>)}
              </div>
              <div className="row mb-3 ps-5">
                <div className="col-12 mb-3 birthdaylabel">
                  <label style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Choose who can see your birthday</label>
                </div>
                <div className="col-12 mb-3 d-flex justify-content-center align-items-center pe-4">
                  <button
                    type="button"
                    className={`only-you ${activeButton === "only-you" ? "active-btn" : ""}`}
                    onClick={() => handleButtonClick("only-you")}
                  >
                    Only you
                  </button>
                  <button
                    type="button"
                    className={`any-one ${activeButton === "any-one" ? "active-btn" : ""}`}
                    onClick={() => handleButtonClick("any-one")}
                  >
                    Anyone
                  </button>
                </div>
              </div>
              {errors && (
                <div className=" text-center text-danger">{errors}</div>
              )}
              <div className="d-flex justify-content-end mb-3 mx-3 mt-3 text-end">
                <button type="button" className=" me-3 profilecancel" onClick={cancel}>
                  Cancel
                </button>
                <button type="button" onClick={save}  disabled={isSaving} className=" profilesave">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
export default WithToast(Datechange);
