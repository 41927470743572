// rootReducer.js

import {
    FETCH_USER_PROFILE_REQUEST,
    FETCH_USER_PROFILE_SUCCESS,
    FETCH_USER_PROFILE_FAILURE,
    FETCH_USER_LANGUAGE_REQUEST,
    FETCH_USER_LANGUAGE_SUCCESS,
    FETCH_USER_LANGUAGE_FAILURE,
  } from './actions'; // Import action types
  
  const initialState = {
    userProfile: {
      image: '',
      name: '',
      dob: '',
      gender: '',
      email: '',
      mobile: '',
      mcc_id: '',
      mcc_countrycode: '',
    },
    languageData:[],
    loading: false,
    error: null,
  };
  
  const userProfile = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_USER_PROFILE_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_USER_PROFILE_SUCCESS:
        return {
          ...state,
          loading: false,
          userProfile: action.payload, // Update userProfile with fetched data
        };
      case FETCH_USER_PROFILE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
        case FETCH_USER_LANGUAGE_REQUEST:
          return{
            ...state,
            loading:true
          };
          case FETCH_USER_LANGUAGE_SUCCESS:
            return{
              ...state,
              loading:true,
              languageData:action.payload,
            };
            case FETCH_USER_LANGUAGE_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.payload,
              };        
      default:
        return state;
    }
  };
  
  export default userProfile;
    
  
