import React, { useEffect, useState, useRef } from 'react';

const SkeletonLoader = ({ documentRef }) => {
  const [skeletonCount, setSkeletonCount] = useState(0);

  useEffect(() => {
    if (documentRef.current) {
      const containerHeight = documentRef.current.clientHeight;
      const skeletonHeight = 100; // Height of one skeleton box (in pixels)
      const count = Math.ceil(containerHeight / skeletonHeight);
      setSkeletonCount(count);
    }
  }, [documentRef]);

  return (
    <>
      {Array.from({ length: skeletonCount }).map((_, index) => (
        <div key={index} className="skeleton-docbox"></div>
      ))}
    </>
  );
};

export default SkeletonLoader;
