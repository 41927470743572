import React from "react";
import Navbars from "./navbar";
import { useNavigate } from "react-router-dom";
import leftarrow from './images/arrow-left-01.png';
import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isValidNumber } from 'libphonenumber-js';
import { toast } from "react-toastify";
import axios from "axios";
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import { country_codeapi, countryselectapi, statelistapi, citylistapi, locationtypesapi, locationupdateapi } from "./api/url";
const country_codeapiurl = country_codeapi();
const countryselectapiurl = countryselectapi();
const statelistapiurl = statelistapi();
const citylistapiurl = citylistapi();
const locationtypesapiurl = locationtypesapi();
const locationstoreapiurl = locationupdateapi();
function Alternativeaddresschange() {
  const location = useLocation();
  const { state } = location;
  const address = state && state.address ? state.address : '';
  console.log(address);
  const navigate = useNavigate();
  var id = address.mcc.country_code;
  const [mcc_idchange, setMcc_idchange] = useState('');
  const [formdata, setFormData] = useState({
    id: address.id,
    firstname: address.first_name || '',
    lastname: address.last_name || '',
    addressmobile: address.mobile || '',
    selectcountry: address.country.id || [],
    statevalue: address.state.id || [],
    addressline1: address.address_line_1 || '',
    addressline2: address.address_line_2 || '',
    postalcode: address.postal_code || '',
    isprimary: address.is_primary || [],
    cites: address.city.id || [],
    location: address.location_type.id || '',
  });

  const [error, setError] = useState({
    firstname: '',
    lastname: '',
    addressmobile: '',
    selectcountry: '',
    statevalue: '',
    addressline1: '',
    addressline2: '',
    postalcode: '',
    isprimary: '',
    cites: '',
    location: '',
  })
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [dial_code, setDial_code] = useState('');
  const mobileInputRef = useRef(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [isPrimary, setIsPrimary] = useState(address.is_primary);
  const [cites, setCites] = useState([]);
  const [locations, setLocations] = useState([]);
  const primaryaarr = [
    { id: 1, name: " Primary Address" },
    { id: 0, name: "Non Primary Address" },
  ]
  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await axios.get(country_codeapiurl);
        if (response.status === 200) {
          const mccList = response.data.data;
          const codes = mccList.map((mcc) => ({
            country_code: mcc.country_code,
            dial_code: mcc.code,
            mcc_id: mcc.id,
          }));
          const selectedCountryData = codes.find(
            (country) => country.country_code === id
          );
          if (selectedCountryData) {
            setMcc_idchange(selectedCountryData.mcc_id);
          }
          // setMcc_idchange(mcc_idchange);
          const phoneInputField = document.querySelector('#mobile');
          const phoneInput = intlTelInput(phoneInputField, {
            initialCountry: selectedCountryData.country_code,
            separateDialCode: false,
            formatOnDisplay: true,
            utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
          });

          // Attach an event listener to handle country change
          phoneInputField.addEventListener('countrychange', () => {
            const selectedCountryData = phoneInput.getSelectedCountryData();
            const mcc_idchange = codes.find(
              (country) => country.country_code === selectedCountryData.iso2.toUpperCase()
            ).mcc_id;
            setMcc_idchange(mcc_idchange);
          });
        }
      } catch (error) {
        console.error('Error fetching country code:', error);
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
        }
        else if (error.response.status === 422) {
          toast.error(error.response.data.mobile_error, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    };

    // Call the fetchCountryCode function when the component mounts
    fetchCountryCode();
    fetchlocationtypesData(); // You can add other necessary function calls here
  }, [id]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(countryselectapiurl)
        if (response.status === 200) {
          setCountries(response.data.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          window.location.href = ('/');
        }
        else if (error.response.status === 422) {
          toast.error(error.response.data.mobile_error, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    };
    fetchCountries();
  }, []);
  const fetchStateData = async () => {
    try {
      if (formdata.selectcountry) {
        // const apiUrl = `${statelistapiurl}?country_id=${formdata.selectcountry}`;
        const response = await axios.get(`${statelistapiurl}?country_id=${formdata.selectcountry}`);
        if (response.data.message === 'Success') {
          setStates(response.data.data);
        }
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
      if (error.response && error.response.status === 401) {
        window.location.href = ('/');
      }
      else if (error.response.status === 422) {
        toast.error(error.response.data.mobile_error, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  useEffect(() => {
    if (formdata.selectcountry) {
      fetchStateData();
    }
  }, [formdata.selectcountry])

  const fetchCityData = async () => {
    try {
      if (formdata.statevalue) {
        // const apiUrl = `${citylistapiurl}?state_id=${formdata.statevalue}`;
        const response = await axios.get(`${citylistapiurl}?state_id=${formdata.statevalue}`);
        if (response.data.message === 'Success') {
          setCites(response.data.data);
        }
      }
    } catch (error) {
      console.error("Error fetching city data:", error);
      if (error.response && error.response.status === 401) {
        window.location.href = ('/');
      }
      else if (error.response.status === 422) {
        toast.error(error.response.data.mobile_error, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  useEffect(() => {
    if (formdata.statevalue) {
      fetchCityData();
    }
  }, [formdata.statevalue]);
  const fetchlocationtypesData = async () => {
    var token = localStorage.getItem('access_token');
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    try {
      const response = await fetch(locationtypesapiurl, { headers });
      const data = await response.json();
      if (data.message === 'Success') {
        setLocations(data.data);
      }
    }
    catch (error) {
      if (error.response && error.response.status === 401) {
        window.location.href = ('/');
      }
      else if (error.response.status === 422) {
        toast.error(error.response.data.mobile_error, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  }
  const statess = () => {
    if (formdata.selectcountry.length === 1) {
      setError((prevErrors) => ({
        ...prevErrors,
        selectcountry: "Please First Select Country ",
      }));
    }
    else {
      setError((prevErrors) => ({
        ...prevErrors,
        selectcountry: "",
      }));
    }
  }
  const city = () => {
    if (formdata.selectcountry.length === 1) {
      setError((prevErrors) => ({
        ...prevErrors,
        selectcountry: 'Please First Select Country',
      }))
      return false;
    }
    else {
      setError((prevErrors) => ({
        ...prevErrors,
        selectcountry: '',
      }))
    }
    if (formdata.statevalue.length === 1) {
      setError((prevErrors) => ({
        ...prevErrors,
        statevalue: 'Please First Select State',
      }));
      return false;
    }
    else {
      setError((prevErrors) => ({
        ...prevErrors,
        statevalue: '',
      }));
    }
  }
  const handleFirstNameChange = (e) => {
    setFormData({ ...formdata, firstname: e.target.value });
    setError({ ...error, firstname: '' }); // Clear the error message
  };

  const handleLastNameChange = (e) => {
    setFormData({ ...formdata, lastname: e.target.value });
    setError({ ...error, lastname: '' }); // Clear the error message
  };

  const handleMobileChanges = (e) => {
    const { value: inputValue } = e.target;
    setFormData({ ...formdata, addressmobile: inputValue });

    const fullNumber = `+${formdata.dial_code}${inputValue}`;

    if (fullNumber && isValidNumber(fullNumber)) {
      setIsValidPhoneNumber(true);
    } else {
      setIsValidPhoneNumber(false);
    }

    setError({ ...error, addressmobile: '' }); // Clear the error message
  };
  const handlePaste = (e) => {
    // Prevent pasting non-numeric characters
    const pastedText = e.clipboardData.getData('text');
    const numericPastedText = pastedText.replace(/[^0-9]/g, '');

    // Update the state with the numeric pasted text
    setFormData({ ...formdata, addressmobile: numericPastedText });

    const fullNumber = `+${dial_code}${numericPastedText}`;

    // Check if the full pasted number is valid
    if (numericPastedText && isValidNumber(fullNumber)) {
      setIsValidPhoneNumber(true);
    } else {
      setIsValidPhoneNumber(false);
    }

    e.preventDefault();
  };

  const handleKeyPress = (e) => {
    // Allow only numeric characters (0-9) and some specific control keys
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];

    if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };
  const handleAddressLine1Change = (e) => {
    setFormData({ ...formdata, addressline1: e.target.value });
    setError({ ...error, addressline1: '' }); // Clear the error message
  };

  const handleAddressLine2Change = (e) => {
    setFormData({ ...formdata, addressline2: e.target.value });
    setError({ ...error, addressline2: '' }); // Clear the error message
  };

  const handlePostalCodeChange = (e) => {
    setFormData({ ...formdata, postalcode: e.target.value });
    setError({ ...error, postalcode: '' }); // Clear the error message
  };
  const handleCountryChange = (selectedCountry) => {
    setFormData({ ...formdata, selectcountry: selectedCountry });
    setError({ ...error, selectcountry: '' });
  };
  const handleStateChange = (selectedState) => {
    setFormData({ ...formdata, statevalue: selectedState });
    setError({ ...error, statevalue: '' });
  };
  const handleCityChange = (selectedCity) => {
    setFormData({ ...formdata, cites: selectedCity });
    setError({ ...error, cites: '' });
  }
  const handleLocationChange = (selectedLocation) => {
    setFormData({ ...formdata, location: selectedLocation });
    setError({ ...error, location: '' });
  }
  const handleCheckboxChange = (event) => {
    setIsPrimary(!isPrimary);
  };
  const save = async () => {
    setError({});
    if (!formdata.firstname.trim()) {
      setError((prevErrors) => ({
        ...prevErrors,
        firstname: "Please Enter Your First Name",
      }));
      return false;
    }
    else {
      setError((prevErrors) => ({
        ...prevErrors,
        firstname: "",
      }));
    }
    if (!formdata.lastname.trim()) {
      setError((prevErrors) => ({
        ...prevErrors,
        lastname: "Please Enter Your Last Name",
      }));
      return false;
    }
    else {
      setError((prevErrors) => ({
        ...prevErrors,
        lastname: "",
      }));
    }

    // Validation for Mobile Number
    if (!formdata.addressmobile.trim()) {
      setError((prevErrors) => ({
        ...prevErrors,
        addressmobile: "Please Enter a Mobile Number",
      }));
      return false;
    } else {
      setError((prevErrors) => ({
        ...prevErrors,
        addressmobile: "",
      }));
    }

    // Validation for Country
    if (!formdata.selectcountry) {
      setError((prevErrors) => ({
        ...prevErrors,
        selectcountry: "Please Select a Country",
      }));
      return false;
    }
    else {
      setError((prevErrors) => ({
        ...prevErrors,
        selectcountry: "",
      }));
    }

    // Validation for State
    if (!formdata.statevalue) {
      setError((prevErrors) => ({
        ...prevErrors,
        statevalue: "Please Select a State",
      }));
      return false;
    }
    else {
      setError((prevErrors) => ({
        ...prevErrors,
        statevalue: "",
      }));
    }

    // Validation for City
    if (!formdata.cites) {
      setError((prevErrors) => ({
        ...prevErrors,
        cites: "Please Select a City",
      }));
      return false;
    }
    else {
      setError((prevErrors) => ({
        ...prevErrors,
        cites: "",
      }));
    }

    // Validation for Address Line 1
    if (!formdata.addressline1.trim()) {
      setError((prevErrors) => ({
        ...prevErrors,
        addressline1: "Please Enter Address Line 1",
      }));
      return false;
    }
    else {
      setError((prevErrors) => ({
        ...prevErrors,
        addressline1: "",
      }));
    }
    if (!formdata.addressline2.trim()) {
      setError((prevErrors) => ({
        ...prevErrors,
        addressline2: "Please Enter Address Line 2",
      }));
      return false;
    }
    else {
      setError((prevErrors) => ({
        ...prevErrors,
        addressline2: "",
      }));
    }
    // Validation for Postal Code
    if (!formdata.postalcode.trim()) {
      setError((prevErrors) => ({
        ...prevErrors,
        postalcode: "Please Enter Postal Code",
      }));
      return false;
    }
    else {
      setError((prevErrors) => ({
        ...prevErrors,
        postalcode: "",
      }));
    }

    // Validation for Location Type
    if (!formdata.location) {
      setError((prevErrors) => ({
        ...prevErrors,
        location: "Please Select a Location Type",
      }));
      return false;
    }
    else {
      setError((prevErrors) => ({
        ...prevErrors,
        location: "",
      }));
    }

    // Validation for Primary Address
    if (formdata.isprimary === '') {
      setError((prevErrors) => ({
        ...prevErrors,
        isprimary: "Please Select an Address Type",
      }));
      return false;
    }
    else {
      setError((prevErrors) => ({
        ...prevErrors,
        isprimary: "",
      }));
    }

    const dataToSave = JSON.stringify({
      first_name: formdata.firstname,
      last_name: formdata.lastname,
      mcc_id: mcc_idchange,
      mobile: formdata.addressmobile,
      country_id: formdata.selectcountry,
      state_id: formdata.statevalue,
      address_line_1: formdata.addressline1,
      address_line_2: formdata.addressline2,
      postal_code: formdata.postalcode,
      is_primary: isPrimary ? 1 : 0,
      city_id: formdata.cites,
      location_type_id: formdata.location,
      id: formdata.id
    });
    console.log(dataToSave);
    const token = localStorage.getItem('access_token');
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    try {
      const response = await axios.post(locationstoreapiurl, dataToSave, { headers });
      if (response.status === 200) {
        toast.success(response.data.message, {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          backgroundColor: '#02CD8D'
        });
        navigate('/profile');
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          toast.error(error.response.data.mobile_error, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        else if (error.response.status === 422) {
          toast.error(error.response.data.mobile_error, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
  };

  const back = () => {
    navigate('/profile');
  }
  const cancel = () => {
    navigate('/profile');
  }
  return (
    <>
      <Navbars />
      <div className="container-fluid homeadress">
        <div className="row justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
          <div className="col-lg-7 col-md-8 col-sm-10  col-12">
            <div className="box" style={{ width: '100%', minHeight: '522px', borderRadius: '18px', border: '1px solid #ABA7A7', backgroundColor: 'white', padding: '30px 30px' }}>
              <div className="d-flex align-items-center mt-3 mb-4">
                {/* Arrow Icon */}
                <img onClick={back} className="me-4" width='19.5px' height='15.5px' style={{ cursor: 'pointer', marginLeft: '-10px' }} src={leftarrow} alt="edit"></img>

                {/* Heading */}
                <h5 className="mb-0" style={{ fontWeight: '500', fontSize: '24px', lineHeight: '36px', color: '#232323' }}>Address</h5>
              </div>      
              <div style={{ padding: '0 50px' }}>
              {/* Label and Input Box for Address */}
              <div className="row mb-2 ">
                <div className="col-12 col-md-6 col-sm-6 ">
                  <label className="form-label" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }} >First Name<span className="docpopstar1">*</span></label>
                  <input type="text" className="form-control homeaddressstreetbox" value={formdata.firstname} onChange={handleFirstNameChange} placeholder="Please enter first name" />
                  {error.firstname && (<div className='errors'>{error.firstname}</div>)}
                </div>
                <div className="col-12 col-md-6  col-sm-6">
                  <label className="form-label" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Last Name<span className="docpopstar1">*</span></label>
                  <input type="text" className="form-control homeaddressstreetbox" value={formdata.lastname} onChange={handleLastNameChange} placeholder="Please enter last name" />
                  {error.lastname && (<div className='errors'>{error.lastname}</div>)}
                </div>
              </div>
              <input type="hidden" value={formdata.id} ></input>
              {/* Two Labels in a Single Row */}
              <div className="row mb-2">
                <div className="col-12 col-md-6 col-sm-6">
                  <label className="form-label" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Mobile Number<span className="docpopstar1">*</span></label>
                  <input type="text" className="form-control homeaddressstreetbox mobilechange" onPaste={(e) => handlePaste(e)} onKeyPress={handleKeyPress} value={formdata.addressmobile} onChange={handleMobileChanges} ref={mobileInputRef} id="mobile" placeholder="Enter a Mobile " />
                  <input type="hidden" value={mcc_idchange} onChange={(e) => setMcc_idchange(e.target.value)} className="form-control signupemalichange" placeholder="Mobile Number" />
                  {error.addressmobile && (<div className='errors'>{error.addressmobile}</div>)}
                </div>
                <div className="col-12 col-md-6  col-sm-6">
                  <label className="form-label text-start" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Location Type<span className="docpopstar1">*</span></label>
                  <div className="select-custom-container2">
                    <select className={`form-control homeaddressstreetbox select-custom2 ${formdata.location ? 'has-value' : ''}`} value={formdata.location} onChange={handleLocationChange} placeholder="Enter a City" style={{ fontSize: '14px', color: formdata.location ? '#000' : 'grey', }}>
                      <option value="" disabled>Choose Location type</option>
                      {locations.map((locate) => (
                        <option key={locate.id} value={locate.id}>
                          {locate.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {error.location && (<div className='errors'>{error.location}</div>)}
                </div>
              </div>
              <div className="col-12">
                <label className="form-label" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Address line1<span className="docpopstar1">*</span></label>
                <input type="text" className="form-control homeaddressstreetbox mb-2" value={formdata.addressline1} onChange={handleAddressLine1Change} placeholder="Please enter Address line 1" />
                {error.addressline1 && (<div className='errors'>{error.addressline1}</div>)}
              </div>
              <div>
                <label className="form-label text-start " style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Address line2<span className="docpopstar1">*</span></label>
                <input type="text" value={formdata.addressline2} onChange={handleAddressLine2Change} className="form-control homeaddressstreetbox mb-2" placeholder="Please enter Address line 2" />
                {error.addressline2 && (<div className='errors'>{error.addressline2}</div>)}
              </div>
              <div className="row mb-2">
                <div className="col-12 col-md-6  ">
                  <label className="form-label" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Country<span className="docpopstar1">*</span></label>
                  <div className="select-custom-container2">
                    <select className="form-control homeaddressstreetbox select-custom2" value={formdata.selectcountry} onChange={handleCountryChange} style={{ fontSize: '14px' }} placeholder="Enter a Country">
                      <option value='' disabled>Choose Country</option>
                      {countries.map((country) => (
                        <option key={country.id} value={country.id}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {error.selectcountry && (<div className='errors'>{error.selectcountry}</div>)}
                </div>
                <div className="col-12 col-md-6 ">
                  <label className="form-label" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>State<span className="docpopstar1">*</span></label>
                  <div className="select-custom-container2">
                    <select className="form-control homeaddressstreetbox select-custom2" value={formdata.statevalue} onChange={handleStateChange} onClick={state} placeholder="Enter a State" style={{ fontSize: '14px', }} >
                      <option value="" disabled>Choose State</option>
                      {states.map((state) => (
                        <option key={state.id} value={state.id}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {error.statevalue && (<div className='errors'>{error.statevalue}</div>)}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-12 col-md-6 ">
                  <label className="form-label text-start" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>City<span className="docpopstar1">*</span></label>
                  <div className="select-custom-container2">
                    <select className="form-control homeaddressstreetbox select-custom2" onClick={city} value={formdata.cites} onChange={handleCityChange} placeholder="Enter a City" style={{ fontSize: '14px', }}>
                      <option value="" disabled>Choose City</option>
                      {cites.map((city) => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {error.cites && (<div className='errors'>{error.cites}</div>)}
                </div>
                <div className="col-12 col-md-6 ">
                  <label className="form-label" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Postal Code</label>
                  <input type="text" className="form-control homeaddressstreetbox" value={formdata.postalcode} onChange={handlePostalCodeChange} placeholder="Enter Postcode" />
                  {error.postalcode && (<div className='errors'>{error.postalcode}</div>)}
                </div>
              </div>
              <div className="col-12 ">
                <div className="form-check mt-4">
                  <input type="checkbox" className="form-check-input addressbox "
                    id="customControlInline" checked={isPrimary} onChange={handleCheckboxChange} style={{ width: '20.22px', height: '20.22px', boxShadow: 'none', marginTop: '-1px', backgroundColor: 'white' }} />
                  <label className="form-check-label" style={{ fontSize: "16px", fontWeight: "500", lineHeight: "24px", color: '#000000' }}  htmlFor="customControlInline">Primary Address
                  </label>
                </div>
              </div>
              <div className="d-flex justify-content-end  mt-4 text-end">
                <button type="button" className=" me-3 profilecancel" onClick={cancel}>
                  Cancel
                </button>
                <button type="button" onClick={save} className=" profilesave">
                  Save
                </button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Alternativeaddresschange;