import React, { useState, useEffect, useRef } from "react";
import { APIProvider, Map, Marker, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import axios from 'axios';
import { GoogleMapurl } from "../api/url";
import PlaceAutocomplete from "./addresssection/autocomplete";



const INITIAL_CAMERA = {
  center: { lat: 8.7274, lng: 77.6838 },
  zoom: 10
};
const googlemapkey = GoogleMapurl();

const MapHandler = ({ place, marker }) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !place || !marker) return;

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry.viewport);
    }

    marker.setPosition(place.geometry?.location);
  }, [map, place, marker]);

  return null;
};
function Locat() {
  const [cameraProps, setCameraProps] = useState(INITIAL_CAMERA);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [addressComponents, setAddressComponents] = useState({
    street: "",
    country: "",
    city: "",
    zipcode: "",
    state: "",
    latitude: "",
    longitude: ""
  });
  const [selectedPlace, setSelectedPlace] = useState(null);
  const markerRef = useRef(null);

  const handleMarkerClick = (event) => {
    if (event) {
      console.log(event);
      const clickedLatLng = {
        lat: event.detail.latLng.lat,
        lng: event.detail.latLng.lng
      };
      setCameraProps({ center: clickedLatLng, zoom: 15 });
      setMarkerPosition(clickedLatLng);
      fetchAddress(clickedLatLng);
    }
  };

  const fetchAddress = async (latLng) => {
    try {
      const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
          latlng: `${latLng.lat},${latLng.lng}`,
          key: googlemapkey
        }
      });

      if (response.data.results.length > 0) {
        const addressData = response.data.results[0];
        const components = addressData.address_components;

        let street = "";
        let country = "";
        let city = "";
        let zipcode = "";
        let state = "";

        components.forEach(component => {
          if (component.types.includes("route")) {
            street = component.long_name;
          }
          if (component.types.includes("country")) {
            country = component.long_name;
          }
          if (component.types.includes("locality")) {
            city = component.long_name;
          }
          if (component.types.includes("postal_code")) {
            zipcode = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
        });

        setAddressComponents({
          street,
          country,
          city,
          zipcode,
          state,
          latitude: latLng.lat,
          longitude: latLng.lng
        });
      } else {
        console.log("No address found for clicked location.");
      }
    } catch (error) {
      console.error("Error fetching geocode address:", error);
    }
  };

  const handleCurrentLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const currentLatLng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        setCameraProps({ center: currentLatLng, zoom: 15 });
        setMarkerPosition(currentLatLng);
        fetchAddress(currentLatLng);
      }, (error) => {
        console.error("Error getting current location:", error);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const handleCameraChange = (ev) => {
    setCameraProps(ev.detail);
  };

  const handlePlaceSelect = (place) => {
    setSelectedPlace(place);
    setCameraProps({ center: place.geometry.location, zoom: 15 });
    setMarkerPosition(place.geometry.location);
    fetchAddress({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    });
  };

  return (
    <div style={{ height: '80vh' }}>
      <APIProvider apiKey={googlemapkey} solutionChannel="GMP_devsite_samples_v3_rgmbasicmap">
        <Map {...cameraProps} onCameraChanged={handleCameraChange} gestureHandling="greedy" onClick={handleMarkerClick}>
          {markerPosition && <Marker position={markerPosition} onClick={handleMarkerClick} ref={markerRef} />}
        </Map>
        <MapHandler place={selectedPlace} marker={markerRef.current} />
      </APIProvider>

      <div className="custom-controls">
        <button onClick={handleCurrentLocationClick}>Current Location</button>
        {/* <input ref={inputRef} type="text" placeholder="Enter a location manually" /> */}
        <PlaceAutocomplete onPlaceSelect={handlePlaceSelect} />
        <h3>Address Details</h3>
        <p>Street: {addressComponents.street}</p>
        <p>City: {addressComponents.city}</p>
        <p>State: {addressComponents.state}</p>
        <p>Country: {addressComponents.country}</p>
        <p>Zipcode: {addressComponents.zipcode}</p>
        <p>Latitude: {addressComponents.latitude}</p>
        <p>Longitude: {addressComponents.longitude}</p>
      </div>
    </div>
  );
}

export default Locat;
