import React from "react";
import leftarrow from './images/arrow-left-01.png';
import Navbars from "./navbar";
import pencil from './images/edit-01.png';
import { useNavigate } from "react-router-dom";
function Personaldocument(){
  const navigate = useNavigate();
    const back = () =>{
      navigate('/documents');
    }
    const personaldocuments = () =>{
      navigate('/personaldocuments');
    }
    return(
        <>
        <Navbars/>
        <div className="container-fluid profilepage">
        <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="col-lg-6 col-md-8 col-sm-10">
            <div className="box  p-5 mt-5 border" style={{ backgroundColor: "white" ,minHeight:'314px',border:'0.5px solid #ABA7A7'}}>
              <div className="d-flex  align-items-center">
                <img onClick={back} src={leftarrow} alt="leftarrow" width='19.5px' height='15.5' className="mb-3 me-4"style={{cursor:'pointer'}}></img>
                <h5 className='mb-3'style={{fontWeight:'500',fontSize:'24px',verticalAlign:'cap-height',lineHeight:'36px'}}>Personal Documents</h5>
              </div>
              <div className="ps-5" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <p className=" text-start pt-4" style={{ color: '#7D7878',fontSize:'16px',fontWeight:'400',verticalAlign:'cap-height',lineHeight:'24px' }}>Personal Documents</p>
                  <img src={pencil} width="16px" height='16px' alt="pencil"  onClick={personaldocuments} style={{cursor:'pointer'}}></img>
                </div>
                <hr className="ms-5"/>
                <div className="ps-5" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <p className="text-start" style={{ color: '#7D7878',fontSize:'16px',fontWeight:'400',verticalAlign:'cap-height',lineHeight:'24px' }}>Vehicle Related Documents</p>
                  <img src={pencil} width="16px" height='16px' alt="pencil"  style={{cursor:'pointer'}}></img>
                </div>
                <hr className="ms-5"/>
                <div className="ps-5" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <p className="text-start" style={{ color: '#7D7878',fontSize:'16px',fontWeight:'400',verticalAlign:'cap-height',lineHeight:'24px' }}>Work Related Documents</p>
                  <img src={pencil} width="16px" height='16px' alt="pencil" style={{cursor:'pointer'}} ></img>
                </div>
              </div>
              </div>
              </div>
              </div>

        </>
    );
}
export default Personaldocument;