import React from "react";
import Navbars from "./navbar";
import Manualsidebar from "./manualsidebar";
import folderexport from "./images/folder-export-01.png";
import wallettowallet from "./images/wallet_to_wallet-01.png";
function Coupons(){
    return(
        <>
        <Navbars/>
         <div className="container-fluid " style={{ backgroundColor: '#F8F8F8', width: '100%', height: '100%' }}>
                <div className="row pt-4">
                    <div className="col-12 col-lg-3 col-md-4 col-xl-3 col-xxl-3 col_corre_2">
                        <Manualsidebar/>
                    </div>
                    <div className="col-12 col-lg-9 col-md-8 col-xl-9 col-xxl-9 col_corr_1" style={{ marginTop: '8.6%' }} >
                        <main className="mobile_height" style={{ backgroundColor: '#FFFFFF', width: '100%', boxShadow: "0px 0px 5px 0px #0000001A", height: '100%', }}>
                            <h5 className="ps-4 pt-4">Coupons</h5>
                            <p className="cardsp ps-4 mb-4">This is a system "D Transfer" that are awarded to the app user after engaging in some activity on the app, or website</p>
                            <div className="massage_box_corr mobile_maxheight ">
                                <div className="purchaserow22 purchase23 after-one">
                                    <h5 className="refundhistory ps-4 pt-3 ">All Transactions</h5>
                                        <div className="d-flex justify-content-between  p-4">
                                        <input type="search" className="serchinputbox" placeholder="Search"></input>
                                        <p className="coupontot">Total Coupon : 17</p>   
                                        <p className="coupontot">Total Value : UGX 3000</p>      
                                    <button className="export_pdf orange me-4"><img src={folderexport} className="me-2 mb-1" alt="folderexport" width="15px" height='14px'></img>Export to PDF</button>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                        <div className="table-responsive">
                                            <table className="table   nowrap custom-table">
                                                <thead style={{backgroundColor: '#FFEEDC'}}>
                                                    <tr style={{ backgroundColor: '#FFEEDC', border: 'none'}}>
                                                    <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000' }}></th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000',textAlign:'left' }}>Description</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000' }}>Date</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000' }}>Amount</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000' }}>Security Check</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000' }}>Status</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000' }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                  <p className="mar-25" style={{textAlign:'center'}}>Today</p>
                                                    <tr className="padtr">
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}><img src={wallettowallet} width='30px' height="30px"></img></td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >
                                                        <div style={{textAlign:'left'}}>
                                                        <p className="desctabname" style={{marginBottom:'0px'}}>Floyd Miles</p>
                                                        <p className="desctabgmail"  style={{marginBottom:'0px'}}>Floyd Miles@domain.com</p>
                                                        </div>
                                                        </td>
                                                        <td className="desctabdate">01 Sep,2023</td>
                                                        <td className="descamount">UGX 3000</td>
                                                        <td className="desctabcheck desctabcheckorange ">Passed</td>
                                                        <td className="desctabdate">Sent</td>
                                                        <td className="desctabsucc desctabcheckorange">Sent Success</td>
                                                    </tr>
                                                    <tr className="padtr">
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}><img src={wallettowallet} width='30px' height="30px"></img></td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >
                                                        <div style={{textAlign:'left'}}>
                                                        <p className="desctabname" style={{marginBottom:'0px'}}>Floyd Miles</p>
                                                        <p className="desctabgmail"  style={{marginBottom:'0px'}}>Floyd Miles@domain.com</p>
                                                        </div>
                                                        </td>
                                                        <td className="desctabdate">01 Sep,2023</td>
                                                        <td className="descamount">UGX 3000</td>
                                                        <td className="desctabcheck desctabcheckred ">blocked</td>
                                                        <td className="desctabdate">Cancelled</td>
                                                        <td ><button className="retrybut orange">Retry</button></td>
                                                    </tr>
                                                    <p className="mar-25" style={{textAlign:'right'}}>Yesterday</p>
                                                    <tr className="padtr">
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}><img src={wallettowallet} width='30px' height="30px"></img></td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >
                                                        <div style={{textAlign:'left'}}>
                                                        <p className="desctabname" style={{marginBottom:'0px'}}>Leslie Alexander</p>
                                                        <p className="desctabgmail"  style={{marginBottom:'0px'}}>lesliealexander@domain.com</p>
                                                        </div>
                                                        </td>
                                                        <td className="desctabdate">01 Sep,2023</td>
                                                        <td className="descamount">UGX 3000</td>
                                                        <td className="desctabcheck desctabcheckred ">blocked</td>
                                                        <td className="desctabdate">Failed</td>
                                                        <td className="desctabsucc desctabcheckred">Insufficient funds</td>
                                                    </tr>
                                                    <tr className="padtr">
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}><img src={wallettowallet} width='30px' height="30px"></img></td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >
                                                        {/* <div className="d-flex"> */}
                                                        {/* <img src={wallettowallet} className="me-2"  width='24px' height="24px"></img> */}
                                                        <div style={{textAlign:'left'}}>
                                                        <p className="desctabname" style={{marginBottom:'0px'}}>Dianne Russell</p>
                                                        <p className="desctabgmail"  style={{marginBottom:'0px'}}>diannerussell@domain.com</p>
                                                        {/* </div> */}
                                                        </div>
                                                        </td>
                                                        <td className="desctabdate">01 Sep,2023</td>
                                                        <td className="descamount">UGX 3000</td>
                                                        <td className="desctabcheck desctabcheckorange ">Checking</td>
                                                        <td className="desctabdate">Pending</td>
                                                        <td className="desctabsucc desctabfund">Sending Funds</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Coupons;